const formData = {
    "lockbox_access_code": {
        label: "Lockbox Access Code*",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "beneficiary": {
        label: "Beneficiary Account",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "schedule_inspection_start": {
        label: "Schedule Inspection Start*",
        iRaw: "",
        iVal: {
            value: "",
            isValid: true
        },
        message: "Message",
        error: "Error",
    },
    "schedule_inspection_end": {
        label: "Schedule Inspection Date*",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "agreement": {
        label: "",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    }
}

export { formData }