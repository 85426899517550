import React from 'react';
import './index.scss';

// import InputSelect from '../../../_forms/InputSelect';
// import InputText from '../../../_forms/_inputText';
// import InputDate from '../../../_forms/InputDate';
// import InputRadioVariable from '../../../_forms/_inputRadioVariable';

import InputSelect from '../../../../../../../../components/legacy/_forms/InputSelect';
import InputText from '../../../../../../../../components/legacy/_forms/_inputText';
import InputDate from '../../../../../../../../components/legacy/_forms/InputDate';
import InputRadioVariable from '../../../../../../../../components/legacy/_forms/_inputRadioVariable';

import NewBeneficiary from './_form_beneficiary';
import { Section } from '../../../../../../../../components/shared/Section';
import TransitionIn from '../../../../../../../../components/shared/TransitionIn';

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

// import ButtonSubmit from '../../../ButtonSubmit';
import ButtonSubmit from '../../../../../../../../components/legacy/ButtonSubmit';

const DrawRequestModal = (props) => {
    // console.log('draw modal => ', props)

    const getNextWork = d => {
        d.setDate(d.getDate() + 2); // tomorrow
        // console.log(d.getDay())
        if (d.getDay() == 0) d.setDate(d.getDate() + 1);
        else if (d.getDay() > 4) d.setDate(d.getDate() + 2);
        return d;
    }

    const onChangeDateStart = (e) => {
        const iv = e.target.value;
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = props.cloneFormData();

        // set the new state object property dynamically (original input and validated input for submission)
        fd[op].iRaw = iv;
        fd[op].iVal.value = iv;
        fd[op].label = fd[op].label
        fd[op].iVal.isValid = true;

        try {
            let nextWorkDay = getNextWork(new Date(iv)).toISOString().split("T")[0]

            fd["schedule_inspection_end"].iRaw = nextWorkDay
            fd["schedule_inspection_end"].iVal.value = nextWorkDay
            fd["schedule_inspection_end"].iVal.isValid = true
        } catch (ex) {
            fd["schedule_inspection_end"].iRaw = null
            fd["schedule_inspection_end"].iVal.value = null
            fd["schedule_inspection_end"].iVal.isValid = false
            document.getElementById('schedule_inspection_end').value = undefined
        }

        props.setFormData(fd)

    }

    const heading = "Draw Submission";
    const body = (
        <>
            <p>Note: Inspection will be conducted during the date range below.</p>
            <fieldset className="input-field-group draw-modal row grow-2">
                <div className="column">
                    <InputDate  {...props} fd_key="schedule_inspection_start" inputDate={onChangeDateStart} label="Inspection Date Start" />
                </div>
                <div className="column">
                    <InputDate  {...props} fd_key="schedule_inspection_end" inputDate={null} label="Inspection Date End" />
                </div>
                <div className="column">
                    <InputText {...props} fullwidth={false} fd_key="lockbox_access_code" maxLength="6" inputText={props.inputText} label="Lockbox Access Code" />
                </div>
                <div className="column">
                    <InputSelect inputSelect={props.inputSelect} {...props} showChoose={true} fd_key="beneficiary" config={props.beneficiaries} label="Select Bank" />
                </div>


                <div style={{ display: "flex", alignItems: "center" }}>
                    <button type="button" className="btn-submit green" onClick={props.toggleBeneficiaryForm}>Add New Account</button>
                    {props.beneficiaries.length === 0 ? <p style={{ color: "rgb(212, 49, 30)", fontWeight: "bold", margin: "0 1rem" }}>No beneficiary exists - please Add New Account</p> : ""}
                </div>

                <p>Confirm: I have read and verified that every field in this form is accurate and that the funds will be wired to the account currently associated with this property.</p>

                <div id="draw-modal-confirm">
                <InputRadioVariable fullwidth={true} {...props} fd_key="agreement" inputRadio={props.inputRadio} config={[{ value: true, label: "I/We Agree" }]} />
                </div>
            </fieldset>


            <div className="submission-wrapper">
                <ButtonSubmit callBack={props.handleSubmit} readOnly={props.submission} />

                <div className={`form-error ${props.formError ? 'is-active' : ''}`}>
                    An error has occured, please try again later.
                </div>
            </div></>
    );

    return (
        <div className="draw-request-modal">
            {props.showBeneficiaryForm ?
                <NewBeneficiary toggleBeneficiaryForm={props.toggleBeneficiaryForm} updateBeneficiaries={props.updateBeneficiaries} />
                :
                <div className="draw-request-wrapper">

                    <form className="form draw-request" onSubmit={props.handleSubmit} noValidate>

                        <div className="form-body">
                            <Section heading={heading} body={body} />
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}

export default DrawRequestModal