// ----- v SUMMARY v ------------------------------------------------------------------------
// - ActionItem child component for Third Party info links. 

// ----- v DESCRIPTION v --------------------------------------------------------------------
// - Conditionally renders a list of links for loans that do not have Third Party info filled.

// - Includes logic necessary to filter the list of links based on the Deal Status and Third 
//   Party Info fields.

// - "loansFilterStatus" filters loans to exclude any with the status "Funded" or "Dead Deal"
// - "loansFilterThirdParty" filters loans to exlude any with blank email in Third Party fields.
// - "loansEvaluate" calls both of the above functions 

// ----- v CRITICAL NOTES v -----------------------------------------------------------------
// - using Memoization may help improve performance as this gets re-rendered due to other 
//   unrelated props changes.

// ----- v IMPORTS v ------------------------------------------------------------------------

import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export default function ActionItemThirdPartyInfo(props) {

    // per specs - only need to check Loans In Process
    const loans = props.loans;

    // filter Loans in Process by status !== "Funded" or "Dead Deal"
    function loansFilterStatus(arr) {
        const loansFiltered = arr.filter(x => !(x.status === "Funded" || x.status === "Dead Deal"));
        return loansFiltered;
    };


    // filter loans by Third Party fields (remove if email field blank - need confirmation of logic)
    function loansFilterThirdParty(arr) {

        const loansFiltered = arr.filter(x => {
            if (x?.third_party) {
                // loan-level evaluation boolean for inclusion in filtered array
                // any field that is blank will flip to true and include loan
                let include = false;

                // convert the "third_party" property to an array (Insurance, Title, Property Access)
                const loanThirdPartyInfo = Object.entries(x.third_party);

                // for each Third Party Info array, loop through properties (Insurance, Title, Property Access) and....
                loanThirdPartyInfo.forEach(x => {
                    if (x[1].email === "") {
                        include = true;  // if email is blank, throw flag (include loan)
                    }
                });

                // evaluate the filter condition - print includes loan in new array
                if (include) {
                    return x;
                }
            }
        });

        // return the filtered array to caller
        return loansFiltered;
    }

    // function to call all filtering/sorting sub functions
    function loansEvaluate(arr) {

        if (arr.length === 0) {
            // return original array unaltered
            return arr;

        } else {

            // filter loans (passing Status into 3PI) and return new array
            return loansFilterThirdParty(loansFilterStatus(arr))
        }
    }

    // ----- v RENDER v -------------------------------------------------------------------------

    // apply filters 
    const rows = loansEvaluate(loans).map(x => {
        return (
            <tr>
                <td>
                    <span>
                        {x.nickname || "-"}
                    </span>
                </td>
                <td>
                    <span>
                        {x.address || "-"}
                    </span>
                </td>
                <td>
                    <Link to={`/dashboard/deal/${x.id}`} data-tip="Click here to update the Third Party information for this deal." data-for="app-tooltip">
                        Third Party Info
                    </Link>
                </td>
                {ReactTooltip.rebuild()}
            </tr>
        )
    })

    return rows
}