import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <section className="section broker-terms-conditions">
                {/* <h4>Licensing</h4>
                <p>Lima One Capital, LLC. NMLS ID # 1324403, 201 E. McBee Ave. Suite 300. Greenville, SC 29601. Lima One Capital, LLC is not currently licensed in AK, ND, NV, SD, or VT. Lima One Capital, LLC is licensed or exempt from licensing in all other states. Lima One Capital, LLC is licensed as a California Finance Lender under Department of Business Oversight license number 60DBO-45834.</p>
                <p>Lima One Capital, LLC (License No. 949706) is licensed in Arizona as a Mortgage Banker.</p>
                <p>Lima One Capital, LLC (License No. ML-5397) is licensed in Oregon as a Mortgage Lender.</p>
                <p>Florida Mortgage Lender and Servicer License (License No. MLD1662)</p>
                <p>Minnesota: This is not an offer to enter into an agreement. Any such offer may only be made in accordance with the requirements of Minn. Stat. §47.206(3), (4).</p>
                <p>Lima One Capital, LLC (License No. MN-MO-1324403) is licensed in Minnesota as a Mortgage Originator</p>
                <p>Lima One Capital, LLC (License No. MBL-2081324403) is licensed in Idaho as a Mortgage Broker/Lender.</p>
                <p>Lima One Capital, LLC (License No. 11755492) is licensed in Utah as a Mortgage Entity.</p>

                <h4>Patriot Act</h4>
                <p>Section 326 of the USA PATRIOT ACT requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account or changes an existing account. This federal requirement applies to all new customers and current customers. This information is used to assist the United States government in the fight against the funding of terrorism and money-laundering activities.</p>
            */}
                <p>To ensure compliance with the European Union’s GDPR legislation, Lima One Capital wants to detail to our clients how their data is collected and how it is used.</p>
                <h4>How is data collected?</h4>
                <p>Data is collected any time a form is submitted such as subscribing to our blog, newsletter, or an information request.  Information that is collected includes your First Name, Last Name, Email Address, Phone Number, Country, and information about your investing strategies.</p>
                <p>This data is used to provide you with company updates, allow our sales team to contact you about your requests, and help us to understand how we can better serve you.</p>
                <h4>Does Lima One Capital use Cookies?</h4>
                <p>Yes.  Lima One Capital uses cookies, which enables us to track a user’s behavior on our website.  This helps Lima One Capital to better understand how customers interact with our website and what areas of the site we can improve upon.  It also allows us to track the number of visitors to the site and overall site performance.</p>
            </section>
        </div>
    )
}

export default PrivacyPolicy