import React from 'react'
import InputText from "../../_components/InputText";
import InputDate from "../../_components/InputDate";
import {checkFileSize} from "../../_api";
import {fileTypes} from "./index";

const Refinancing = React.forwardRef(({errors, submitted, success, fileInputStyle, withLO, fileTypes}, ref) => {


    return (
        <React.Fragment>
            <h2>If you are refinancing with {withLO ? "Lima One" : "another lender"}, please fill out the following</h2>

            <fieldset className="input-field-group gaps">
                {!withLO && <InputText ref={ref} type={'text'} label={'Please provide the lenders contact information*'} name={'lenders_contact_information'} errors={errors} />}
                {withLO && <InputText ref={ref} type={'text'} label={'Please provide the name of the sales representative you are refinancing with.*'} name={'lenders_contact_information'} errors={errors} />}
                <InputText ref={ref} type={'text'} label={'Have you applied to refinance as of today? If no, what is causing the delay?*'} name={'applied_to_refinance'} errors={errors} />
                    <InputDate ref={ref} type={'date'} label={'When is the expected closing date?*'} name={'expected_closing_date'} errors={errors} />
                    {/*{withLO &&*/}
                    {/*    <div style={{ display: 'flex', width: '100%', top: '-0.5em', paddingBottom: '1.5em', alignItems: 'center' }}>*/}
                    {/*        Please provide a copy of the Seller's Agreement:<br />*/}
                    {/*        <input ref={ref} type="file" name={"seller_agreement_letter"} style={{...fileInputStyle, left: '1rem' }} multiple />*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {!withLO &&
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '20px' }} className={errors?.approval_commitment_letter ? "fileError" : ""}>
                            <div style={{ width: '440px' }}><label htmlFor={"approval_commitment_letter"}>Please provide a Pre-Approval Letter or Commitment Letter*:</label></div>
                            <input ref={ref} type="file" accept={fileTypes} name={"approval_commitment_letter"} style={{...fileInputStyle, left: '1rem' }} onChange={checkFileSize} />
                        </div>
                    }
            </fieldset>


         </React.Fragment>
    )
})

export default Refinancing