import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default function Card(props) {

    const title = () => {
        if ( props.rep?.name && props.rep?.title ) {
            return props.rep?.name + " | " + props.rep?.title
        } else if ( props.rep?.name && !props.rep?.title ) {
            return props.rep?.name;
        } else if ( !props.rep?.name && props.rep?.title ) {
            return props.rep?.title;
        } else return "Lima One Capital"
    }
    const email = props.rep?.email || "hello@limaone.com";
    const phone = props.rep?.phone || "800.390.4212";
    const image =  props.rep?.image || "";

    return (
        <div className="card">
            <div className="card-inner">
                <div className="card-image">
                <img src={image} alt="" />
                    <FontAwesomeIcon icon={faUser} />
                </div>

                <div className="card-text">
                    <span>
                        {title()}
                    </span>
                    <a href={`mailto:${email}`} target="_blank">
                        {email}
                    </a>
                    <a href={`tel:${phone.replace(/\D/g,'')}`} target="_blank">
                        {phone}
                    </a>
                </div>
            </div>
        </div>
    )
}