import React from 'react';
import axios from 'axios';
import ButtonSubmit from '../../../components/shared/ButtonSubmit'

import './index.scss';


import './index.scss';

class EnterEmail extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      submitted: false,
      email: null,
      respCode: null,
      errors: {
        email: '',
        emailval: ''
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({submitted: true});

    if (this.validateForm()) {
      let username = document.querySelector(".email-input");

      axios.post('/lima/api/users/gettoken', {
        "email": username.value,
        "sendEmail": true
      })
      .then(function (response) {
        document.querySelector('#success-msg').style.display = 'block';
        document.querySelector('.enteremail-form').style.display = 'none';
        console.log(response.data.code)
        if (response.data.code) {
          this.setState({ respCode: response.data.code })
        }
      }.bind(this))
      .catch(function (error) {
        this.setState({submitted: false});
        document.querySelector('#error-msg').style.display = 'block';
        document.querySelector('.enteremail-form').style.display = 'none';
        console.log(JSON.stringify(error));
      }.bind(this));
    } else {
      this.setState({submitted: false});
      console.error('Invalid Form')
    }
  }

  validateForm = () => {
    let errors = this.state.errors;
    let emailval = document.querySelector(".email-input");
    
    let valid = 0;
  
    if (emailval.value === "") {
      errors.email = "Email is required";
      valid = 1;
    } else {
      errors.email = "";
    }
    
    //Set any feild to true to kick off errors
    if (valid === 1) {
      this.setState({errors, email: true});
      return false;
    } else {
      this.setState({errors, email: null});
      return true;
    }
  }

  render() {
    const {errors, submitted} = this.state;
    console.log(this.state.respCode)
    return (
      <section>
        <h1>Password Assistance</h1>
        <form id="enteremail" onSubmit={this.handleSubmit} className="enteremail-form" style={{ order: '2' }} noValidate>
        <label htmlFor="input__email" className={errors.email.length > 0 && {} ? 'error' : null } data-content="Email Address*">
              Email Address*
            </label>
            <input id="input__email" className={'email-input'} name="email" type="email" placeholder="email@address.com" />
           
            {/* {errors.email.length > 0 && <span className="erro-mess">{errors.email}</span>} */}
          
          {/* <button type="submit" name="submit" className="submit">EMAIL RESET PASSWORD LINK</button> */}
          <ButtonSubmit 
          variant={"rounded"}
                readOnly={submitted} 
                callback={this.handleSubmit}
                value={"Email Reset Password Link"} />
        </form>
        <div className="password-reset-message" id="success-msg" style={{ order: '5'}}>
        
          {this.state.respCode === 1 ?
          <React.Fragment>
              <h3>Account Not Found...</h3>
              <br />
              <h3>Please go <a href="/signup">here</a> to signup for an account.</h3>
          </React.Fragment>
          : this.state.respCode === 2 ?
          <React.Fragment>
            <h3>Please go <a href="/">here</a> to login.</h3>
          </React.Fragment>
          : this.state.respCode === 3 ?
          <React.Fragment>
            <h3>
              We see that you already have a profile with us.<br />
              We need to set a new login and password for you by entering your email address at the page <a href="/create-account">here</a>.
            </h3>
          </React.Fragment>
          : 
          <React.Fragment>
            <p style={{ width: '100%' }}>Thank you!  Please check your inbox for a link to reset your password.</p>
            <p style={{ width: '100%' }}>If you didn't receive this email, check your spam folder or contact us at <a href="tel:8003904212">(800) 390-4212</a> for more assistance.</p>
            <p style={{ width: '100%' }}>This link is only valid for 24 hours.</p>
          </React.Fragment>
        }
          
        </div>
        <div className="password-reset-message" id="error-msg" style={{ order: '5'}}>
          <p style={{ width: '100%' }}>There was a problem sending the password reset email.</p>
          <p style={{ width: '100%' }}>Please contact support at <a href="tel:8003904212">(800) 390-4212</a> for more assistance.</p>
        </div>
      </section>
    )
  }
}

export default EnterEmail;