import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form"
import axios from 'axios'

import states from '../../../assets/config/_us_states.json';
import countries from '../../../assets/config/_countries.json';

import './index.scss'
import TermsModal from '../_components/terms';
import SubmitApplication from '../_components/SubmitApplication';
import InputText from "../_components/InputText";
import InputSelect from "../_components/InputSelect";
import InputDate from "../_components/InputDate";
import InputRadioBinary from "../_components/InputRadioBinary";
import { encodeJson } from '../_api';
import Swal from "sweetalert2";
import InputTextMasked from "../_components/InputTextMasked";

const AdditionalGuarantorForm = props => {

    const [submitted, setSubmitted] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [borrowingEntityName, setBorrowingEntityName] = useState(null)
    const [borrowingEntityID, setBorrowingEntityID] = useState(null)
    const [responseMessage, setResponseMessage] = useState(null)
    const { register, handleSubmit, errors, setValue, control } = useForm()
    const [terms, setTerms] = useState(false)
    let params = props.location.state ? props.location.state : props.match.params;

    const onSubmit = async formData => {
        setSubmitted(true)

        let payload = {
            "borrowing_entity_id": borrowingEntityID
        }

        for (const [key, value] of Object.entries(formData)) {
            payload[key] = value.replace(/,/g, "").replace(/\$/g, "").replace(/-/g, '')
        }

        payload = {...payload, 'invitee_id': params.invitee_id}

        var date_of_birth = formData['date_of_birth'].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');

        payload = {...payload, 'date_of_birth': date_of_birth }

        axios.defaults.headers['Content-Type'] = "application/json";
        axios.post(process.env.REACT_APP_LIMA + `/lima/api/submit-additional-guarantor-app`, encodeJson(payload))
        .then((data) => {
            setSubmitted(false)
           if (data.data.message) {
               if (data.data.code == 1) {
                   window.location.href = "/account-setup/" + data.data.token
               } else {
                   window.location.href = "/thankyou/additional-guarantor"
               }
           } else {
                window.location.href = "/thankyou/additional-guarantor"
           }
        })
        .catch((error) => {
            setSubmitted(false)
            console.log(error.response)
            if(error.response && error.response.data.error) {
                setResponseMessage(error.response.data.error)
                Swal.fire({
                  icon: 'error',
                  title: 'An error occurred',
                  text: error.response.data.error
                })
            }
        });
    }

    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            axios.defaults.headers['Content-Type'] = "application/json";
            axios.post(process.env.REACT_APP_LIMA + `/lima/api/get-invitee-record`, { "id": params.invitee_id })
            .then((data) => {
                setBorrowingEntityName(data.data.borrowing_entity_name)
                setBorrowingEntityID(data.data.borrowing_entity_id)
                setValue([
                    { first_name: data.data.first_name }, 
                    { last_name: data.data.last_name },
                    { email: data.data.email },
                    { phone_number: formatNumber(data.data.phone) }])
            })
            .catch((error) => {
                console.log(error.response)
            });
        }

        if (document.getElementsByClassName('layout_base')[0]) {
            document.getElementsByClassName('layout_base')[0].style.margin = "0 auto"
            document.getElementsByClassName('layout_base')[0].style.background = "#0c224B"
            if (document.getElementsByClassName('inner').length > 0) {
                for(var i = 0; i < document.getElementsByClassName('inner').length; i++) {
                    document.getElementsByClassName('inner')[i].style.background = "#0c224B"
                }
            }
        }
    });

    const inputCurrency = e => {
        var v = e.target.value.replace(/,/g, "").replace(/\$/g, ""), dollar = '0', cents = '00';
        if (v.indexOf('.') !== -1) {
          var price = v.split('.');
          dollar = price[0] || '0';
          cents = price[1] || '00';
        }
        if (cents.length === 1) {
          if (dollar) {
            var dollarNumbers = dollar.split('');
            var dollarLength = dollarNumbers.length;
            cents = dollarNumbers[dollarLength-1]+cents;
            dollar = '';
            for (var i = 0; i < dollarLength-1 ; i++) {
              dollar += dollarNumbers[i];
            }
            if (!dollar) {dollar = '0';}
          }
        }
        if (v.length === 1) {
          cents = '0'+v;
        }
        if (cents.length === 3) {
          var centNumbers = cents.split('');
          dollar = dollar === '0' ? centNumbers[0] : dollar+centNumbers[0];
          cents = centNumbers[1] + centNumbers[2];
        }
        e.target.value = "$" + dollar.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + cents;
        calcTotal('total_non-liquid_assets', ['non_liquid_real_estate', 'other_personal_property', 'autos'])
        calcTotal('total_liquid_assets', ['cash', 'retirement_accounts', 'stocks_bonds', 'other_liquid_assets'])
        calcTotal('debt_total', ['debt_real_estate', 'debt_revolving', 'debt_installment', 'debt_other', 'debt_notes_payable'])

        let total_non_liquid_assets = parseFloat(document.getElementById('total_non-liquid_assets').value.replace(/,/g, "").replace(/\$/g, ""))
        let total_liquid_assets = parseFloat(document.getElementById('total_liquid_assets').value.replace(/,/g, "").replace(/\$/g, ""))
        let total_debt = parseFloat(document.getElementById('debt_total').value.replace(/,/g, "").replace(/\$/g, ""))
        let net_worth = (total_liquid_assets + total_non_liquid_assets - total_debt).toFixed(2)
        
        setValue([ { net_worth: "$" + net_worth.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + (net_worth.toString().split(".")[1] == undefined ? "00" : net_worth.toString().split(".")[1].substring(0, 2)) }])
    }

    const inputZip = e => {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        if ((iv.match(/^[0-9.,$]+$/) || iv === "") && iv.length < 6) {
            e.target.value = iv
        } else {
            e.target.value = e.target.value.substring(0, 5)
        }
    }

    const inputSSN = e => {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')

        if (iv.match(/^[0-9.,-]+$/) || iv === "") {
            // remove dashes
            iv = iv.replace(/-/g, '');

            if (iv.length < 10) {
                // remove white spaces
                iv = iv.trim();

                // insert dashes into string for formatted value (fv)
                var fv = iv.toString().replace(/\D/g, '');
                if (fv.length > 3)
                fv = fv.replace(/^(\d{3})/, '$1-');
                if (fv.length > 6)
                fv = fv.replace(/-(\d{2})/, '-$1-');
                if (fv.length > 7)
                fv = fv.replace(/(\d)-(\d{4}).*/, '$1-$2');

                e.target.value = fv
            } else {
                e.target.value = e.target.value.substring(0, 11)
            }
        }
    }

    const formatNumber = (iv) => {
        iv = iv.replace(/[\[\]&]+/g, '');
        // remove dashes
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        iv = iv.trim()

        if (iv.length < 11) {

            var fv = iv
            if (fv.length > 3)
            fv = fv.replace(/^(\d{3})/, '$1-');
            if (fv.length > 7)
            fv = fv.replace(/-(\d{3})/, '-$1-');
            
            return fv
        } else {
            return iv.substring(0, 12)
        }
    }

    const inputPhone = e => {
        e.target.value = formatNumber(e.target.value)
    }

    const inputCreditScore = e => {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        // remove dashes
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        iv = iv.trim()
        if (!iv) {
            iv = "0"
        }

        if (iv.length < 4) {
            if (parseInt(iv) > 0 && parseInt(iv) < 851) {
                e.target.value = parseInt(iv)
            } else {
                e.target.value = e.target.value.substring(0, 2)
            }
        } else {
            e.target.value = e.target.value.substring(0, 3)
        }
    }

    const calcTotal = (totalId, fieldsArray) => {
        var total = 0
        fieldsArray.forEach(field => {
            total += document.getElementById(field).value ? parseFloat(document.getElementById(field).value.replace(/,/g, "").replace(/\$/g, "")) : 0
        })

        let total_liquid_assets = document.getElementById(totalId)

        total_liquid_assets.value = "$" + total.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + (total.toString().split(".")[1] == undefined ? "00" : total.toString().split(".")[1].substring(0, 2))
    }
    
    const termsToggle = e => {
        e.preventDefault();
        e.stopPropagation();
    
        // prevent modal toggling from clicks in the body
        if (e.target.classList[0] === "terms-inner") {
            return;
        } else {
    
            // parent component will need a terms property in state (bind in parent)
            // pass this boolean value as a prop to the modal
        } 
        
        setTerms(!terms)
    }

    let tabIndex = 0

    return (
        <main className="form loc-borrower additional-guarantor-form blue-bg" id="form" >
            <section className="section">
                <div className="section_heading">
                    <h1>New Entity Member Application</h1>
                    {borrowingEntityName &&
                    <h5>You are filling out this application as an additional guarantor for the <span style={{fontWeight: 'bold'}}>{borrowingEntityName}</span> borrowing entity.</h5>
                    }
                </div>
            </section>

            <form className={`form-body ${errors.length > 0 ? 'form-error' : ''}`} onSubmit={handleSubmit(onSubmit)} >

                <div className="additional-guarantor-form blue-bg">
                    <div className="row">

                        <div className="column">
                            <InputText ref={register({ required: true })} type={'text'} label={'First Name*'} name={'first_name'} id={'first_name'} tabIndex={tabIndex++} errors={errors} />
                        </div>
                        
                        <div className="column">
                            <InputText ref={register({ required: true })} type={'text'} label={'Last Name*'} name={'last_name'} id={'last_name'} tabIndex={tabIndex++} errors={errors} />
                        </div>

                    </div>

                    <div className="row">
                        <div className="column">
                            <InputText ref={register({ required: true })} type={'text'} label={'Street Address*'} name={'street_address'} tabIndex={tabIndex++} errors={errors} />
                        </div>

                        <div className="column">
                            <InputText ref={register({ required: true })} type={'text'} label={'City*'} name={'city'} tabIndex={tabIndex++} errors={errors} />
                        </div>

                        <div className="column">
                            <InputSelect ref={register({ required: true })} config={states} useName={true} label={'State*'} name={'state'} tabIndex={tabIndex++} errors={errors} />
                        </div>

                        <div className="column">
                            <InputText ref={register({ required: true })} onChange={inputZip} type={'text'} label={'Zip*'} name={'zip'} placeholder={"XXXXX"} tabIndex={tabIndex++} errors={errors} />
                        </div>

                    </div>

                    <div className="row">
                        <div className="column">
                            <InputText ref={register({ required: true })} onChange={inputSSN} type={'text'} label={'Social Security Number*'} name={'ssn'} placeholder={"XXX-XX-XXXX"} tabIndex={tabIndex++} errors={errors} />
                        </div>

                        <div className="column">
                            <InputDate ref={register({ required: true })} label={'Date of Birth*'} name={'date_of_birth'} tabIndex={tabIndex++} errors={errors} />
                        </div>

                        <div className="column">
                            <InputTextMasked ref={register({ required: true })} control={control} label={'Phone Number*'} errors={errors} mask="999-999-9999" maskChar=" " type="tel" name="phone_number" placeholder="XXX-XXX-XXXX" tabIndex={tabIndex++} />
                        </div>

                        <div className="column">
                            <InputText ref={register({ required: true })} type={'email'} label={'Email*'} name={'email'} id={'email'} tabIndex={tabIndex++} errors={errors} readOnly={true} />
                        </div>

                        <div className="column">
                            <InputSelect ref={register({ required: true })} config={countries} label={'Country of Citizenship*'} name={'country_of_citizenship'} tabIndex={tabIndex++} errors={errors} />
                        </div>

                        <div className="column">
                            <InputText ref={register({ required: true })} onChange={inputCreditScore} type={'text'} label={'Estimated Credit Score*'} name={'estimated_credit_score'} placeholder={"XXX"} tabIndex={tabIndex++} errors={errors} />
                        </div>
                    </div>

                    <div className="row input-radio-fields" style={{ paddingTop: '1rem' }}>
                        <fieldset className={"input-field-group boxed"}>
                            <table>
                                <tr>
                                    <td>
                                        <InputRadioBinary ref={register({ required: true })} fullwidth={false} tabIndex={tabIndex++} errors={errors}
                                                          name={"convicted_of_currently_accused_of_a_felony"}
                                                          label="Convicted of or currently accused of a felony, or any crime involving fraud, financial malfeasance, or misrepresentation?*"
                                        />
                                    </td>
                                    <td>
                                        <InputRadioBinary ref={register({ required: true })} fullwidth={false} tabIndex={tabIndex++} errors={errors}
                                                          name={"have_any_outstanding_judgements"}
                                                          label="Have any outstanding judgments?*"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <InputRadioBinary ref={register({ required: true })} fullwidth={false} tabIndex={tabIndex++} errors={errors}
                                                          name={"had_a_foreclosure"}
                                                          label="Had a foreclosure or given title or deed in lieu of foreclosure, or a short sale, in the past 3 years?*"
                                        />
                                    </td>
                                    <td>
                                        <InputRadioBinary ref={register({ required: true })} fullwidth={false} tabIndex={tabIndex++} errors={errors}
                                                          name={"party_to_any_outstanding_lawsuits"}
                                                          label="Party to any outstanding lawsuits?*"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <InputRadioBinary ref={register({ required: true })} fullwidth={false} tabIndex={tabIndex++} errors={errors}
                                                          name={"declared_bankruptcy"}
                                                          label="Declared bankruptcy in the past 4 years, or actively involved in a bankruptcy?*"
                                        />
                                    </td>
                                    <td>
                                        <InputRadioBinary ref={register({ required: true })} fullwidth={false} tabIndex={tabIndex++} errors={errors}
                                                          name={"presently_delinquent"}
                                                          label="Presently delinquent on any mortgage loan of other financial obligation?*"
                                        />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                    </div>

                    <div className="section_heading" style={{ paddingTop: '2rem' }}>
                        <h1>Financial Information</h1>
                        
                        <h2>Liquid Assets</h2>
                        <div className="row">
                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Cash'} name={'liquid_assets_cash'} id={'cash'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Retirement Accounts'} name={'retirement_accounts'} id={'retirement_accounts'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Stocks & Bonds'} name={'stocks_bonds'} id={'stocks_bonds'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Other Liquid Assets'} name={'other_liquid_assets'} id={'other_liquid_assets'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} type={'text'} label={'Total Liquid Assets'} name={'total_liquid_assets'} placeholder={"$0.00"} id={'total_liquid_assets'} tabIndex={tabIndex++} errors={errors} readOnly={true} />
                            </div>
                        </div>

                        <h2>Non-Liquid Assets</h2>
                        <div className="row">
                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Non-Liquid Real Estate Assets'} name={'non-liquid_real_estate_assets'} id={'non_liquid_real_estate'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Other Personal Property'} name={'other_personal_property'} id={'other_personal_property'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Autos'} name={'autos'} id={'autos'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} type={'text'} label={'Total Non-Liquid Assets'} name={'total_non-liquid_assets'} id={'total_non-liquid_assets'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} readOnly={true} />
                            </div>
                        </div>

                        <h2>Debt</h2>
                        <div className="row">
                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Real Estate Debt'} name={'real_estate_debt'} id={'debt_real_estate'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Revolving Debt'} name={'revolving_debt'} id={'debt_revolving'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Installment Debt'} name={'installment_debt'} id={'debt_installment'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Other Debt'} name={'other_debt'} id={'debt_other'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} onChange={inputCurrency} type={'text'} label={'Notes Payable'} name={'notes_payable'} id={'debt_notes_payable'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: false })} type={'text'} label={'Total Debt'} name={'total_debt'} id={'debt_total'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} readOnly={true} />
                            </div>

                            <br />

                            <div className="column">
                                <InputText ref={register({ required: false })} type={'text'} label={'Net Worth'} name={'net_worth'} id={'net_worth'} placeholder={"$0.00"} tabIndex={tabIndex++} errors={errors} readOnly={true} />
                            </div>
                        </div>
                    </div>

                </div>

                <SubmitApplication
                    submitted={submitted}
                    errorMsg={responseMessage}
                    errors={errors}
                    termsToggle={termsToggle}
                    ref={register({ required: true })}
                />
            </form>

             {/* Terms Modal */}
             <section className="terms-conditions-wrapper">
                <TermsModal terms={terms} termsToggle={termsToggle} />
            </section>
        </main>
    )

}

export default withRouter(AdditionalGuarantorForm)