import React, {useCallback, useEffect, useState} from 'react';
import './index.scss';
import { Section } from '../../../App/components/shared/Section';

const SelectFormType = React.forwardRef((props, ref) => {
    const heading = (
        "Property Information"
    )

    const body = (
        <fieldset className="input-field-group select-form-type" id={"select-form-type"}>
            <div className={`input-field full-width ${'Portfolio_Type__c' in props.errors && "error"}`}>
                Is this application for a single property or a portfolio of properties?
                <ul className={"radio-field"}>
                    <li>
                        <label htmlFor="single_property"><input ref={ref} name="Portfolio_Type__c" id="single_property" type="radio" value="Single Property" tabIndex={props.tabIndex} />Single Property</label>
                    </li>
                    <li>
                        <label htmlFor="portfolio"><input ref={ref} name="Portfolio_Type__c" id="portfolio" type="radio" value="Portfolio" tabIndex={props.tabIndex}  />
                        Portfolio</label>
                    </li>
                </ul>
                {'Portfolio_Type__c' in props.errors && <span className="error-message">Please make a selection.</span>}
            </div>
        </fieldset>
    )
    
    return (
        <Section heading={heading} body={body} id={"form-select-type"} max={20} />
    )
})

export default React.memo(SelectFormType)