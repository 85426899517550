import React from 'react'
import InputWrapper from './InputWrapper'

const InputTextField = React.forwardRef((props, ref) => {
    return (
        <InputWrapper {...props}>
            <textarea ref={ref} name={props.name} tabIndex={props.tabIndex} placeholder={props.placeholder} readOnly={props.readOnly} data-cleanup={props.cleanup}></textarea>
        </InputWrapper>
    )
})

export default React.memo(InputTextField)