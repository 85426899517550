// ----- v SUMMARY v ------------------------------------------------------------------------ 

// ----- v DESCRIPTION v --------------------------------------------------------------------

// ----- v PROPS v --------------------------------------------------------------------------

// ----- v STATE v --------------------------------------------------------------------------

// ----- v RENDER v -------------------------------------------------------------------------

// ----- v CRITICAL NOTES v -----------------------------------------------------------------

// ----- v IMPORTS v ------------------------------------------------------------------------

// - react imports
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// - utility imports (internal)
import { filterData, sortData, generateId } from '../../../../utility';
import utilityData from '../../../../utility/data';
import animations from '../../../../animations';

// - utility imports (3rd party packages)
import axios from 'axios';
import { useSpring, animated } from '@react-spring/web';

// - component imports
import CopyTable from '../../../../components/shared/CopyTable';
import { SectionExpandable } from '../../../../components/shared/Section';
import SkeletonTable from '../SkeletonTable';

// - style imports
import '../_css/sections.scss'; // - section styles for dashboard (includes controls) - extract to higher level with table if possible!
import './index.scss'; // - explicit styles


// ------------------------------------------------------------------------------------------

const LoansInProcess = (props) => {

    // - state variables (handle API fetch and res)
    const [data, setData] = useState([]); // - initial data load - immutable - cleaned up and saved for reference/default data
    const [dataFiltered, setDataFiltered] = useState([]) // - filtered data - mutable - cleaned up and filterable 

    const [dataLoaded, setDataLoaded] = useState(null); // - api success (null == pending, true == success, false = failure/error)
    const [dataError, setDataError] = useState("Unspecified error."); // - api res error message (if applied - default provided)

    // - SectionExpandable and CopyTable (linked together) variables
    const id = 'dashboard_loans-in-process'; // - id for css styling targets (passed to SectionExpandable)
    const tableRef = useRef(); // - ref to table (body) for copying (utilize <CopyTable ref={tableRef} in SectionExpandable controls prop element)

    // - base url for table links to deals
    const link = process.env.REACT_APP_SESSION_URL + '/deal/';

    // - table body load animation (match table expand - only fires once when data loads intially - subsequent expand/collapse in child
    const styleLoaded = useSpring(animations("fadeIn", dataLoaded));

    // - controls handler to search/filter data (sorting is already done on load and should not need to be called here - add only if necessary!)
    function handleSearch(event) {
        if (event.target.value === "") {
            setDataFiltered(data)
        } else {
            setDataFiltered(filterData(data, event.target.value))
        }
    };

    // --------------------------------------------------------------------------------------
    // - fetch data - Loans In Process - should be moved higher up and passed as prop (from 
    //   main App component) - remember to pass down to any error handler for reloading (error 
    //   var element in this case)

    const fetchData = async () => {

        // - reset all API-related state variables for refresh after load
        if (dataLoaded === false) {
            setDataLoaded(null);
            setData([]);
            setDataFiltered([]);
        }

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");

        try {
            let res = await axios.get(props.accountType === 'CustomerAccount' ? '/lima/api/deals/inprocess' : '/lima/api/deals/broker/inprocess');

            // remove any deals without an id property (deals without id should not occur in prod)
            const data = utilityData.removeByValue(res.data.deals, "id", "");

            // additional utility helpers for data cleanup (format currency, date, and empty fields)
            utilityData.formatCurrency(data, ['amount']);
            utilityData.formatDate(data, ['maturityDate', 'loanSignDate', 'nextPaymentDate']);
            utilityData.formatReplace(data, '-');

            const deals = sortData(data, "nickname");
            
            // const data = sortData(data, "nickname")
            // < ----- EXTRACT - END ------- <

            // - set initial values for data, dataFiltered (immutable and mutable, respectively) and dataLoaded
            setTimeout(() => {
                setData(deals);
                setDataFiltered(deals);
                setDataLoaded(true);
            }, 900);

        } catch (error) {
            setTimeout(() => {
                setDataLoaded(false);
                setDataError(error.message);
            }, 900);
        };
    };

    // - fetch loan data - pass empty array as second arg to prevent infinite loop (same funcitonality as componentDidMount)
    useEffect(() => {
        fetchData();
    }, []);

    // --------------------------------------------------------------------------------------

    const bodyError = (
        <span className="error-message">
            There was a problem loading data (error: {dataError}).  <button onClick={fetchData}>&nbsp;Click here to refresh.</button>
        </span>
    );

    // - section heading (passed to SectionExpandable)
    const heading = (
        <span style={{ display: "flex", alignItems: "center" }}>
            Loans In Process ({dataFiltered.length} of {data.length})
        </span>
    );

    // - section controls (passed to SectionExpandable - pass ref for CopyTable here)
    const controls = (
        <React.Fragment>
            <input type="text"
                placeholder="Search"
                onChange={handleSearch}
                data-tip="Type here to search the table."
                data-for="app-tooltip" />

            <CopyTable tableRef={tableRef} />
        </React.Fragment>
    );

    // - section body (passed to SectionExpandable - add CopyTable ref below on <table>)
    const bodyContent = (

        // - check if data is loaded from API (props in future).  If null > loading animation,
        //   if true > table, if false > error.  NOTE - this is a nested ternary - pay attention!

        // - loading animation
        dataLoaded === null ?

          <SkeletonTable />

            // - table with data
            : dataLoaded === true ?

                // - note the ref below - needed to copy this table
                <animated.table style={styleLoaded} id="table" ref={tableRef} className="table">

                    <thead>
                        <tr>
                            <th>
                             <span>Nickname</span>
                            </th>
                            <th>
                                <span>Address</span>
                            </th>
                            <th>
                                <span>Loan Number</span>
                            </th>
                            <th>
                                <span>Product</span>
                            </th>
                            <th>
                                <span>Status</span>
                            </th>
                            <th>
                                <span>Status Date</span>
                            </th>
                            <th>
                                <span>Loan Amount</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            // - table rows with search results
                            dataFiltered.length > 0 ?
                                dataFiltered.map(row => {
                                    return (
                                        <tr key={"loans-in-process-" + generateId(1000, 10000)}>
                                            <td>
                                                <Link to={link + row.id}>
                                                    {row.nickname}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={link + row.id} tabIndex="-1">
                                                    {row.address}
                                                </Link>
                                            </td>
                                            <td>
                                                <span>
                                                    {row.number}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {row.product}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {row.status}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {row.status_date}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {row.loan_amount}
                                                </span>
                                            </td>
                                        </tr>

                                    )
                                })
                                : // - table row with no search results
                                <tr>
                                    <td>
                                        <span>
                                            No results found.
                                        </span>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </animated.table>

                // - error messaging
                : bodyError
    );

    // - render the SectionExpandable with the heading, controls, and body elements above (future - pass animation)
    return (
        <SectionExpandable heading={heading} body={bodyContent} controls={controls} id={id} flush />
    );
};

export default LoansInProcess;