import axios from 'axios';
import { encodeJson } from '../../../_applications/_api';

// api object (include all calls here)
const api = {

  fetchBorrowingEntities() {
       // fetch initial borrowing entity data to populate fields
       axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
       axios.post(process.env.REACT_APP_LIMA + '/lima/api/fetchborrowingentity', {
           email: window.localStorage.getItem("email")
       })
           .then((res) => {
             let data = res.data.sort((a, b) => (a.Borrowing_Entity_Name > b.Borrowing_Entity_Name) ? 1 : -1);

               this.setState({
                   borrowers: data
               })
           })
           .catch(console.log);
  },

  // send form data to server
  postFormData(state) {

    if(state.submitted) return;

    this.setState({ submitted: true })
  // fetch initial borrowing entity data to populate fields
    axios.post(process.env.REACT_APP_LIMA + '/lima/api/broker/createbroker', encodeJson({
      "first_name": state.formData.first_name.iVal.value,
      "last_name": state.formData.last_name.iVal.value,
      "middle_name": state.formData.middle_name.iVal.value,
      "total_real_estate_assets_owned": "$0-$500k",
      number_of_rental_units_currently_owned_regardless_of_purchase_date: "0",
      // "licenses_held":
      //   [
      //     state.formData.licenses_held.iVal.value.join(";")
      //   ],
      "mailing_address": state.formData.mailing_address.iVal.value,
      "city": state.formData.city.iVal.value,
      "state": state.formData.state.iVal.value,
      "zip": state.formData.zip.iVal.value.toString(),
      "phone": state.formData.cell.iVal.value.toString().replace(/-/g, ''),
      "business_name": state.formData.business_name.iVal.value,
      "date_of_birth": new Date(state.formData.date_of_birth.iVal.value).toISOString().slice(0, 10),
      "email": state.formData.email.iVal.value,
      "ssn": state.formData.ssn.iVal.value.toString().replace(/-/g, ''),
      "are_you_foreign_national": state.formData.are_you_foreign_national.iVal.value,
      "utm_source": state.utm_source,
      "utm_medium": state.utm_medium,
      "utm_campaign": state.utm_campaign,
      "utm_term": state.utm_term,
      "utm_content": state.utm_content
    }))
    .then((response) => {
      if('code' in response.data) {
        if(response.data?.token) {
          var token = response.data.token;
          window.location.href = "/broker/account-setup/" + token
        } else {
          this.setState({ submitted: false, error: response.data.message, errorCode: response?.data?.code, token: response?.data?.token })
        }
      } else {
        this.setState({ submitted: false, error: "Something went wrong." })
      }
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        formError: true,
        submitted: false
      })
    });
  }
}

export { api }
