import React from 'react';
import { Link } from 'react-router-dom'
// import './index.scss'

class SignUpThankYou extends React.Component {
  constructor (props) {
    super(props);
  }

  render() {
    return (
      <div className="" style={{ alignItems: 'center' }}>
        <h1>Welcome to Lima One Capital!</h1>
        <p>Your account was successfully created. Please visit the <Link to="/" >login page</Link> to sign in.</p>
      </div>
    )
  }
}

export default SignUpThankYou; 