import React from 'react';
import './index.scss'

const InputRadioBinary = React.forwardRef(({name, label, fullwidth, inner, errors, tabIndex, disabled, yesNo}, ref) => {

    return (
        <div className={`${inner ? 'input-field_inner' : 'input-field'} ${fullwidth ? 'full-width' : ''} ${name in errors ? 'error' : ''}`}>
            <p htmlFor={name}>{label}</p>
            <ul tabIndex={tabIndex}>
                <li><label><input ref={ref} type="radio" name={name} value={yesNo ? "Yes" : "true"} disabled={disabled} /> Yes</label></li>
                <li><label><input ref={ref} type="radio" name={name} value={yesNo ? "No" : "false"} disabled={disabled} /> No</label></li>
            </ul>
            {/* {name in errors && <span className="error-message">{label} is required.</span>} */}
        </div>
    )
})

export default React.memo(InputRadioBinary)