import React from 'react';

// renders a small box in the top left for notes on a route/component, only visible if dev mode env var is truthy
const DevInfo = (props) => {
    if (process.env.REACT_APP_DEV_MODE) {
        return (
            <div style={{ position: "fixed", left: "0", top: "0", padding: "0.5rem", fontSize: "0.85rem", zIndex: 10000, background: "black", color: "white" }}>
                {props.info ? `Notes: ${props.info}` : "Notes: no info.."}
            </div>
        )
    } else return null;
}

export default DevInfo;