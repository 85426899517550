import React from 'react';


const InputText = (props) => {
    let label = props.label ? props.label : props.fd[props.fd_key].label

    if (! (props.fd_key in props.fd)) return ""

    if (props.floating) {
        return (
        <div className="floating">
            <input type={props.type ? props.type : `${props.fd_key.includes('email') ? 'email' : 'text'}`} style={props.style} name={props.fd_key} id={props.id ? props.id : props.fd_key} data-input={props.fd_key}
                value={props.value ? props.value : props.fd[props.fd_key].iVal.value ? props.fd[props.fd_key].iVal.value : undefined} onChange={props.inputText} 
                placeholder={props.placeholder} 
                className={props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? 'floating__input inperror' : 'floating__input'} tabIndex={props.tabIndex} readOnly={props.readOnly} />
            <label htmlFor={props.fd_key} className={props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? 'floating__label laberror' : 'floating__label'} data-content={props.label}>
                <span className="hidden--visually">{label}</span>
            </label>
            <span className="error-message">{(props.formSubmission && !props.fd[props.fd_key].iVal.isValid) && props.fd[props.fd_key].error }</span>
        </div>
        )
    }

    return (
        <div className={`${props.inner ? 'input-field_inner' : 'input-field'} ${props.fullwidth ? "full-width" : ""} ${props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? "error" : ""}`}>
            <label htmlFor={props.fd_key}>{label}</label>
            <input type={`${props.fd_key.includes('email') ? 'email' : 'text'}`} name={props.fd_key} id={props.id ? props.id : props.fd_key} data-input={props.fd_key} tabIndex={props.tabIndex} 
                value={props.fd[props.fd_key].iVal.value ? props.fd[props.fd_key].iVal.value : undefined} onChange={props.inputText} placeholder={props.placeholder} readOnly={props.readOnly} />
            <span className="error-message">{(props.formSubmission && !props.fd[props.fd_key].iVal.isValid) && props.fd[props.fd_key].error }</span>
        </div>
    )
}

export default InputText