import React, { useState, useEffect } from 'react';
import './index.scss';
import axios from 'axios'
import { useForm } from "react-hook-form";

import InputText from '../../../../../components/legacy/_applications/_components/InputText';
import { handleInput } from '../../../../../utility/legacy/hookforms_handle_input';
import InputSelect from '../../../../../components/legacy/_applications/_components/InputSelect';

import months from "./ExpMonths.json";
import states from '../../../../../config/_us_states.json';
import countries from '../../../../../config/_countries.json';
import ButtonSubmit from '../../../../../components/shared/ButtonSubmit';
import Swal from 'sweetalert2';
import { SectionExpandable } from '../../../../../components/shared/Section';
import TransitionIn from '../../../../../components/shared/TransitionIn';

const DealCredit = props => {

    const [responseMessage, setResponseMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [success, setSuccess] = useState(null);
    const [cardIcon, setCardIcon] = useState('generic');
    const { register, handleSubmit, errors, setValue } = useForm();

    const onSubmit = async formData => {
        formData = { ...formData, dealId: props.deal.id, CardType: cardIcon };

        if (formData['Country'] === 'United States') formData['Country'] = 'United States of America';

        setSubmitted(true);

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");

        try {
            let response = await axios.post(process.env.REACT_APP_LIMA + `/lima/api/process-chargent-order`, formData);
            if (response?.data?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Transaction successful.',
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonColor: '#1D3B62'
                })
                setSuccess(true)
                if (props?.callback) {
                    props.callback();
                }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Transaction failed.',
                    text: response?.data?.message,
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonColor: '#1D3B62'
                })
                setSuccess(false)
            }
            setSubmitted(false)
        } catch (e) {
            Swal.fire({
                icon: 'warning',
                title: 'Transaction failed.',
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#1D3B62'
            })
            setSuccess(false)
            setSubmitted(false)
        }
    }

    useEffect(() => {

    }, [cardIcon, responseMessage])

    const updateCardIcon = (e) => {
        const maskedInput = e.target.value.replace(/-/g, "")
        const visaRegex = /^4[0-9]{6,}$/
        const masterCardRegex = /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/
        const amexRegex = /^3[47][0-9]{5,}$/
        const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{3,}$/
        const jcbRegex = /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/

        if (maskedInput.match(visaRegex)) {
            setCardIcon('visa')
        } else if (maskedInput.match(masterCardRegex)) {
            setCardIcon('mastercard')
        } else if (maskedInput.match(amexRegex)) {
            setCardIcon('amex')
        } else if (maskedInput.match(discoverRegex)) {
            setCardIcon('discover')
        } else if (maskedInput.match(jcbRegex)) {
            setCardIcon('jcb')
        } else {
            setCardIcon('generic')
        }

        if (maskedInput.match(amexRegex)) {
            let first4 = maskedInput.substring(0, 4)
            let second4 = maskedInput.substring(4, 10)
            let third4 = maskedInput.substring(10, 15)
            let maskedCardNumber = `${first4}${second4 && '-' + second4}${third4 && '-' + third4}`
            setValue(e.target.name, maskedCardNumber)
        } else {
            let first4 = maskedInput.substring(0, 4)
            let second4 = maskedInput.substring(4, 8)
            let third4 = maskedInput.substring(8, 12)
            let last4 = maskedInput.substring(12, 16)
            let maskedCardNumber = `${first4}${second4 && '-' + second4}${third4 && '-' + third4}${last4 && '-' + last4}`
            setValue(e.target.name, maskedCardNumber)
        }
    }

    const inputZip = e => {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        if ((iv.match(/^[0-9.,$]+$/) || iv === "") && iv.length < 6) {
            setValue(e.target.name, iv)
        } else {
            setValue(e.target.name, e.target.value.substring(0, 5))
        }
    }

    const inputNumbers = e => {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        if ((iv.match(/^[0-9.,$]+$/) || iv === "")) {
            setValue(e.target.name, iv)
        }
    }

    const inputCCV = e => {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        let length = cardIcon === 'amex' ? 5 : 4
        if ((iv.match(/^[0-9.,$]+$/) || iv === "") && iv.length < length) {
            setValue(e.target.name, iv)
        } else {
            setValue(e.target.name, e.target.value.substring(0, length - 1))
        }
    }

    const years = () => {
        let yearsArray = []
        for (let i = new Date().getFullYear(); i < new Date().getFullYear() + 10; i++) {
            yearsArray.push({
                "name": '' + i,
                "value": ('' + i).substring(2, 4)
            })
        }
        return yearsArray
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });


    let yearsArray = years();

    let tabIndex = 1

    return (
        <TransitionIn content={
            <SectionExpandable heading={"Credit Card Info"} body={
                <div className="form loc-borrower" id="form">
                    <form className={`form-body ${errors.length > 0 ? 'form-error' : ''}`} onSubmit={handleSubmit(onSubmit)}>
                        <div className="payoff-request-form">

                            <div className="row">
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'First Name*'}
                                        name={'FirstName'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Last Name*'}
                                        name={'LastName'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Street Address*'}
                                        name={'Address'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputText ref={register({ required: false })} type={'text'} label={'Unit/Apt. #'}
                                        name={'Address2'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'City*'}
                                        name={'City'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={states} label={'State*'}
                                        name={'State'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputText ref={register({ required: true })} onChange={inputZip} type={'text'} label={'Zip*'}
                                        name={'Zip'} placeholder={"XXXXX"} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={countries} label={'Country*'}
                                        name={'Country'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Company*'}
                                        name={'Company'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Email*'}
                                        name={'Email'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Phone Number*'}
                                        name={'Phone'} tabIndex={tabIndex++} errors={errors} onChange={handleInput.inputPhone} />
                                </div>
                                <div className="column ">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Card Number*'}
                                        name={'CardNumber'} tabIndex={tabIndex++} errors={errors} onChange={(e) => {
                                            updateCardIcon(e)
                                        }} />
                                </div>
                            </div>

                            <div className="row grow-3">
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={months} label={'Exp. Month*'}
                                        name={'ExpMonth'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={yearsArray} label={'Exp. Year*'}
                                        name={'ExpYear'} tabIndex={tabIndex++} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'CCV'} className={'ccv'}
                                        name={'CardSecurity'} tabIndex={tabIndex++} errors={errors} onChange={inputCCV}
                                        placeholder={`${cardIcon === 'amex' ? 'XXXX' : 'XXX'}`}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <h4>Appraisal Amount: {formatter.format(props?.deal?.appraisal_amount ? parseFloat(props.deal.appraisal_amount) : 0)}</h4>
                                </div>
                            </div>

                            <div className="row">
                                <div className={'column'}>
                                    <ButtonSubmit readOnly={submitted} />
                                </div>
                            </div>

                            {responseMessage &&
                                <div className="row">
                                    <div className={'column'}>
                                        <p className={success ? 'success' : 'error'}>{responseMessage}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            } />
        } />
    )

}

export default DealCredit;