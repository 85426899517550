import React from 'react';

function ThankYouGuarantor(props) {

    return (
        <div className="thank-you">

            <section className="section thank-you">
                <div className="section-heading">
                    <h1>Additional Guarantor Application Submission</h1>
                </div>

                <br />
                
                <p>
                    Thank you for submitting your application. Our sales and underwriting teams will begin working on your file. They will be in touch with next steps and to collect any outstanding documentation.
                </p>
                <br />
                <p style={{fontWeight: 'bold'}}>
                    Please check your email for further steps and account setup.
                </p>
            </section>

        </div>
    )
}

export default ThankYouGuarantor