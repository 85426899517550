import React, { useEffect } from 'react'
import states from '../../../assets/config/_us_states.json'
import countries from '../../../assets/config/_countries.json'
import InputText from '../_components/InputText'
import InputDate from '../_components/InputDate'
import InputSelect from '../_components/InputSelect'
import {Textfit} from "react-textfit";
import {SectionHeading} from "../../../App/components/shared/Section/Heading";
import InputTextMasked from "../_components/InputTextMasked";

const PrimaryGuarantor = React.forwardRef((props, ref) => {

    let customer_id = props.watch('customer_id')

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const formatCurrency = (val) => val ? formatter.format(val) : undefined;


    const getCustomer = () =>  props.customers.find((customer) => customer.id === customer_id);

    useEffect(() => {
        let customer = getCustomer();

        if (!customer) return

        props.setValue('first_name', customer?.first_name)
        props.setValue('last_name', customer?.last_name)
        props.setValue('street_address', customer?.street_address)
        props.setValue('city', customer?.city)
        props.setValue('state', states.find((x, i) => x.name === customer?.state)?.abbreviation)
        props.setValue('zip', customer?.zip)
        props.setValue('ssn', customer?.ssn)
        props.setValue('date_of_birth', customer?.date_of_birth)
        props.setValue('phone_number', customer?.phone_number)
        props.setValue('email', customer?.email)
        props.setValue('country_of_citizenship', customer?.country_of_citizenship)
        props.setValue('estimated_credit_score', customer?.estimated_credit_score)
        props.setValue('ownership_percentage_of_borrowing_entity', customer?.ownership_percentage_of_borrowing_entity + "%")
        props.setValue('number_of_rental_units_currently_owned_bought_in_last_3_years', customer?.number_of_rental_units_currently_owned_bought_in_last_3_years)
        props.setValue('number_of_rental_units_currently_owned_regardless_of_purchase_date', customer?.number_of_rental_units_currently_owned_regardless_of_purchase_date)
        props.setValue('currently_estimated_value_of_your_real_estate_portfolio', formatter.format(customer?.currently_estimated_value_of_your_real_estate_portfolio))
        props.setValue('number_of_FixNFlips_sold_in_the_past_3_years', customer?.number_of_fixnflips_sold_in_the_past_3_years)
        props.setValue('number_of_new_Construction_builds_in_the_past_3_years', customer?.number_of_new_construction_builds_in_the_past_3_years)
        props.setValue('do_you_have_a_GC_on_staff_or_do_you_hire_a_third-party', customer?.do_you_have_a_GC_on_staff_or_do_you_hire_a_third_party)
        props.setValue('convicted_of_currently_accused_of_a_felony', customer?.convicted_of_currently_accused_of_a_felony)
        props.setValue('have_any_outstanding_judgements', customer?.have_any_outstanding_judgements)
        props.setValue('had_a_foreclosure', customer?.had_a_foreclosure)
        props.setValue('party_to_any_outstanding_lawsuits', customer?.party_to_any_outstanding_lawsuits)
        props.setValue('declared_bankruptcy', customer?.declared_bankruptcy)
        props.setValue('presently_delinquent', customer?.presently_delinquent)

        props.setValue('liquid_assets_cash', formatCurrency(customer?.liquid_assets_cash))
        props.setValue('retirement_accounts', formatCurrency(customer?.retirement_accounts))
        props.setValue('stocks_bonds', formatCurrency(customer?.stocks_bonds))
        props.setValue('other_liquid_assets', formatCurrency(customer?.other_liquid_assets))
        props.setValue('total_liquid_assets', formatCurrency(customer?.total_liquid_assets))
        props.setValue('non-liquid_real_estate_assets', formatCurrency(customer?.non_liquid_real_estate_assets))
        props.setValue('other_personal_property', formatCurrency(customer?.other_personal_propery))
        props.setValue('autos', formatCurrency(customer?.autos))
        props.setValue('total_non-liquid_assets', formatCurrency(customer?.total_non_liquid_assets))
        props.setValue('real_estate_debt', formatCurrency(customer?.real_estate_debt))
        props.setValue('revolving_debt', formatCurrency(customer?.revolving_debt))
        props.setValue('installment_debt', formatCurrency(customer?.installment_debt))
        props.setValue('other_debt', formatCurrency(customer?.other_debt))
        props.setValue('notes_payable', formatCurrency(customer?.notes_payable))
        props.setValue('total_debt', formatCurrency(customer?.total_debt))
        props.setValue('net_worth', formatCurrency(customer?.net_worth))
    }, [customer_id])

    return (
        <>
            {/* ----- Primary Guarantor Info ----- */}
            <div className="section_heading">
                <SectionHeading heading={"Primary Guarantor Information"} max={20} />
            </div>

            <div className="row">

                <div className="column">
                    <InputText ref={ref} type={'text'} label={'First Name*'} name={'first_name'} id={'first_name'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={ref} type={'text'} label={'Last Name*'} name={'last_name'} id={'last_name'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>

            
                <div className="column">
                    <InputText ref={ref} type={'text'} label={'Street Address*'} name={'street_address'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={ref} type={'text'} label={'City*'} name={'city'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>

                <div className="column">
                    <div className="row">
                        <div className="column" style={{ padding: '0 1rem 0 0' }}>
                            <InputSelect ref={ref} config={states} label={'State*'} name={'state'} tabIndex={props.tabIndex} errors={props.errors} />
                        </div>
                        <div className="column" style={{ padding: '0 0 0 1rem' }}>
                            <InputText ref={ref} onChange={props.inputZip} placeholder={"XXXXX"} label={'Zip*'} name={'zip'} tabIndex={props.tabIndex} errors={props.errors} />
                        </div>
                    </div>
                </div>

                <div className="column">
                    {customer_id && getCustomer()?.ssn ?
                        <InputText ref={ref} label={'Social Security Number*'} name={'ssn'} tabIndex={props.tabIndex} errors={props.errors} disabled={true} />
                        :
                        <InputTextMasked ref={ref} control={props.control} label={'Social Security Number*'}
                                         errors={props.errors} mask="999-99-9999" maskChar=" " type="tel" name="ssn"
                                         placeholder="XXX-XX-XXXX"/>
                    }
                    </div>

                <div className="column">
                    <InputDate ref={ref} label={'Date of Birth*'} name={'date_of_birth'} tabIndex={props.tabIndex} errors={props.errors} min="1900-01-01" max={new Date().toISOString().slice(0, 10)} />
                </div>

                <div className="column">
                    <InputTextMasked ref={ref} control={props.control} label={'Phone Number*'} errors={props.errors} mask="999-999-9999" maskChar=" " type="tel" name="phone_number" placeholder="XXX-XXX-XXXX" />
                </div>

                <div className="column">
                    <InputText ref={ref} type={'email'} label={'Email*'} name={'email'} id={'email'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>

                <div className="column">
                    <InputSelect ref={ref} config={countries} label={'Country of Citizenship*'} name={'country_of_citizenship'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={ref} onChange={props.inputCreditScore} type={'text'} label={'Estimated Credit Score*'} name={'estimated_credit_score'} placeholder={"XXX"} tabIndex={props.tabIndex} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={ref} onChange={props.inputPercentage} type={'text'} label={'Ownership % of Borrowing Entity*'} name={'ownership_percentage_of_borrowing_entity'} placeholder={"0%"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>
            </div>

            <hr />
            {/* ----- Real Estate - Areas of Operation ----- */}
            <fieldset className={`input-field-group real-estate ${props.errors['areas_of_operation'] ? 'error' : ''}`}>
                <div className={`input-field full-width areas-of-operation`}>
                    <label htmlFor="real-estate">Which areas of real estate do you operate in? (Select all that apply.)*</label>
                    <ul>
                        <li>
                            <input ref={ref} type="checkbox" value="Fix & Flip" id="fixnflip" className="aor" name="areas_of_operation" />
                            <label htmlFor={"fixnflip"}>Fix & Flip</label>
                        </li>
                        <li>
                            <input ref={ref} type="checkbox" value="New Construction" id="new_construction" className="aor" name="areas_of_operation" />
                            <label htmlFor={"new_construction"}>New Construction</label>
                        </li>
                        <li>
                            <input ref={ref} type="checkbox" value="Rentals, Commercial" id="rentals_commercial" className="aor" name="areas_of_operation" />
                            <label htmlFor={"rentals_commercial"}>Rentals</label>
                        </li>
                        <li>
                            <input ref={ref} type="checkbox" value="Multi Family" id="multifamily" className="aor" name="areas_of_operation" />
                            <label htmlFor={"multifamily"}>Multifamily</label>
                        </li>
                        <li>
                            <input ref={ref} type="checkbox" value="Mixed Use" id="mixed_use" className="aor" name="areas_of_operation" />
                            <label htmlFor={"mixed_use"}>Mixed Use</label>
                        </li>
                    </ul>
                    {/* <span className="error-message">{formSubmission && !fd.customRE.iVal.isValid ? "Select at least one area of operation." : ""}</span> */}
                </div>
            </fieldset>

            <div className="row grow-3" style={{ paddingTop: '1rem' }}>

                <div className="column">
                    <InputText ref={ref} onChange={props.inputNumber} name="number_of_rental_units_currently_owned_bought_in_last_3_years" label="Number of rental units currently owned/bought in the last 3 years*" tabIndex={props.tabIndex} errors={props.errors} />
                </div>
                <div className="column">
                    <InputText ref={ref} onChange={props.inputNumber} name="number_of_rental_units_currently_owned_regardless_of_purchase_date" label="Number of rental units currently owned regardless of purchase date*" tabIndex={props.tabIndex} errors={props.errors} />
                </div>
                <div className="column">
                    <InputText ref={ref} onChange={props.inputCurrency} name="currently_estimated_value_of_your_real_estate_portfolio" label="Currently estimated value of your real estate portfolio*" placeholder="$0" tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>
                <div className="column">
                    <InputText ref={ref} onChange={props.inputNumber} name="number_of_FixNFlips_sold_in_the_past_3_years" label="Number of FixNFlips sold in the past 3 years*" tabIndex={props.tabIndex} errors={props.errors} />
                </div>
                <div className="column">
                    <InputText ref={ref} onChange={props.inputNumber} name="number_of_new_Construction_builds_in_the_past_3_years" label="Number of new construction builds in the past 3 years*" tabIndex={props.tabIndex} errors={props.errors} />
                </div>

                <div className="column">
                    <div className={`input-field ${props?.errors['do_you_have_a_GC_on_staff_or_do_you_hire_a_third-party'] ? 'error' : ''}`}>
                        <label htmlFor="do_you_have_a_GC_on_staff_or_do_you_hire_a_third-party">Do you have a GC on staff or do you hire a third party?*</label>
                        <select ref={ref} name="do_you_have_a_GC_on_staff_or_do_you_hire_a_third-party">
                            <option value="">Make a selection</option>
                            <option value="GC on Staff">GC on Staff</option>
                            <option value="Third Party GC">Third Party GC</option>
                        </select>
                        {/* <span className="error-message">{fd.do_you_have_a_GC_on_staff_or_do_you_hire_a_third_party.error}</span> */}
                    </div>
                    {/* <br />
                    <p>Note: If third party, ask your sales representative for a contractor application</p> */}
                </div>
            </div>
        </>
    )

})

export default React.memo(PrimaryGuarantor)