import { log } from './utilities_global';
import { validateInput } from './utilities_validation_input'; // global input validation utilities
import states from '../../../assets/config/_us_states.json';

const handleInput = {
    inputText(e) {
        const iv = e.target.value; // input value 
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input
     
        let fd = this.cloneFormData() // copy formData property from state''==
        const opt = this.state.formData[op].optional; // check for optional property

        fd[op].iRaw = iv;
        fd[op].label = fd[op].label

        if(op.includes('email')) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            
            if(!opt) {
                fd[op].iVal.isValid = iv.length > 0 && re.test(String(iv).toLowerCase());
            } else {
                fd[op].iVal.isValid = iv.length < 1 || re.test(String(iv).toLowerCase());
            }
        } else {
            fd[op].iVal.isValid = opt ? true : iv.length > 0;
        }

        fd[op].iVal.value = validateInput.text(iv);
       

        // Update state and log the new state
        this.setState({
            formData: fd
        })
    },

    inputNumber(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9,]+$/)) {
           
            var doNothing = false;
            if (op.includes('zip') && iv.toString().length > 5) {
                doNothing = true;
            }

            if (!op.includes('zip')) {
                iv = validateInput.number(iv)
                iv = parseInt(iv)
            }

            if (!doNothing) {
                fd[op].iRaw = iv;
                fd[op].label = fd[op].label
                fd[op].iVal.value = iv;
                fd[op].iVal.isValid = op.includes('zip') ? iv.toString().length === 5 : true;
            }
        } else {
            if (fd[op].iRaw === null || (fd[op].iRaw !== null && fd[op].iRaw.toString().length === 0) || iv.length === 0) {
                fd[op].iRaw = null;
                fd[op].label = fd[op].label
                fd[op].iVal.value = null;
                fd[op].iVal.isValid = true;
                e.target.value = ""
            }
        }

        // console.log(fd[op])

          // update state and log the new state
          this.setState({
            formData: fd
        });
    },

    inputNumberAsString(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9,]+$/)) {
           
            var doNothing = false;
            if (op.includes('zip') && iv.toString().length > 5) {
                doNothing = true;
            }

            if (!doNothing) {
                fd[op].iRaw = iv;
                fd[op].label = fd[op].label
                fd[op].iVal.value = iv;
                fd[op].iVal.isValid = op.includes('zip') ? iv.toString().length === 5 : true;
            }
        } else {
            if (fd[op].iRaw === null || (fd[op].iRaw !== null && fd[op].iRaw.toString().length === 0) || iv.length === 0) {
                fd[op].iRaw = null;
                fd[op].label = fd[op].label
                fd[op].iVal.value = null;
                fd[op].iVal.isValid = true;
                e.target.value = ""
            }
        }

        // console.log(fd[op])

          // update state and log the new state
          this.setState({
            formData: fd
        });
    },

    inputRadio(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        if(iv === "true") {
            iv = true
        } else {
            iv = false
        }

        let fd = this.cloneFormData();
        
        fd[op].iRaw = iv;
        fd[op].label = fd[op].label
        fd[op].iVal.value = iv ;
        fd[op].iVal.isValid = iv === true || iv === false ? true : false;

        // update state and log the new state
        this.setState({
            formData: fd
        })
    },

    inputRadioMultiple(e) {
        const iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        // set the new state object property dynamically (original input and validated input for submission)
        fd[op].iRaw = iv;
        fd[op].iVal.value = iv;
        fd[op].label = fd[op].label
        fd[op].iVal.isValid = iv !== "" ? true : false;

        // update state and log the new state
        this.setState({
            formData: fd
        })
    },

    inputCheckbox(e) {
        const iv = e.target.value;
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        const i = fd[op].iVal.value.indexOf(iv); // get index of value in state

        // check and add/remove as needed
        if (fd[op].iVal.value.indexOf(iv) < 0) {
            fd[op].iVal.value.push(iv);
        } else {
            fd[op].iVal.value.splice(i, 1);
        }

        if (fd[op].iVal.value.length > 0) {
            fd[op].iVal.isValid = true
        }

        this.setState({
            formData: fd
        }, this.checkState)
    },

    inputSelect(e) {
        const iv = e.target.value;
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        fd[op].iRaw = iv;
        fd[op].iVal.value = iv;
        fd[op].label = fd[op].label
        fd[op].label = fd[op].label
        fd[op].iVal.isValid = iv ? true : false;

        this.setState({
            formData: fd
        })
    },

    inputSelectMulti(e) {
        let iv = Array.from(e.target.selectedOptions, option => option.value);
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input
       
        let fd = this.cloneFormData();

        fd[op].iRaw = iv;
        fd[op].iVal.value = iv;
        fd[op].label = fd[op].label
        fd[op].label = fd[op].label
        fd[op].iVal.isValid = iv ? true : false;

        this.setState({
            formData: fd
        })
    },

    inputDate(e) {
        const iv = e.target.value;
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        // set the new state object property dynamically (original input and validated input for submission)
        fd[op].iRaw = iv;
        fd[op].iVal.value = iv;
        fd[op].label = fd[op].label
        fd[op].iVal.isValid = true;

        this.setState({
            formData: fd
        })
    },

    inputPhone(e) {
        let iv = e.target.value;
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input
        let fd = this.cloneFormData();
        var label = fd[op].label

        const opt = fd[op].optional;

        if (iv.match(/^[0-9.,-]+$/)) {
            // remove dashes
            iv = iv.replace(/-/g, '')
            iv = iv.replace(/\D/g, '')
            iv = iv.trim()

            // insert dashes into string for formatted value (fv)
            var fv = iv.substring(iv.length - 10 < 0 ? 0 : iv.length - 10, iv.length);
            
            if (fv.length < 11) {

                if (iv.length === 11 && fd[op].iRaw) {
                    iv = fd[op].iRaw
                    fv = fd[op].iVal.value 
                } else {
                    if (fv.length > 3)
                    fv = fv.replace(/^(\d{3})/, '$1-');
                    if (fv.length > 7)
                    fv = fv.replace(/-(\d{3})/, '-$1-');
                }
             
                fd[op] = {
                    label: label,
                    iRaw: validateInput.phone(iv),
                    iVal: {
                        value: fv,
                        isValid: opt ? true : validateInput.phone(iv) ? true : false
                    }
                }
            } else {
                console.log(op)
                console.log(fd[op].iRaw)
            }
        } else {
            if (!fd[op].iVal.value || fd[op].iVal.value.length === 0 || iv.length === 0) {
                fd[op].iRaw = null
                fd[op].iVal.value = null
                fd[op].iVal.isValid = opt ? true : false
                e.target.value = ""
            }
        }

        this.setState({
            formData: fd
        })
    },

    inputSSN(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.,-]+$/) || iv === "") {

            
            // remove dashes
            iv = iv.replace(/-/g, '');

            if (iv.length < 10) {
                // remove white spaces
                iv = iv.trim();

                // insert dashes into string for formatted value (fv)
                var fv = iv.toString().replace(/\D/g, '');
                if (fv.length > 3)
                fv = fv.replace(/^(\d{3})/, '$1-');
                if (fv.length > 6)
                fv = fv.replace(/-(\d{2})/, '-$1-');
                if (fv.length > 7)
                fv = fv.replace(/(\d)-(\d{4}).*/, '$1-$2');

                var label = fd[op].label

                fd[op] = {
                    label: label,
                    iRaw: iv,
                    iVal: {
                        value: fv,
                        isValid: (iv).length === 9 && (iv) !== "" ? true : false
                    }
                }
            }}
        // } else {
        //     fd[op] = {
        //         iRaw: "",
        //         iVal: {
        //             value: "",
        //             isValid: validateInput.number(iv).length === 9 ? true : false
        //         }
        //     }
        // }

          // update state and log the new state
          this.setState({
            formData: fd
        });
    },

    inputZipcode(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        let label = fd[op].label

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^\d+$/)) {
            // make sure it's a valid number without dashes
            iv = validateInput.number(iv);
            if (iv !== undefined && iv.toString().length < 6) {
                fd[op] = {
                    label: label,
                    iRaw: iv,
                    iVal: {
                        value: iv,
                        isValid: iv.toString().length === 5 ? true : false
                    }
                }
            }
        } else {
            fd[op] = {
                label: label,
                iRaw: "",
                iVal: {
                    value: "",
                    isValid: false
                }
            }
        }

          // update state and log the new state
          this.setState({
            formData: fd
        });
    },

    inputCreditScore(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        let label = fd[op].label

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.]+$/)) {
            iv = validateInput.number(iv);
            
            if (iv !== undefined && iv.toString().length < 4 && iv < 851) {
                fd[op] = {
                    label: label,
                    iRaw: iv,
                    iVal: {
                        value: iv,
                        isValid: true
                    }
                }
            }
        } else {
            if (iv.length < 1) {
                e.target.value = ""
                fd[op].iVal.value = ""
                fd[op].iVal.isValid = false
                fd[op].iRaw = ""
            } else {
                iv = validateInput.number(iv);
                e.target.value = fd[op].iRaw
                fd[op].iVal.isValid = iv !== undefined
            }
        }

          // update state and log the new state
          this.setState({
            formData: fd
        });
    },

    inputDOB(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        let label = fd[op].label

        let date = new Date(iv)
        let isValidDate = date instanceof Date && !isNaN(date);

        if (isValidDate) {
            let today = new Date();
            today.setHours(0,0,0,0);

            if (date < today) {
                iv = validateInput.text(iv);
            
                fd[op] = {
                    label: label,
                    iRaw: iv,
                    iVal: {
                        value: iv,
                        isValid: true
                    }
                }
            }
        } else {
            fd[op] = {
                label: label,
                iRaw: "",
                iVal: {
                    value: "",
                    isValid: false
                }
            }
        }

        // update state and log the new state
        this.setState({
            formData: fd
        });
    },

    inputBusinessEIN(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        let label = fd[op].label

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.,-]+$/) || iv === "") {
            // remove dashes
            iv = iv.replace(/-/g, '');

            if (iv.length < 10) {
                // remove white spaces
                iv = iv.trim();

                // insert dashes into string for formatted value (fv)
                var fv = iv.toString().replace(/\D/g, '');
                
                if (fv.length > 2)
                fv = fv.replace(/^(\d{2})/, '$1-');

                fd[op] = {
                    label: label,
                    iRaw: validateInput.number(iv),
                    iVal: {
                        value: fv,
                        isValid: (iv).length === 9 && (iv) !== "" ? true : false
                    }
                }
            }
        // } else {
        //     fd[op] = {
        //         iRaw: "",
        //         iVal: {
        //             value: "",
        //             isValid: false
        //         }
        //     }
        }

          // update state and log the new state
          this.setState({
            formData: fd
        });
    },

    inputPercentage(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        let label = fd[op].label

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.]/)) {

            if (iv.endsWith('.')) {
                iv = validateInput.float(iv + "0").toString() + ".";
            } else {
                iv = validateInput.float(iv);
            }

            if (iv !== undefined && iv < 101) {
                fd[op] = {
                    iRaw: iv,
                    label: label,
                    iVal: {
                        value: iv,
                        isValid: true
                    }
                }
            }
        } else {
            fd[op] = {
                iRaw: "",
                label: label,
                iVal: {
                    value: "",
                    isValid: false
                }
            }
        }

          // update state and log the new state
          this.setState({
            formData: fd
        });
    },

    inputHidden(e) {
        const iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input
        const dt = e.target.dataset.children.split(","); // grab the child inputs from the hidden field,
        let df = e.target.dataset.trigger;

        let fd = this.cloneFormData();

        fd[op].iVal.iRaw = iv;
        fd[op].iVal.value = iv === "true" ? true : false;
        fd[op].iVal.isValid = iv !== "" ? true : false;

        let value = fd[op].iVal.value.toString()
                
        // if toggle value statement is not equal (hidden) reset children 
        // to pass validation and send empty field (preserving original error message)
        if(value !== df) {
            log('reset')
            
           dt.forEach(child => {
            fd[child].iRaw = "";
            fd[child].iVal.value =  "";
            fd[child].iVal.isValid = true
            });
        } else {
            dt.forEach(child => {
                fd[child].iRaw = "";
                fd[child].iVal.value =  "";
                fd[child].iVal.isValid = false
            })
        }

        log(fd)
 
        this.setState({
            formData: fd
        })
    },

    inputCurrency(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();
        let label = undefined;

        if (fd[op].label) {
            label = fd[op].label;
        }

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.,$]+$/) || iv === "" || iv === "$") {
            var atEnd = iv === "" || iv === "$"
            var cleanIV = iv.replace(/,/g, "").replace(/\$/g, "")

            var fv = "0"

            if (iv !== "") {
                var hasPeriodBefore = fd[op].iRaw && fd[op].iRaw.toString().includes('.')
                var hasPeriod = iv.includes('.')
                fv = parseFloat(hasPeriod ? cleanIV.split('.')[0] : cleanIV)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  
                if (!hasPeriodBefore && hasPeriod) {
                    fv += "."
                } else if (hasPeriod) {
                    fv += "." + cleanIV.split('.')[1].substring(0, 2)
                }

            }

            iv = validateInput.float(cleanIV);

            if (iv === undefined) {
                iv = null
            }

            if(atEnd) {
                iv = 0;
                fv = ""
            }

            if(atEnd) {
                fd[op] = {
                    iRaw: "$0",
                    iVal: {
                        value: 0,
                        isValid: true
                    },
                    label
                }
            } else {
                fd[op] = {
                    iRaw: "$" + fv,
                    iVal: {
                        value: parseFloat(fv.replace(/,/g, "").replace(/\$/g, "")),
                        isValid: true
                    },
                    label
                }
            }
        } else {
            if (!fd[op].iVal.value || fd[op].iVal.value.length < 1) {
                fd[op] = {
                    iRaw: "$0",
                    iVal: {
                        value: 0,
                        isValid: true
                    },
                    label
                }
            }
        }

        if (this.pfs) {
            this.pfs(fd); // update pfs (almost like middleware)
        }
        // update state and log the new state
        this.setState({
            formData: fd
        })
    },

    inputStates(e) {
        const iv = e.target.value;
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        const i = fd[op].iVal.value.indexOf(iv); // get index of value in state

        // check and add/remove as needed
        if (fd[op].iVal.value.indexOf(iv) < 0) {
            fd[op].iVal.value.push(iv);
        } else {
            fd[op].iVal.value.splice(i, 1);
        }

        if (fd[op].iVal.value.length > 0) {
            fd[op].iVal.isValid = true
        }

        this.setState({
            formData: fd
        }, this.checkStates)
    },

    checkStates() {
        console.log('checking states...')
        let state = this.cloneState();
        let statesUpdated = [];

        //   clear out initially to reset
        states.forEach(x => {
            x.selected = false
        })

        // check and update states 
        states.forEach(x => {
            state.formData.registered_to_do_business_in_what_states.iVal.value.forEach(i => {
                if (i === x.name) {
                    x.selected = true
                }
            })
            statesUpdated.push(x);
        })
        console.log(statesUpdated);

        this.setState({
            usStates: statesUpdated
        })
    },
    
    formatStringAsCurrency (iv) {
        if(!iv) return ''

        var cleanIV = iv.replace(/,/g, "").replace(/\$/g, "").replace(/\.0/g, "")

        var fv = "0"

        if (iv !== "") {
            fv = parseFloat(cleanIV)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return "$" + fv;
    },

    formatStringAsPhoneNumber (iv) {
        if(!iv) return ''
        if (iv.match(/^[0-9.,-]+$/) || iv === "") {
            // remove dashes
            iv = iv.replace(/-/g, '');
      
            if (iv.length < 11) {
                // remove white spaces
                iv = iv.trim();
                // insert dashes into string for formatted value (fv)
                var fv = iv.toString().replace(/\D/g, '');
                if (fv.length > 3)
                fv = fv.replace(/^(\d{3})/, '$1-');
                if (fv.length > 7)
                fv = fv.replace(/-(\d{3})/, '-$1-');

                return fv
            }
        }

        return iv
    }
}

export { handleInput }
