import React, { Fragment } from 'react'
import InputText from '../_components/InputText'

const Financials = React.forwardRef((props, ref) => {

    return (
        <Fragment>
            <div className="section_heading" style={{ paddingTop: '2rem' }}>
                <h1 style={{ width: "100%" }}>Financial Information</h1>
            </div>

            <h2>Liquid Assets</h2>
            <div className="row grow-4">
                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Cash'} name={'liquid_assets_cash'} id={'cash'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Retirement Accounts'} name={'retirement_accounts'} id={'retirement_accounts'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Stocks & Bonds'} name={'stocks_bonds'} id={'stocks_bonds'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Other Liquid Assets'} name={'other_liquid_assets'} id={'other_liquid_assets'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} type={'text'} label={'Total Liquid Assets'} name={'total_liquid_assets'} placeholder={"$0.00"} id={'total_liquid_assets'} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} readOnly={true} />
                </div>
            </div>

            <h2>Non-Liquid Assets</h2>
            <div className="row grow-4">
                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Non-Liquid Real Estate Assets'} name={'non-liquid_real_estate_assets'} id={'non_liquid_real_estate'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Other Personal Property'} name={'other_personal_property'} id={'other_personal_property'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Autos'} name={'autos'} id={'autos'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} type={'text'} label={'Total Non-Liquid Assets'} name={'total_non-liquid_assets'} id={'total_non-liquid_assets'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} readOnly={true} />
                </div>
            </div>

            <h2>Debt</h2>
            <div className="row grow-4">
                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Real Estate Debt'} name={'real_estate_debt'} id={'debt_real_estate'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Revolving Debt'} name={'revolving_debt'} id={'debt_revolving'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Installment Debt'} name={'installment_debt'} id={'debt_installment'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Other Debt'} name={'other_debt'} id={'debt_other'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} onChange={props.inputCurrency} type={'text'} label={'Notes Payable'} name={'notes_payable'} id={'debt_notes_payable'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} type={'text'} label={'Total Debt'} name={'total_debt'} id={'debt_total'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} readOnly={true} />
                </div>

                <div className="column">
                    <InputText ref={props.notReqRef} type={'text'} label={'Net Worth'} name={'net_worth'} id={'net_worth'} placeholder={"$0.00"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} readOnly={true} />
                </div>
            </div>

        </Fragment>
    )

})

export default React.memo(Financials)
