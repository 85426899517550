import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/css/main.scss';
import axios from "axios";


// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-27082909-3', {
//       debug: process.env.REACT_APP_GA_DEBUG || false
//     }
//   );
if(process.env.REACT_APP_LIVE == "true") {
    console.log = function () {};
    console.error = function () {};
  }

  console.log('LOGGING ENABLED IF VISIBLE')

axios.defaults.headers.common = {
    "client_id": process.env.REACT_APP_HEADERS_CLIENT_ID,
    "client_secret": process.env.REACT_APP_HEADERS_CLIENT_SECRET
};
 
// Call the render DOM method to render the app - entry point to HTML.
// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));
