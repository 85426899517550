import React from 'react';
import './index.scss';
import ButtonSubmit from '../ButtonSubmit';

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            base64file: "",
            filename: "",
            uploadCount: 0,
            uploadStatus: null,
            submitted: false
        }
    }

    getBinary = (e) => {
        const file = e.target.files[0];
        const name = e.target.value.split(/(\\|\/)/g).pop();

        const reader = new FileReader();

        reader.onloadend = function () {
            const b64 = reader.result.replace(/^data:.+;base64,/, '');
            res(b64);
        };

        reader.readAsDataURL(file);

        const res = (b64) => {
            if (b64) {
                this.setState({
                    base64file: name,
                    filename: name
                })

                if(this.props?.setFormData) {
                    this.props.setFormData({
                        rentrollfilename: name,
                        rentrollfiledata: b64
                    })
                }
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submitted: true });

        setTimeout(() => {
            this.setState({ submitted: false, filename: "", base64file: "", uploadStatus: true })
        }, 1000)
    }

    render() {
        console.log(this.props);

        if(this.state.uploadStatus) {
            return <div>
                <br/>
                <p style={{fontWeight: "bold"}}>Your Rent Roll was uploaded successfully.</p>
            </div>
        }

        return (
            <form className="form file-upload" onSubmit={this.handleSubmit}>
                <fieldset>

                    <input type="file" name="file" id="file" onChange={this.getBinary} />

                    <div className="button" >
                        <label htmlFor="file" type="button" role="button">Select File</label>
                        <span className="file-name">
                            {this.state.filename || "No File Selected"}
                        </span>
                    </div>



                </fieldset>
                <span className="error-message"></span>

                {(this.state.filename !== "" || this.state.base64file !== "") &&
                    <ButtonSubmit
                        onClick={this.handleSubmit}
                        readOnly={this.state.submitted}
                        valueReadOnly={"Uploading File"}
                        value={"Upload File"} />
                }
            </form>
        );
    }
}

export default FileUploader