import { formattedDate } from '..';
import currency from 'currency.js';

const utilityData = {

    // format currency 
    formatCurrency: function (arr, arrKeys) {

        return arr.forEach(e => {

            arrKeys.forEach(key => {
                if (!(e[key] === "" || e[key] === null  || e[key] === undefined)) {
                    e[key] = currency(e[key]).format();
                }
            })

        })
    },

    // format dates as 00/00/0000
    formatDate: function (arr, arrKeys) {

        return arr.forEach(e => {
           
            arrKeys.forEach(key => {
                if (!(e[key] === "" || e[key] === null  || e[key] === undefined)) {
                    e[key] = formattedDate(e[key]);
                };
            });
        });
    },

    // format empty fields (* - replace with new string as val)
    formatReplace: (arr, val) => {
        if (arr === null) return;
        
        return arr.forEach((e) => {
            Object.keys(e).forEach(key => {
                if (e[key] === null || e[key] === "" || e[key] === undefined) {
                    e[key] = val;
                };
            });
        });
    },

    removeByValue: (arr, key, criteria) => {
        return arr.filter(x => x[key] !== criteria)
    },
   
    removeDuplicates: function(array, key) {
        var check = new Set();
        return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
    }
}


export default utilityData;