import { config } from '@react-spring/web'; // access pre-defined library configs (optional)
import { log } from '../utility';

const animations = (name, state) => {

    const animation = name || "default"; // library object property name of desired effect

    // ----- v Animation Config v -----------------------------------------------------------------------------

    const configs = {
        custom: {
            clamp: true,
            mass: 20,
            tension: 1800,
            friction: 225
        },
        fade: {
            clamp: true,
            mass: 20,
            tension: 1000,
            friction: 10
        }
    }

    
    // ----- v Animation Library v ----------------------------------------------------------------------------

    const library = {

        // ----- v Fades v ---------
        
        fadeIn: {
            config: configs.fade,
            opacity: state ? 1 : 0
        },

        // general fade up and down for toggling table collapse/expansion
        fadeInUp: {
            config: configs.custom,
            opacity: state ? 1 : 0,
            transform: state ? "translateY(0rem)" : "translateY(1rem)"
        },
        fadeInDown: {
            config: configs.custom,
            opacity: state ? 1 : 0,
            transform: state ? "translateY(0rem)" : "translateY(-2.5rem)"
        },

        // fade in and set pointer events from none to all (active)
        fadeInEnable: {
            config: configs.fade,
            opacity: state ? 1 : 0,
            pointerEvents: state ? "all" : "none"
        },

        // ----- v Toggles v -------

        // toggling between multiple elements (e.g. section controls icons - copyTable)
        toggleInUp: {
            config: configs.custom,
            opacity: state ? 1 : 0,
            transform: state ? "translateY(0rem)" : "translateY(1.25rem)",
        },
        toggleInDown: {
            config: configs.custom,
            opacity: !state ? 1 : 0,
            transform: !state ? "translateY(0rem)" : "translateY(-1.25rem)",
        },
        toggleInUpReverse: {
            config: configs.custom,
            opacity: state ? 1 : 0,
            transform: state ? "translateY(0rem)" : "translateY(-1.25rem)",
        },
        toggleInDownReverse: {
            config: configs.custom,
            opacity: !state ? 1 : 0,
            transform: !state ? "translateY(0rem)" : "translateY(1.25em)",
        },
        toggleInStatusGreen: {
            config: configs.custom,
            opacity: state ? 1 : 0,
            transform: state ? "translateY(0rem)" : "translateY(1.25em)",
            color: "white",
        },
        toggleInStatusIdle: {
            config: configs.custom,
            opacity: state ? 1 : 0,
            transform: state ? "translateY(0rem)" : "translateY(1.25em)",
            color: "white",
        },
        toggleInStatusWorking: {
            config: configs.custom,
            opacity: state ? 1 : 0,
            transform: state ? "translateY(0rem)" : "translateY(1.25em)",
            color: "white",
            background: "yellow"
        },
        toggleInStatusSuccess: {
            config: configs.custom,
            opacity: state ? 1 : 0,
            transform: state ? "translateY(0rem)" : "translateY(1.25em)",
            color: "white",
        },
        toggleInStatusDefault: {
            config: configs.custom,
            opacity: !state ? 1 : 0,
            transform: !state ? "translateY(0rem)" : "translateY(-1.25rem)",
            color: "#0c224b"
        },

        // ----- v Default v -------
        
        default: {
            config: configs.custom,
            opacity: true ? 1 : 0,
            transform: true ? "translateY(0rem) !important" : "translateY(0rem) !important"
        }
    }

    // ----- v Animation Logic v ------------------------------------------------------------------------------
  
    // logging abstraction
    const lg = function (val, obj, print) {
        const pre = "animations -";
        const printDefault = false;
        return log(`${pre} ${val}`, obj, print || printDefault);
    }

    if (Object.keys(library).includes(animation)) {
        lg('name provided - animation:', animation);
    } else lg('name omitted - animation:', animation);

    if (!state) {
        lg('state not provided - no animation');
    }

    return library[animation];
}

export default animations

