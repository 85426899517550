import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Section } from '../../../../components/shared/Section';

export default function BorrowerApplication(props) {
    const isBroker = props.isBroker;

    const sectionLOCHeading = (
        <Fragment>
          Borrower Application
        </Fragment>
      );
    
      const sectionLOCBody = (
        <Fragment>
          <ul>
            <li>
              <Link to={`${process.env.REACT_APP_SESSION_URL}/applications/line-of-credit`}>
                <img src="/assets/images/LineofCredit-ICON.png" alt="img" />
                <p>
                  Submit for<br /> {isBroker ? "Borrower Approval" : "Approval as a Borrower"}
                </p>
              </Link>
            </li>
          </ul>
        </Fragment>
      );

    return (
        <Section heading={sectionLOCHeading} body={sectionLOCBody} />
    )
}