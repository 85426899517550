import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const DevNav = props => {
    const [expanded, setExpanded] = useState(false);

    const toggle = () => setExpanded(!expanded);

    if (!process.env.REACT_APP_DEV_MODE) return null;

    return (
        <div style={{
            position: "fixed",
            bottom: '0',
            left: '0',
            background: "black",
            borderRadius: "0.25rem",
            width: "calc(auto)",
            marginRight: "0",
            padding: "0.5rem 1rem",
            zIndex: 200000,
            fontSize: "1rem"
        }}>
            <button style={{ fontSize: "0.85rem", padding: "calc(0.25em)", textTransform: "uppercase", color: "white" }} onClick={toggle}>{expanded ? "Nav - Close" : "Nav - Open"}</button>
            {
                expanded ?
                    <div>
                        <Link to={`${process.env.REACT_APP_SESSION_URL}/sandbox`} style={{ margin: "0.5em", color: "white", display: "inline-block" }}>Sandbox</Link>
                        <Link to={props.accountType ? "/lo-user-dashboard" : "/"} style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Root</Link>
                        <Link to="/signup" style={{ margin: "0.5em", color: "white", display: "inline-block" }}>Signup</Link>
                        <Link to="/create-account" style={{ margin: "0.5em", color: "white", display: "inline-block" }}>Create Account</Link>
                        <Link to="/thank-you/:type" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Thank You / Type </Link>
                        <Link to="/broker/signup" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Broker Signup </Link>
                        <Link to="/signup-setpassword" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Signup Set PW </Link>
                        <Link to="/set-password/:token" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Set Password / Token </Link>
                        <Link to="/signup/:brokeremail" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Signup / Broker Email </Link>
                        <Link to="/account-setup/:token" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Account Setup / Token </Link>
                        <Link to="/broker/account-setup/:token" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Broker Account Setup / Token  </Link>
                        <Link to="/forgot-password" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Forgot Password </Link>
                        <Link to="/change-password/:token" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Change Password / Token </Link>
                        <Link to="/create-account/:jrobertson@swarmagency.com" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Create Account / Email </Link>
                        <Link to="/additional-guarantor-form/:invitee_id" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Add Guar Form / Invitee_ID </Link>
                        <Link to="/thankyou/additional-guarantor" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Thank You / Add Guar </Link>
                        <Link to="/signup-thankyou" style={{ margin: "0.5em", color: "white", display: "inline-block" }}> Signup Thank You </Link>

                        {/* not implemented yet */}
                        <span>
                            <Link to="/white-label-loc" style={{ margin: "0.5em", color: "white", display: "inline-block" }}>White Label LOC</Link>
                            <Link to="/white-label-fnf" style={{ margin: "0.5em", color: "white", display: "inline-block" }}>White Label LOC</Link>
                            <Link to="/white-label-rental" style={{ margin: "0.5em", color: "white", display: "inline-block" }}>White Label LOC</Link>
                            <Link to="/white-label-new-construction" style={{ margin: "0.5em", color: "white", display: "inline-block" }}>White Label LOC</Link>
                        </span>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default DevNav