import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import animations from '../../../../animations';

function SectionExpandableBody(props) {

    // transistion animation and conditional styles (passing function as props or default config)
    const stylesContentAnimation = useSpring(
        (props.animate) && (typeof (props.animate) === Array) ?
            // custom aniamtion function from props
            animations(props.animate[0], props.expanded) // truthy value triggers animation
            // default animation if no props provided as function
            : animations("fadeIn", props.expanded));

    // const styleHidden = useSpring(animations("fadeInUp", !props.expanded));
    const styleHidden = useSpring(
        (props.animate) && (typeof (props.animate) === Array) ?
            // custom aniamtion function from props
            animations(props.animate[1], !props.expanded) // truthy value triggers animation
            // default animation if no props provided as function
            : animations("fadeIn", !props.expanded))

    if (props.body) {
        return (
            <React.Fragment>
                {
                    props.expanded ?
                        <animated.div style={props.animate ? stylesContentAnimation : null}>
                            {props.body /* main content */}
                        </animated.div>
                        :
                        <animated.div style={props.animate ? styleHidden : null}>
                            <div className="content-hidden">
                                {
                                    props.body ? "Content is hidden - expand to view." : null
                                }
                            </div>
                        </animated.div>
                }
            </React.Fragment>
        )
    } else return <p>Error: content is not availabe.</p>
}


function SectionBody(props) {

    return (
        <React.Fragment>
            {props.body /* main content */}
        </React.Fragment>
    )
}

export { SectionBody, SectionExpandableBody }
