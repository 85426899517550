const apiHelper = {
    dataBeneficiary(state) {

        const data = {
                "bank_account_number": state.formData.bank_account_number.iVal.value.toString(),
                "bank_account_type": state.formData.bank_account_type.iVal.value.toString(),
                "bank_instructions": state.formData.bank_instructions.iVal.value,
                "bank_name": state.formData.bank_name.iVal.value,
                "bank_routing_number": state.formData.bank_routing_number.iVal.value.toString(),
                "account_address_city": state.formData.city.iVal.value.toString(),
                "account_address_state": state.formData.state.iVal.value.toString(),
                "account_address_street": state.formData.billing_street_address.iVal.value.toString(),
                "account_address_zip": state.formData.zip.iVal.value.toString(),
                "bank_account_name": state.formData.name_on_account.iVal.value,
        }

        return data;
    },

}

export { apiHelper }