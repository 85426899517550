import React, { Fragment } from 'react';
import LoansInProcess from '../../LoansInProcess';
import LoansFunded from '../../LoansFunded';
import TransitionIn from '../../../../../components/shared/TransitionIn';
import ActionItems from '../../ActionItems';
import BorrowerApplications from '../../components/BorrowerApplications';

export default function CustomerDashboard(props) {

    const content = (
        <Fragment>
            <ActionItems {...props} />
            <BorrowerApplications {...props} />
            <LoansInProcess {...props} />
            <LoansFunded {...props} />
        </Fragment>
    );

    return (
        <TransitionIn content={content} />
    );
};