import React, { Fragment, useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import animations from '../../../animations';

export default function TransitionIn(props) {

    const [animate, setAnimate] = useState(false);
    const animation = useSpring(animations(props.animation || "fadeIn", animate));
    const loaded = props.loaded || false;
    const content = props.content || null;

    useEffect(() => {
        if(loaded) return;
        setAnimate(true);
    },[])

    if(loaded) return content;

    return (
        <animated.div style={animation}>
            {content}
        </animated.div>
    )
}