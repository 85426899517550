import React, { useState, useEffect } from 'react'
import SubmitApplication from './SubmitApplication';
import TermsModal from './terms';
import './index.scss'
import {withRouter} from "react-router-dom";

const ApplicationWrapper = React.forwardRef((props, ref) => {

    const [terms, setTerms] = useState(false)
    let params = props.location.state ? props.location.state : props.match.params;

    const termsToggle = e => {
        e.preventDefault();
        e.stopPropagation();

        // prevent modal toggling from clicks in the body
        if (e.target.classList[0] === "terms-inner") {
            return;
        }
        
        setTerms(!terms)
    }

    return (
        <main className="form loc-borrower">
            <form className={`form-body ${props.errors.length > 0 ? 'form-error' : ''}`} onSubmit={props.handleSubmit} id="app-form" autoComplete="off">
                <div className="additional-guarantor-form">
                    {props.children}
                </div>

                {props.showSubmit &&
                    <SubmitApplication
                        submitted={props.submitted}
                        errorMsg={props.responseMessage}
                        errors={props.errors}
                        termsToggle={termsToggle}
                        terms={terms}
                        ref={ref}
                        isEmployee={props?.isEmployee}
                    />
                }

                <section className="terms-conditions-wrapper">
                    <span className="error-message">{props?.errors?.terms && "You must agree to the Terms and Conditions."}</span>
                    <TermsModal terms={terms} termsToggle={termsToggle} whitelabel={params?.broker} />
                </section>
            </form>
        </main>
    )

})

export default withRouter(ApplicationWrapper)