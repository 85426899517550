import React from 'react';
import { Link } from 'react-router-dom';
import { Section } from '../../../components/shared/Section';
import TransitionIn from '../../../components/shared/TransitionIn';

export default function FourOhFour(props) {

    const link = (
        <Link exact to={props.accountType ? process.env.REACT_APP_SESSION_URL : '/'} style={{ textDecoration: "none !important" }}>
            {props.accountType ? "Click here to return to the Dashboard." : "Click here to return to the Customer Sign In."}
        </Link>
    )

    const content = (
        <section>
            <p>{props.message ? props.message : "We're sorry!  We were unable to find the resource you requested."} {link} </p>
        </section>
    );

    return (
        <Section heading={"Something went wrong!"} body={content} />
    )
}
