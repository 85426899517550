import React from 'react'

const InputWrapper = props => {
    return (
        <div className={`${props.inner ? 'input-field_inner' : 'input-field'} ${props.fullwidth ? 'full-width' : ''} ${props.name in props.errors ? 'error' : ''}`}>
            <label htmlFor={props.name}>{props.label}</label>
            {props.children}
            {props.name in props.errors && <span className="error-message">{props.label} is required.</span>}
        </div>
    )
}

export default React.memo(InputWrapper)