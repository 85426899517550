import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import PrivacyPolicy from './PrivacyPolicy';

// exported function to toggle modal visibility (bind to parent and parent state)
// state is lifted to parent to allow other events to toggle the display of modal
function privacyToggle(e) {
    e.preventDefault();
    e.stopPropagation();

    // prevent modal toggling from clicks in the body
    if (e.target.classList[0] === "terms-inner") {
        return;
    } else {
        // parent component will need a terms property in state (bind in parent)
        // pass this boolean value as a prop to the modal
    } 
    
    this.setState({
        privacy: !this.state.privacy
    })
}

function PrivacyModal(props) {

    // prevent page scrolling while modal visible 
    if (props.privacy) {
        document.querySelector('body').style.overflow = "hidden"
    } else {
        document.querySelector('body').style.overflow = "unset"
    }

    console.log(props)

    return (
        <div className={props.privacy ? "terms-modal is-active" : "terms-modal"} onClick={props.toggle}>
            <div className="terms-inner">
                <div className="close">
                    <button className="terms-close" data-toggle="hide" type="button">
                        <FontAwesomeIcon icon={faTimes} onClick={props.toggle}/>
                    </button>
                </div>
                <div className="terms-inner_body">
                <h1>Privacy Policy</h1>
                    <PrivacyPolicy />
                </div>
            </div>
        </div>
    )
}

// export as a component and a function toggle the modal that can be bound to the parent/parent state
export { PrivacyModal, privacyToggle }