const validateForm = (obj, log) => {
    const objArray = Object.values(obj); // transform formData to array to loop thru and check for all completed validation 

    const test = (x) => x.iVal.isValid; // expression to evaluate validation for every form input
    const result = objArray.every(test); // test validation

    if (log) {
        // console.log('validating form...')
        const keys = Object.keys(obj);
        let resultArray = []

        keys.forEach((x, index) => {
            
            let value = {
                [x]: objArray[index].iVal.isValid
            }

            resultArray.push(value);
        });
        
        // console.log(`result: ${result}`);
        // console.log(resultArray)
    }

    if(!result) {
        window.location.href = '#form'; // scroll to top (replace with history.push?)
    }

    return result; // return boolean for form validation
}

export { validateForm }