// Summary - Lower Footer (blue)

// Description - 

import React from 'react';
import './index.scss';

const FooterSecondary = props => {
    return (
        <div className="footer-secondary">
            <div className="footer-secondary_inner">
                
                <p>
                    <a href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/1324403" target="_blank">NMLS #1324403 (www.nmlsconsumeraccess.org)</a>
                </p>

                <p>
                    Lima One Capital, LLC / 201 E. McBee Ave. Suite 300. Greenville, SC 29601/ (800) 390-4212 / Lima One Capital, LLC does not  lend in Alaska, North Dakota, South Dakota, or Vermont. / Arizona Mortgage Banker License #0949706 / Florida Mortgage Servicer License # MLD1662 / Idaho Mortgage Broker/Lender License # MBL-2081324403/ Minnesota Residential Mortgage Originator License # MN-MO-1324403/ Nevada Mortgage Company License #5304/ Oregon Mortgage Lender License # ML-5397/ Utah-DRE Mortgage Entity License # 11755492 / Licensed as a California Finance Lender under the Department of Business Oversight License #60DBO-45834.
                </p>

                <div>
                    <p>© {new Date().getFullYear()} Lima One Capital</p>
                    <p>
                        <a href="https://limaone.com/privacy-policy/">Privacy Policy</a> | <a href="https://limaone.com/terms-and-conditions/">Terms and Conditions</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FooterSecondary;