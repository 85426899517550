import React, { useState, useEffect } from 'react';
import './index.scss';
import NavBroker from './NavBroker';
import NavCustomer from './NavCustomer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { throttle } from 'throttle-debounce';
import { ReactSVG } from 'react-svg';

const MainNavNew = React.memo(props => {
    const [navToggle, setNavToggle] = useState(false);

    const toggleNav = () => {
        setNavToggle(!navToggle);
    };

    const isBroker = () => {
        if (props.accountType === "CustomerAccount") {
            return false;
        } else return true
    };

    const checkWindowSize = throttle(200, false, () => {
        setNavToggle(false);
    });

    window.addEventListener("resize", checkWindowSize);

    return (
        <nav className={`nav-main-wrapper ${navToggle ? "is-active" : ""}`}>

            <div className={`nav-modal ${navToggle ? "is-active" : ""}`} onClick={toggleNav}>{/* Modal */}</div>

                <div className="main-nav_top">
                    <div className="nav-toggle nav-placeholder" onClick={toggleNav}>
                        <FontAwesomeIcon icon={navToggle ? faTimes : faBars} />
                    </div>

                    <div className="nav-logo">
                        <ReactSVG src="/assets/images/LimaOne_Logo_Mobile.svg" className="mobile" />
                        <ReactSVG src="/assets/images/logo.svg" className="desktop" />
                    </div>

                </div>

                <div className={`main-nav_body ${navToggle ? "is-active" : ""}`}>
                    {isBroker() ? <NavBroker {...props} toggleNav={toggleNav} /> : <NavCustomer {...props} toggleNav={toggleNav} />}

                    <div className={"main-nav-footer"}>
                        <div className="main-nav-footer_inner">
                            <ul>
                                <li>
                                    <a href="https://www.limaone.com/privacy-policy/" target={"_blank"}>Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="https://www.limaone.com/terms-and-conditions/" target={"_blank"}>Terms & Conditions</a>
                                </li>
                                <li>
                                    <a href="https://limaone.com/contact-us/">Contact Us</a>
                                </li>
                            </ul>
                            <p>
                                Lima One Capital LLC <a href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1324403">NMLS ID# 1324403</a>
                            </p>
                        </div>
                    </div>
                </div>
              
                
        </nav>
    )
})


export default MainNavNew