import React from 'react'
import InputWrapper from './InputWrapper'

const InputDate = React.forwardRef((props, ref) => {
    return (
        <InputWrapper {...props}>
            <input ref={ref} type={'date'} name={props.name} tabIndex={props.tabIndex} min={props.min} max={props.max} readOnly={props.readOnly} />
        </InputWrapper>
    )
})

export default React.memo(InputDate)