// ----- v SUMMARY v ------------------------------------------------------------------------ 
// - This is a boilerplate jsx file.  Please fill in the sections below explaining the parts 
//   of the function/component.  Summary is be a brief (1-2 sentence(s)) overview (required).

// ----- v DESCRIPTION v --------------------------------------------------------------------
// - Detailed description of the functionality (required)

// ----- v PROPS v --------------------------------------------------------------------------
// - Explanation of required and optional props (is applicable)

// ----- v CRITICAL NOTES v -----------------------------------------------------------------
// - Detail any critical details or notes here (is applicable).

// ----- v IMPORTS v ------------------------------------------------------------------------

import React from 'react';
import { Link } from 'react-router-dom';
import { log } from '../../../../../utility';
import ReactTooltip from 'react-tooltip';

// ----- v STATE v --------------------------------------------------------------------------
// - Explanation of state (is applicable).
// - Place content here.

// ----- v API CALLS v ----------------------------------------------------------------------
// - n/a

// ----- v RENDERING v ----------------------------------------------------------------------
// - Explanation and logic related to rendering here.

export default function PayForAppraisal(props) {

    const loans = props.loans; // Loans in Process

    // pre-rendering logic/filtering
    function loansEvaluate(arr) {

        // handle empty array or array with data
        if (!Boolean(arr.length)) {
            return [];

        } else {
            console.log(arr)
            const arrFiltered = arr.filter(x =>
                x.waive_appraisal_fee !== "true" &&
                x.appraisal_amount_paid !== null &&
                (x.chargent_paid === "false") &&
                !(x.status === "Funded" || x.status === "Dead Deal"));

            return arrFiltered;

        }
    }

    // create the rows to render if applicable
    const rows = loansEvaluate(loans).map(x => {
        return (
            <tr>
                <td>
                    <span>
                        {x.nickname || "-"}
                    </span>
                </td>
                <td>
                    <span>
                        {x.address || "-"}
                    </span>
                </td>
                <td>
                    <Link to={`/dashboard/deal/${x.id}/credit`} data-tip="Click here to pay for an appraisal for this deal." data-for="app-tooltip">
                        Pay For Appraisal
                    </Link>
                </td>
                {ReactTooltip.rebuild()}
            </tr>
        )
    })

    return rows;
}

