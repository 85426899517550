import { cloneData } from "../../../../../../../../utility/legacy/utilities_global";

const lineItem = {
    // Modify additional lineItem(s) properties
    updateLineItem(e) {
        e.preventDefault();
        e.stopPropagation();
        
        if(e.target.value === "") {
            e.target.value = 0
        }

        const lineItemKey = e.target.closest("tr").dataset.key; // parent key for lineItem
        const inputProperty = e.target.dataset.inputkey; // input element property

        let LineItemsUpdated = cloneData(this.state.lineItems); // new array to replace current state
        // Updating input element values (find lineItem by table row data-key and property by data-input from input)
        LineItemsUpdated.forEach((sublot) => {
            sublot.items.forEach((element) => {
                let elementKey = `${sublot.sublot}-${element.code}`
                // match the event target data-key to a lineItem from array
                if (elementKey === lineItemKey) {
                    element[inputProperty] = e.target.value; // update corresponding lineItem property
                }

                // if "amount" field update "remainingBalance" by netting invoiced and amount from budget
                if (element["amount"]) {
                    element.balance = parseFloat(element.budget - element.invoiced - element.amount.toString().replace(/,/g, "").replace(/\$/g, "")).toFixed(2);
                }
            });
        })

        // total the amounts
        // let total = LineItemsUpdated.reduce((acc, lineItem) => acc + parseFloat(lineItem.amount.toString().replace(/,/g, "").replace(/\$/g, "")), 0)
        let total = LineItemsUpdated.reduce((total, sublot) => {
            const sublotTotal = sublot.items.reduce((acc, lineItem) => {
                return acc + parseFloat(lineItem.amount.toString().replace(/,/g, "").replace(/\$/g, ""))
            }, 0)
            return total + sublotTotal
        }, 0)
            
        // Update new state with modified lineItems (replace whole object)
        this.setState({
            lineItems: LineItemsUpdated,
            drawTotal: Number.isNaN(total) ? 0 : total
        }, this.validateLineItems);
    },

    // Validate all additional LineItems and clean up data w/ callback 
    validateLineItems(cb) {
        // check for form submission (make sure not to mutate state - clone data if needed (see utilities))
        let valid = true;
        // let LineItemsUpdated = cloneData(this.state.lineItems); // new array to replace current state

        // LineItemsUpdated.forEach(x => {
        //     if (x.amount < 0 || x.budget < 0) {
        //         x.isValid = false;
        //         valid = false;
        //     } else {
        //         x.isValid = true
        //     }
        //     console.log(x);
        // })

        // this.setState({
        //     formValid: valid
        // })

        if (this.state.formSubmission) {
            // validation for listItems if true
            this.setState({
                formValid: valid,
                // lineItems: LineItemsUpdated
            }, cb); // callback
        }
    }
}

export { lineItem }