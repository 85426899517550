import React, { Fragment } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated } from '@react-spring/web';
import { log } from '../../../utility';

const ToggleSwitch = props => {

    const activeToggleError = () => log('ToggleSwitch - no active handler passed.');

    // active state from parent
    const active = props.active;
    const activeToggle = props.activeToggle || activeToggleError;
    const toolTipFor = props.toolTipFor || "app-tooltip";
    const toolTipData = props.toolTipData || "Toggle this option active/inactive.";
    const icon = props.icon || faCog;

    // spring animation config
    const config = {
        clamp: true,
        mass: 2,
        tension: 900,
        friction: 50
    }

    const styleIconActive = useSpring({
        config: config,
        transform: active ? "translateX(1rem)" : "translateX(0rem)"
    });

    const styleToggleActive = useSpring({
        config: config,
        backgroundImage: active ? "linear-gradient(to bottom left, #0f9e0f, #04a504)" : "linear-gradient(to bottom left, #EB3941, #d10913)"
    })

    const toggleActive = function () {
        activeToggle(!active);
    };


    return (
        <Fragment>

            <animated.button className="toggle-switch" data-tip={toolTipData} data-for={toolTipFor} onClick={toggleActive} style={styleToggleActive}>
                <animated.span style={styleIconActive} className="toggle-switch_icon">
                    <FontAwesomeIcon icon={icon} />
                </animated.span>
            </animated.button>

        </Fragment>
    )
};

export default ToggleSwitch;