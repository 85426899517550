const pfs = function(fd) {
    let liquidAssets = (
        fd.liquid_assets_cash.iVal.value +
        fd.retirement_accounts.iVal.value +
        fd.stocks_bonds.iVal.value +
        fd.other_liquid_assets.iVal.value
    );

    let nonLiquidAssets = (
        fd.non_liquid_real_estate_assets.iVal.value +
        fd.autos.iVal.value +
        fd.other_personal_propery.iVal.value
    );

    let debt = (
        fd.real_estate_debt.iVal.value +
        fd.revolving_debt.iVal.value +
        fd.installment_debt.iVal.value +
        fd.other_debt.iVal.value +
        fd.notes_payable.iVal.value
    )

    let netWorth = (liquidAssets + nonLiquidAssets) - debt;

    fd.total_liquid_assets.iVal = {
        value: liquidAssets,
        isValid: liquidAssets !== 0 ? true : false
    };
    fd.total_non_liquid_assets.iVal = {
        value: nonLiquidAssets,
        isValid: nonLiquidAssets !== 0 ? true : false
    };
    fd.total_debt.iVal = {
        value: debt,
        isValid: debt !== 0 ? true : false
    };
    fd.net_worth.iVal = {
        value: netWorth,
        isValid: fd.total_liquid_assets.iVal.isValid && 
        fd.total_non_liquid_assets.iVal.isValid && 
        fd.total_debt.iVal.isValid ? true : false
    };

    return fd;
}

export { pfs }