import React, {useEffect, useState} from 'react';
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form"
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import states from "../../../../assets/config/_us_states.json";
import { handleInput } from '../../../../assets/javascript/utilities/hookforms_handle_input'
import InputSelect from "../../_components/InputSelect";
import InputText from "../../_components/InputText";
import InputTextField from "../../_components/InputTextField";
import InputDate from "../../_components/InputDate";
import TermsModal from "../../_components/terms";
import SubmitApplication from "../../_components/SubmitApplication";
import {checkFileSize, encodeJson, getBinary} from '../../_api';
import { Section } from '../../../../App/components/shared/Section';
import './index.scss'
import {fileTypes} from "../_extension";

const LossMitigation = props => {

    const [submitted, setSubmitted] = useState(false)
    const [responseMessage, setResponse] = useState(null)
    const [success, setSuccess] = useState(null); // state for API submission
    const { register, handleSubmit, errors, watch } = useForm()
    const [terms, setTerms] = useState(false)
    const watchLoanType = watch('loan_type', null)

    const onSubmit = async formData => {
        setSubmitted(true);

        let documents = []

        if (formData['file1'].length > 0) {
            let base64 = await getBinary(formData['file1'][0])
            let type = formData['file1'][0]['name'].split(".").pop()
            documents.push({
                fileData: base64.replace(/^data:.+;base64,/, ''),
                fileName: `File1.${type}`
            })
        }
        if (formData['file2'].length > 0) {
            let base64 = await getBinary(formData['file2'][0])
            let type = formData['file2'][0]['name'].split(".").pop()
            documents.push({
                fileData: base64.replace(/^data:.+;base64,/, ''),
                fileName: `File2.${type}`
            })
        }
        if (formData['rent_roll'].length > 0) {
            let base64 = await getBinary(formData['rent_roll'][0])
            let type = formData['rent_roll'][0]['name'].split(".").pop()
            documents.push({
                fileData: base64.replace(/^data:.+;base64,/, ''),
                fileName: `Rent_Roll.${type}`
            })
        }

        let files = { files: documents }

        encodeJson(formData)

        let fd = { ...formData, ...files }

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.post(process.env.REACT_APP_LIMA + `/lima/api/loss-mitigation`, fd
        ).then((data) => {
            setSubmitted(false);
            setSuccess(true);
        }
        ).catch(error => {
            setSubmitted(false);
            setSuccess(false);
        });

    }

    const termsToggle = e => {
        e.preventDefault();
        e.stopPropagation();

        // prevent modal toggling from clicks in the body
        if (e.target.classList[0] === "terms-inner") {
            return;
        } else {

            // parent component will need a terms property in state (bind in parent)
            // pass this boolean value as a prop to the modal
        }

        setTerms(!terms)
    }

    // If API submission returns 200, redirect to thank you, otherwise show form
    if (success === true) {
        return <Redirect
            to={{
                pathname: '/thank-you/form',
                state: { external: props?.external === true, message: "Thank you we will reach out quickly to the email provided!" }
            }}
        />
    } else
        return (
            <main className="form loc-borrower " id="loss-mitigation-form" >

                <form className={`form-body ${errors.length > 0 ? 'form-error' : ''}`} onSubmit={handleSubmit(onSubmit)} >

                    <div className="payoff-request-form">
                        <Section heading={"COVID-19 Hardship Relief Application"} body={
                            <>
                                <p>Important Notice: All fields must be completed before the form can be submitted. The "Borrower Name" should be the name of the borrowing entity (the LLC).</p>

                                <div className="row grow-2">
                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Borrowing Entity*'} name={'borrowing_entity'} errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Affected Property*'} name={'affected_property'} errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Loan Number*'} name={'loan_number'} errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Loan Amount*'} onChange={handleInput.inputCurrency} name={'loan_amount'} placeholder="$0.00" errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputSelect ref={register({ required: true })} config={[
                                            {
                                                name: 'Rental',
                                                value: 'Rental'
                                            },
                                            {
                                                name: 'FixNFlip',
                                                value: 'FixNFlip'
                                            },
                                            {
                                                name: 'New Construction',
                                                value: 'New Construction'
                                            }
                                        ]} label={'Loan Type*'} name={'loan_type'} errors={errors} />
                                    </div>
                                    <div className="column">
                                        <InputTextField ref={register({ required: true })} type={'text'} label={'Request*'} name={'request'} errors={errors} />
                                    </div>
                                </div>
                            </>
                        } />

                        <Section heading={"COVID-19 - Affected Area"} body={

                            <div className="row grow-2">
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'City*'} name={'city_property'} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={states} label={'State / Province / Region'} name={'state_property'} errors={errors} />
                                </div>

                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={[
                                        {
                                            name: 'Social distancing advisory - essential businesses still in operation',
                                            value: 'Social distancing advisory - essential businesses still in operation'
                                        }
                                    ]} label={'Please outline the active Government order/advisory level'} name={'advisory_level'} errors={errors} />
                                </div>

                                <div className="column">
                                    <InputTextField ref={register({ required: true })} type={'text'} label={'Provide Detail*'} name={'advisory_details'} errors={errors} />
                                </div>
                            </div>

                        } />

                        <Section heading={"Personal Income and Assets"} body={
                            <>
                                <h2>Employer Name and Address</h2>

                                <div className="row grow-3">
                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Name of Employer*'} name={'employer_name'} errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'City*'} name={'employer_city'} errors={errors} />
                                    </div>
                                    <div className="column">
                                        <InputSelect ref={register({ required: true })} config={states} label={'State / Province / Region'} name={'employer_state'} errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputSelect ref={register({ required: true })} config={[
                                            {
                                                name: 'Yes',
                                                value: 'Yes'
                                            },
                                            {
                                                name: 'No',
                                                value: 'No'
                                            }
                                        ]} label={'Self Employed?'} name={'self_employed'} errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputDate ref={register({ required: true })} label={'Start Date*'} name={'start_date'} errors={errors} />
                                    </div>
                                    <div className="column">
                                        <InputDate ref={register({ required: true })} label={'End Date*'} name={'end_date'} errors={errors} />
                                    </div>
                                </div>

                                <h2>What were monthly household income and net worth prior to March 1, 2020?</h2>
                                <div className="row grow-2">
                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Monthly Income*'} onChange={handleInput.inputCurrency} name={'prior_monthly_income'} placeholder="$0.00" errors={errors} />
                                    </div>
                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Net Worth*'} onChange={handleInput.inputCurrency} name={'prior_net_worth'} placeholder="$0.00" errors={errors} />
                                    </div>
                                </div>

                                <h2>What were (or expected) monthly household income and net worth for the next three months?</h2>
                                <div className="row grow-3">
                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Monthly Income*'} onChange={handleInput.inputCurrency} name={'monthly_income'} placeholder="$0.00" errors={errors} />
                                    </div>
                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Net Worth*'} onChange={handleInput.inputCurrency} name={'net_worth'} placeholder="$0.00" errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputDate ref={register({ required: true })} label={'Last Mortgage Payment*'} name={'last_mortgage_payment'} errors={errors} />
                                    </div>
                                </div>

                                <h2>Most recent Bank Statement(s) (Statement(s) requested with Hardship Application)</h2>
                                <div className="row grow-2">
                                    <div className="column">
                                        <label htmlFor="file1">File 1</label>
                                        <input ref={register({ required: true })} type="file" accept={fileTypes} name="file1" onChange={checkFileSize} />
                                        {'file1' in errors &&
                                            <span className="error-message">File 1 is Required.</span>}
                                    </div>

                                    <div className="column">
                                        <label htmlFor="file2">File 2</label>
                                        <input ref={register({ required: true })} type="file" accept={fileTypes} name="file2" onChange={checkFileSize} />
                                        {'file2' in errors &&
                                            <span className="error-message">File 2 is Required.</span>}
                                    </div>
                                </div>

                                {watchLoanType === 'Rental' &&
                                    <div className="row grow-2">
                                        <div className="column">
                                            <label htmlFor="rent_roll" className="bold">Please provide the last 3-6 months of rent roll for the property (<a href="/documents/Rent_Roll_Spreadsheet.xlsx" download>download template</a>)</label>
                                            <input ref={register({ required: true })} type="file" accept={fileTypes} name="rent_roll" onChange={checkFileSize} />
                                            {'rent_roll' in errors &&
                                                <span className="error-message">Rent Roll is Required.</span>}
                                        </div>
                                    </div>
                                }
                            </>
                        } />

                        <Section heading={"Construction"} body={

                            <div className="row grow-3">
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={[
                                        {
                                            name: 'Yes',
                                            value: 'Yes'
                                        },
                                        {
                                            name: 'No',
                                            value: 'No'
                                        }
                                    ]} label={'Has construction of the project halted due to COVID-19? (if applicable)'} name={'halted_construction'} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Percentage Complete*'} name={'completed_construction'} onChange={handleInput.inputPercentage} placeholder="0%" errors={errors} />
                                </div>

                                <div className="column">
                                    <InputTextField ref={register({ required: true })} type={'text'} label={'Further delay reasoning/explanation'} name={'construction_delay_reasoning'} errors={errors} />
                                </div>
                            </div>
                        } />

                        <Section heading="Borrower's Financial Condition" body={
                            <div className="row grow-2">
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={[
                                        {
                                            name: 'Yes',
                                            value: 'Yes'
                                        },
                                        {
                                            name: 'No',
                                            value: 'No'
                                        }
                                    ]} label={'How have Borrower’s financial condition, the value of the property, and the property’s viability as an investment been adversely impacted due to COVID-19 leading to this request for hardship relief?'} name={'borrowers_financial_condition'} errors={errors} />
                                </div>


                                <div className="column">
                                    <InputTextField ref={register({ required: true })} type={'text'} label={'Further reasoning/explanation'} name={'borrower_financial_reasoning'} errors={errors} />
                                </div>
                            </div>
                        } />

                        <SubmitApplication
                            submitted={submitted}
                            errorMsg={responseMessage}
                            errors={errors}
                            termsToggle={termsToggle}
                            ref={register({ required: true })}
                            style={{ padding: 'unset', paddingTop: '3rem' }}
                        />

                    </div>
                </form>

                {/* Terms Modal */}
                <section className="terms-conditions-wrapper">
                    <TermsModal terms={terms} termsToggle={termsToggle} />
                </section>
            </main>
        )
}

export default withRouter(LossMitigation)