import React, { Fragment, useState } from 'react';
import './index.scss';
import ReactTooltip from 'react-tooltip';
import { faMousePointer } from '@fortawesome/free-solid-svg-icons';
import ToggleSwitch from '../../../shared/ToggleSwitch';

const ToolTips = props => {

    const [enabled, setEnabled] = useState(props.options.toolTips);

    const toggleTips = function () {
        setEnabled(!enabled);
        props.optionsUpdate("toolTips", !enabled);
        ReactTooltip.rebuild();
    };

    return (
        <Fragment>

            <ReactTooltip id="app-tooltip-toggle" getContent={() => { return enabled ? "Disable ToolTips." : ""}} place="left" />

            <ToggleSwitch active={enabled} icon={faMousePointer} activeToggle={toggleTips} toolTipFor={"app-tooltip-toggle"} tooltipData={''} />

            {
                enabled ?
                    <ReactTooltip
                        textColor="white"
                        backgroundColor="#2E4B71"
                        arrowColor="#2e4b7100"
                        id="app-tooltip" />
                    : null
            }

        </Fragment>
    )
};

export default ToolTips;