import React from 'react'
import InputSelect from '../../_components/InputSelect'
import InputText from '../../_components/InputText'
import InputDate from '../../_components/InputDate'
import configLP from '../../_components/configs/_loan_purpose.json'
import configPM from '../../_components/configs/_property_manager.json'
import states from '../../_components/configs/_us_states.json'

export const PropertyInformation = React.forwardRef((props, ref) => {

    var watchLoanPurpose = props.watch("loan_purpose"); 

    let renderDOP = watchLoanPurpose && watchLoanPurpose !== 'Purchase';

    return (
        <>
        {/* ----- Property Info ----- */}

        <div className="row">

            <div className="column">
                <InputSelect ref={ref} config={configLP} label={'Loan Purpose*'} name={'Loan_Purpose__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>

            {renderDOP &&
                <div className="column">
                    <InputDate ref={ref}  label={'Date the property was originally purchased*'} name={'Originally_Purchased_Date__c'} tabIndex={props.tabIndex} errors={props.errors} max={new Date().toISOString().slice(0, 10)} />
                </div>
            }

            <div className="column">
                <InputText ref={ref} onChange={props.inputCurrency}  placeholder="$0.00" type={'text'} label={'Requested Loan Amount*'} name={'Requested_Loan_Amount__c'} tabIndex={props.tabIndex} errors={props.errors} cleanup={true} />
            </div>

            <div className="column">
                <InputDate ref={ref}  label={'Target Close Date*'} name={'Target_Close_Date__c'} tabIndex={props.tabIndex} errors={props.errors} min={new Date().toISOString().slice(0, 10)} />
            </div>

            <div className="column">
                <InputSelect ref={ref} config={states} label={'State*'} name={'State__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>

        </div>

        <hr />
        <div className="row">
            <div className="column">
                <InputSelect ref={ref} config={configPM} fullwidth={true} label={'Who intends to serve as the property manager?*'} name={'Property_Manager__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
        </div>
        <hr />

        </>
    )

})