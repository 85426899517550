import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
import MemoizedIcon from '../MemoizedIcon';
import TermsAdditionalGuarantor from './TermsAdditionalGuarantor'
import TermsWhitelabel from "./TermsWhitelabel";
import TermsBroker from "./TermsBroker";
import TermsCustomer from "./TermsCustomer";

const TermsModal = ({terms, termsToggle, whitelabel, additionalGuarantor}) => {


    // prevent page scrolling while modal visible 
    if (terms) {
        document.querySelector('body').style.overflow = "hidden"
    } else {
        document.querySelector('body').style.overflow = "unset"
    }


    return (
        <div className={terms ? "terms-modal is-active" : "terms-modal"} onClick={termsToggle}>
            <div className="terms-inner">
                <div className="close">
                    <button className="terms-close" data-toggle="hide" type="button">
                        <MemoizedIcon icon={faTimes} onClick={termsToggle} />
                    </button>
                </div>
                <div className="terms-inner_body">
                <h1>Terms & Conditions</h1>
                    {additionalGuarantor ? <TermsAdditionalGuarantor /> : whitelabel ? <TermsWhitelabel /> : window.localStorage.getItem('accountType') === 'CustomerAccount' ? <TermsCustomer /> : <TermsBroker />}
                </div>
            </div>
        </div>
    )
    
}

export default React.memo(TermsModal)