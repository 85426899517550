import React, { useState } from 'react';
import './index.scss';
import { log } from '../../../utility';

// Imports - components, routes, etc. for testing
import { Section, SectionExpandable } from '../../../components/shared/Section';

const Sandbox = props => {
    log('Sandbox - props', props);

    const style = {
        fontWeight: 800,
    };

    const styleControls = {
        padding: "0 1rem"
    };

    const listStyle = {
        listStyle: "square",
        padding: "0 1rem",
        marginLeft: "1rem",
        display: "flex",
        flexWrap: "wrap"
    };

    const listItemStyle = {
     flexBasis: "20%",
     margin
     : "0.5rem"
    };

    const headingStyle = {
        padding: "0 0 !important",
        margin: "1rem 0 !important",
        fontWeight: 800,
        fontSize: "1.25rem"
    };

    const fakeData = [
        "lemons", "limes", "coconuts", "apples", "oranges",
        "bananas", "grapefruits", "lettuce", "turnips",
        "grapes", "kiwis", "bread"
    ];

    const [fakeDataFiltered, setFakeDataFiltered] = useState(fakeData);

    function filterFakeData(e) {
        const fakeDataFiltered = fakeData.filter(x => x.includes(e.target.value));

        if (fakeDataFiltered.length === 0) {
            setFakeDataFiltered([`No results for "${e.target.value}""`])
        } else {
            setFakeDataFiltered(fakeDataFiltered)
        };
    };

    const controls = (
        <input type="text" 
        placeholder="Search" 
        data-tip={"Click here to interact with this section."}
        style={styleControls} 
        onChange={filterFakeData} />
    );

    const body = (
        <div>
            <p>
                I am an expandable section that includes filterable content.
                Type in the search control element to see me update the parent component state and update your content below.
            </p>

            <br />

            <p>
                I have been hooked into the expansion functionality to auto-expand content on input change.
            </p>

            <br />

            <h6 style={headingStyle}>
                Grocery List
            </h6>

            <hr />

            <ul style={listStyle}>
                {
                    fakeDataFiltered.map(x => {
                        return (
                            <li style={listItemStyle}>
                                {x}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );

    return (
        <div className="Sandbox">
            <h1>Sandbox</h1>
            <p>This is a Sandbox page to experiment with components, routing, etc... <br />
                This route is not available outside of dev environments (<span style={{ style }}>process.env.REACT_APP_DEV_MODE = {process.env.REACT_APP_DEV_MODE.toString()}</span>)</p>

            <Section
                noHeading
                body={<p>I am a basic section without a heading.</p>} />

            <Section
                heading={"Section"}
                body={<p>I am a basic section with a heading.  You can even add icons to the heading!</p>} />

            <SectionExpandable
                heading={"Section Expandable - Absurdly Long Title"}
                body={body}
                animate={true}
                controls={controls} />

            <SectionExpandable expanded heading={"Section Expandable - Fullwidth Click Enabled"} click={true} animate
                body={<p>I am a section with the expandable functionality.  I can also have optional controls,
                    animations, and a fullwidth clickable heading.</p>} />
        </div>
    );
};

export default Sandbox;