// ----- v SUMMARY v ------------------------------------------------------------------------ 
// - Component for Sections (basic and expandable) with headings (wrappers/containers).

// - Includes 2 components (Section and SectionExpandable) with variable props and behaviors.


// ----- v DESCRIPTION v --------------------------------------------------------------------
// - Section is a basic section element with an optional (default included) heading.

// - Data and api calls are completely decoupled from the the expand/collapse functionality.

// - The parent component (including API calls, heading, main content) can contain all
//   of the rendering logic, so we place the API call in the parent and include an element 
//   with our data/logic to pass as the content prop.  

// - Any filtering or search element can be dropped in as a controls props and it will 
//   automatically slide to the right by the expansion button.  Include a function bound
//   to the parent as a prop to handle any filtering or manipulation of the main content.


// ----- v PROPS v --------------------------------------------------------------------------
// - Props contains some shared and some explicit items.  

// ----- v Shared v -------------------------------------------------------------------------
// - id - string (optional).  Unique ID for element/style targeting/overrides.

// - heading - string/element/component (required).  Heading text/element for section.

// - body - element/component (required).  Child element rendered in the content section.

// ----- v Section v ------------------------------------------------------------------------
// - noHeading - (optional) disables the heading element to allow a basic section wrapper.
//   Defaults to false (headings will render unless explicitly disabled).

// ----- v SectionExpandable v --------------------------------------------------------------
// - controls - element/component (optional).  Optional controls for manipulation of content.

// - expanded - controls the intial expanded state of the body content/element. Defaults to 
//   true (expanded, expanded={true}, or simply omit) unless the prop is explicity set to false 
//   (expanded={false} loads closed)

// - headingOnClick - boolean (optional).  TRUE for whole heading clickable and show pointer, 
//   FALSE for only clickable toggle icon.

// - flush - removes the top padding of section content

// ----- v STATE v --------------------------------------------------------------------------
// - Section maintains no internal state.  SectionExpandable maintains the expanded state via 
//   the useState hook. See below for details...

// ----- v Shared v -------------------------------------------------------------------------
// - Any state that deals with the content itself should be maintained in the parent and 
//   passed to the children.

// - Data, API calls, etc. are decoupled from the Section/Expandable elements.  Content from 
//   state, including any loading/spinner logic, should be maintained higher up and passed in
//   the heading/controls and body props.


// ----- v SectionExpandable v --------------------------------------------------------------
// - The ONLY state that should be managed internally is the expanded state (set via the 
//   expanded prop initially) and subsequently controlled using the useState hook.

// - noGrow - adds class "no-grow" to controls to prevent underdesirable sizing of toggle.

// ----- v RENDER v -------------------------------------------------------------------------
// - Content is created via passing props (strings or other components). Do not alter here.

// - If style overrides are needed please place them in the parent component scss.

// - The dev variable (process.env.REACT_APP_DEV_MODE) enables borders to be visible.

// - Default content for missing heading and body props shows default or error content (if 
//   Dev Mode is enabled in .env)


// ----- v CRITICAL NOTES v -----------------------------------------------------------------
// - Including a controls prop will override the headingOnClick prop and disallow the use
//   of a full-width clickable heading to allow use of the controls.

// ----- v IMPORTS v ------------------------------------------------------------------------
import React, { useState } from 'react';
import { SectionHeading, SectionExpandableHeading } from './Heading';
import { SectionBody, SectionExpandableBody } from './Body';
import './index.scss';
import { generateId } from '../../../utility';

// dev mode
let dev = process.env.REACT_APP_DEV_MODE;

// default content rendering logic when props are not passed
function devError(error) {
    if (dev) {
        return (
            <span className={`dev-error`}>
                Error{error ? ": " + error : ""}
            </span>
        )
    } else {
        return false;
    }
}

// ----- v SECTION (base with optional heading) v -------------------------------------------
const Section = props => {

    // default props and renaming
    const [id] = useState(props.id || generateId(99999, 10000));
    const className = props.class || "";
    const heading = props.heading || devError("No object provided as props.heading.");
    const body = props.body || devError("No object provided as props.body.");
    const noHeading = props.heading || false;
    const controls = props.controls || false;

    return (
        <section className={`section-new ${className}`} id={id}>

            {noHeading ? <div className="section_heading">
                <SectionHeading heading={heading} controls={controls} max={props.max} />
            </div> : null}

            <div className="section_content">
                <SectionBody body={body} />
            </div>

        </section>
    )
}


// ----- v SECTION EXPANDABLE (expandable toggle with optional controls) v ------------------
const SectionExpandable = props => {

    // default props and renaming
    const click = props.click || false;
    const [id] = useState(props.id || generateId(9999, 1000));
    const className = props.class || "";
    const heading = props.heading || devError("No object provided as props.heading.");
    const controls = props.controls || false;
    const body = props.body || devError("No object provided as props.body.");
    const animate = props.animate || true;
    const callback = props.callback || false;
    const flush = props.flush || false;
    const noGrow = props.noGrow || false;

    // expanded state hook (default to true for expanded/show)
    const [expanded, setExpanded] = useState(props.expanded === false ? false : true);

    return (
        <section className={`section-new expandable ${className}`} id={id}>

            <div className={`section_heading expandable ${controls ? 'with-controls' : ""}`}>

                <SectionExpandableHeading
                    expanded={expanded}
                    heading={heading}
                    controls={controls}
                    click={click}
                    setExpanded={setExpanded}
                    body={body}
                    animate={animate}
                    id={id}
                    noGrow={noGrow}
                    callback={callback} />
            </div>

            {/* Flush is needed for table sections, for example... */}
            <div className={`section_content expandable ${flush ? "flush" : ""}`}>

                <SectionExpandableBody
                    body={body}
                    setExpanded={setExpanded}
                    expanded={expanded}
                    animate={animate}
                    flush={flush} />
            </div>

        </section>
    )
}

export { Section, SectionExpandable }