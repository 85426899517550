// ----- v SUMMARY v ------------------------------------------------------------------------ 

// ----- v DESCRIPTION v --------------------------------------------------------------------

// ----- v PROPS v --------------------------------------------------------------------------

// ----- v CRITICAL NOTES v -----------------------------------------------------------------

// ----- v IMPORTS v ------------------------------------------------------------------------

import React, { useEffect, useState, Fragment } from 'react';
import './index.scss';
import { Switch } from 'react-router-dom';
import ResourcesCustomer from './CustomerResources';
import CustomerDashboard from './CustomerDashboard';
import axios from 'axios';
import DashboardTopNav from '../Navigation';
import { Base, Min } from '../../../Layouts';
import { RouteBasic } from '../../../Wrappers';
import { log, removeDuplicates } from '../../../../utility';
import FourOhFour from '../../FourOhFour';

function Dashboard(props) {
    const [recentLOCs, setRecentLOCs] = useState(null); // LOC data - recent
    const [acctLOCs, setAcctLOCs] = useState(null); // Acct LOC data - recent
    const [recentUnderwritings, setRecentUnderwritings] = useState(null); // LOC data - underwriting
    const [plaid, setPlaid] = useState(null); // plaid modified date array

    const [loaded, setLoaded] = useState(false); // loaded (needed for collapse componet called further down tree)
    const [userId, setUserId] = useState(null);

    // LOC Section (deal with this later)
    const [iframeURL, setiFrameURL] = useState(null)
    const [locFiles, setLocFiles] = useState([])
    const [locUrls, setLocUrls] = useState([])

    // ----- v API CALLS v ----------------------------------------------------------------------
    // - moved the LOC query here from the actual LOC component since it is used in 
    //   multiple places and should not call when flipping to resources and back

    // - FUTURE - MOVE DASHBAORD API CALLS HERE (AXIOS.ALL) AND LOAD ONCE ALL COMPELTE OR FAIL

    // api call for Plaid last modified dates
    const getPlaidData = async () => {
        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.token;
        axios.defaults.headers['Content-Type'] = "application/json";

        if (process.env.REACT_APP_ENABLE_ACTION_ITEMS == 'true') {
            try {
                let { data } = await axios.get(process.env.REACT_APP_LIMA +
                    '/lima/api/users/get-plaid-data', { id: props.user.userId });

                setPlaid(data.datesLastModified || []);
            } catch (error) {
                // silent error... do nothing (no render)
                setPlaid([]);
            }
        }
    }

    // this needs to be moved higher in the dashboard and only be called once (also in recent LOC) - slow query
    const loadRecentLOCStatus = async () => {
        const url = process.env.REACT_APP_LIMA + "/lima/api/most-recent-locs";

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";

        try {
            const response = await axios.post(url);

            let recentLOCs = removeDuplicates(response?.data?.recentLOCs, "Id") || [];
            let recentUnderwritings = removeDuplicates(response?.data?.recentUnderwritings, "Id") || [];
            let acctLOCs = response?.data?.acctLOCs || [];


            // below is used for LOC checklist 
            setiFrameURL(response?.data?.iframeURL)

            let files = response?.data?.files?.files || response?.data?.files || [];
            let urls =  response?.data?.files?.urls || response?.data?.urls || [];

            const getFiles = (linkedEntityId) => files.filter((file) => file.LinkedEntityId === linkedEntityId);

            recentLOCs = recentLOCs.filter((loc) => loc?.checklistItems).map((loc) => {
                loc.checklistItems.forEach((checklistItem) => {
                    checklistItem.files = getFiles(checklistItem.Id);
                });
                return loc;
            });

            recentUnderwritings = recentLOCs.filter((loc) => loc?.checklistItems).map((loc) => {
                loc.checklistItems.forEach((checklistItem) => {
                    checklistItem.files = getFiles(checklistItem.Id);
                });
                return loc;
            });

            // ActionItems data
            setAcctLOCs(acctLOCs);
            setRecentLOCs(recentLOCs);
            setRecentUnderwritings(recentUnderwritings);
            setLocFiles(files)
            setLocUrls(urls)
            setLoaded(true);
        } catch (error) {
            // silent error... do nothing (no render)
            console.log(error)
            setAcctLOCs([])
            setRecentLOCs([]);
            setRecentUnderwritings([]);
            setLocFiles([])
            setLocUrls([])
            setLoaded(true);
        }
    }

    useEffect(() => {
        if (props.user.userId !== userId) {
            setPlaid(null)
            setAcctLOCs(null)
            setRecentLOCs(null)
            setRecentUnderwritings(null)
            setUserId(props.user.userId);
        }
        loadRecentLOCStatus(); // get LOC data
        getPlaidData(); // get Plaid mod date data
    }, []
    // [props.user, loaded]
    )

    const content = (
        <main className="dashboard-customer">
            <DashboardTopNav {...props} />

            <Switch>

                    <RouteBasic path={`${process.env.REACT_APP_SESSION_URL}/resources`}
                    exact={true}
                    layout={Base}
                    component={ResourcesCustomer}
                    title="Customer Resources" />
                    
                <RouteBasic path={`${process.env.REACT_APP_SESSION_URL}`}

                    // Basic config
                    exact={true}
                    layout={Base}
                    component={CustomerDashboard}
                    title="Customer Dashboard" 
                    setLocUrls={setLocUrls}

                    // LOC section
                    iframeURL={iframeURL}
                    locFiles={locFiles}
                    setLocFiles={setLocFiles}
                    // locFiles={locFiles}
                    locUrls={locUrls}

                    // Multiple Sections
                    acctLOCs={acctLOCs}
                    recentLOCs={recentLOCs}
                    updateLOCs={loadRecentLOCStatus}
                    recentUnderwritings={recentUnderwritings}
                    plaidModDates={plaid}
                    getPlaidData={getPlaidData}
                    {...props} />

                <RouteBasic path
                    layout={Min}
                    component={FourOhFour}
                    accountType={props.accountType}
                    title="Resource Not Found" />
            </Switch>
        </main>
    )

    
    const leadContent = (
        <main className="dashboard-customer">
            <Base>
                <ResourcesCustomer />
            </Base>
        </main>
    )
    return (
        props.user.isLead?.toString() === "true" ? leadContent : content
    )
};

export default Dashboard
