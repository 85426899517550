import { Transition } from '@react-spring/core';
import React, { Fragment } from 'react';
import TransitionIn from '../../../../../components/shared/TransitionIn';
import { Section } from '../../../../../components/shared/Section';
import './index.scss';

const Resources = (props) => {

    const content = (
        <Fragment>

            <Section heading={"Resources"} class="broker-resources" body={
                <Fragment>
                    <a download href="/documents/resources/Broker-Packet-Lima-One-1.4.2021-2R30.pdf">About Lima One and Our Programs</a>
                    <br />
                    <br />
                    <p>
                        Below you will find unbranded marketing materials for each of our unique loan programs.
                        These materials contain useful program information that will give your clients a better understanding of each program before they apply.
                    </p>
                    <br />

                    <h3>Client Applications</h3>
                    <hr/>
                    <br />
                    <p>
                        Below you will find applications for each of our programs.
                        Click the link and download the application or complete it online and submit it to your Broker Consultant.
                    </p>
                    <br />

                <ul>
                    <li>
                        <a download href="/documents/resources/Borrower-Guarantor-app-fillable-whitelabel.pdf">Borrower, Guarantor Application</a>
                    </li>    
                    <li>
                        <a download href="/documents/resources/White-Label-Credit-Consent-Letter.docx">Whitelabel Credit Consent Letter</a>
                    </li>
                    <li>
                        <a download href="/documents/resources/Fix-Flip-Bridge-app-fillable-whitelabel-1.pdf">FixNFlip Bridge Application (Whitelabel)</a>
                    </li>
                    <li>
                        <a download href="/documents/resources/Rental-Single-Property-App.pdf">Rental Single Property Application</a>
                    </li>
                    <li>
                        <a download href="/documents/resources/Rental-Portfolio-Property-App.pdf">Rental Portfolio Property Application</a>
                    </li>
                    <li>
                        <a download href="/documents/resources/New-Construction-Property-App.pdf">New Construction Property Application</a>
                    </li>
                    <li>
                        <a download href="/documents/resources/Secondary-Guarantor-if-applicable-app-fillable-whitelabel.pdf">Secondary Guarantor (If Applicable)</a>
                    </li>
                    <li>
                        <a download href="/documents/resources/Canadian-Order-Form.doc">Canadian National, Guarantor Application (If Applicable)</a>
                    </li>
                    <li>
                        <a href="/dashboard/servicing-requests/capex">CapEx Request Form</a>
                    </li>
                    <li>
                        <a href="/dashboard/servicing-requests/payoff">Payoff Request Form</a>
                    </li>
                    <li>
                        <a href="/dashboard/servicing-requests/extension">Loan Extension Request Form</a>
                    </li>
                </ul>
             

                <h3>Marketing Materials</h3>
                <hr/>

                <div className="marketing-materials">
                    <div className="col">
                        <div className={"img-container"}><img src="/assets/images/FIXNFLIP-ICON@2x.png" alt="img" /></div>
                        <ul>
                            <li>
                                <a download href="/documents/resources/White-Label-Short-Term-Investment-Property-Financing.pdf">FixNFlip White Label</a>
                            </li>
                            <li>
                                <a download href="/documents/resources/New-Construction-Brokers.pdf">New Construction White Label</a>
                            </li>
                            <li>
                                <a download href="/documents/resources/Onboarding-Contractor-Profile.pdf">Contractor Bio and Referral</a>
                            </li>
                            <li>
                                <a download href="/documents/resources/Mortgagee-Clause-Lima-One-Capital-1.pdf">Mortgagee Clause</a>
                            </li>
                            <li>
                                <a download href="/documents/resources/Construction-Budget-Template-BR-ILU-v.4.4.zip">FixNFlip Construction Budget</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col">
                        <div className={"img-container"}><img src="/assets/images/RENTAL-ICON-166-271x125.png" alt="img" /></div>
                        <ul>
                            <li>
                                <a download href="/documents/resources/White-Label-Rental-Property-Financing-Broker-1.pdf">Rental Property Financing</a>
                            </li>
                            <li>
                                <a download href="/documents/resources/Mortgagee-Clause-Rental30-1.pdf">Rental30 Mortgagee Clause</a>
                            </li>
                            <li>
                                <a download href="/documents/resources/Rent-Roll-Spreadsheet.xlsx">Rent Roll Template</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col">
                        <div className={"img-container"}><img src="/assets/images/Multifamily-191x125.png" alt="img" /></div>
                    </div>
                    </div>
                </Fragment>
            } />

            <Section heading={"Additional Materials"} class="broker-resources" body={
                <Fragment>
                    <ul>
                        <li>
                            <a download href="/documents/resources/Infographic-How-the-Loan-Process-Works-White-Label.pdf">How the Loan Process Works White Label Infographic</a>
                        </li>
                        <li>
                            <a download href="/documents/resources/Infographic-Rental-Loan-Options-White-Label.pdf">Rental Loan Options White Label Infographic</a>
                        </li>
                        <li>
                            <a download href="/documents/resources/PFS-TEMPLATE.xlsx">PFS Template</a>
                        </li>
                        <li>
                            <a download href="/documents/resources/Track-Record-&-REO-Schedule.xlsx">Track Record & REO Schedule</a>
                        </li>
                        <li>
                            <a download href="/documents/resources/Property-Management-Questionnaire.pdf">Property Management Questionnaire</a>
                        </li>
                    </ul>
                </Fragment>
            } />

        </Fragment>
    )
    return (
        <TransitionIn content={content} />
    )
}

export default Resources
