import React from 'react'
import InputWrapper from './InputWrapper'

const InputSelect = React.forwardRef((props, ref) => {
    return (
        <InputWrapper {...props}>
            <select ref={ref} className="area-input" multiple={props.multiple ? true : false} name={props.name} tabIndex={props.tabIndex} disabled={props.readOnly} onChange={props.onChange} data-cleanup={props.cleanup}>
                <option key="CHOOSE" value="">Select</option>
                {props.config.map((x, index) => {
                    return (
                        <option key={props.name + index} name={props.name} value={props?.useName ? x.name : (x?.abbreviation || x?.value)}>{x.name}</option>
                    )
                })}
            </select>
        </InputWrapper>
    )
})

export default React.memo(InputSelect)