import { validateInput } from '../../../../assets/javascript/utilities/utilities_validation_input'; // global input validation utilities
import { log } from '../../../../assets/javascript/utilities/utilities_global'

const handleInputsCustom = {

    inputSignature(e) {
        const iv = e.target.value;
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        fd[op] = {
            iRaw: iv,
            iVal: {
                value: validateInput.text(iv),
                isValid: iv.length > 0 ? true : false
            }
        };

        this.setState({
            formData: fd
        })
    },

    inputSignatureDate(e) {
        const iv = e.target.value;
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        fd[op] = {
            iRaw: iv,
            iVal: {
                value: iv,
                isValid: iv.length > 0 ? true : false
            }
        };

        this.setState({
            formData: fd
        })
    },

    inputNumberPFS(e) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.,]+$/) || iv === "") {
            iv = validateInput.number(iv);

            fd[op] = {
                iRaw: iv,
                iVal: {
                    value: iv,
                    isValid: true
                }
            }
        } else {
            fd[op] = {
                iRaw: "",
                iVal: {
                    value: 0,
                    isValid: false
                }
            }
        }

        this.pfs(fd); // update pfs (almost like middleware)

        // update state and log the new state
        this.setState({
            formData: fd
        })
    },

    inputCustomRE(e) {
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        let fd = this.cloneFormData();


        fd[op] = {
            iRaw: null,
            iVal: {
                value: !fd[op].iVal.value ? true : false,
                isValid: true
            }
        };

        log(fd[op].iVal.value)

        this.setState({
            formData: fd
        }, this.validateCustomRE)
    },

    inputTermsCheck() {
        this.setState({
            terms_check: !this.state.terms_check
        })
    },

    inputRecaptcha() {
        // need someting for recap here
    },

    setBorrower(e) {
        const borrowerSelected = this.borrowers.value;
        const fd = this.cloneFormData();

        if (borrowerSelected === "") {
            fd.borrowing_entity_name = {
                iRaw: "",
                iVal: {
                    value: "",
                    isValid: false
                }
            }
            fd.company_type = {
                iRaw: "",
                iVal: {
                    value: "",
                    isValid: false
                }
            };
            fd.business_EIN = {
                iRaw: "",
                iVal: {
                    value: "",
                    isValid: false
                }
            };
            fd.registered_to_do_business_in_what_states = {
                iRaw: "",
                iVal: {
                    value: [],
                    isValid: false
                }
            };
        } else {
            const borrower = this.state.borrowers.find(x => x.Borrowing_Entity_Name.replace(/\s+/g, ' ').trim() === borrowerSelected);

            fd.borrowing_entity_name = {
                iRaw: borrower.Borrowing_Entity_Name.replace(/\s+/g, ' ').trim(),
                iVal: {
                    value: borrower.Borrowing_Entity_Name.replace(/\s+/g, ' ').trim(),
                    isValid: true
                }
            };
            fd.company_type = {
                iRaw: borrower.Company_Type,
                iVal: {
                    value: borrower.Company_Type,
                    isValid: true
                }
            };
            fd.business_EIN = {
                iRaw: borrower.Business_EIN,
                iVal: {
                    value: borrower.Business_EIN.replace('-', ''),
                    isValid: true
                }
            };
            fd.registered_to_do_business_in_what_states = {
                iRaw: "",
                iVal: {
                    value: borrower.Business_in_What_States.split(";"),
                    isValid: true
                }
            };
        }

        this.setState({
            formData: fd
        }, checkStates);


        // callback for checking states once borrower is set
        function checkStates() {
            let state = this.cloneState();
            let statesUpdated = [];

            //   clear out initially to reset
            state.usStates.forEach(x => {
                x.selected = false
            })

            // check and update states 
            state.usStates.forEach(x => {
                state.formData.registered_to_do_business_in_what_states.iVal.value.forEach(i => {
                    if (i === x.name) {
                        x.selected = true
                    }
                })
                statesUpdated.push(x);
            })
            console.log(statesUpdated);

            this.setState({
                usStates: statesUpdated
            })
        }
    },

}

export { handleInputsCustom }