import React from 'react';
import axios from 'axios';
import ButtonSubmit from '../../components/ButtonSubmit';
import {Redirect} from 'react-router-dom';
import jwt_decode from "jwt-decode";

import './index.scss';

class SignUpTwo extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      submitted: false,
      email: null,
      password: null,
      password2: null,
      token: null,
      errors: {
        email: '',
        password: '',
        password2: ''
      },
      params: {
        email: '',
        token: ''
      }
    }
  }

  componentDidMount() {
    let params = this.props.location.state ? this.props.location.state : this.props.match.params;
    if(params && 'token' in params) {
      let decoded = jwt_decode(params.token)
      params.email = decoded.aud
    } else {
      params = { email: '' }
    }
    this.setState({params, email: true});
  }

  clearSubmitButton = () => {
    this.setState({
      submitted: false
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      
      // let formData = document.querySelector('#signupform2');
      let params = this.state.params;
      let emailval = document.querySelector(".email-input");
      let passwordval = document.querySelector(".password-input");

      var body = {"password": passwordval.value};

      this.setState({
        submitted: true
      })

      axios.defaults.headers.common['Authorization'] = "Bearer " + params.token;
      axios.defaults.headers.common['Content-Type'] = "application/json";
      axios.post('/lima/api/users/createpassword', body)
      .then(function (response) {
        this.clearSubmitButton();
        window.location.href = '/signup-thankyou';
        if (response?.data?.rep) {
          window.localStorage.setItem('rep_email', response.data.rep.email)
          window.localStorage.setItem('rep_name', response.data.rep.name)
        }
        // return <Redirect to="/" />
      }.bind(this))
      .catch(function (error) {
        this.clearSubmitButton();
        console.error(error);
      }.bind(this));
    } else {
      this.setState({
        submitted: false
      })
      console.error('Invalid Form')
    }
  }

  validateForm = () => {
    
    let errors = this.state.errors;
    let passwordval = document.querySelector(".password-input");
    let password2val = document.querySelector(".password2-input");
    let emailval = document.querySelector(".email-input");
    let passwordCheck = /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/;

    let valid = 0;

    if (emailval.value === "") {
      errors.email = "Email is required";
      valid = 1;
    } else {
      errors.email = "";
    }

    if (passwordval.value === "") {
      errors.password = "Password is required";
      valid = 1;
    } else if(!passwordCheck.test(passwordval.value)){
      errors.password = "Password must have 1 lowercase letter, 1 uppercase letter, 1 number, and be at least 8 characters long.";
      valid = 1;
    } else {
      errors.password = "";
    }

    if (password2val.value === "") {
      errors.password2 = "Confirm password is required";
      valid = 1;
    } else if (password2val.value !== passwordval.value) {
      errors.password2 = "Confirm password does not match password";
      valid = 1;
    } else {
      errors.password2 = "";
    }

    //Set any feild to true to kick off errors
    if (valid === 1) {
      this.setState({errors, email: true});
      return false;
    } else {
      this.setState({errors, email: null});
      return true;
    }
  }

  render() {
    const {errors, params, submitted} = this.state;
    // const {} = this.state;

    return (
      <div className="sign-up create-account sign-up-wrapper">
        <div className="sign-up-box-left1 sign-up-box left">
          <h1>Let’s Set Up Your Portal Account</h1>
          <h3>Go ahead and pick a password to create your portal account. We will then send an email to confirm it's you.</h3>
          <br />
          <h3>You will be able to submit applications, upload documents, monitor the progress of your loans, make payments and more!</h3>
        </div>
        <div className="sign-up-box-right1 sign-up-box right">
          <form onSubmit={this.handleSubmit} id="signupform2" className="signupform">
            <div className="sign-up-inner1">
              <div className="sign-up-left1">
                <div className="floating">
                  <input id="input__email" className={errors.email.length > 0 && {} ? 'floating__input email-input inperror' : 'floating__input email-input'} name="email" type="text" placeholder="Email*" value={params.email} />
                  <label htmlFor="input__fname" className={errors.email.length > 0 && {} ? 'floating__label laberror' : 'floating__label'} data-content="Email*">
                    <span className="hidden--visually">Email*</span>
                  </label>
                  {errors.email.length > 0 && <span className="erro-mess">{errors.email}</span>}
                </div>
                <div className="floating">
                  <input id="input__password" className={errors.password.length > 0 && {} ? 'floating__input password-input inperror' : 'floating__input password-input'} name="password" type="password" placeholder="Password*" />
                  <label htmlFor="input__password" className={errors.password.length > 0 && {} ? 'floating__label laberror' : 'floating__label'} data-content="Password*">
                    <span className="hidden--visually">Password*</span>
                  </label>
                  {errors.password.length > 0 && <span className="erro-mess">{errors.password}</span>}
                </div>
                <div className="floating">
                  <input id="input__password2" className={errors.password2.length > 0 && {} ? 'floating__input password2-input inperror' : 'floating__input password2-input'} name="password2" type="password" placeholder="Confirm Password*" />
                  <label htmlFor="input__password2" className={errors.password2.length > 0 && {} ? 'floating__label laberror' : 'floating__label'} data-content="Confirm Password*">
                    <span className="hidden--visually">Confirm Password*</span>
                  </label>
                  {errors.password2.length > 0 && <span className="erro-mess">{errors.password2}</span>}
                </div>
              </div>
            </div>
            <div className="sign-up-inner6">
              {/* <button type="submit" name="submit" className="submit">Submit</button> */}
              <ButtonSubmit 
                readOnly={submitted} 
                callback={this.handleSubmit} />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default SignUpTwo;