import React from 'react'
import InputRadioBinary from '../_components/InputRadioBinary'
import InputHiddenSelect from '../_components/InputHiddenSelect'
import InputHiddenText from '../_components/InputHiddenText'

const Questionnaire = React.forwardRef((props, ref) => {

    return (
        <div className="row input-radio-fields" style={{ paddingTop: '1rem' }}>
            <fieldset className="input-field-group boxed">

                <table>
                    <tr>
                        <td>
                            <InputHiddenText ref={props.register} watch={props.watch} inverse={true} name="Do_you_have_permits_yet__c" label="Do you have the required building permits yet?*" tabIndex={props.tabIndex} errors={props.errors} yesNo={true}
                                             children={[
                                                 {
                                                     name: 'Expected_time_for_Permits__c',
                                                     label: 'When do you expect to receive them?*',
                                                     type: 'date'
                                                 }
                                             ]}
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Any_Zoning_Changes__c"}
                                              label="Does the property require any zoning changes to complete your project?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Intend_to_subdivide_the_property__c"}
                                              label="Do you intend to subdivide the property or request partial releases?*"
                            />
                        </td>
                        <td>
                            <InputHiddenText ref={props.register} watch={props.watch} name="Existing_Mortgage__c" label="Is there an existing mortgage on the property?*" tabIndex={props.tabIndex} errors={props.errors}
                                             children={[
                                                 {
                                                     name: 'Mortgage_Company__c',
                                                     label: 'Who is the mortgage company?*'
                                                 },
                                                 {
                                                     name: 'Estimated_Outstanding_Balance__c',
                                                     label: 'Estimated outstanding balance*',
                                                     placeholder: "$0.00",
                                                     onChange: props.inputCurrency,
                                                     cleanup: true
                                                 }
                                             ]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputHiddenText ref={props.register} watch={props.watch} name="Any_other_existing_liens__c" label="Are there any other existing liens on the property?*" tabIndex={props.tabIndex} errors={props.errors}
                                             children={[
                                                 {
                                                     name: 'Lien_Holder_Name__c',
                                                     label: 'Who are the lien holders?*'
                                                 },
                                                 {
                                                     name: 'Estimated_Lien_Outstanding_Balance__c',
                                                     label: 'Estimated outstanding balance*',
                                                     placeholder: "$0.00",
                                                     onChange: props.inputCurrency,
                                                     cleanup: true
                                                 }
                                             ]}
                            />
                        </td>
                        <td>
                            <InputHiddenText ref={props.register} watch={props.watch} name="Is_home_already_under_contract__c" label="Is the home already under contract?*" tabIndex={props.tabIndex} errors={props.errors}
                                             children={[
                                                 {
                                                     name: 'Contract_Amount__c',
                                                     label: 'What is the contract amount?*',
                                                     placeholder: "$0.00",
                                                     onChange: props.inputCurrency,
                                                     cleanup: true
                                                 }
                                             ]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputHiddenSelect ref={props.register} watch={props.watch} inverse={true} name="Using_third_party_contractor__c" label="Using a third party contractor?*" tabIndex={props.tabIndex} errors={props.errors}
                                               children={[
                                                   {
                                                       name: 'Proper_Licensing_Experience_Required__c',
                                                       label: 'Does your company have the proper licensing and experience required to complete the project on and on budget?'
                                                   }
                                               ]}
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Property_have_a_well_or_a_septic__c"}
                                              label="Will the property have a well or septic system?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Is_lot_properly_zoned__c"}
                                              label="Is the lot already properly zoned and individually platted with a parcel ID#*"
                            />
                        </td>
                        <td>
                            <InputHiddenText ref={props.register} watch={props.watch} name="Completed_any_construction__c" label="Have you already completed any construction (including demo, lot prep, foundation work, etc.)?*" tabIndex={props.tabIndex} errors={props.errors}
                                             children={[
                                                 {
                                                     name: 'Cost_of_completed_construction__c',
                                                     label: 'Cost of the construction already completed?*',
                                                     placeholder: "$0.00",
                                                     onChange: props.inputCurrency,
                                                     cleanup: true
                                                 }
                                             ]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Lot_ready_in_next_60_days__c"}
                                              label="Is the lot ready to build on within the next 60 days?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors} yesNo={true}
                                              name={"Do_you_have_plans_and_specs__c"}
                                              label="Do you already have plans and specs?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Requesting_cash_out_at_closing__c"}
                                              label="If you have sufficient equity in the lot you own, are you requesting cash out at closing?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors} yesNo={true}
                                              name={"Are_utilities_available_at_the_lot__c"}
                                              label="Are utilities available at the lot?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Use_Lima_One_Capital_s_Preferred_Title_I__c"}
                                              label="Use Lima One Capital's Preferred Title Insurance Provider?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Use_Lima_One_Capital_s_Preferred_Propert__c"}
                                              label="Use Lima One Capital's Preferred Property Insurance Provider?*"
                            />
                        </td>
                    </tr>
                </table>

            </fieldset>
        </div>
    )

})

export default React.memo(Questionnaire)