import React from 'react'
import InputRadioBinary from '../_components/InputRadioBinary'

const LegalQuestions = React.forwardRef((props, ref) => {

    let customer_id = props.watch('customer_id')

    return (
        <div className="row input-radio-fields" style={{ paddingTop: '1rem' }}>
            <fieldset className="input-field-group boxed">

                <table>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} fullwidth={false} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"convicted_of_currently_accused_of_a_felony"}
                                              label="Convicted of or currently accused of a felony, or any crime involving fraud, financial malfeasance, or misrepresentation?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} fullwidth={false} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"have_any_outstanding_judgements"}
                                              label="Have any outstanding judgments?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} fullwidth={false} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"had_a_foreclosure"}
                                              label="Had a foreclosure or given title or deed in lieu of foreclosure, or a short sale, in the past 3 years?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} fullwidth={false} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"party_to_any_outstanding_lawsuits"}
                                              label="Party to any outstanding lawsuits?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} fullwidth={false} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"declared_bankruptcy"}
                                              label="Declared bankruptcy in the past 4 years, or actively involved in a bankruptcy?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} fullwidth={false} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"presently_delinquent"}
                                              label="Presently delinquent on any mortgage loan of other financial obligation?*"
                            />
                        </td>
                    </tr>

                </table>

            </fieldset>
        </div>
    )

})

export default React.memo(LegalQuestions)