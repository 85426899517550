import { log } from '../../../assets/javascript/utilities/utilities_global';
import { validateInput } from '../../../assets/javascript/utilities/utilities_validation_input'; // global input validation utilities
import states from '../../../assets/config/_us_states.json';

const handleInput = {

    inputCurrency(e) {
        var v = e.target.value.replace(/,/g, "").replace(/\$/g, ""), dollar = '0', cents = '00';
        v = v.replace(/[\[\]&]+/g, '');
        v = v.replace(/-/g, '')
        v = v.replace(/[^\d.-]/g, '')

        if ((v.match(/^[0-9.,$]+$/) || v === "")) {

            if (v.indexOf('.') !== -1) {
                var price = v.split('.');
                dollar = price[0] || '0';
                cents = price[1] || '00';
            }

            if (cents.length === 1) {
                if (dollar) {
                    var dollarNumbers = dollar.split('');
                    var dollarLength = dollarNumbers.length;
                    cents = dollarNumbers[dollarLength-1]+cents;
                    dollar = '';
                    for (var i = 0; i < dollarLength-1 ; i++) {
                        dollar += dollarNumbers[i];
                    }
                    if (!dollar) {dollar = '0';}
                }
            }

            if (v.length === 1) {
                cents = '0'+v;
            }

            if (cents.length === 3) {
                var centNumbers = cents.split('');
                dollar = dollar === '0' ? centNumbers[0] : dollar+centNumbers[0];
                cents = centNumbers[1] + centNumbers[2];
            }
            e.target.value = "$" + dollar.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + cents;
        }
    },


    inputZip(e) {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        if ((iv.match(/^[0-9.,$]+$/) || iv === "") && iv.length < 6) {
            e.target.value = iv
        } else {
            e.target.value = e.target.value.substring(0, 5)
        }
    },

    inputSSN(e) {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')

        if (iv.match(/^[0-9.,-]+$/) || iv === "") {
            // remove dashes
            iv = iv.replace(/-/g, '');

            if (iv.length < 10) {
                // remove white spaces
                iv = iv.trim();

                // insert dashes into string for formatted value (fv)
                var fv = iv.toString().replace(/\D/g, '');
                if (fv.length > 3)
                    fv = fv.replace(/^(\d{3})/, '$1-');
                if (fv.length > 6)
                    fv = fv.replace(/-(\d{2})/, '-$1-');
                if (fv.length > 7)
                    fv = fv.replace(/(\d)-(\d{4}).*/, '$1-$2');

                e.target.value = fv
            } else {
                e.target.value = e.target.value.substring(0, 11)
            }
        }
    },

    inputPhone(e) {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        // remove dashes
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        iv = iv.trim()

        if (iv.length > 10) {
            iv = iv.substring(0, 10)
        }

        var fv = iv
        if (fv.length > 3)
            fv = fv.replace(/^(\d{3})/, '$1-');
        if (fv.length > 7)
            fv = fv.replace(/-(\d{3})/, '-$1-');

        e.target.value = fv
    },

    inputCreditScore(e) {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        // remove dashes
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        iv = iv.trim()
        if (!iv) {
            iv = "0"
        }

        if (iv.length < 4) {
            if (parseInt(iv) > 0 && parseInt(iv) < 851) {
                e.target.value = parseInt(iv)
            } else {
                e.target.value = e.target.value.substring(0, 2)
            }
        } else {
            e.target.value = e.target.value.substring(0, 3)
        }
    },

    inputEIN(e) {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')

        if (iv.match(/^[0-9.,-]+$/) || iv === "") {
            // remove dashes
            iv = iv.replace(/-/g, '');

            if (iv.length < 9) {
                // remove white spaces
                iv = iv.trim();

                // insert dashes into string for formatted value (fv)
                var fv = iv.toString().replace(/\D/g, '');
                if (fv.length > 2)
                    fv = fv.replace(/^(\d{2})/, '$1-');

                e.target.value = fv
            } else {
                e.target.value = e.target.value.substring(0, 10)
            }
        }
    },

    inputPercentage(e) {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace("%", '')
        // iv = iv.replace(/\D/g, '')

        if (iv.match(/^[0-9.]/) || iv === "") {
            // remove dashes
            iv = iv.replace(/-/g, '');

            if (iv.endsWith('.')) {
                iv = validateInput.float(iv + "0").toString() + ".";
            } else {
                iv = validateInput.float(iv);
            }

            if (iv !== undefined && iv < 101) {
                e.target.value = iv + "%"

                if (validateInput.float(e.target.value) > 100) {
                    e.target.value = "100%"
                }
            } else {
                e.target.value = e.target.value.substring(0, e.target.value.length - 2) + "%"
            }
        }
    },

    inputNumber(e) {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace("%", '')
        // iv = iv.replace(/\D/g, '')

        if (iv.match(/^[0-9.]/) || iv === "") {
            // remove dashes
            iv = iv.replace(/-/g, '');

            iv = validateInput.number(iv);

            if (iv !== undefined) {
                e.target.value = iv
            } else {
                e.target.value = e.target.value.substring(0, e.target.value.length - 1)
            }
        } else {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)
        }
    },

    validateSSN(iv) {
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')

        return (iv.match(/^[0-9.,-]+$/)) && iv.length >= 9
    },

    validatePhone(iv) {
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')

        return (iv.match(/^[0-9.,-]+$/)) && iv.length >= 10
    },

    validateZip(iv) {
        iv = iv.replace(/[\[\]&]+/g, '');
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')

        return (iv.match(/^[0-9.,-]+$/)) && iv.length >= 5
    }
}

export { handleInput }