// ----- v SUMMARY v ------------------------------------------------------------------------
// - Main wrapper for the Action Items list.  

// ----- v DESCRIPTION v --------------------------------------------------------------------
// - All individual Action Items are imported and contain their own rendering logic.

// - All individual Action Items should also contain any explicit API calls for data
//   not available as props from higher in the tree.

// - Action Item components are listed in the Table object/element below. These should
//   be <tr> elements for all!  <tr> content will vary per component.

// - Table is passed as a child to the SectionExpandable component which simply provides 
//   a wrapper with expand/collapse toggle functionality decoupled from the content and 
//   heading elements/addtional filtering logic (optional) - (these are passed as props).

// ----- v API CALLS v ----------------------------------------------------------------------
// ----- v STATE v --------------------------------------------------------------------------
// ----- v RENDER v -------------------------------------------------------------------------

// ----- v CRITICAL NOTES v -----------------------------------------------------------------
// - This wrapper component has access to all implcitly passed props.  Props needed include
//   general loans data originally called in the intitial Nav API call at the App root, as 
//   well as the LOC  data (recentLOCs and recentUnderwritings) and the Plaid data (all 
//   originally called in the Customer Dashboard to prevent jumpy rendering as data comes in).
//   
// - ActionItemPlaid requires the props recentLOCs and recentUnderwritings - needed for 
//   rendering logic and initially called from the Dashboard parent component.  It also 
//   requires the getPlaidInfo passed as a callback once the user clicks a link and returns.

// - ActionItemThirdPartyInfo only requires the Loans in Process to be passed as a prop.

// ----- v IMPORTS v ------------------------------------------------------------------------

import React, { Fragment, useRef, useEffect, useState } from 'react';
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// expandable section wrapper (we pass our content into it with expandable functionality)
import { SectionExpandable } from '../../../../components/shared/Section';

// child components (individual ActionItems components each returning a collection of <tr>)
// each action item child component conditionally rendered via internal logic
import ActionItemLOCApply from './LOCApply';
import ActionItemPlaid from './Plaid';
import PayForAppraisal from './PayForAppraisal';
import ActionItemThirdPartyInfo from './ThirdPartyInfo';
import LOCUploadDocs from './LOCUploadDocs';

import SkeletonTable from '../SkeletonTable';

export default function ActionItems(props) {

    // destructuring props to only pass data as needed
    let { loansInProcess } = props.loans; // Loans in Process
    let { recentLOCs, recentUnderwritings } = props; // Lines of Credit
    const [count, setCount] = useState(0)

    let plaid = props.plaidModDates;

    const items = useRef();

    // inner main content of section - passed to SectionExpandable as body prop 
    const Table = (

        <Fragment>
            {
                !(recentUnderwritings &&
                    recentLOCs &&
                    props.plaidModDates &&
                    loansInProcess) ?

                    <SkeletonTable count={4} /> :

                    <table className='table'>
                        
                        <thead>
                            <tr>
                                <th>
                                    <span>Nickname</span>
                                </th>
                                <th>
                                    <span>
                                        Address
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Needed Tasks
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody ref={items}>
                            <ActionItemLOCApply
                                loans={loansInProcess}
                                recentUnderwritings={recentUnderwritings}
                                recentLOCs={recentLOCs} />

                            <LOCUploadDocs
                                recentUnderwritings={recentUnderwritings}
                                recentLOCs={recentLOCs} />

                            <ActionItemPlaid
                                getPlaidData={props.getPlaidData}
                                recentLOCs={recentLOCs}
                                recentUnderwritings={recentUnderwritings}
                                plaidModDates={props.plaidModDates}
                                user={props.user} />

                            <ActionItemThirdPartyInfo
                                loans={loansInProcess} />

                            <PayForAppraisal
                                loans={loansInProcess} />

                            {
                                count <= 1 ? <tr>
                                    <td>
                                        <span>
                                            No results found.
                                        </span>
                                    </td>
                                </tr> :
                                    null
                            }
                        </tbody>
                    </table>
            }
        </Fragment>
    );

    useEffect(() => {
        if (items.current?.children) {
            setCount(items.current?.children.length)
        };
    }, [loansInProcess, recentLOCs, recentUnderwritings, plaid]);

    return (
        <SectionExpandable
            flush
            id={"action-items"} // unique ID for css targeting if needed
            heading={
                <span>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    Action Items
                </span>
            } // heading text and icon
            body={Table} // main content of expanded section
            headingOnClick // make entire heading clickable for expand/collapse
            animated // animate expand/collapse
            controls={false} // no controls for filtering/sorting/etc.
            {...props} /* implicit props */ />
    )
}
