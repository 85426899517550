import React from 'react';
import IdleTimer from 'react-idle-timer'
import axios from 'axios';
import './index.scss';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

// SESSION MANAGEMENT
// This component handles the idle timeout portion of session management.
// It serves as a wrapper for the IdleTimer library and tracks user activity.
// Once a user goes idle, it displays a modal with the option to continue, as
// well as starts a timer for the user to take action.  If no action is taken 
// before the modal timer runs out, the user is logged out (via the logout method)
// passed from the top level App as a prop (bound there).  

// The wrapper requires the fllowing props:
// - modalTimer - timeout interval to display the modal and ultimately fire the logout prop method
// - actionInterval - time interval minimum on user action to renew token (if user actions happen further apart than interval, fire renewToken method)
// - idleTimout - length of time before user is considered idle and the callback is fired to handle that event.
// - logout - callback.handler method bound to App passed down as prop

// NOTE - there is a bug where if the user leaves browser (closed laptop for example) and 
// returns the timeout counter does not progress and picks up where it left off.  Possibly 
// could be resolved by setting a time and checking against it?

class IdleNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalTimer: (this.props.modalTimer || 2) * 60 * 1000 // set same as logout timer below
    }

    this.modalInterval = null; // empty bindng for modal tick timer
    this.modalTimeout = null; // empty binding for modal logout timer
    this.idleTimer = null; // empty binding for idle timer ref

    this.handleOnAction = this.handleOnAction.bind(this); // handler for all user actions (mouse, keys, etc.)
    this.handleOnIdle = this.handleOnIdle.bind(this); // handle user idling (opens modal starts timers)
    this.handleResetToken = this.handleResetToken.bind(this); // renews token and resets modal/modal timers
  }

  // any user action (click, mouse move, etc) triggers this handler 
  // to check the idleTimer and if needed renew the token
  handleOnAction(event) {

    const interval = (this.props.actionInterval || 10) * 60 * 1000;

    // check for user closing laptop and returning or if the local stroage was cleared
    if ((new Date() - new Date(window.localStorage.getItem("sessionStart")) > this.props.idleTimeout * 60 * 1000) || (!window.localStorage.accountType)) {
      this.props.logout()
    }

    // check to see if the user has been idle greater than x minutes 
    // (variable above), if true renew token to keep session fresh...
    if ((new Date()) - new Date(window.localStorage.getItem("sessionStart")) > interval) {
      this.handleResetToken(event);
    }
  }

  // helper to convert ms to minutes/seconds
  toMinutes(s) {
    s = s / 1000; // convert from ms to seconds
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  }

  // method/callback to reset the modal by clearing the bound timeout and interval
  modalReset() {
    clearInterval(this.modalInterval); // clear/reset the the countdown modal countdown ticker
    clearTimeout(this.modalTimeout); // clear/reset the the countdown modal countdown timer to logout
    window.localStorage.idleTimeoutStart = '';

    this.setState({
      modal: false, // hide modal
      modalTimer: (this.props.modalTimer || 2) * 60 * 1000, // reset modal timer - defaults to 2 min
      tokenRenewalError: false
    })
  }

  // handle user going idle (show modal and start logout timers and ticker)
  handleOnIdle() {

    // callback handler to start countdown timer to logout
    function logoutTimer() {

      window.localStorage.idleTimeoutStart = new Date();

      // set the logout timer (no user action on button logs out after timeout)
      this.modalTimeout = setTimeout(() => {
        window.localStorage.sessionStart = '';
        this.modalReset(); // constructor method to clear/reset modal on logout
        this.props.logout(true); // passed from App top level sessions mgmt methods
      }, this.state.modalTimer) // timeout length from state/props

      // set the modal timeout ticker (count down by seconds and display in modal)
      this.modalInterval = setInterval(() => {

        // check the time elapsed since idle/modal trigger.  if greater than initial modalTimer, logout.
        // Otherwise, continue ticking.  This fixes the bug of a sleeping computer not logging out if sleeping/on wake
        if (new Date() - new Date(window.localStorage.idleTimeoutStart) > this.props.modalTimer * 60 * 1000) {
          this.modalReset(); // constructor method to clear/reset modal on logout
          this.props.logout(true); // passed from App top level sessions mgmt methods
        } else {
          this.setState({
            modalTimer: this.state.modalTimer - (1000)
          })
        }
      }, 1000)
    };

    // display modal on idle and start logout timer/ticker for no action taken by user
    if (!this.state.modal) {
      this.setState({
        modal: true
      }, logoutTimer) // callback to start modal timer/logout
    };
  };

  // renew token and reset modal
  handleResetToken(e) {
    e.preventDefault();

    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");

    // define using arrow function here to bind to constructor (context - different scope/closure in axios calls - required to be bound here)
    const modalResetAxios = () => {
      this.modalReset(); // constructor method to clear/reset modal
    }

    // define using arrow function here to bind to constructor (context)
    const tokenRenewalError = () => this.setState({ tokenRenewalError: true }); // set token renewal error 

    axios.post(process.env.REACT_APP_LIMA + '/lima/api/users/renewtoken', {
      "email": window.localStorage.getItem("username")
    })
      .then(function (response) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;

        window.localStorage.setItem("sessionStart", new Date()); // set new idleTimer
        window.localStorage.setItem("token", response.data.token); // set new token (replace with login?)

        modalResetAxios(); // call bound clear modal function
      })
      .catch(function (error) {
        tokenRenewalError(); // call bound token renewal error function
      });
  }

  componentDidMount() {

    if (window.localStorage.sessionStart) {
      return;
    } else {
      window.localStorage.sessionStart = new Date(); // setting initial idleTimer on mount
    }
  }

  render() {
    return (
      <>

        <IdleTimer
          ref={ref => { this.idleTimer = ref }} // ref for this idletimer component
          timeout={(this.props.idleTimeout || 20) * 60 * 1000} // time until idle default to 20 min
          onActive={this.handleOnActive} // method from library
          onIdle={this.handleOnIdle} // method from library
          onAction={this.handleOnAction} // method from library
          debounce={500} // debounce rate for all user action events
        />

        {
          this.state.modal ?
            <div className="modal-continue">
              <div className="modal-continue_content">

                <h1 className="title">Session Expiring</h1>

                <p>Your session is about expire due to inactivity. To keep working, select {this.state.tokenRenewalError ? "Continue Session - Retry" : "Continue Session"}.</p>

                {
                  this.state.tokenRenewalError ?
                    <p><span style={{ fontWeight: "bold" }}>There was a problem continuing your session.</span>  Please try again or you will automatically be logged out in <span style={this.state.modalTimer <= (1000 * 60 * 0.5) ? { color: "#C03939", fontWeight: "bold" } : { fontWeight: "bold" }}>{this.toMinutes(this.state.modalTimer)}</span>.</p>
                    :
                    <p>If no action is taken you will automatically be logged out in <span style={this.state.modalTimer <= (1000 * 60 * 0.5) ? { color: "#C03939", fontWeight: "bold" } : { fontWeight: "bold" }}>{this.toMinutes(this.state.modalTimer)}</span>.</p>
                }

                <button onClick={this.handleResetToken} className="continue">
                  {
                    this.state.tokenRenewalError ? "Continue Session - Retry" : "Continue Session"
                  }
                </button>

              </div>
            </div>
            : null
        }

      </>
    );
  }
}

export default IdleNew;