import React, { Fragment, useEffect, useState } from 'react';
import { SectionExpandable } from '../../../../../components/shared/Section';
import Skeleton from 'react-loading-skeleton';
import './index.scss';
import animations from '../../../../../animations';
import { useSpring, animated } from '@react-spring/web';
import DealStatus from './Status';
import DealServicing from './Servicing';
import Card from './Card';

export default function Header(props) {

  const deal = props.deal;
  const street = deal?.properties[0]?.street || "";
  const rep = deal?.rep;

  const [image, setImage] = useState("/assets/images/sample-building.jpg");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [type, setType] = useState(null)

  useEffect(() => {

    if (street.length > 0) {

      const url = "https://maps.googleapis.com/maps/api/streetview?size=400x400&location=" + street + "t&fov=80&heading=70&pitch=0&return_error_code=true&key=AIzaSyBAn2XKTT4oYvBx3OqFQgIqqprQx4TSQUo"

      fetch(url).then(res => {
        if (res.status === 200) {
          setImage(res.url)
          setImageLoaded(true)
        }

      }).catch(error => {
        // silence is golden
      })
    };

    switch (deal?.productType) {
      case "FixNFlip":
        setType({
          tip: "FixNFlip",
          img: "/assets/images/FIXNFLIP-ICON-166-271x125.png"
        })
        break;

      case "Rental":
        setType({
          tip: "Rental",
          img: "/assets/images/rentalIcon.png"
        })
        break;

      case "Multifamily":
        setType({
          tip: "Multifamily",
          img: "/assets/images/Multifamily-191x125.png"
        })
        break;

      case "NewConstruction":
        setType({
          tip: "New Construction",
          img: "/assets/images/new-construction-for-website-home-page-2-271x125.png"
        })
        break;

      default:
        setType(false)
    }

  }, [deal])

  const imageBG = { backgroundImage: `url(${image})` }

  const loading = <Skeleton />

  const styleLoaded = useSpring(animations("fadeIn", deal));

  const imageContent = (

    <Fragment>
      {
        !imageLoaded ?

          <div className="space-holder-text">
            <div>
              <img src="/assets/images/logo.svg" alt="" />
              <span>Image not available.</span>
            </div>
          </div>

          : null
      }
    </Fragment>
  )

  const headingText = (
    <animated.span style={styleLoaded}>
      {deal?.nickname}
      {/* <img src={deal?.type?.img} alt="" /> */}
    </animated.span>
  )

  const heading = (
    <Fragment>
      {deal ? headingText : loading}
    </Fragment>
  )

  const content = (
    <Fragment>
      {
        deal === null ? <Skeleton /> :
          <Fragment>
            {
              (deal?.status !== "Funded" && !(
                deal?.type === "Short Term Finance" ||
                deal?.type === "NewConstruction" ||
                deal?.type === "New Construction" ||
                deal?.type === "FixNFlip"
              )) ? <DealStatus dealDetails={deal} /> : <DealServicing dealDetails={deal} />
            }

          </Fragment>
      }
    </Fragment>
  )

  const body = (
    <div className="deal-header-body" style={styleLoaded}>

      <div className="deal-header-body_inner first" >

        <div className="inner image" style={imageBG}>
          {
            deal === null ? <Skeleton /> : imageContent
          }
        </div>

        <div className="inner card">
          {
            deal === null ? <Skeleton /> : <Card rep={rep} />
          }
        </div>

      </div>

      <div className="deal-header-body_inner second">
        <div className="inner">
          {content}
        </div>
      </div>
    </div>
  )

  const errorBody = (
    <Fragment>
      An error was encountered loading this deal.
    </Fragment>
  )

  return (
    <SectionExpandable heading={heading} body={body} id="deal-header" animate />
  )
}

