import React from 'react';
import { Link } from 'react-router-dom';
import TransitionIn from '../../../components/shared/TransitionIn';

export default function FourOhFour(props) {
    return (
        <TransitionIn content={
            <main style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", minHeight: "70vh", textAlign: "center", color: "black", background: "white" }}>
                <section>
                    <h1 className="heading-main" style={{ color: "#152A58" }}>404</h1>

                    <p>{props.message ? props.message : "The requested resource could not be reached."}</p>

                    <Link exact to={props.accountType ? process.env.REACT_APP_SESSION_URL : '/'} style={{ color: 'black' }}>
                        {props.accountType ? "Return to Dashboard" : "Return to Login"}
                    </Link>
                </section>
            </main>
        } />
    )
}