import React from 'react';
import axios from 'axios';

import './index.scss';

import qs from 'qs'

import { Redirect, withRouter } from "react-router";
import { handleInput } from '../../assets/javascript/utilities/utilities_form_handle_input'; // global form input handlers
import { formData } from './_form_data'; // state.formData object
import statesConfig from '../../assets/config/_us_states.json'
import InputText from '../../components/_forms/_inputText';
import InputSelect from '../../components/_forms/InputSelect';
import InputRadioBinary from '../../components/_forms/_inputRadioBinary';
import ButtonSubmit from '../../components/ButtonSubmit';
import { PrivacyModal, privacyToggle } from './privacy_policy/PrivacyModal'

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      formSubmission: false,
      formData: formData,
      fname: null,
      area: null,
      phone: null,
      reo: null,
      lname: null,
      email: null,
      country: null,
      flips: null,
      token: null,
      agree_privacy: false,
      privacy: false, // terms and conditions modal toggle
      errors: {
        fname: '',
        area: '',
        phone: '',
        reo: '',
        lname: '',
        email: '',
        country: '',
        flips: '',
      },
      submitResponse: '',
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      utm_content: null
    }

    this.inputText = handleInput.inputText.bind(this);
    this.inputSelect = handleInput.inputSelect.bind(this);
    this.inputSelectMulti = handleInput.inputSelectMulti.bind(this);
    this.inputNumber = handleInput.inputNumber.bind(this);
    this.inputPhone = handleInput.inputPhone.bind(this);
    this.inputCurrency = handleInput.inputCurrency.bind(this);
    this.inputRadio = handleInput.inputRadio.bind(this);
    this.privacy = privacyToggle.bind(this); // handle terms and conditions modal toggle
  }

  cloneFormData = () => {
    return JSON.parse(JSON.stringify(this.state.formData));
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ formSubmission: true })

    if (this.state.submitted) return;
    this.setState({ submitted: true })
    let fd = this.state.formData
    let _this = this;
    if (this.validateForm(true, this.state.agree_privacy)) {

      let areasString = [fd.areas_of_operation.iVal.value.join(";")].toString();
      var volumeOfFlips = parseInt(fd.number_of_fixnflips_sold_in_the_past_3_years.iVal.value);

      axios.post(process.env.REACT_APP_LIMA + '/lima/api/users/signup', {
        "first_name": fd.first_name.iVal.value,
        "last_name": fd.last_name.iVal.value,
        "typeofrealestate": areasString,
        "email": fd.email.iVal.value,
        "phone": fd.phone_number.iVal.value.replace(/-/g, ''),
        "state": fd.state.iVal.value,
        "portfolioREO": fd.currently_estimated_value_of_your_real_estate_portfolio.iVal.value.toString(),
        "number_of_rental_units_currently_owned_regardless_of_purchase_date": fd.number_of_rental_units_currently_owned_regardless_of_purchase_date.iVal.value,
        "volumeofflips": volumeOfFlips,
        "new_construction_builds": fd.number_of_new_construction_builds_in_the_past_3_years.iVal.value,
        "needs_financing": fd.has_property_needs_financing.iVal.value ? "Yes" : "No",
        "notes": fd.notes.iVal.value,
        "utm_source": this.state.utm_source,
        "utm_medium": this.state.utm_medium,
        "utm_campaign": this.state.utm_campaign,
        "utm_term": this.state.utm_term,
        "utm_content": this.state.utm_content,
        "broker_email": this.props.match.params.brokeremail
      })
        .then(function (response) {
          console.log(response.data)
          if ('code' in response.data) {
            if (response.data.code === 200) {
              // no lead no account, send to account setup
              window.location.href = '/account-setup/' + response.data.token;
            } else {
              _this.setState({ submitted: false, submitResponse: response.data.statusCode })
            }
          } else {
            _this.setState({ submitted: false, submitResponse: "Something went wrong." })
          }
        })
        .catch(function (error) {
          if (error.response) {
            _this.setState({ submitResponse: error.response.data.status })
          }
          _this.setState({ submitted: false })
        });
    } else {
      console.error('Invalid Form')
      _this.setState({ submitted: false })
    }
  }

  validateForm = (log, terms) => {
    const obj = this.state.formData
    console.log('validating... =>', obj)
    const objArray = Object.values(obj); // transform formData to array to loop thru and check for all completed validation 

    const test = (x) => x.iVal.isValid;
    let result = objArray.every(test); // test validation

    if (log) {
      const keys = Object.keys(obj);
      let resultArray = []

      keys.forEach((x, index) => {

        let value = {
          [x]: objArray[index].iVal.isValid
        }

        console.log(value)

        resultArray.push(value)
      });
    }

    console.log(result)

    if (!result) {
      window.location.href = '#form'; // scroll to top (replace with history.push?)
    }

    if (!terms) {
      result = false;
    }

    return result; // return boolean for form validation
  }

  componentDidMount() {
    let utm_source = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_source
    let utm_medium = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_medium
    let utm_campaign = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_campaign
    let utm_term = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_term
    let utm_content = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_content

    this.setState({
      utm_source, utm_medium, utm_campaign, utm_term, utm_content
    })
  }

  render() {
    const { formSubmission, formData: fd, submitted } = this.state;

    return (
      <div className="sign-up-wrapper">
        <div className="sign-up-box left">
          <h1>Let’s Get Started</h1>
          <h3>Tell us a little about yourself by completing the form below. We will then pair you with a dedicated sales consultant. Your sales consultant will be your primary point of contact and help you to accomplish your real estate investment goals.</h3>
        </div>

        <div className="sign-up-box right">
          <form onSubmit={this.handleSubmit} id="signupform" className="signupform">
            <div className="sign-up-inner one">
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"first_name"} label="First Name*" inputText={this.inputText} />
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"last_name"} label="Last Name*" inputText={this.inputText} />
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"email"} label="Email Address*" inputText={this.inputText} />
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"phone_number"} label="Phone Number*" inputText={this.inputPhone} value={fd.phone_number.iVal.value} />
              <InputSelect formSubmission={formSubmission} fd={fd} fd_key={"state"} label="State*" inputSelect={this.inputSelect} config={statesConfig} />
            </div>

            <div className="sign-up-inner two">
              <div className="two_panel">
                <InputSelect multiple={true} formSubmission={formSubmission} fd={fd} fd_key={"areas_of_operation"} label="Which Areas of Real Estate Do You Primarily Operate In?*" inputSelect={this.inputSelectMulti} config={[
                  { name: "FixNFlip", value: "FixNFlip" }, { name: "New Construction", value: "New Construction" },
                  { name: "Rental", value: "Rental30" }, { name: "Multifamily", value: "Multifamily" }]} />
                <p>Click your selection. To select more than one area, hold CTRL as you click.</p>
              </div>
            </div>

            <div className="sign-up-inner three">
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"currently_estimated_value_of_your_real_estate_portfolio"} label="Value of Existing REO Portfolio*" inputText={this.inputCurrency} value={fd.currently_estimated_value_of_your_real_estate_portfolio.iRaw} />
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"number_of_fixnflips_sold_in_the_past_3_years"} label="Number of Flips Completed in the Last 3 Years*" inputText={this.inputNumber} />
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"number_of_rental_units_currently_owned_regardless_of_purchase_date"} label="Number of Rental Units Owned*" inputText={this.inputNumber}  />
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"number_of_new_construction_builds_in_the_past_3_years"} label="Number of New Construction Builds in the Last 3 Years*" inputText={this.inputNumber} />
            </div>

            <div className="sign-up-inner four">
              <InputRadioBinary fd={fd} fullwidth={true} inputRadio={this.inputRadio} formSubmission={formSubmission}
                fd_key={"has_property_needs_financing"}
                label="Do You Currently Have a Property That Needs Financing?*" />
            </div>

            <div className="sign-up-inner five">
              <InputText formSubmission={formSubmission} fd={fd} fd_key={"notes"} label="Notes (optional)" inputText={this.inputText} />
            </div>

            <div className="sign-up-inner six">
              <div className={`agree-section ${(formSubmission && !this.state.agree_privacy) && 'inperror'}`}>
                <p>In addition, you consent to the terms and conditions outlined <span onClick={this.privacy} style={{ color: "#0086CA" }}>Privacy Policy*</span>.</p>
                <br />
                <br />
                <span className="agree-section-body">
                  <input type="radio" name="agree" id="agree-privacy" value={this.state.agree_privacy} onChange={(e) => this.setState({ agree_privacy: e.target.value })} /> 
                  <span>
                    By submitting this form, I agree to receive information about Lima One’s products and services by email and phone. I understand I can opt-out at any time. I have read and agree to Lima One’s <a href="https://www.limaone.com/terms-and-conditions/" target='_blank'>Terms and Conditions</a> and <a href="https://www.limaone.com/privacy-policy/" target="_blank">Privacy Policy</a>.
                  </span>
              </span>
                <br />
                <span className="error-message">{(formSubmission && !this.state.agree_privacy) && 'You must agree to our privacy policy.'}</span>
              </div>

              <ButtonSubmit
                readOnly={submitted}
                callback={this.handleSubmit} />

              <div className={`form-error ${this.state.formError ? 'is-active' : ''}`}>
                {this.state.submitResponse && (
                  <div>
                    <br />
                    {(() => {
                      if (this.state.submitResponse === 0) {
                        return (
                          <h3>Unfortunately we could not find an existing account for your email. Complete the <a href="/signup">Sign Up</a> for Portal access.</h3>
                        )
                      } else if (this.state.submitResponse === 1) {
                        return (
                          <h3>Please check your email to complete the account registration by setting up your password, or request a new <a href="/forgot-password">password reset link.</a></h3>
                        )
                      } else if (this.state.submitResponse === 2) {
                        return (
                          <h3>Check your email for a link to set your password and complete setup.</h3>
                        )
                      } else if (this.state.submitResponse === 3) {
                        return (
                          <h3>We found you already have an account. Proceed to <a href="/">Login</a> or <a href="/forgot-password">reset your password.</a></h3>
                        )
                      } else {
                        return (
                          <h3>Something unexpected happened, please contact support for assistance.</h3>
                        )
                      }
                    })()}
                  </div>
                )}
              </div>
            </div>

            <section className="privacy-conditions-wrapper">
                  {/* <TermsModal terms={terms} toggle={this.terms} /> */}
                  <PrivacyModal privacy={this.state.privacy} toggle={this.privacy} />
              </section>

          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(SignUp);
