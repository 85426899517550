const validateForm = (obj, log) => {
    const objArray = Object.values(obj); // transform formData to array to loop thru and check for all completed validation 

    const test = (x) => x.iVal.isValid;
    const result = objArray.every(test); // test validation

    if (log) {
        const keys = Object.keys(obj);
        let resultArray = []

        keys.forEach((x, index) => {
            
            let value = {
                [x]: objArray[index].iVal.isValid
            }

            resultArray.push(value)
        });
    }

    if (!result) {
        window.location.href = '#form'; // scroll to top (replace with history.push?)
    }

    return result; // return boolean for form validation
}

const validateCustomRE = function() {
    let fd = this.cloneFormData();

    if (
        fd.FixFlip.iVal.value === true ||
        fd.NewConstruction.iVal.value === true ||
        fd.RentalsCommercial.iVal.value === true ||
        fd.MultiFamily.iVal.value === true ||
        fd.MixedUse.iVal.value === true
    ) {
        fd.customRE.iVal.isValid = true;
    } else {
        fd.customRE.iVal.isValid = false;
        window.location.href = '#form'; // scroll to top (replace with history.push?)
    };

    this.setState({
        formData: fd
    })
}

export { validateForm, validateCustomRE }