import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import TopNav from '../../../../../components/protected/TopNav';

export default function Navigation(props) {
    const accountType = props.accountType;
    const id = props.id;
    const deal = props.deal;
    const devMode = process.env.REACT_APP_DEV_MODE;


    const setBreadcrumb = () => {

        const bakeBread = () => {

            let crumb = null;
            const path = window.location.pathname;
           
            if(path.includes('property')) { crumb = "Property" };
            if(path.includes('credit')) { crumb = "Credit" };
            if(path.includes('payment-history')) { crumb = "Payment History" };
            if(path.includes('documents')) { crumb = "Documents" };
            if(path.includes('construction')) { crumb = "Construction Management" };
            

            return (
                <Fragment>
                    {crumb ? <span>&#160;|&#160; {crumb}</span> : null}
                </Fragment>
            )
        }

        return (
            <span className="breadcrumb">
                <span className="root">Deal</span>{bakeBread()}
            </span>
        )
    }

    const links = (
        <Fragment>
            <li>
                <NavLink
                    exact
                    to={`${process.env.REACT_APP_SESSION_URL}/deal/${id}`}
                    activeClassName="is-active">
                    Deal
                </NavLink>
            </li>

            <li>
                <NavLink exact to={`${process.env.REACT_APP_SESSION_URL}/deal/${id}/payment-history`} activeClassName="is-active">
                    Payment History
                </NavLink>
            </li>

            <li>
                <NavLink
                    exact
                    to={`${process.env.REACT_APP_SESSION_URL}/deal/${id}/property`}
                    activeClassName="is-active">
                    Property
                </NavLink>
            </li>

            <li>
                <NavLink
                    exact
                    to={`${process.env.REACT_APP_SESSION_URL}/deal/${id}/documents`}
                    activeClassName="is-active">
                    Documents
                </NavLink>
            </li>

            {
                (
                    accountType !== "CustomerAccount" ||
                    (
                        deal?.status !== "Funded" &&
                            !(
                                deal?.type === "Short Term Finance" ||
                                deal?.type === "NewConstruction" ||
                                deal?.type === "New Construction" ||
                                deal?.type === "FixNFlip"
                            )
                    )
                ) ? (
                    <span></span>
                ) : (
                    <li
                        className={`construction`}>

                       
                            <NavLink exact to={`${process.env.REACT_APP_SESSION_URL}/deal/${id}/construction`} activeClassName="is-active">
                                Construction Management
                            </NavLink>
                      
                    </li>
                )}



            {/* {
                devMode ?
                    <li style={{background: "red"}}>
                        <NavLink exact to={`${process.env.REACT_APP_SESSION_URL}/deal/${id}/credit`} activeClassName="is-active">
                            Credit
                        </NavLink>
                    </li>
                    : null
            } */}
        </Fragment>
    )

    return (
        <TopNav links={links} id={id} buttonText={setBreadcrumb()} />
    )
}