// ----- v SUMMARY v ------------------------------------------------------------------------ 

// ----- v DESCRIPTION v --------------------------------------------------------------------

// ----- v PROPS v --------------------------------------------------------------------------

// ----- v STATE v --------------------------------------------------------------------------

// ----- v RENDER v -------------------------------------------------------------------------

// ----- v CRITICAL NOTES v -----------------------------------------------------------------

// ----- v IMPORTS v ------------------------------------------------------------------------

// - react imports
import React, { useRef, useState, useEffect } from 'react';

// - utility imports (internal)
import { log, generateId } from '../../../../utility';

// - utility imports (3rd party packages)
import axios from 'axios';
import { useSpring, animated } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify, faGripLines } from '@fortawesome/free-solid-svg-icons';

// - component imports
import { SectionExpandable } from '../../../../components/shared/Section';
import CopyTable from '../../../../components/shared/CopyTable';
import animations from '../../../../animations';
import { Link } from 'react-router-dom';

// - style imports
import './index.scss';

import utilityData from '../../../../utility/data';
import SkeletonTable from '../SkeletonTable';

import Rows from './Rows';

// ------------------------------------------------------------------------------------------

const LoansFunded = (props) => {

    // - state variables (handle API fetch and res)
    const [data, setData] = useState([]); // - initial data load - immutable - cleaned up and saved for reference/default data
    const [dataFiltered, setDataFiltered] = useState([]) // - filtered data - mutable - cleaned up and filterable 
    const [dataFilteredCount, setDataFilteredCount] = useState(0);

    const [dataLoaded, setDataLoaded] = useState(null); // - api success (null == pending, true == success, false = failure/error)
    const [dataError, setDataError] = useState("Unspecified error."); // - api res error message (if applied - default provided)

    const [expandRows, setExpandRows] = useState(false);

    // - SectionExpandable and CopyTable (linked together) variables
    const id = 'dashboard_loans-funded'; // - id for css styling targets (passed to SectionExpandable)
    const tableRef = useRef(); // - ref to table (body) for copying (utilize <CopyTable ref={tableRef} in SectionExpandable controls prop element)

    // - base url for table links to deals
    // const link = process.env.REACT_APP_SESSION_URL + '/deal/';

    
    // - table body load animation (match table expand by passing animation type as aniamte prop string)
    const styleLoaded = useSpring(animations("fadeIn", dataLoaded));

     // transistion animation and conditional styles
     const styleUp = useSpring(animations("toggleInUpReverse", expandRows));
     const styleDown = useSpring(animations("toggleInDownReverse", expandRows))

    // --------------------------------------------------------------------------------------
    // - fetch data - Funded Loans - should be moved higher up and passed as prop (from 
    //   main App component) - remember to pass down to any error handler for reloading (error 
    //   var element in this case)

    // > ----- NEW - START ----- >

    // filter data (receives a simpe flat object and searches string values of each object entry/property for input/search term)
    function filter(data, search, exclusions) {
        const dataFiltered = data.filter((deal) => {

            let dealEntries = Object.entries(deal); // convert object into a new array of filtered deals with key/value pairs
            let found = false; // boolean to indicate if search/filter found the search arg
            const dealExclusionsApplied = []; // deal entry array with exlusions applied (excluding specific entry keys from search/filter but keep in object)

            // apply exclusions to each entry
            dealEntries.forEach((entry) => {
                exclusions.forEach(exclusion => {
                    if (exclusion !== entry[0]) {
                        dealExclusionsApplied.push(entry);
                    };
                });
            });

            // search/filter the deal with the exclusions applied
            dealExclusionsApplied.forEach(entry => {
                if (typeof (entry[1]) === 'string' &&
                    entry[1].toLowerCase().includes(search.toLowerCase())) {
                    found = true; // found
                }
            });

            if (found) return dealExclusionsApplied; // return found deal to original map function
        })


        if (dataFiltered.length === 0) {

            setDataFilteredCount(0);
            return [];
        } else {
            setDataFilteredCount(dataFiltered.length);
            return dataFiltered; // new array of filtered data
        }


    }

    // group a flat array by a key term (string) - returns new array with key for each entry
    function group(data, key) {

        const groupBy = key => array =>

            array.reduce((objectsByKeyValue, obj) => {

                const value = obj[key];

                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

                return objectsByKeyValue;
            }, {});

        const groupByNickname = groupBy(key);

        return Object.entries(groupByNickname(data))
    }

    // combining filter and group (to keep each utility composable)
    function filterAndGroup(arr, value) {
        return filter(arr, value, ['id'])
    }

    // - controls handler to search/filter data (sorting is already done on load and should not need to be called here - add only if necessary!)
    function handleSearch(event) {
        if (event.target.value === "") {
            setDataFilteredCount(data.length);
            setDataFiltered(data, 'nameBorrowingEntity');
        } else {
            setDataFiltered(filter(data, event.target.value))
        }
    };

    // < ----- NEW - END ------- <

    const fetchData = async () => {

        // - reset all API-related state variables for refresh after load/error
        if (!dataLoaded) {
            setDataLoaded(null);
            setData([]);
            setDataFiltered([]);
        }

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");

        try {
            const res = await axios.get(props.accountType === 'CustomerAccount' ? '/lima/api/deals/funded': '/lima/api/deals/broker/funded');

            let data = res.data.deals;
          
            data = utilityData.removeByValue(data, "id", ""); // remove deals with no id
            data = utilityData.removeDuplicates(data, "id"); // remove duplicate deals (id)

            // strip irrelevant data from each deal (ONLY if absolutely not needed - specific filter exclusions can be ignored in the filter function)
            data = data.map(deal => {

                // delete deal.nickname;
                delete deal.legalName;
                delete deal.remaining_escrow;
                delete deal.construction;
                delete deal.borrowingEntityName;

                return deal;
            })

            // utility helpers for formatting data
            utilityData.formatCurrency(data, ['amount']);
            utilityData.formatDate(data, ['maturityDate', 'loanSignDate', 'nextPaymentDate']);
            utilityData.formatReplace(data, '-');

            // - set initial values for data, dataFiltered (immutable and mutable, respectively) and dataLoaded
            setTimeout(() => {
                setDataFilteredCount(data.length)
                setData(data);
                setDataFiltered(data);
                setDataLoaded(true);
            }, 1100);

        } catch (error) {
            setTimeout(() => {
                setDataLoaded(false);
                setDataError(error.message);
            }, 1100);
        };
    };

    // - fetch loan data - pass empty array as second arg to prevent infinite loop (same funcitonality as componentDidMount)
    useEffect(() => {
        fetchData();
    }, []);

  
    const sets = () => {
        setExpandRows(!expandRows)
    }

    const setsCallback = () => {
        if(!props.expanded) {
            setExpandRows(false)
        }
    }

    // --------------------------------------------------------------------------------------

    // - section body error 
    const bodyError = (
        <span className="error-message" ref={tableRef}>
            There was a problem loading data (error: {dataError}).  <button onClick={fetchData}>&nbsp;Click here to refresh.</button>
        </span>
    );

    // - section heading (passed to SectionExpandable)
    const heading = (
        <span style={{ display: "flex", alignItems: "center" }}>
            Funded Loans ({dataFilteredCount} of {data.length})
        </span>
    );

    // - section controls (passed to SectionExpandable - pass ref for CopyTable here)
    const controls = (
        <React.Fragment>

            <input type="text"
                placeholder="Search"
                onChange={handleSearch}
                data-tip="Type here to search the table."
                data-for="app-tooltip" />

            <CopyTable tableRef={tableRef} />

            <button onClick={sets} className="button-rows-expand" data-tip="Expand/collapse all individual rows." data-for="app-tooltip">
                {
                        expandRows ?
                            <animated.div style={styleUp}>
                                <FontAwesomeIcon icon={faGripLines} />
                            </animated.div>
                            :
                            <animated.div style={styleDown}>
                                <FontAwesomeIcon icon={faAlignJustify} />
                            </animated.div>
                    }
            </button>

        </React.Fragment>
    );

    // - section body main content (passed to SectionExpandable - add CopyTable ref below on <table>)
    const bodyContent = (

        // - check if data is loaded from API (props in future).  If null > loading animation,
        //   if true > table, if false > error.  NOTE - this is a nested ternary - pay attention!

        // - loading animation
        dataLoaded === null ?

            <SkeletonTable />

            // - table with data
            : dataLoaded === true ?

                // - note the ref below - needed to copy this table
                <animated.table style={styleLoaded} id="table" ref={tableRef} className="table">

                    <thead>
                        <tr key={generateId(1, 1000)}>
                            <th>
                                <span>Nickname</span>
                            </th>
                            <th>
                                <span>Loan Number</span>
                            </th>
                            {/* <th>
                                <span>Name on Loan</span>
                            </th> */}
                            <th>
                                <span>Product Code</span>
                            </th>
                            <th>
                                <span>Origination Date</span>
                            </th>
                            <th>
                                <span>Property Address</span>
                            </th>
                            <th>
                                <span>Loan Amount</span>
                            </th>
                            <th>
                                <span>Next Payment</span>
                            </th>
                            <th>
                                <span>Maturity</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            // - table rows with search results
                            dataFiltered.length > 0 ?
                                dataFiltered.map(x => {
                                    return (
                                        <tr key={"loans-funded-nested-" + generateId(1, 10000)}>
                                            <td>
                                                <Link to={`${process.env.REACT_APP_SESSION_URL}/deal/${x.id}`}>
                                                    {x.nickname}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`${process.env.REACT_APP_SESSION_URL}/deal/${x.id}`}>
                                                    {x.loanNumber}
                                                </Link>
                                            </td>
                                            {/* <td>
                                                <Link to={`${process.env.REACT_APP_SESSION_URL}/deal/${x.id}`} tabIndex="-1">
                                                    {x.accountName}
                                                </Link>
                                            </td> */}
                                            <td>
                                                <span>
                                                    {x.productType}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {x.loanSignDate}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {x.street}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {x.amount}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {x.nextPaymentDate}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {x.maturityDate}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                                : // - table row with no search results
                                <tr key={generateId(1, 1000)}>
                                    <td>
                                        <span>
                                            No results found.
                                        </span>
                                    </td>
                                </tr>
                        }
                    </tbody>

                </animated.table>

                // - error messaging
                : bodyError
    );

    // - render the SectionExpandable with the heading, controls, and body elements above (future - pass animation)
    return (
        <SectionExpandable heading={heading} body={bodyContent} controls={controls} id={id} expanded={true} callback={setsCallback} flush animate />
    );
};

export default LoansFunded;