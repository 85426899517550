import React, { Fragment } from 'react';
import TransitionIn from '../../../../../components/shared/TransitionIn';
import ConstructionRefactor from './Construction';
import Skeleton  from 'react-loading-skeleton';

export default function Construction(props) {

    const content = (
        <Fragment>
            {
                props.deal === null ? <Skeleton height={"20rem"} /> : 
                <>
                    {
                        props.deal ? <ConstructionRefactor {...props} /> :  null
                    }
                </>
            }
        </Fragment>
    )

    return (
        <TransitionIn content={content} />
    )
}