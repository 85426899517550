import React from 'react';
import './index.scss';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// accepts the following props:
// ----- value: button text
// ----- valueReadOnly: button text in read-only state
// ----- callback: handler for click 
// ----- readOnly: toggle for access (true blocks button access during an API call, for example - controlled in parent state)
// ----- spinner: if true a spinner is visible while read-only is true (defaults to true)

function ButtonStandard(props) {

    const callback = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (props.readOnly) {
            console.log('callback prohibited in read-only state...');
        } else {
            if (props.callBack) {
                console.log('callback in props', props.callBack);
                props.callBack()
            } else {
                console.log('no callback provided in props...')
            }
        }
    }

    return (
        <button
            type="button" // default
            className={`button-standard-rf ${props.readOnly ? "read-only" : ""} ${props.variant}`} // optionally prop for style variants
            onClick={props.callback} // required for onClick handler
            style={props.style} // optional inline styles
            disabled={props.disabledIf ? props.disabledIf : false} // required to prevent multiple submissions
        >
            {props.value || "Button"}
        </button>
    )
}

export default ButtonStandard