// ----- v SUMMARY v ------------------------------------------------------------------------ 
// - Component for the Aply for a Line of Credit link (dynamically rendered)
// - Sprint 16 - US-366 - JR 2021-09-07

// ----- v DESCRIPTION v --------------------------------------------------------------------
// - Action Item child (<tr> with link to Line of Credit applciation).  

// - From DevOps > Apply for a Line of Credit - Show link to LOC Application if any attached 
//   Borrowing Entity has a Deal in Processing Status or beyond (Stage <> Funded, Dead Deal, 
//   Application) and no current Line of Credit submission (Sales_LOC_Status__c = Null)

// ----- v PROPS v --------------------------------------------------------------------------

// ----- v STATE v --------------------------------------------------------------------------
// - none

// ----- v RENDERING v ----------------------------------------------------------------------

// ----- v CRITICAL NOTES v -----------------------------------------------------------------
// - All data should be available via props - no calls to API are needed

// ----- v IMPORTS v ------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import { log, getMaxValue } from '../../../../../utility';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export default function ActionItemLOCApply(props) {

    const dataLoans = props.loans || []; // loans received as props - possibly use null instead of [] to prevent any rendering/flashes...
    const dataLOCs = props.recentLOCs && props.recentUnderwritings ? [...props.recentLOCs || [], ...props.recentUnderwritings || []] : null;   // joining both arrays - null default since part of the evaluation considers an empty array a condition
    let entity = "";

    // evaluate deal statuses
    function checkLoans(arr) {

        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                if ((arr[i].status === "Funded" || arr[i].status === "Dead Deal")) return false;
            };
        };

        return true;
    };

    // check the LOC criteria (pending confirmation from LO on data needed vs data available)
    function checkLOC(arr) {

        if (!arr) {
            return false;
        };

        if (arr.length > 0) {
            const LOCLast = getMaxValue(arr, 'CreatedDate', true);

            if (LOCLast.LOC_Status__c === "Approval") return false;
            if (LOCLast.LOC_Status__c === "Denied" || LOCLast.LOC_Status__c === "Expired") return true;

        } else return true; // handles all other cases, including a new Borrower with no LOC yet
    };

    if (checkLoans(dataLoans) && checkLOC(dataLOCs)) {
        return (
            <tr>
                <td>
                    <span>
                        Borrower Application {entity ? "for " + entity?.borrower?.Borrowing_Entity_Name__r?.Name + "-" : null}
                    </span>
                </td>
                <td>
                    <span>
                        -
                    </span>
                </td>
                <td>
                    <Link to="/dashboard/applications/line-of-credit" data-tip="Click here to complete the Borrower Application." data-for="app-tooltip">
                        Complete a Borrower Application
                    </Link>
                </td>
                {ReactTooltip.rebuild()}
            </tr>
        );
    } else return null;
};