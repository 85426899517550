import React, { useState, useEffect, Fragment } from 'react'
import { useForm } from "react-hook-form";
import axios from "axios";
import { faCopy, faSave } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputText from "../../../../../../../components/_applications/_components/InputText";
import { Section } from '../../../../../../components/shared/Section';
import ReactTooltip from 'react-tooltip';
import { useSpring, animated, config } from '@react-spring/web';
import animations from '../../../../../../animations';
import { log } from '../../../../../../utility';
import ButtonSubmit from "../../../../../../../components/ButtonSubmit";

const ThirdPartyContacts = props => {

    let tabIndex = 0

    const { register, handleSubmit, errors, setValue, reset } = useForm({
        defaultValues: {
            property_access_contact_name: props?.dealDetails?.third_party?.property_access?.contact_name,
            property_access_contact_email: props.dealDetails?.third_party?.property_access?.contact_email,
            property_access_contact_phone: props?.dealDetails?.third_party?.property_access?.contact_phone.replace(/-/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{3})/, '-$1-'),
            title_company_name: props?.dealDetails?.third_party?.title?.company_name,
            title_contact_name: props?.dealDetails?.third_party?.title?.contact_name,
            title_contact_email: props?.dealDetails?.third_party?.title?.contact_email,
            title_contact_phone: props?.dealDetails?.third_party?.title?.contact_phone.replace(/-/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{3})/, '-$1-'),
            insurance_company_name: props?.dealDetails?.third_party?.insurance?.company_name,
            insurance_contact_name: props?.dealDetails?.third_party?.insurance?.contact_name,
            insurance_contact_email: props?.dealDetails?.third_party?.insurance?.contact_email,
            insurance_contact_phone: props?.dealDetails?.third_party?.insurance?.contact_phone.replace(/-/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{3})/, '-$1-'),
            hoa_company_name: props?.dealDetails?.third_party?.hoa?.company_name,
            hoa_contact_name: props?.dealDetails?.third_party?.hoa?.contact_name,
            hoa_contact_email: props?.dealDetails?.third_party?.hoa?.contact_email,
            hoa_contact_phone: props?.dealDetails?.third_party?.hoa?.contact_phone.replace(/-/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{3})/, '-$1-'),
        },
    })

    const [showSave, setShowSave] = useState({
        property_access: false,
        title: false,
        insurance: false,
        hoa: false
    })

    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    const formRef = React.createRef()

    const onSubmit = async formData => {
        log('3P - api start')
        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";
        console.log("BLAM")
        try {
            setSaving(true);
            let { data } = await axios.post(process.env.REACT_APP_LIMA + `/lima/api/deals/update-third-parties`, {
                ...formData,
                id: props?.dealDetails?.id
            }).then(
                log('3P - api returned')
            )
            if (data.success === true) {
                log('3P - api success')
                props.fetchDeal();

                setSaved(true);

                setTimeout(() => {
                    setSaved(false);
                    setShowSave({
                        property_access: false,
                        title: false,
                        insurance: false,
                        hoa: false
                    })

                }, 1000);

            }
        } catch (err) {
            log('3P - api fail')
            console.log(err)
        }

        setSaving(false);
    }

    const onInputChange = e => {
        if (e.target.id.includes('property_access')) {
            setShowSave({ ...showSave, property_access: true })
        }
        if (e.target.id.includes('title')) {
            setShowSave({ ...showSave, title: true })
        }
        if (e.target.id.includes('insurance')) {
            setShowSave({ ...showSave, insurance: true })
        }
        if (e.target.id.includes('hoa')) {
            setShowSave({ ...showSave, hoa: true })
        }
    }

    const clickSave = (e) => {
        e.preventDefault();
        log('3P - click')
        document.activeElement.blur(); // remove focus to hide tooltip before rebuild callback
        // onSubmit()
        formRef.current.dispatchEvent(new Event("submit"))
    }

    const inputPhone = e => {
        let iv = e.target.value
        iv = iv.replace(/[\[\]&]+/g, '');
        // remove dashes
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        iv = iv.trim()

        if (iv.length < 11) {

            var fv = iv
            if (fv.length > 3)
                fv = fv.replace(/^(\d{3})/, '$1-');
            if (fv.length > 7)
                fv = fv.replace(/-(\d{3})/, '-$1-');

            e.target.value = fv
        } else {
            e.target.value = e.target.value.substring(0, 12)
        }
    }

    useEffect(() => {
        reset({
            Property_Access_Contact_Name__c: props?.dealDetails?.third_party?.property_access?.contact_name,
            Property_Access_Contact_Email__c: props.dealDetails?.third_party?.property_access?.contact_email,
            Property_Access_Contact_Phone__c: props?.dealDetails?.third_party?.property_access?.contact_phone.replace(/-/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{3})/, '-$1-'),
            Title_Company_Name__c: props?.dealDetails?.third_party?.title?.company_name,
            Title_Contact_Name__c: props?.dealDetails?.third_party?.title?.contact_name,
            Title_Contact_Email__c: props?.dealDetails?.third_party?.title?.contact_email,
            Title_Contact_Phone__c: props?.dealDetails?.third_party?.title?.contact_phone.replace(/-/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{3})/, '-$1-'),
            Insurance_Company_Name__c: props?.dealDetails?.third_party?.insurance?.company_name,
            Insurance_Contact_Name__c: props?.dealDetails?.third_party?.insurance?.contact_name,
            Insurance_Contact_Email__c: props?.dealDetails?.third_party?.insurance?.contact_email,
            Insurance_Contact_Phone__c: props?.dealDetails?.third_party?.insurance?.contact_phone.replace(/-/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{3})/, '-$1-'),
            HOA_Name__c: props?.dealDetails?.third_party?.hoa?.company_name,
            HOA_Contact_Name__c: props?.dealDetails?.third_party?.hoa?.contact_name,
            HOA_Contact_Email__c: props?.dealDetails?.third_party?.hoa?.contact_email,
            HOA_Contact_Phone__c: props?.dealDetails?.third_party?.hoa?.contact_phone.replace(/-/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{3})/, '-$1-'),
        })
    }, [props.dealDetails])

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [showSave])


    const styles = {
        propertyAccess: useSpring(animations("fadeInEnable", showSave.property_access)),
        title: useSpring(animations("fadeInEnable", showSave.title)),
        insurance: useSpring(animations("fadeInEnable", showSave.insurance)),
        hoa: useSpring(animations("fadeInEnable", showSave.hoa)),
        saveBtn: {
            marginBottom: "0.5rem",
            marginLeft: saving ? "-13rem" : "-7rem",
            color: "white",
            position: "absolute",
            ...(saved && { backgroundColor: "green", pointerEvents: "none", cursor: "not-allowed" }),

        }
    };

    const forms = {
        propertyAccess: {
            heading: (
                " Property Access Contact Info"
            ),
            controls: (
                <animated.div style={styles.propertyAccess}><ButtonSubmit value={saved ? "SAVED!" : "SAVE"} type={"button"} readOnly={saving} callback={clickSave} data-tip="Click here to save your changes" data-for="app-tooltip" style={styles.saveBtn}  /></animated.div>
            ),
            body: (
                <section className="section select-property-type">
                    <fieldset
                        className={`input-field-group row-global grow-3`}>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} placeholder="Contact Name"
                                label={'Property Access Contact Name'} name={'Property_Access_Contact_Name__c'}
                                id={'property_access_contact_name'} tabIndex={tabIndex++} errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'email'} placeholder="address@email.com"
                                label={'Property Access Contact Email'}
                                name={'Property_Access_Contact_Email__c'} id={'property_access_contact_email'}
                                tabIndex={tabIndex++} errors={errors} />
                        </span>
                        <span className="column">

                            <InputText ref={register({ required: false })} type={'text'}
                                onChange={inputPhone}
                                label={'Property Access Contact Phone'}
                                name={'Property_Access_Contact_Phone__c'} id={'property_access_contact_phone'}
                                tabIndex={tabIndex++} errors={errors} placeholder='012-345-6789' />
                        </span>
                    </fieldset>
                </section>
            )
        },
        title: {
            heading: (
                "Title Contact Information"
            ),
            controls:
                (
                    <animated.div style={styles.title}><ButtonSubmit value={saved ? "SAVED!" : "SAVE"} type={"button"} readOnly={saving} callback={clickSave} data-tip="Click here to save your changes" data-for="app-tooltip" style={styles.saveBtn}  /></animated.div>
                ),
            body: (
                <section className="section select-property-type">
                    <fieldset
                        className={`input-field-group row`}>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'Title Company Name'}
                                name={'Title_Company_Name__c'} id={'title_company_name'} tabIndex={tabIndex++} placeholder="Company Name"
                                errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'Title Contact Name'}
                                name={'Title_Contact_Name__c'} id={'title_contact_name'} tabIndex={tabIndex++} placeholder="Contact Name"
                                errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'email'} label={'Title Contact Email'}
                                name={'Title_Contact_Email__c'} id={'title_contact_email'} tabIndex={tabIndex++} placeholder="email@address.com"
                                errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'Title Contact Phone'}
                                onChange={inputPhone} 
                                name={'Title_Contact_Phone__c'} id={'title_contact_phone'} tabIndex={tabIndex++}
                                errors={errors} placeholder='012-345-6789' />
                        </span>
                    </fieldset>
                </section>
            )
        },
        insurance: {
            heading: (
                "Insurance Contact Information"
            ),
            controls:
                (
                    <animated.div style={styles.insurance}><ButtonSubmit value={saved ? "SAVED!" : "SAVE"} type={"button"} readOnly={saving} callback={clickSave} data-tip="Click here to save your changes" data-for="app-tooltip" style={styles.saveBtn}  /></animated.div>
                ),
            body: (
                <section className="section select-property-type">
                    <fieldset
                        className={`input-field-group row`}>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'Insurance Company Name'}
                                name={'Insurance_Company_Name__c'} id={'insurance_company_name'} placeholder="Company Name"
                                tabIndex={tabIndex++} errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'Insurance Contact Name'}
                                name={'Insurance_Contact_Name__c'} id={'insurance_contact_name'} placeholder="Contact Name"
                                tabIndex={tabIndex++} errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'email'}
                                label={'Insurance Contact Email'} placeholder="address@email.com"
                                name={'Insurance_Contact_Email__c'} id={'insurance_contact_email'}
                                tabIndex={tabIndex++} errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'Insurance Contact Phone'}
                                onChange={inputPhone}
                                name={'Insurance_Contact_Phone__c'} id={'insurance_contact_phone'}
                                tabIndex={tabIndex++} errors={errors} placeholder='012-345-6789' />
                        </span>
                    </fieldset>
                </section>
            )
        },
        hoa: {
            heading: (
                "HOA Contact Information"
            ),
            controls:
                (
                    <animated.div style={styles.hoa}><ButtonSubmit value={saved ? "SAVED!" : "SAVE"} type={"button"} readOnly={saving} callback={clickSave} data-tip="Click here to save your changes" data-for="app-tooltip" style={styles.saveBtn}  /></animated.div>
                ),
            body: (
                <section className="section select-property-type">
                    <fieldset
                        className={`input-field-group row`}>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'HOA Company Name'}
                                name={'HOA_Name__c'} id={'hoa_company_name'} placeholder="Company Name"
                                tabIndex={tabIndex++} errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'HOA Contact Name'}
                                name={'HOA_Contact_Name__c'} id={'hoa_contact_name'} placeholder="Contact Name"
                                tabIndex={tabIndex++} errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'email'}
                                label={'HOA Contact Email'} placeholder="address@email.com"
                                name={'HOA_Contact_Email__c'} id={'hoa_contact_email'}
                                tabIndex={tabIndex++} errors={errors} />
                        </span>
                        <span className="column">
                            <InputText ref={register({ required: false })} type={'text'} label={'HOA Contact Phone'}
                                onChange={inputPhone}
                                name={'HOA_Contact_Phone__c'} id={'hoa_contact_phone'}
                                tabIndex={tabIndex++} errors={errors} placeholder='012-345-6789' />
                        </span>
                    </fieldset>
                </section>
            )
        }
    }

    return (
        <main className="form loc-borrower third-party-form" id="third-party-form" style={{ padding: 'unset' }}>

            <form ref={formRef} className={`form-body ${errors.length > 0 ? 'form-error' : ''}`}
                onSubmit={handleSubmit(onSubmit)}
                onChange={onInputChange}>

                <div className="additional-guarantor-form">

                    {
                        props.dealDetails.type !== "NewConstruction" &&
                        <Section heading={forms.propertyAccess.heading} body={forms.propertyAccess.body} controls={forms.propertyAccess.controls} />
                    }

                    <Section heading={forms.title.heading} body={forms.title.body} controls={forms.title.controls} />
                    <Section heading={forms.insurance.heading} body={forms.insurance.body} controls={forms.insurance.controls} />
                    <Section heading={forms.hoa.heading} body={forms.hoa.body} controls={forms.hoa.controls} />

                </div>
            </form>
        </main>
    )
}

export default ThirdPartyContacts