import { cloneData } from "../../../../../../../utility/legacy/utilities_global";

const apiHelper = {
  
    dataDrawRequest(state) {
        // console.log('configuring post...');

        state.lineItems.forEach(x => {
            x.budgetItem = x.name;
            x.costCode = x.code;
        });


        let LineItemsUpdated = cloneData(state.lineItems); // new array to replace current state

        // Updating input element values (find lineItem by table row data-key and property by data-input from input)
        LineItemsUpdated.forEach((element) => {
            // if "amount" field update "remainingBalance" by netting invoiced and amount from budget
            if (element["amount"]) {
                element.balance = parseFloat(element.budget - element.invoiced - element.amount.toString().replace(/,/g, "").replace(/\$/g, "")).toFixed(2);
                element.amount = parseFloat(element.amount.toString().replace(/,/g, "").replace(/\$/g, "")).toFixed(2)
            }
        });

        const data = {
            "dealid": state.dealid,
            "LockboxAccessCode": state.formData.lockbox_access_code.iVal.value,
            "BeneficiaryId": state.formData.beneficiary.iVal.value,
            "lineItems": LineItemsUpdated,
            "inspection_end_date": state.formData.schedule_inspection_end.iVal.value ? new Date(state.formData.schedule_inspection_end.iVal.value).toISOString().slice(0, 10) : ""
        }

        console.log('data', data)

        return data;
    }
}

export { apiHelper }