import React from "react";

import DocumentsBox from './DocumentBox';
import DocumentUpload from "./document_upload";

import axios from "axios";
import { SectionExpandable } from "../../../../../components/shared/Section";
import TransitionIn from "../../../../../components/shared/TransitionIn";

// Deal Component fetches all deal data at the top level.
export default class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: null,
      files: null,
      uploadSubmitted: false,
      clearFiles: false,
    };

    this.serverError = this.serverError.bind(this);

    this.documentsTable = React.createRef();

  }

  setFiles = (files) => {
    this.setState({ files });
  };

  serverError(error, msg) {
    if (!msg) {
      msg = "";
    }

    this.setState({
      serverError: true,
    });
  }

  uploadFiles = async (files) => {
    this.setState({ uploadSubmitted: true });

    if (files.length < 1) {
      this.setState({ uploadSubmitted: false });
      return;
    }

    await Promise.all(
      files.map(async (file) => {
        try {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + window.localStorage.getItem("token");

          const resp = await axios.post(
            process.env.REACT_APP_LIMA + `/lima/api/deals/uploaddocument`,
            JSON.stringify(file)
          );

          console.log(resp)

          this.setState({
            files: [
              ...this.state.files,
              {
                name: file.strippedname,
                url: resp.data.url,
                created_at: new Date().toISOString()
              },
            ],
          });
          // setTriggerRender((triggerRender) => !triggerRender);
        } catch (error) {
          console.error(error);
          this.setState({
            files: []
          })
        }
      })
    );

    this.setState({
      uploadSubmitted: false,
      clearFiles: !this.state.clearFiles,
    });
  };

  render() {

    if (!this.props.deal) return null

    return (
      <TransitionIn content={
        <>
          <SectionExpandable heading="Documents - Upload New" id="doc-upload" body={
            <DocumentUpload
              handleSubmit={(files) => this.uploadFiles(files)}
              dealId={this.props.deal.id}
              submitted={this.state.uploadSubmitted}
              files={this.state.files}
              clearFiles={this.state.clearFiles}
            />
          } />

          <SectionExpandable heading="Documents - Uploaded" flush body={
            <DocumentsBox
              setFiles={this.setFiles}
              files={this.state.files}
              dealId={this.props.deal.id}
              tableRef={this.documentsTable}
            />
          } />
        </>
      } />

    );
  }
}
