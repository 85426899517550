import React, { useState } from 'react';
import './index.scss';
import currency from 'currency.js';
import { dateToday } from '../../../../../../utility';

const DealLoanServicing = (props) => {

    // deal details shorthand / destructure
    let { loanNumber, address, city, state, zip, type, rate, nextPaymentDate,
        loan_amount, principalBalance, tIEscrowBalance, suspenseBalance, interestReserves, status } = props.dealDetails;

    if (props.dealDetails?.properties && props.dealDetails.properties.length) {
        if (city === "" || !city) {
            city = props.dealDetails.properties[0].city
        }

        if (state === "" || !state) {
            state = props.dealDetails.properties[0].state
        }

        if (zip === "" || !zip) {
            zip = props.dealDetails.properties[0].zip
        }

        if (address === "" || !address) {
            address = props.dealDetails.properties[0].street;
        }
    }

    // helper shorthand for currency().format()
    const format = (value) => {
        // Check if the value contains scientific notation (e.g., "4.874915326E7")
        if (/[\d.]+[eE][-+]?\d+/g.test(value)) {
          value = parseFloat(value);
        }
        return currency(value).format();
    };
    // set date for loan balances (today - 1, show friday on weekends and mondays)
    const date = dateToday()

    if (status === 'Funded' ||
        props.dealDetails?.type === "Short Term Finance" ||
        props.dealDetails?.type === "NewConstruction" ||
        props.dealDetails?.type === "New Construction" ||
        props.dealDetails?.type === "FixNFlip"
      ) {
        return (
            <div className="deal-details-new">

                <div className="deal-details-static">
                    <div className="deal-details_panel left">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span>Loan Information</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th>
                                        <span>Loan Number</span>
                                    </th>
                                    <td>
                                        <span>
                                            {loanNumber || "-"}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            Property Address
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {address || "-"}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            City, State, Zip
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {`${(city && state && zip) ? city + ', ' + state + ' ' + zip : '-'}`}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            Product
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {type || '-'}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            Rate
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {rate || '-'}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            Next Due Date
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {nextPaymentDate || '-'}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="deal-details_panel right">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span>
                                            Loan Balances <span className="date">{`as of ${date}*`}</span>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>
                                        <span>
                                            Loan Amount
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {format(loan_amount) || '-'}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            Current Principal Balance
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {format(principalBalance) || '-'}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            Taxes & Insurance
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {format(tIEscrowBalance) || '-'}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            Suspense Balance
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {format(suspenseBalance) || '-'}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <span>
                                            Interest Reserves
                                        </span>
                                    </th>
                                    <td>
                                        <span>
                                            {format(interestReserves) || '-'}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* <div className="deal-details-toggle">

                    <div className="deal-details_panel left">
                        <table>
                            <thead>
                                <tr>
                                    <th> {toggle ? "Loan Information" : "Loan Balances"} </th>
                                </tr>
                            </thead>

                            {toggle ?
                                <tbody>
                                    <tr>
                                        <th>Loan Number</th>
                                        <td>{loanNumber}</td>
                                    </tr>
                                    <tr>
                                        <th>Property Address</th>
                                        <td>{address}</td>
                                    </tr>
                                    <tr>
                                        <th>City, State, Zip</th>
                                        <td>{`${!(city && state && zip) ? city + ',' + state + ' ' + zip : '-'}`}</td>
                                    </tr>
                                    <tr>
                                        <th>Product</th>
                                        <td>{type || '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>Rate</th>
                                        <td>{rate || '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>Next Due Date</th>
                                        <td>{nextPaymentDate || '-'}</td>
                                    </tr>
                                </tbody>
                                :
                                <tbody>
                                    <tr>
                                        <th>Loan Amount</th>
                                        <td>{format(loan_amount) || '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>Current Principal Balance</th>
                                        <td>{format(principalBalance) || '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>Taxes & Interest Escrow Balance</th>
                                        <td>{format(tIEscrowBalance) || '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>Suspense Balance</th>
                                        <td>{format(suspenseBalance) || '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>Interest Reserves</th>
                                        <td>{format(interestReserves) || '-'}</td>
                                    </tr>
                                </tbody>

                            }
                        </table>
                        <p class="date">{`*as of ${date()}`}</p>
                    </div>
                </div> */}

            </div>
        )
    } else return null

}

export default DealLoanServicing;
