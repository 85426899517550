import React from 'react';
import axios from 'axios';

const LinkPaymentus = props => {

    // declare props and their fallbacks
    const text = props.text || 'Text'; // button/link text 
    const icon = props.icon || 'icon'; // icon for button/link

    const getPaymentusLink = e => {
        e.preventDefault();
        props.toggleNav();

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";

        axios.get('/lima/api/makepayment')
            .then((res) => {
                let url = res.data.url;
                window.open(url, "_blank")
            })
            .catch()
    }

    return (
        <button onClick={getPaymentusLink} className="nav-item">
            <div>
                <span className="button-icon">{icon}</span>
                <span>{text}</span>
            </div>
        </button>
    )
}

export default LinkPaymentus