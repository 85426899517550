import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactModal from "react-modal";
import { ReactSVG } from "react-svg";
import DocumentUpload from "./DocumentUpload";

export const MobileView = ({
  item,
  setSelectedChecklist,
  setSelectedCommentBox,
  handleChange,
  comments,
  setShowModal,
  showModal,
  uploadFiles,
  uploadSubmitted,
  selectedChecklist,
  clearFiles,
  opportunityId,
  urls
}) => {
  const renderChecklistItems = () => {
    return item.checklistItems.map((checklistItem, i) => {
      const date = new Date(
        checklistItem.TC_Origination__User_Last_Modified__c
      );
      if (!checklistItem?.TC_Origination__No_Comments__c) {
        return (
          <>
            <tr key={Math.random()}>
              <td>
                <span>
                  {checklistItem.Name}
                </span>
              </td>
              <td>
                <span>
                  {checklistItem.TC_Origination__Status__c}
                </span>
              </td>

              <td>
                <span>
                  {date.getMonth() +
                    1 +
                    "/" +
                    date.getDate() +
                    "/" +
                    date.getFullYear()}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <p className={"comment-box-label"}>Your Comments:</p>
                  <textarea
                    onClick={(e) => setSelectedCommentBox(e.target)}
                    onChange={handleChange.bind(checklistItem)}
                    value={comments[checklistItem.Id]}
                    placeholder={"Comment here"}
                    data-id={checklistItem.Id}
                  />
                </span>
              </td>
              <td>
             <span>
             <p className={"comment-box-label"}>Lima One Comments:</p>
                <textarea
                  disabled
                  value={checklistItem.TC_Origination__Default_Comment__c}
                  placeholder={"There are no comments yet."}
                />
             </span>
              </td>
              <td>
                <button
                  className="button-submit green"
                  // style={{ height: "26px" }}
                  type="button"
                  onClick={() => {
                    setShowModal(true);
                    setSelectedChecklist(checklistItem);
                  }}
                >
                  <FontAwesomeIcon icon={faUpload} />
                  <span className="upload-files">
                      {!checklistItem?.files?.length ? "UPLOAD" : `FILES (${checklistItem?.files?.length})`}
                  </span>
                </button>
              </td>
            </tr>
          </>
        );
      }
    });
  };

  return (
    <>
      <table className="locTable table mobile">
        <tbody>
          <tr>
            <th style={{ width: "33%" }}>
              <span>
                ITEM
              </span>
            </th>
            <th style={{ width: "33%" }}>STATUS</th>
            <th style={{ width: "33%", textAlign: "right" }}>LAST UPDATED</th>
          </tr>

          {item?.checklistItems && renderChecklistItems()}
        </tbody>
      </table>

      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        contentLabel="onRequestClose Example"
        onRequestClose={() => {
          setShowModal(false);
          setSelectedChecklist(null);
        }}
        shouldCloseOnOverlayClick={true}
        dialogClassName="add-members-modal"
        className="loc-doc-upload-modal"
      >
        <div className="close-bar" style={{ textAlign: "right", padding: "0" }}>
          <button
            onClick={() => {
              setShowModal(false);
              setSelectedChecklist(null);
            }}
          >
            <ReactSVG src="/assets/images/xicon.svg" />
          </button>
        </div>
        <div class="document-upload-modal">
          <DocumentUpload
            handleSubmit={(files) => uploadFiles(files)}
            submitted={uploadSubmitted}
            checklist={selectedChecklist}
            opportunityId={opportunityId}
            clearFiles={clearFiles}
            urls={urls}
          />
        </div>
      </ReactModal>
    </>
  );
};
