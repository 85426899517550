import React from 'react'
import InputText from "../../_components/InputText";
import InputDate from "../../_components/InputDate";

const Selling = React.forwardRef(({errors, submitted, success, children, notReqRef}, ref) => {
    return (
        <React.Fragment>
        <h2>If the property is currently listed to sell, please fill out the following</h2>

        <fieldset className="input-field-group gaps flex-start">
            <div className={`d-flex w-100`}>
                <InputText ref={notReqRef} type={'text'} label={'Please provide the link to the listing*'} name={'link_to_listing'} errors={{...errors, ...(errors?.listing_agreement && { 'link_to_listing': { type: "manual", message: "required"}})}} />
            </div>
            <div className={`d-flex w-100`}>
            {errors?.listing_agreement && <span className="error">Please provide a link to the listing or upload the Listing Agreement..</span>}
            </div>
            {children}
       </fieldset>

        <fieldset className="input-field-group gaps">
            <div className={`input-field full-width ${errors?.price_reductions_30_days ? "error" : ""}`} >
                <label htmlFor={"price_reductions_30_days"}>Has there been any price reductions in the last 30 days?*</label>
                <br />
                <ul className={"radio-field"}>
                    <li>
                        <input ref={ref} style={{ width: '24px' }} type="radio" name={"price_reductions_30_days"} id={"price_reductions_30_days_yes"} value="Yes" /><label htmlFor={"price_reductions_30_days_yes"}>Yes</label>
                    </li>
                    <li>
                        <input ref={ref} style={{ width: '24px' }} type="radio" name={"price_reductions_30_days"} id={"price_reductions_30_days_no"} value="No" /><label htmlFor={"price_reductions_30_days_no"}>No</label>
                    </li>
                </ul>
            </div>
        </fieldset>

        <fieldset className="input-field-group gaps">
            <div className={`input-field full-width ${errors?.price_plan_to_reduce ? "error" : ""}`} >
                <label htmlFor={"price_plan_to_reduce"}>If no, do you plan on reducing the sales price?*</label>
                <br />
                <ul className={"radio-field"}>
                    <li>
                        <input ref={ref} style={{ width: '24px' }} type="radio" name={"price_plan_to_reduce"} id={"price_plan_to_reduce_yes"} value="Yes" /><label htmlFor={"price_plan_to_reduce_yes"}>Yes</label>
                    </li>
                    <li>
                        <input ref={ref} style={{ width: '24px' }} type="radio" name={"price_plan_to_reduce"} id={"price_plan_to_reduce_no"} value="No" /><label htmlFor={"price_plan_to_reduce_no"}>No</label>
                    </li>
                </ul>
            </div>
        </fieldset>

        <fieldset className={`input-field-group gaps ${errors?.price_expected_reduction_date ? "error" : ""}`}>
                <InputDate ref={notReqRef} type={'text'} label={'If you plan on reducing the price, what is the expected date this will take place?*'} name={'price_expected_reduction_date'} errors={errors} />
         </fieldset>
         </React.Fragment>
    )

})

export default Selling