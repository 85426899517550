import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import ButtonSubmit from "../../../../../components/legacy/ButtonSubmit";
import ReactTooltip from "react-tooltip";
import "./upload-table.scss";
import Swal from "sweetalert2";
import {fileTypes} from "../../../../../../components/_applications/_servicing/_extension";

const DocumentUpload = (props) => {
  const [files, setFiles] = useState([]);
  const inputRef = useRef();
  const headingRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("doc-upload")) {
        Array.from(document.getElementsByTagName("INPUT")).filter((input) => input.type === "file").forEach((input) => {
          input.addEventListener("change", (e) => {
            const megabytes = (e.target.files[0].size / 1024) / 1024;
            if (megabytes > 20) {
              input.value = null;
              Swal.fire({
                icon: 'warning',
                title: 'File size too large.',
                text: 'Max file size is 20MB',
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#1D3B62'
              })
            }
          }, false);
        });
      }
    }, 1000);
  }, []);

  const cancelDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnter = (e) => {
    cancelDefaults(e);
    headingRef.current.classList.add("hovered");
  };

  const dragLeave = (e) => {
    cancelDefaults(e);
    headingRef.current.classList.remove("hovered");
  };

  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.addEventListener(
        "dragenter",
        (e) => dragEnter(e),
        false
      );
      headingRef.current.addEventListener(
        "dragover",
        (e) => dragEnter(e),
        false
      );
      headingRef.current.addEventListener(
        "dragleave",
        (e) => dragLeave(e),
        false
      );
      headingRef.current.addEventListener(
        "drop",
        (e) => {
          dragLeave(e);
          getBinary(e.dataTransfer.files);
        },
        false
      );
    }
  }, []);

  useEffect(() => { ReactTooltip.rebuild() }, [files]);
  useEffect(() => {
    inputRef.current.value = null;
    setFiles((files) => []);
  }, [props.clearFiles]);

  const getBinary = (files) => {
    Array.from(files).forEach((file) => {
      const name = file.name.split(/(\\|\/)/g).pop();
      const fileExtension = name.substr(name.lastIndexOf("."), name.length);

      if (!file) return;

      if (!fileTypes.includes(fileExtension)) return;

      const reader = new FileReader();

      reader.onloadend = function () {
        const b64 = reader.result.replace(/^data:.+;base64,/, "");
        res(b64);
      };

      reader.readAsDataURL(file);

      const res = (b64) => {
        if (b64)
          setFiles((files) => [
            ...files,
            {
              strippedname: name.substr(0, name.lastIndexOf(".")) || name,
              rentrollfilename: name,
              rentrollfiledata: b64,
              opportunityId: props.dealId,
              id: "_" + Math.random().toString(36).substr(2, 9),
            },
          ]);
      };
    });
  };

  return (
    <form
      className="form file-upload"
      onSubmit={(e) => {
        e.preventDefault();
        props.handleSubmit(files);
      }}
    >
      <fieldset disabled={props?.submitted ? props.submitted : false}>
        <input
          ref={inputRef}
          type="file"
          multiple
          accept={fileTypes}
          onChange={(e) => getBinary(e.target.files)}
        />

        <div className="heading" ref={headingRef}>
          <div>
            <img src="/assets/images/Upload_Icon.svg" alt="UPLOAD" />
            <p>
              <span className={"hide-mobile"}>Drag & Drop files here</span>
            </p>
            <p className={"hide-mobile"}>or</p>

            <div className="button-group">
              <button type="button" onClick={() => inputRef.current.click()}>
                Browse Files
              </button>

              {files.length > 0 && (
                <ButtonSubmit
                  value="UPLOAD"
                  callBack={() => props.handleSubmit(files)}
                  readOnly={props?.submitted ? props.submitted : false}
                  spinner={props?.submitted ? props.submitted : false}
                  disabledIf={props?.submitted ? props.submitted : false}
                />
              )}
            </div>
          </div>
        </div>

        {/* <label>Ready to upload</label> */}
        <table className={"upload-files-table table"}>
          <thead>
            <tr>
              <th>
                <span>Files Ready For Upload</span>
              </th>
              <th>
                <span></span>
              </th>
            </tr>
          </thead>

          <tbody>
            {files.length > 0 ? (
              files.map((file) => (

                <tr className="clickable" data-for="app-tooltip" data-tip="Click to remove the file from the pending uploads." onClick={() => {
                  setFiles((files) =>
                    files.filter((file1) => file1.id !== file.id)
                  );
                  // ReactTooltip.rebuild();
                }
                }>
                  <td>
                    <span style={{ paddingLeft: "0 !important" }}>
                      {file.rentrollfilename}
                    </span>
                  </td>
                  <td>
                    <span style={{ textAlign: "right" }}>
                      <FontAwesomeIcon
                        icon={faTrash}
                      />
                    </span>
                  </td>
                </tr>

              ))
            ) : (
              <tr>
                <td>
                  <span style={{ paddingLeft: "0 !important" }} >
                    No files selected.
                  </span>
                </td>
                <td>
                  <span></span>
                </td>
              </tr>
            )}

          </tbody>
        </table>


      </fieldset>
    </form>
  );
};

export default DocumentUpload;