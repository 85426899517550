import React, { useState, useEffect } from "react";
import axios from "axios";
import { DesktopView } from "./DesktopView";
import { MobileView } from "./MobileView";

const ChecklistTable = (props) => {
  const [selectedCommentBox, setSelectedCommentBox] = useState(null);
  const [comments, setComments] = useState(null);
  const [triggerRender, setTriggerRender] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [uploadSubmitted, setUploadSubmitted] = useState(false);
  const [clearFiles, setClearFiles] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 1040;

  useEffect(() => {
    document.addEventListener("mousedown", updateComments);

    if (!comments) {
      let initComments = [];
      if (props.item?.checklistItems) {
        props.item.checklistItems.forEach((checklistItem) => {
          initComments[checklistItem.Id] =
              checklistItem.TC_Origination__Question__c;
        });
      }
      setComments(initComments);
    }
  }, [selectedCommentBox, showModal]);

  useEffect(() => {}, [triggerRender, uploadSubmitted]);

  const uploadFiles = async (files) => {
    setUploadSubmitted(true);

    if (files.length < 1) {
      setUploadSubmitted(false);
      return;
    }

    const urls = props.urls

    await Promise.all(
      files.map(async (file) => {
        try {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + window.localStorage.getItem("token");
          const res = await axios.post(
            process.env.REACT_APP_LIMA + `/lima/api/checklist/uploaddocument`,
            JSON.stringify(file)
          )

          if (props?.callback)
              props.callback()

          if (!selectedChecklist.files) {
            selectedChecklist['files'] = [{
              ContentDocumentId: res.data.ContentDocumentId,
              ContentDocument: {
                Title: file.strippedname,
              }
            }]
          } else {
            selectedChecklist.files.push({
              ContentDocumentId: res.data.ContentDocumentId,
              ContentDocument: {
                Title: file.strippedname,
              },
            });
          }
          setTriggerRender((triggerRender) => !triggerRender);
        } catch (error) {
          console.error(error);
        }
      })
    );

    props.setUrls(urls)
    setUploadSubmitted(false);
    setClearFiles((clearFiles) => !clearFiles);
    setTriggerRender((triggerRender) => !triggerRender);
  };

  const handleChange = (event) => {
    let values = comments;
    values[event.target.dataset.id] = event.target.value;
    setComments(values);
    setTriggerRender((triggerRender) => !triggerRender);
  };

  const updateComments = (event) => {
    if (selectedCommentBox) {
      if (event.target !== selectedCommentBox) {
        update(selectedCommentBox.dataset.id, selectedCommentBox.value);
        setSelectedCommentBox(null);
      }
    }
  };

  const update = async (Id, comments) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_LIMA +
          `/lima/api/deals/checklist-update-comments`,
        { Id, comments }
      );
      if (data.success === false) {
        console.log("FAILED TO SAVE COMMENTS by success");
      }
    } catch (error) {
      console.log(error);
      console.log("FAILED TO SAVE COMMENTS");
    }
  };

  const data = {
    item: props.item,
    setSelectedChecklist,
    setSelectedCommentBox,
    handleChange,
    comments,
    setShowModal,
    showModal,
    uploadFiles,
    uploadSubmitted,
    selectedChecklist,
    clearFiles,
    opportunityId: props.item.Id,
    urls: props.urls
  };

  if (comments !== null) {
    return isMobile ? <MobileView {...data} /> : <DesktopView {...data} />;
  } else return "";
};

export default ChecklistTable;
