import './index.scss';
import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {useForm} from "react-hook-form"
import ButtonSubmit from '../../../ButtonSubmit';
import {Redirect} from 'react-router-dom';
import axios from 'axios'
import InputText from "../../_components/InputText";
import InputDate from "../../_components/InputDate";
import InputSelect from "../../_components/InputSelect";
import {encodeJson} from '../../_api';
import './index.scss';

const PayoffRequest = props => {

    const [submitted, setSubmitted] = useState(false);
    const [success, setSuccess] = useState(null); // state for API submission
    const {register, handleSubmit, errors, watch} = useForm();
    const watchSellOrFinance = watch("sale_or_refinance");

    const inputCurrency = e => {
        let iv = e.target.value; // target value

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.,$]+$/) || iv === "" || iv === "$") {
            var atEnd = iv === "" || iv === "$"
            var cleanIV = iv.replace(/,/g, "").replace(/\$/g, "").replace(/\./g, "")

            var fv = "0"

            if (iv !== "") {
                fv = parseFloat(cleanIV)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            // iv = validateInput.number(cleanIV);

            if (atEnd) {
                fv = ""
            }

            e.target.value = "$" + fv
        } else {
            e.target.value = "$0.00"
        }
    }

    const onSubmit = formData => {
        setSubmitted(true);

        if (props?.external === true) {
            formData['external'] = true;
        }

        // axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.post(process.env.REACT_APP_LIMA + `/lima/api/payoff-request`, encodeJson(formData)
        ).then((data) => {
                setSubmitted(false);
                setSuccess(true);
            }
        ).catch(error => {
            setSubmitted(false);
            setSuccess(false);
        });
    }

    let tabIndex = 0
    // console.log(errors);

    // If API submission returns 200, redirect to thank you, otherwise show form
    // If API submission returns 200, redirect to thank you, otherwise show form
    if (success === true) {
        return <Redirect
            to={{
                pathname: '/thank-you/form',
                state: {
                    external: props?.external === true,
                    message: "Thank you! Please allow 3 business days to generate and approve the Payoff Letter. We will reach out to the email provided once complete."
                }
            }}
        />
    } else
        return (
            <div className="form loc-borrower form-payoff-request" id="form">
                <section className="section">
                    <div>
                        <p>
                            Typical turnaround time is 3 business days from date of request. Please note that more than
                            one payoff request in a 30 day timeframe will result in a $20 fee. Lima One Capital retains
                            the right to disregard payoff requested based on account delinquency. If severely
                            delinquent, please email specialservicing@limaone.com.
                        </p>

                        <p>
                            All fields must be completed before the form can be submitted. The "Borrower Name" should be
                            the name of the borrowing entity (the LLC).
                        </p>

                        <p>
                            <span>Good Through Date</span>

                            <ul>
                                <li>
                                    A) Definition – This date is the expiration date of the payoff letter. It is
                                    suggested that additional days be added to prevent shortages.
                                </li>
                                <li>
                                    B) Disclaimer – Cannot be more than 30 days out. Any request exceeding this limit
                                    will be generated using the maximum date.
                                </li>
                            </ul>
                        </p>
                    </div>
                </section>

                <form className={`form-body ${errors.length > 0 ? 'form-error' : ''}`}
                      onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-payoff-request">

                        <div className="row input-field-group">
                            <div className="column">
                                <InputText ref={register({required: true})} type={'text'} label={'Loan Number*'}
                                           name={'loan_number'} errors={errors}/>
                            </div>

                            <div className="column">
                                <InputText ref={register({required: true})} type={'text'} label={[
                                    <div style={{height: '1.2em', bottom: '1em'}}>
                                        Property Address*
                                        <br/>
                                        <small style={{ whiteSpace: "nowrap" }}>(or "ALL" if paying off multi-property loan)</small>
                                    </div>
                                ]} name={'property_address'} errors={errors}/>
                            </div>

                            <div className="column">
                                <InputDate ref={register({required: true})} label={'Good through date? (see above)*'}
                                           name={'good_through_date'} errors={errors}/>
                            </div>
                            <div className="column">
                                <InputText ref={register({required: true})} type={'email'}
                                           label={'Payoff recipient email?*'} name={'email'} errors={errors}/>
                            </div>
                        </div>

                        <div className="row input-field-group grow-2">
                            <div className="column">
                                <InputText ref={register({required: false})} type={'email'} label={'Optional Email'}
                                           name={'optional_email'} errors={errors}/>
                            </div>

                            <div className="column">
                                <InputSelect ref={register({required: true})} label={'Sale or Refinance*'}
                                             name={'sale_or_refinance'} errors={errors} config={[
                                    {
                                        name: 'Sell',
                                        value: 'Sell'
                                    },
                                    {
                                        name: 'Refinance',
                                        value: 'Refinance'
                                    }
                                ]}/>
                            </div>

                            <div className="column">
                                <InputText ref={register({required: watchSellOrFinance === 'Sell'})}
                                           onChange={inputCurrency} type={'text'}
                                           label={`If sale, then what is the estimated sales price?${watchSellOrFinance === 'Sell' ? '*' : ''}`}
                                           name={'if_sale_then_what_is_the_estimated_sales_price'} errors={errors}/>
                            </div>

                            <div className="column">
                                <InputText ref={register({required: watchSellOrFinance === 'Refinance'})} type={'text'}
                                           label={`If refinance, name of lender and agent contact${watchSellOrFinance === 'Refinance' ? '*' : ''}:`}
                                           name={'if_refinance_name_of_lender_and_agent_contact'} errors={errors}/>
                            </div>

                            <div className="column">
                                <InputText ref={register({required: true})} type={'text'}
                                           label={'Updated borrower mailing address (City, State, Zip)*'}
                                           name={'updated_borrower_mailing_address'} errors={errors}/>
                            </div>

                        </div>

                        <div className="row input-field-group">
                            <div className="column">
                                <br/>
                                <ButtonSubmit readOnly={submitted}
                                              disabledIf={window.localStorage.getItem("employeeToken")}/>
                                {success === false ? "An error ocurred during submission.  Please try again later." : ""}
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        )
}

export default withRouter(PayoffRequest)
