import './index.scss';
import React, {useState, Fragment, useEffect} from 'react';
import { Redirect, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form"
import states from '../../../../assets/config/_us_states.json';
import countries from '../../../../assets/config/_countries.json';
import ButtonSubmit from '../../../ButtonSubmit';
import axios from 'axios'
import InputText from "../../_components/InputText";
import InputTextField from "../../_components/InputTextField";
import InputSelect from "../../_components/InputSelect";
import InputDate from "../../_components/InputDate";
import { Section } from '../../../../App/components/shared/Section';
import {checkFileSize, encodeJson, getBinary} from '../../_api';
import {fileTypes} from "../_extension";

const CapExForm = props => {

    const [submitted, setSubmitted] = useState(false)
    const [success, setSuccess] = useState(null); // state for API submission
    const { register, handleSubmit, errors } = useForm()

    const onSubmit = async formData => {
        setSubmitted(true);

        let invoices_photos = []

        if (formData['invoices_photos'].length > 3) {
            alert('You can only upload a maximum of 3 files.')
            setSubmitted(false)
            return;
        }

        for (var i = 0; i < formData['invoices_photos'].length; i++) {
            let base64 = await getBinary(formData['invoices_photos'][i])
            invoices_photos.push({
                fileData: base64.replace(/^data:.+;base64,/, ''),
                fileName: formData['invoices_photos'][i]['name']
            })
        }

        let mailing_address = {
            mailing_address: `
        ${formData['street_address_check']}<br />
        ${formData['city_check']}, ${formData['state_check']} ${formData['zip_check']}<br />
        ${formData['country_check']}`
        }

        let property_address = {
            property_address: `
        ${formData['street_address_property']}<br />
        ${formData['city_property']}, ${formData['state_property']} ${formData['zip_property']}<br />
        ${formData['country_property']}`
        }

        let files = { files: invoices_photos }

        encodeJson(formData)
        encodeJson(mailing_address)
        encodeJson(property_address)

        let fd = {...formData, ...mailing_address, ...property_address, ...files}

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.post(process.env.REACT_APP_LIMA + `/lima/api/capex-request`, fd
        ).then((data) => {
            setSubmitted(false);
            setSuccess(true);
        }
        ).catch(error => {
            setSubmitted(false);
            setSuccess(false);
        });
    }

    const fileInputStyle = {
        maxHeight: "2rem",
        paddingTop: "0.4rem",
        maxWidth: "15rem",
        border: "1px solid gray"
    }

    let tabIndex = 0
    if (success === true) {
        return <Redirect
            to={{
                pathname: '/thank-you/form',
                state: { external: props?.external === true, message: "Thank you we will reach out quickly to the email provided!" }
            }}
        />
    } else
        return (
            <main className="form loc-borrower capex-body" id="form" >

                <section className="section">
                    <p>
                        All fields must be completed before the form can be submitted. The "Borrower Name" should be the name of the borrowing entity (the LLC).
                    </p>
                </section>

                <form className={`form-body ${errors.length > 0 ? 'form-error' : ''}`} onSubmit={handleSubmit(onSubmit)} >

                    <div className="row input-field-group">

                        <div className="column">
                            <InputText ref={register({ required: true })} type={'email'} label={'Email*'} name={'email'} errors={errors} />
                        </div>

                        <div className="column">
                            <InputText ref={register({ required: true })} type={'text'} label={'Loan Number*'} name={'loan_number'} errors={errors} />
                        </div>

                        <div className="column">
                            <InputText ref={register({ required: true })} type={'text'} label={'Entity Name*'} name={'entity_name'} errors={errors} />
                        </div>

                        <div className="column">
                            <InputDate ref={register({ required: true })} label={'Date*'} name={'date'} errors={errors} />
                        </div>
                    </div>

                    <div className="row input-field-group grow-2">
                        <div className="column">
                            <InputText ref={register({ required: true })} label={'Name*'} name={'name'} errors={errors} />
                        </div>

                        <div className="column">
                            <InputTextField ref={register({ required: true })} label={'Description of work completed*'} name={'desc_work_completed'} errors={errors} />
                        </div>
                    </div>

                    <div className="row input-field-group grow-2">
                        <div className="column doc-upload-custom">
                            <label for="invoices_photos">Invoices and Photos</label>
                            <input ref={register({ required: false })} type="file" name="invoices_photos" accept={fileTypes} multiple style={fileInputStyle} onChange={checkFileSize}/>
                        </div>
                    </div>

                    <Section heading={"Property Address"} body={
                        <Fragment>
                            <div className="row input-field-group grow-3">
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Street Address*'} name={'street_address_property'} errors={errors} />
                                </div>

                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'City*'} name={'city_property'} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={states} label={'State / Province / Region'} name={'state_property'} errors={errors} />
                                </div>
                            </div>

                            <div className="row input-field-group  grow-2">
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Zip / Postal Code*'} name={'zip_property'} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={countries} label={'Country'} name={'country_property'} errors={errors} />
                                </div>
                            </div>
                        </Fragment>
                    } />

                    <Section heading={"Mailing Address for Check"} body={
                        <Fragment>
                            <div className="row input-field-group grow-3">
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Street Address*'} name={'street_address_check'} errors={errors} />
                                </div>

                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'City*'} name={'city_check'} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={states} label={'State / Province / Region'} name={'state_check'} errors={errors} />
                                </div>
                            </div>

                            <div className="row input-field-group grow-2">
                                <div className="column">
                                    <InputText ref={register({ required: true })} type={'text'} label={'Zip / Postal Code*'} name={'zip_check'} errors={errors} />
                                </div>
                                <div className="column">
                                    <InputSelect ref={register({ required: true })} config={countries} label={'Country'} name={'country_check'} errors={errors} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <ButtonSubmit readOnly={submitted} disabledIf={window.localStorage.getItem("employeeToken")} />
                                </div>
                            </div>
                        </Fragment>
                    } />
                </form>
            </main>
        )
}

export default withRouter(CapExForm)
