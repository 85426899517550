import React from 'react';
import currency from 'currency.js';
import { Section } from '../../../../../../components/shared/Section';
import TransitionIn from '../../../../../../components/shared/TransitionIn';

import './index.scss';

export default function Escrow(props) {

  const heading = `Remaining Escrow`;

  const body = (
    <TransitionIn content={
      <>
        <div className="escrow-bar">
          <div className="escrow-bar-inner" style={{ width: ((parseFloat(props.dealBudget?.totalBudget) - parseFloat(props.dealBudget?.totalInvoiced)) / parseFloat(props.dealBudget?.totalBudget)) * 100 + "%" }}>
          </div>
        </div>
        <p className="remaining">
          <strong>{currency(props.dealBudget?.totalBalance).format()}</strong> remaining of the total budget of <strong>{currency(props.dealBudget?.totalBudget).format()}</strong>.
        </p></>
    } />
  )

  return (
    <Section heading={heading} body={body} class={"construction-escrow"} />
  )
}
