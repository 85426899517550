import React, { Fragment, useState } from 'react'
import { Redirect, withRouter } from "react-router-dom";
import DocumentUpload from '../Deal/document_upload';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './index.scss';

import { Section } from '../../../components/shared/Section';
import TransitionIn from '../../../components/shared/TransitionIn';

export default withRouter(class ApplicationThankYou extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.match.params.type,
            completed: false,
            files: [],
            referral: props.location.state || {},
            needsLOC: false,
            sendToLOC: false
        }
    }

    componentDidMount = () => {

        if (this.state.referral?.borrower_id) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");

            axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/loc', {
                borrower_id: this.state.referral?.borrower_id
            }
            ).then((res) => {
                if (!res.data.loc && !res.data.underwriting) {
                    this.setState({ needsLOC: true })
                }
            }).catch();
        }

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";
        axios.get(process.env.REACT_APP_LIMA + `/lima/api/users/get-owner-info`, {})
            .then((data) => {
                if (data?.data) this.setState({ owner: data.data })
            })
            .catch();

            // update deals in App (main nav)
            this.props.loans.fetchDeals();
    }

    completedCallback = () => {
        this.setState({ completed: true })
    }

    setFiles = (filesArray) => {
        this.setState({ files: filesArray })
    }

    maskPhoneNumber = (iv) => {
        iv = iv.replace(/-/g, '')
        iv = iv.replace(/\D/g, '')
        iv = iv.trim()

        // insert dashes into string for formatted value (fv)
        var fv = iv.substring(iv.length - 10 < 0 ? 0 : iv.length - 10, iv.length);

        if (fv.length > 3)
            fv = fv.replace(/^(\d{3})/, '$1-');
        if (fv.length > 7)
            fv = fv.replace(/-(\d{3})/, '-$1-');

        return fv
    }

    setTitle() {
        if (this.state.referral.title === "NewConstruction") {
            return "New Construction";
        } else return this.state.referral.title
    }

    render() {

        if (this.state.sendToLOC) {
            return <Redirect to={{
                pathname: '/dashboard/applications/line-of-credit',
                state: {
                    opportunity_id: this.state.referral.opportunity_id,
                    borrower_id: this.state.referral.borrower_id,
                    account_id: this.state.referral.account_id
                }
            }} />
        }



        let renderRentRoll = this.state.referral && this.state.referral.formVersion && this.state.referral.formVersion === "portfolio"

        const heading = (
            <span>
                Thank you for your {this.setTitle()} application!
            </span>
        );

        console.log(this.state)

        const content = (
            <Fragment>

                {
                    this.props.accountType === "" ? <p>Thank you, we will reach out shortly to the email provided!</p> :
                        <React.Fragment>
                            <p>
                                {this.state.referral?.message ? this.state.referral.message : `Thank you for submitting your ${this.setTitle()} application. Our sales and underwriting teams will begin working on your file. They will be in touch with next steps and to collect any outstanding documentation.`}
                            </p>
                            <br />
                            <p>
                                <Link to="/dashboard">Click here to return to the Dashboard</Link>
                            </p>
                        </React.Fragment>
                }


                <br />

                {renderRentRoll &&
                    <React.Fragment>
                        {this.state.needsLOC &&
                            <><p><br /><br />An LOC application must be submitted for the borrowing entity. <a href="#" onClick={() => this.setState({ sendToLOC: true })}>Click here</a> to navigate to the LOC application.</p><br /></>
                        }

                        <p>Please download the <a download style={{ fontWeight: 'bold' }} href="/documents/Rent_Roll_Spreadsheet.xlsx"> Rent Roll Template</a> to get started.</p>
                        <br />
                        {this.state.completed ? <p style={{ fontWeight: 'bold' }}>Document upload completed.</p> :
                            <DocumentUpload setFiles={this.setFiles} files={this.state.files} dealId={this.state.referral.opportunity_id} align={'left'} callback={this.props.completedCallback} />
                        }
                    </React.Fragment>
                }

                {(this.state.needsLOC && !renderRentRoll) &&
                    <p><br /><br />An LOC application must be submitted for the borrowing entity. <a href="#" onClick={() => this.setState({ sendToLOC: true })}>Click here</a> to navigate to the LOC application.</p>
                }


                {(this.state?.owner?.email && !this.state.referral?.whitelabel) &&
                    <p>
                        <div>Rep Contact Information:</div>
                        <br />
                        <span>{this.state.owner?.name}</span>
                        <br />
                        <a href={`mailto:${this.state.owner?.email}`}>{this.state.owner?.email}</a>
                        <br />
                        <a href={`tel:${this.state.owner?.phone}`}>{this.maskPhoneNumber(this.state.owner?.phone)}</a>
                    </p>
                }
            </Fragment>
        );

        return (
            <TransitionIn content={
                <Section heading={heading} body={content} />
            } />
        )
    }

})
