import React from 'react';
import FooterPrimary from './FooterPrimary';
import FooterSecondary from './FooterSecondary';

const Footer = () => {
    return (
        <footer>
            <FooterPrimary />
            <FooterSecondary />
        </footer>
    )
}

export default Footer