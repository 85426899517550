import React, { useState, useEffect } from "react";
import InputSelect from "./InputSelect";
import InputText from "./InputText";
import states from "../../../assets/config/_us_states.json";
import InputRadioMultiple from "./InputRadioMultiple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import ReactTooltip from 'react-tooltip';

const PropertyInformation = React.forwardRef((props, ref) => {

  const [properties, setProperties] = useState([
    { id: Math.random().toString(36).substr(2, 9) },
  ]);

  const propertyTypes = ["Single Family", "Townhome", "2-4 Unit", "Condo"];

  if (props.application_type === "rental") {
    propertyTypes.push("5+ Unit");
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [properties])

  return (
    <>
      {/* ----- Property Info ----- */}

      {
        properties.map((property, index) => {
          return (
            <div key={property.id} className={`row property ${properties.length > 1 ? "property-added" : ""}`}>

              {properties.length > 1 ?
                <h3 className="property-added_heading">
                  {
                    index > 0 && index < properties.length && (
                   
                        <button data-tip="Remove this property." data-for="app-tooltip"
                          type={"button"}
                          onClick={() =>
                            setProperties((properties) =>
                              properties.filter(
                                (property1) => property1.id !== property.id
                              )
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                    
                    )}

                  <span>
                    Property {index + 1}
                  </span>
                </h3> : null
              }

              <div className={`row property-row-top`}>
                <InputRadioMultiple
                  ref={ref}
                  config={propertyTypes}
                  fullwidth={true}
                  label="Select Property Type*"
                  name={`CREProperty_Type__c${property.id}`}
                  tabIndex={props.tabIndex}
                  errors={props.errors}
                />
              </div>

              <div className={`row`}>
                <div className="column">
                  <InputText
                    ref={ref}
                    type={"text"}
                    placeholder="123 Street"
                    label={"Street Address*"}
                    name={`Street_Address__c${property.id}`}
                    tabIndex={props.tabIndex}
                    errors={props.errors}
                  />
                </div>

                <div className="column">
                  <InputText
                    ref={ref({ required: false })}
                    type={"text"}
                    placeholder="XX"
                    label={"Unit Number"}
                    name={`Unit_Number__c${property.id}`}
                    tabIndex={props.tabIndex}
                    errors={props.errors}
                  />
                </div>

                <div className="column">
                  <InputText
                    ref={ref}
                    type={"text"}
                    placeholder="City"
                    label={"City*"}
                    name={`City__c${property.id}`}
                    tabIndex={props.tabIndex}
                    errors={props.errors}
                  />
                </div>

                <div className="column">
                  <div className={`row`}>
                    <div className="column" style={{ padding: "0 1rem 0 0" }}>
                      <InputSelect
                        ref={ref}
                        config={states}
                        label={"State*"}
                        name={`State__c${property.id}`}
                        tabIndex={props.tabIndex}
                        errors={props.errors}
                      />
                    </div>
                    <div className="column" style={{ padding: "0 0 0 1rem" }}>
                      <InputText
                        placeholder="XXXXX"
                        ref={ref}
                        onChange={props.inputZip}
                        label={"Zip*"}
                        name={`Zip__c${property.id}`}
                        tabIndex={props.tabIndex}
                        errors={props.errors}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      {props.multiProperty && (
        <div className="additional-property-wrapper">
          <button
            data-tip="Add additional property."
            data-for="app-tooltip"
            type="button"
            className={"additional-property"}
            onClick={() => {
              setProperties((properties) => [
                ...properties,
                { id: Math.random().toString(36).substr(2, 9) }
              ]);
            }
            }
          >
            <FontAwesomeIcon icon={faPlusCircle} /> ADD ADDITIONAL PROPERTY
          </button>
        </div>
      )}
    </>
  );
});

export default React.memo(PropertyInformation);
