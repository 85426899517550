import React from 'react';
import './index.scss';
import ButtonSubmit from '../../../components/shared/ButtonSubmit';

import axios from 'axios';

import './index.scss';

import qs from 'qs';

import { Redirect, withRouter, useHistory } from "react-router";
import { handleInput } from '../../../../assets/javascript/utilities/utilities_form_handle_input'; // global form input handlers
import { formData } from './_form_data'; // state.formData object
import statesConfig from '../../../../assets/config/_us_states.json'
import InputText from '../../../../components/_forms/_inputText';
import InputSelect from '../../../../components/_forms/InputSelect';
import InputRadioBinary from '../../../../components/_forms/_inputRadioBinary';

import { PrivacyModal, privacyToggle } from './PrivacyPolicy/PrivacyModal'
import InputCurrency from "../../../../components/_forms/_inputCurrency";

import InputAO from './InputAO';
import ButtonStandard from '../../../components/shared/ButtonStandard';
import { encodeJson } from '../../../../components/_applications/_api';

class SignupRF extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            stepsValidated: 0,
            // imported from old component
            submitted: false,
            formSubmission: false,
            formData: formData,
            fname: null,
            area: null,
            phone: null,
            reo: null,
            lname: null,
            email: null,
            country: null,
            flips: null,
            token: null,
            agree_privacy: false,
            privacy: false, // terms and conditions modal toggle
            errors: {
                fname: '',
                area: '',
                phone: '',
                reo: '',
                lname: '',
                email: '',
                country: '',
                flips: '',
            },
            submitResponse: '',
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            utm_term: null,
            utm_content: null,
            lead_source: 'Organic Website',
            lead_source_detail: null,
            formError: false
        }

        this.stepAdvance = this.stepAdvance.bind(this);

        // old bindings
        this.inputText = handleInput.inputText.bind(this);
        this.inputSelect = handleInput.inputSelect.bind(this);
        this.inputSelectMulti = handleInput.inputSelectMulti.bind(this);
        this.inputNumber = handleInput.inputNumber.bind(this);
        this.inputPhone = handleInput.inputPhone.bind(this);
        this.inputCurrency = handleInput.inputCurrency.bind(this);
        this.inputRadio = handleInput.inputRadio.bind(this);
        this.privacy = privacyToggle.bind(this); // handle terms and conditions modal toggle
        this.handleMultSelect = this.handleMultSelect.bind(this);
        this.stepSelect = this.stepSelect.bind(this);
        this.stepValidate = this.stepValidate.bind(this);
    }

    cloneFormData = () => {
        return JSON.parse(JSON.stringify(this.state.formData));
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ formSubmission: true })

        if (this.state.submitted) return;

        this.setState({ submitted: true });

        let fd = this.state.formData
        let _this = this;

        if (this.validateForm(true, this.state.agree_privacy)) {

            let areasString = [fd.areas_of_operation.iVal.value.join(";")].toString();
            var volumeOfFlips = parseInt(fd.number_of_fixnflips_sold_in_the_past_3_years.iVal.value);

            // take into account Keith's email for this special Lead Source and Lead Source Detail
            let isKeith = this.props.match.params["brokeremail"] === "kwiseman@limaonecapital.com";


            axios.post(process.env.REACT_APP_LIMA + '/lima/api/users/signup', encodeJson({
                "first_name": fd.first_name.iVal.value,
                "last_name": fd.last_name.iVal.value,
                "typeofrealestate": areasString,
                "email": fd.email.iVal.value,
                "phone": fd.phone_number.iVal.value.replace(/-/g, ''),
                "state": fd.state.iVal.value,
                "total_real_estate_assets_owned": fd.currently_estimated_value_of_your_real_estate_portfolio.iVal.value.toString(),
                "number_of_rental_units_currently_owned_regardless_of_purchase_date": fd.number_of_rental_units_currently_owned_regardless_of_purchase_date.iVal.value,
                "volumeofflips": volumeOfFlips,
                "new_construction_builds": fd.number_of_new_construction_builds_in_the_past_3_years.iVal.value,
                "needs_financing": fd.has_property_needs_financing.iVal.value ? "Yes" : "No",
                "notes": fd.notes.iVal.value,
                "utm_source": this.state.utm_source,
                "utm_medium": this.state.utm_medium,
                "utm_campaign": this.state.utm_campaign,
                "utm_term": this.state.utm_term,
                "utm_content": this.state.utm_content,
                "lead_source": isKeith ? "Organic Website" : this.state.lead_source,
                "lead_source_detail": isKeith ? "BD Landing Page" : this.state.lead_source_detail,
                "broker_email": this.props.match.params.brokeremail
            }))
                .then(function (response) {
                    if ('code' in response.data) {
                        if (response.data?.token) {
                            // no lead no account, send to account setup
                            window.location.href = '/account-setup/' + response.data.token;
                        } else {
                            _this.setState({ submitted: false, submitResponse: response.data.statusCode })
                        }
                    } else {
                        _this.setState({ submitted: false, submitResponse: "Something went wrong." })
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        _this.setState({ submitResponse: error.response.data.status })
                    }
                    _this.setState({ submitted: false })
                });
        } else {
            console.error('Invalid Form')
            _this.setState({ submitted: false })
        }
    }

    validateForm = (log, terms) => {
        const obj = this.state.formData
        console.log('validating... =>', obj)

        const objArray = Object.values(obj); // transform formData to array to loop thru and check for all completed validation 

        const test = (x) => x.iVal.isValid;
        let result = objArray.every(test); // test validation

        if (log) {
            const keys = Object.keys(obj);
            let resultArray = []

            keys.forEach((x, index) => {

                let value = {
                    [x]: objArray[index].iVal.isValid
                }

                console.log(value)

                resultArray.push(value)
            });
        }

        if (!result) {
            this.setState({
                formError: true
            })
        }

        if (!terms) {
            result = false;
        }

        return result; // return boolean for form validation
    }

    componentDidMount() {
        let utm_source = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_source
        let utm_medium = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_medium
        let utm_campaign = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_campaign
        let utm_term = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_term
        let utm_content = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_content
        let lead_source = utm_source && utm_source.toLowerCase().includes("inf") ? "Digital Advertising" :
        utm_medium && utm_medium.toLowerCase().includes("affiliate") ? "Affiliate" :
        utm_medium && utm_medium.toLowerCase().includes("publication") ? "Publication" :
        "Organic Website";
        let lead_source_detail = null;

        if (utm_source) {
            utm_source = utm_source.toLowerCase();

            lead_source_detail = utm_source.includes("facebook") ? "Facebook" :
            utm_source.includes("linkedin") ? "LinkedIn" :
            utm_source.includes("google") ? "Google" :
            utm_source.includes("youtube") ? "Youtube" :
            utm_source.includes("ttd") ? "Display" :
            utm_source.includes("fitsmallbusiness") ? "fitsmallbusiness" :
            utm_source.includes("privatelenderlink") ? "privatelenderlink" :
            utm_source.includes("scotsmanguide") ? "scotsmanguide" :
            utm_source.includes("coachcarson") ? "coachcarson" : null;
        }

        if (!utm_medium || !utm_source) {
            lead_source_detail = null;
            lead_source = "Organic Website";
        }

        this.setState({
            utm_source, utm_medium, utm_campaign, utm_term, utm_content, lead_source, lead_source_detail
        })
    }

    stepSelect(e) {
        this.stepValidate();
        this.setState({
            step: parseInt({ ...e.target.dataset }.step)
        })
    }

    stepValidate() {
        const fd = this.state.formData

        if (this.state.formData.areas_of_operation.iVal.value.length > 0) {
            this.setState({
                stepsValidated: 1
            })
        }

        if (
            fd.first_name.iVal.value &&
            fd.last_name.iVal.value &&
            fd.email.iVal.value &&
            fd.phone_number.iVal.value &&
            fd.state.iVal.value &&
            fd.currently_estimated_value_of_your_real_estate_portfolio.iVal.value.toString().length > 0 &&
            fd.number_of_fixnflips_sold_in_the_past_3_years.iVal.value.toString().length > 0 &&
            fd.number_of_rental_units_currently_owned_regardless_of_purchase_date.iVal.value.toString().length > 0 &&
            fd.number_of_new_construction_builds_in_the_past_3_years.iVal.value.toString().length > 0 &&
            fd.has_property_needs_financing.iVal.value.toString().length > 0
        ) {
            this.setState({
                stepsValidated: 2
            })
        }
    }

    stepAdvance(e) {
        e.preventDefault();
        const fd = this.state.formData

        if (this.state.step === 0) {

            if (this.state.formData.areas_of_operation.iVal.value.length > 0) {
                this.setState({
                    step: this.state.step + 1
                })
            }
        }
    }

    handleMultSelect(e) {
        if ((e.type === "click") || (e.key && e.which === 32 || 13)) {
            let formDataCloned = this.cloneFormData();

            const property = { ...e.target.dataset }.value.toString();
            const propertyIndex = formDataCloned.areas_of_operation.iVal.value.indexOf(property);

            if (propertyIndex < 0) {
                formDataCloned.areas_of_operation.iVal.value = [property]
            } else {
                formDataCloned.areas_of_operation.iVal.value.splice(propertyIndex, 1)
            }

            this.setState({
                formData: formDataCloned
            })
        }
    }


    render() {
        const { step, formData: fd, formSubmission, submitted, stepsValidated } = this.state;

        return (
            <div className="signup-rf">
                <div className="guide">
                    <div className="guide-inner"></div>
                </div>

                <div className="signup-rf_panel one">
                    <div className="one_inner">
                        <h1>Let's Get Started!</h1>
                        <p>
                            Tell us a little about yourself. We will pair you with a dedicated sales consultant.
                            Your sales consultant is an industry pro who will help you accomplish your real estate investment goals.
                        </p>
                    </div>
                </div>

                <div className="signup-rf_panel two">
                    <div className="two_inner">
                        <div className="step-wrapper">

                            <h2>Get Financing</h2>

                            {/* step counter */}
                            <div className="steps">
                                <button className={`step ${step >= 0 ? 'is-active' : null}`} data-step="0" onClick={this.stepSelect}>
                                    <div className="step_inner"></div>
                                </button>
                                <button className={`step ${step > 0 || (stepsValidated > 0) ? 'is-active' : null}`} data-step="1" onClick={this.stepSelect} disabled={step > 0 || (stepsValidated > 0) ? false : true}>
                                    <div className="step_inner"></div>
                                </button>
                                <div className="step-line">
                                    {/* this is the line behind the dots */}
                                </div>
                            </div>

                            <form onSubmit={this.handleSubmit}>

                                {/* initial panel */}
                                <div className={`form-panel ${step === 0 ? "is-active" : ""}`} id="panel-0">
                                    <h3>Which product are you most interested in?</h3>
                                    <p>Choose your primary interest</p>

                                    <div className="input-field-ao" role="select">
                                        <InputAO
                                            value={"FixNFlip"}
                                            valueDisplay={"FixNFlip"}
                                            img={'/assets/images/leadforms/fixnflip.png'}
                                            handleEvent={this.handleMultSelect}
                                            isActive={fd.areas_of_operation.iVal.value.includes("FixNFlip")}
                                        />

                                        <InputAO
                                            value={"Rental30"}
                                            valueDisplay={"Rental"}
                                            img={'/assets/images/leadforms/rental.png'}
                                            handleEvent={this.handleMultSelect}
                                            isActive={fd.areas_of_operation.iVal.value.includes("Rental30")}
                                        />

                                        <InputAO
                                            value={"Multifamily"}
                                            valueDisplay={"Multifamily"}
                                            img={'/assets/images/leadforms/multifamily.png'}
                                            handleEvent={this.handleMultSelect}
                                            isActive={fd.areas_of_operation.iVal.value.includes("Multifamily")}
                                        />

                                        <InputAO
                                            value={"New Construction"}
                                            valueDisplay={"New Construction"}
                                            img={'/assets/images/leadforms/newconstruction.png'}
                                            handleEvent={this.handleMultSelect}
                                            isActive={fd.areas_of_operation.iVal.value.includes("New Construction")}
                                        />
                                    </div>
                                    <ButtonStandard value={"Next"} variant="rounded" callback={this.stepAdvance} />
                                </div>


                                <div className={`form-panel ${step === 1 ? "is-active" : ""}`} id="panel-1">
                                    <div className="sign-up-inner one">
                                        <h3>Tell Us About Yourself</h3>
                                        <InputText formSubmission={formSubmission} fd={fd} fd_key={"first_name"} label="First Name*" inputText={this.inputText} placeholder={"First Name"} />
                                        <InputText formSubmission={formSubmission} fd={fd} fd_key={"last_name"} label="Last Name*" inputText={this.inputText} placeholder={"Last Name"} />
                                        <InputText formSubmission={formSubmission} fd={fd} fd_key={"email"} label="Email Address*" inputText={this.inputText} placeholder={"Email Address"} />
                                        <InputText formSubmission={formSubmission} fd={fd} fd_key={"phone_number"} label="Phone Number*" inputText={this.inputPhone} value={fd.phone_number.iVal.value} placeholder={"XXX-XXX-XXXX"} numeric={true} />
                                        <InputSelect formSubmission={formSubmission} fd={fd} fd_key={"state"} label="State*" inputSelect={this.inputSelect} config={statesConfig} showChoose={true} className={"transparent"} />
                                    </div>

                                    <div className="sign-up-inner three">
                                        <h3>Tell Us About Your Business</h3>
                                        <InputSelect formSubmission={formSubmission} fd={fd} fd_key={"currently_estimated_value_of_your_real_estate_portfolio"} label="Value of Existing REO Portfolio*" inputSelect={this.inputSelect} config={[
                                            {
                                                name: "$0 - $500k",
                                                value: "$0-$500k"
                                            },
                                            {
                                                name: "$500k - $1MM",
                                                value: "$500 - $1MM"
                                            },
                                            {
                                                name: "$1MM - $2MM",
                                                value: "$1MM - $2MM"
                                            },
                                            {
                                                name: "$2MM - $5MM",
                                                value: "$2MM - $5MM"
                                            },
                                            {
                                                name: "$5MM+",
                                                value: "$5MM+"
                                            },
                                        ]} showChoose={true} className={"transparent"} />
                                        <InputText formSubmission={formSubmission} fd={fd} fd_key={"number_of_fixnflips_sold_in_the_past_3_years"} label="Number of Flips Completed in the Last 3 Years*" inputText={this.inputNumber} placeholder={"0"} numeric={true} />
                                        <InputSelect formSubmission={formSubmission} fd={fd} fd_key={"number_of_rental_units_currently_owned_regardless_of_purchase_date"} label="Number of Rental Units Owned*" inputSelect={this.inputSelect} config={[
                                            {
                                                name: "0",
                                                value: "0"
                                            },
                                            {
                                                name: "1 - 4",
                                                value: "1 - 4"
                                            },
                                            {
                                                name: "5 - 11",
                                                value: "5 - 11"
                                            },
                                            {
                                                name: "12 - 23",
                                                value: "12 - 23"
                                            },
                                            {
                                                name: "24+",
                                                value: "24+"
                                            },
                                        ]} showChoose={true} className={"transparent"} />
                                        <InputText formSubmission={formSubmission} fd={fd} fd_key={"number_of_new_construction_builds_in_the_past_3_years"} label="Number of New Construction Builds in the Last 3 Years*" inputText={this.inputNumber} placeholder={"0"} numeric={true} />
                                        <InputRadioBinary fd={fd} fullwidth={true} inputRadio={this.inputRadio} formSubmission={formSubmission}
                                            fd_key={"has_property_needs_financing"}
                                            label="Do You Currently Have a Property That Needs Financing?*" />
                                        <InputText formSubmission={formSubmission} fd={fd} fd_key={"notes"} label="Notes (optional)" inputText={this.inputText} placeholder={"Additional Notes"} />
                                    </div>

                                    <div className="sign-up-inner six">
                                        <div className={`agree-section`}>
                                            <p>In addition, you consent to the terms and conditions outlined <span onClick={this.privacy} style={{ color: "#0086CA" }}>Privacy Policy*</span>.</p>

                                            <span className={`agree-section-body ${(formSubmission && !this.state.agree_privacy) && 'error'}`}>
                                                <input type="radio" name="agree" id="agree-privacy" value={this.state.agree_privacy} onChange={(e) => this.setState({ agree_privacy: e.target.value })} />
                                                <span>
                                                    By submitting this form, I agree to receive information about Lima One’s products and services by email and phone. I understand I can opt-out at any time. I have read and agree to Lima One’s <a href="https://www.limaone.com/terms-and-conditions/" target='_blank'>Terms and Conditions</a> and <a href="https://www.limaone.com/privacy-policy/" target="_blank">Privacy Policy</a>.
                                                </span>
                                            </span>

                                            <p className="error-message">{(formSubmission && !this.state.agree_privacy) && 'You must agree to our privacy policy.'}</p>
                                        </div>

                                        <ButtonSubmit
                                            variant="rounded"
                                            readOnly={submitted}
                                            callback={this.handleSubmit} />

                                        <div className={`form-error ${this.state.formError ? 'is-active' : ''}`}>
                                            {this.state.submitResponse && (
                                                <div>
                                                    <br />

                                                    {
                                                        console.log('Yes')
                                                    }
                                                    {(() => {
                                                        if (this.state.submitResponse === 0) {
                                                            return (
                                                                <h3>Unfortunately we could not find an existing account for your email. Complete the <a href="/signup">Sign Up</a> for Portal access.</h3>
                                                            )
                                                        } else if (this.state.submitResponse === 1) {
                                                            return (
                                                                <h3>Please check your email to complete the account registration by setting up your password, or request a new <a href="/forgot-password">password reset link.</a></h3>
                                                            )
                                                        } else if (this.state.submitResponse === 2) {
                                                            return (
                                                                <h3>Check your email for a link to set your password and complete setup.</h3>
                                                            )
                                                        } else if (this.state.submitResponse === 3) {
                                                            return (
                                                                <h3>We found you already have an account. Proceed to <a href="/">Login</a> or <a href="/forgot-password">reset your password.</a></h3>
                                                            )
                                                        } else {
                                                            return (
                                                                <h3>Something unexpected happened, please contact support for assistance.</h3>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <section className="privacy-conditions-wrapper">
                                        {/* <TermsModal terms={terms} toggle={this.terms} /> */}
                                        <PrivacyModal privacy={this.state.privacy} toggle={this.privacy} />
                                    </section>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default SignupRF