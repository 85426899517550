import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

// exported function to toggle modal visibility (bind to parent and parent state)
function modalToggle(e) {
    // prevent modal toggling from clicks in the body
    if (e.target.classList.contains("modal-trigger")) {
        // parent component will need a modal property in state (bind in parent)
        // pass this boolean value as a prop to the modal
        this.setState({
            modal: !this.state.modal
        })

    } else {
        // ignore all other events outside of targeted classes
        e.stopPropagation();
        return;
    }
}

function Modal(props) {
    // prevent page scrolling while modal visible
    if (props.modal) {
        document.querySelector('body').style.overflow = "hidden";
    } else {
        document.querySelector('body').style.overflow = "unset";
    }

    // clone child to pass props to it
    const child = React.cloneElement(props.children, { ...props })

    return (
        <div className={props.modal ? "modal modal-trigger is-active" : "modal"} onClick={props.modalToggle}>
            <div className="modal-inner">
                
                    <button className="modal-close modal-trigger" type="button" >
                        <FontAwesomeIcon className="modal-close_icon" icon={faTimes}  />
                    </button>
                
                <div className="modal-inner_body">
                    { child }
                </div>
            </div>
        </div>
    )
}

// export as a component and a function toggle the modal that can be bound to the parent/parent state
export { Modal, modalToggle }