import React from 'react';
import PaymentEntry from './PaymentEntry';

const PaymentEntryList = props => {

    const sortDates = (dateArray, property) => {
        dateArray.sort(function(a,b){
            return new Date(b[property]) - new Date(a[property]);
          });

          return dateArray;
    }

    sortDates(props.data.payments, "Date_Received");

    return (
        <ul className="payment-history_entries">
            {/* <h6 style={{color: "red"}}>Payment History - Payment List</h6> */}

            {props.data.payments.length > 0 ?
                sortDates(props.data.payments, "Date_Received").map((accountHistory) => {
                        return <PaymentEntry accountHistory={accountHistory} />
                })
                :
                <li style={{ padding: '0.25em', borderBottom: '0.15em solid #F1F1F1', fontStyle: 'italic' }}>
                    Account History data is currently not available.
                </li>
            }
        </ul>
    )
}

export default PaymentEntryList