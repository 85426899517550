// ----- v SUMMARY v ------------------------------------------------------------------------

// ----- v DESCRIPTION v --------------------------------------------------------------------

// ----- v PROPS v --------------------------------------------------------------------------
// - visible - (required) - show/hide the dialogue box
// - closeButton - (optional) - show the close button - defaults to true
// - closeCallback - (required if closeButton is true) - callback to close the dialogue box 
//   (prop.visible) in parent component + other functions from parent.
// - closeBackground - (optional) - allows the background to be clickable - defaults to true

// ----- v CRITICAL NOTES v -----------------------------------------------------------------


import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import TransitionIn from '../TransitionIn';
import { log } from '../../../utility';


export default function DialogueBox(props) {

    const visible = props.visible || false;
    const content = props.content || null;
    const closeButton = props.closeButton;
    const closeBackground = props.closeBackground;
    const closeCallback = props.closeCallback || log('Dialogue Box - no callback prop.')

    const dialogueBox = (
        <aside className="dialogue-box">

            <div className="dialogue-box_background" onClick={closeBackground ? closeCallback : null}>
                {/* seperate background element to prevent issues with event bubbling */}
            </div>

            <section className="dialogue-box_content">
                {
                    closeButton ? <button className="close" onClick={closeCallback}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button> : null
                }

                {content}
            </section>
        </aside>
    )

    if (visible) {
        return (
            <TransitionIn content={dialogueBox} />
        )
    } else return null;
}