import React from 'react';
import axios from 'axios'
import AccountActions from '../AcctActions';
import ButtonSubmit from '../ButtonSubmit';
import './index.scss';

class SearchBarDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      submitted: false,
      user: window.localStorage.getItem("username")
    }

    this.handleClick = this.handleClick.bind(this);
  }

  // Toggle main menu dropdown (logout)
  handleClick(event) {
    event.preventDefault();
    this.setState({
      active: !this.state.active
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    // e.stopPropagation();

    if (this.state.submitted) return;

    this.setState({submitted: true});

    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("employeeToken");

    try {
      let response = await axios.post('/lima/api/users/impersonate', { username: this.state.user })
      let { token, name, email, accountType } = response.data;

      window.localStorage.setItem("token", token);
      window.localStorage.setItem("name", name);
      window.localStorage.setItem("username", email);
      window.localStorage.setItem("accountType", accountType);

      if(name.length > 0) {
        // window.Appcues.identify(email);
        // console.log(`AppCues - identified user ${email} as account type ${accountType}`);
      }

      // if user is coming from a deep linked URL, continue to that route once logged in
        window.location.href = '/dashboard';

    } catch (error) {
        if (error.response && error.response.data.error) {
          alert(error.response.data.error);
        }

        this.setState({ submitted: false })
      }
  }

  render() {
    let state = this.state;

    return (
      <div className="top-bar">
        <div className="top-bar-left">
          {(window.localStorage.getItem('employeeToken')) &&
            <React.Fragment>
              <form className={`form-body`} onSubmit={this.handleSubmit} >
                <input type="email" className='account-search-bar' value={this.state.user} onChange={(e) => this.setState({user: e.target.value})}></input>
                <ButtonSubmit callBack={this.handleSubmit} readOnly={this.state.submitted} value={"View Account"} />
              </form>
            </React.Fragment>
          }
        </div>
        <div className="top-bar-right">
          <ul>
            <li><p className="username">{window.localStorage.getItem("username")}</p></li>
            <li>
              <a href="/#" onClick={this.handleClick}>
                <svg className={state.active ? "searchbar-arrow is-active" : "searchbar-arrow"} xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
                  <path d="M8.1,11.6,2.6,6.041,4.026,4.6,8.1,8.718,12.174,4.6,13.6,6.041Z" transform="translate(-2.6 -4.6)" />
                </svg>
              </a>
            </li>
          </ul>
          <div className={state.active ? "is-active nav-dropdown" : "nav-dropdown"}>
            <AccountActions />
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBarDesktop;