import React from 'react'
import FileUpload from "./FileUpload";
import {SectionHeading} from "../../../App/components/shared/Section/Heading";

const BrokerConsentForm = React.forwardRef((props, ref) => {
    return (
        <section className="consent section">
            <div className="section_heading">
                <SectionHeading heading={"Upload Application & Consent"} max={20} />
            </div>

            <p>
                In order to process the application for your client, we require an executed Credit Consent Form. Upload a customer-signed copy of the consent form using the upload button below. Use the download button in order to obtain an up-to-date copy of the consent form.
            </p>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', width: '75%', flexWrap: 'wrap' }} className={"flex-mobile-center"}>
                <a href="/documents/White-Label-Credit-Consent-Letter.docx" download className="form-button">Download Credit Consent Form</a>
                <FileUpload {...props} ref={ref} file_name={'filename'} binary_name={'filedata'} style={{ width: 'auto', marginLeft: '1rem' }} />
            </div>
        </section>
    )
})

export default BrokerConsentForm