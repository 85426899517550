import React, { Fragment } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import './index.scss';
import ButtonSubmit from '../../../components/ButtonSubmit';
import { Section } from '../../../App/components/shared/Section';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { TermsModal, termsToggle } from './form_components/terms_modal/index';
// general components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// form configurations
import { formData } from './form_config/_form_data'; // state.formData object
import states from '../../../assets/config/_us_states.json'; // us states list
import countries from '../../../assets/config/_countries.json'; // us states list
import companies from './form_config/_companies.json'; // companies

// form validation utilities
import { validateForm, validateCustomRE } from './form_utilities/_form_validation'; // local form validation utilities

import qs from 'qs'
// form specific utilities and helpers
import { api } from './form_api/_api'; // form api routes and config
import { cloneFormData } from './form_utilities/_form_data'; // utility to clone form data from state for mutation
import { pfs } from './form_utilities/_pfs_calculator'; // pfs calculations and state updates
import { handleInputsCustom } from './form_utilities/_form_input_handlers'; // local custom input handlers

// global utilities
import { state } from '../../../assets/javascript/utilities/utilities_state'; // global state utility (state clone and log/check)
import { handleInput } from '../../../assets/javascript/utilities/utilities_form_handle_input'; // global form input handlers
import InputText from '../../_forms/_inputText';


class BrokerSignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            formSubmission: false, // form submit event has fired (triggers validations)
            formError: false,
            formValidation: null, // form is valid?
            borrowers: [], // borrowing entity api result
            borrowerSelected: "",
            formData: formData, // form data (found in config)
            terms_check: "", // terms and conditions I agree check
            terms: false, // terms and conditions modal toggle
            token: null,
            usStates: states,
            companies: companies,
            error: null,
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            utm_term: null,
            utm_content: null,
            errorCode: undefined
        }

        // input handlers general (global utilities)
        this.inputText = handleInput.inputText.bind(this);
        this.inputRadio = handleInput.inputRadio.bind(this);
        this.inputCheckbox = handleInput.inputCheckbox.bind(this);
        this.inputSelect = handleInput.inputSelect.bind(this);
        this.inputNumber = handleInput.inputNumber.bind(this);
        this.inputPhone = handleInput.inputPhone.bind(this);
        this.inputSSN = handleInput.inputSSN.bind(this);
        this.inputDOB = handleInput.inputDOB.bind(this);
        this.inputBusinessEIN = handleInput.inputBusinessEIN.bind(this);
        this.inputCreditScore = handleInput.inputCreditScore.bind(this);
        this.inputPercentage = handleInput.inputPercentage.bind(this);

        // input handlers custom/specific to form
        this.inputNumberPFS = handleInputsCustom.inputNumberPFS.bind(this);
        this.inputCustomRE = handleInputsCustom.inputCustomRE.bind(this);
        this.inputSignature = handleInputsCustom.inputSignature.bind(this);
        this.inputSignatureDate = handleInputsCustom.inputSignatureDate.bind(this);
        this.inputTermsCheck = handleInputsCustom.inputTermsCheck.bind(this);
        this.setBorrower = handleInputsCustom.setBorrower.bind(this);

        // utilities - general
        this.checkState = state.check.bind(this); // helper to log current state (pass true to mute)
        this.cloneState = state.clone.bind(this); // helper to copy entire state to a new object (not a reference to actual state)

        // utilities and validations - custom form
        this.validateCustomRE = validateCustomRE.bind(this)
        this.pfs = pfs.bind(this);
        this.cloneFormData = cloneFormData.bind(this); // helper to copy form data state to a new object (not a reference to actual state)

        this.setBorrower = this.setBorrower.bind(this) // update borrowing entity
        this.handleSubmit = this.handleSubmit.bind(this); // handle submit event

        // additional guarantors handlers
        this.postFormData = api.postFormData.bind(this);

        this.terms = termsToggle.bind(this);
    }

    // handle form submission event
    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            formSubmission: true, // triggers the initial submission status to kick off validation errors w/ real-time validations
        }, this.handleValidation);
    }

    // handle form validation
    handleValidation() {
        this.setState({
            formValidation: validateForm(this.state.formData, true)
        }, this.formSubmit)
    }

    // handle submit form/api call
    formSubmit() {
        if (this.state.formValidation) {
            this.postFormData(this.state)
        }
    }

    componentDidMount() {
        let utm_source = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_source
        let utm_medium = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_medium
        let utm_campaign = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_campaign
        let utm_term = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_term
        let utm_content = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_content
        this.setState({
            utm_source, utm_medium, utm_campaign, utm_term, utm_content
        })
    }


    render() {
        const { formSubmission, formValidation, formData: fd, terms, submitted } = this.state; // deconstruct form state to variables (for rendering data only - immutable)

        if (this.state?.token) {
            return <Redirect to={`/broker/account-setup/${this.state.token}`} />
        }

        return (
            <form className="form loc-borrower" id="form" onSubmit={this.handleSubmit} >

                {/* ===== ===== Form Error Message ===== ===== */}
                {/* <figure className={formValidation === false ? "form-alert error" : "form-alert"}>
                    <FontAwesomeIcon icon={faExclamationCircle} className="alert-icon" />
                    <div className="alert-message">
                        <h2>APPLICATION NOT COMPLETE</h2>
                        <p>One or more required fields have not been entered. Please correct the errors below to successfully submit application.</p>
                    </div>
                </figure> */}

                <form className={`form-body ${formSubmission ? "form-error" : ""}`}>

                    {/* ===== ===== SECTION - Borrower and Primary Guarantor Application ===== ===== */}
                    <Section heading={"Broker Portal Account Application"} body={

                        <>
                            <p>Please fill out the application below in its entirety.
                                Your changes will not be saved until the application is submitted.</p>

                            <fieldset className="input-field-group row">

                                <div className="column">
                                    <InputText formSubmission={formSubmission} fd={fd} fd_key={"first_name"} label="First Name*" inputText={this.inputText} />
                                </div>
                                <div className="column">
                                    <InputText formSubmission={formSubmission} fd={fd} fd_key={"middle_name"} label="Middle Name" inputText={this.inputText}  />
                                </div>
                                <div className="column">
                                    <InputText formSubmission={formSubmission} fd={fd} fd_key={"last_name"} label="Last Name*" inputText={this.inputText} />
                                </div>
                                <div className="column">
                                    <InputText formSubmission={formSubmission} fd={fd} fd_key={"mailing_address"} label="Mailing Address*" inputText={this.inputText}  />
                                </div>

                                <div className={`input-field column`}>
                                    <label htmlFor="state">State*</label>
                                    <select name="state" id="state" onChange={this.inputSelect} data-input="state">
                                        <option value="">Choose State</option>
                                        {
                                            states.map(x => {
                                                return (
                                                    <option name={x.name} value={x.name} id={x.name}>
                                                        {x.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                <div className={`input-field column ${formSubmission && !fd.zip.iVal.isValid ? "error" : ""}`}>
                                    <label htmlFor="zip">Zip*</label>
                                    <input type="text" name="zip" id="zip" data-input="zip"
                                        onChange={this.inputNumber} value={fd.zip.iVal.value} maxLength="5" />

                                </div>

                                <div className="column">
                                    <InputText formSubmission={formSubmission} fd={fd} fd_key={"city"} label="City*" inputText={this.inputText}  />
                                </div>
                                <div className="column">
                                    <InputText formSubmission={formSubmission} fd={fd} fd_key={"business_name"} label="Business Name*" inputText={this.inputText} />
                                </div>
                                <div className="column">
                                    <InputText formSubmission={formSubmission} fd={fd} fd_key={"cell"} label="Phone Number*" inputText={this.inputPhone} placeholder="XXX-XXX-XXXX" />
                                </div>
                                <div className="column">
                                    <InputText formSubmission={formSubmission} fd={fd} fd_key={"email"} label="Email*" inputText={this.inputText} />
                                </div>


                                <div className={`input-field column ${formSubmission && !fd.date_of_birth.iVal.isValid ? "error" : ""}`}>
                                    <label htmlFor="date-of-birth">Date of Birth*</label>
                                    <input type="date" name="date_of_birth" id="date_of_birth" data-input="date_of_birth"
                                        onChange={this.inputDOB} value={fd.date_of_birth.iVal.value} min="1900-01-01" max={new Date().toISOString().slice(0, 10)} />
                                    <span className="error-message">{`${"Error Message! Date of Birth is required."}`}</span>
                                </div>


                                <div className="column">
                                <InputText formSubmission={formSubmission} fd={fd} fd_key={"ssn"} label="Social Security Number*" placeholder="XXX-XX-XXXX" inputText={this.inputSSN}    />
                                </div>

                                <div className={`input-field column ${formSubmission && !fd.are_you_foreign_national.iVal.isValid ? "error" : ""}`}>
                                    <label htmlFor="cell">Please Select your Nationality*</label>

                                    <select name="are_you_foreign_national" id="are_you_foreign_national" data-input="are_you_foreign_national" value={fd.are_you_foreign_national.iRaw} onChange={this.inputSelect}>
                                        <option value="">Choose Country*</option>
                                        {
                                            countries.map(x => {
                                                return (
                                                    <option name={x.name} value={x.value} id={x.name}>
                                                        {x.name}
                                                    </option>
                                                )
                                            })
                                        }

                                        <option value="United States">United States</option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="IndAntigua & Depsia">Antigua & Deps</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia Herzegovina">Bosnia Herzegovina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="Brunei">Brunei</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina">Burkina</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Central African Rep">Central African Rep</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Congo {Democratic Rep}">Congo (Democratic Rep)</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Croatia">Croatia</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland {Republic}">Ireland (Republic)</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Ivory Coast">Ivory Coast</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Korea North">Korea North</option>
                                        <option value="Korea South">Korea South</option>
                                        <option value="Kosovo">Kosovo</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon">Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libya">Libya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macedonia">Macedonia</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia</option>
                                        <option value="Moldova">Moldova</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montenegro">Montenegro</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar, {Burma}">Myanmar, (Burma)</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russian Federation">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="St Kitts & Nevis">St Kitts & Nevis</option>
                                        <option value="St Lucia">St Lucia</option>
                                        <option value="Saint Vincent & the Grenadines">Saint Vincent & the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra Leone">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Sudan">South Sudan</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Sri Lanka">Sri Lanka</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syria</option>
                                        <option value="Taiwan">Taiwan</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Vatican City">Vatican City</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Vietnam</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                    <span className="error-message">{`Please make a selection.`}</span>
                                </div>
                                {/* ----- States Checkbox list ----- */}
                                {/* <div className="input-field-group_inner states-checkbox" id="loc">
                                <div className={`input-field full-width ${formSubmission && fd.licenses_held.iVal.isValid < 1 ? "error" : ""}`}>
                                    <label htmlFor="licenses_held">licenses Held*</label>
                                    <ul>
                                        {this.state.usStates.map(x => {
                                            return <li>
                                                <input type="checkbox" name="licenses_held" id="licenses_held"
                                                    value={x.name} id={`us-state-${x.name}`} onChange={this.inputCheckbox}
                                                    data-input="licenses_held" checked={x.selected} />
                                                <label htmlFor={x.name}>{x.name}</label>
                                            </li>
                                        })}
                                    </ul>
                                    <span className="error-message">{`${"Error Mxessage!"}`}</span>
                                </div>
                            </div> */}
                            </fieldset>

                        </>

                    } />
                    {/* ===== ===== SECTION - Form Submit ===== ===== */}

                    <Section heading={"Submit Application"} body={
                        <Fragment>
                            <p>
                                My transmission of this application as an “electronic record” containing my “electronic signature,” as
                                those terms are defined in applicable federal and/or state laws (excluding audio and video recordings),
                                or my facsimile transmission of this application containing a facsimile of my signature, shall be as effective,
                                enforceable and valid as if a paper version of this application were delivered containing my original written signature.
                            </p>


                            {/* ----- Signature and Date ----- */}
                            <fieldset className="input-field-group sign-and-date row grow-2">

                                <div className={`input-field column ${formSubmission && !fd.signature.iVal.isValid ? "error" : ""}`}>
                                    <label htmlFor="signature">First and Last Name of Broker*</label>
                                    <input type="text" name="signature" id="signature" data-input="signature" onChange={this.inputSignature}
                                        value={fd.signature.iVal.value} />
                                    <span className="error-message">{`${"Error Message!"}`}</span>
                                </div>

                                <div className={`input-field column ${formSubmission && !fd.sign_date.iVal.isValid ? "error" : ""}`}>
                                    <label htmlFor="signature-date">Date*</label>
                                    <input type="date" name="signature-date" id="signature-date" data-input="sign_date" onChange={this.inputSignatureDate}
                                        value={fd.sign_date.iVal.value} />
                                    <span className="error-message">{`${"Error Message!"}`}</span>
                                </div>
                            </fieldset>

                            {/* <p>In addition, you consent to the terms and conditions outlined <span onClick={this.terms} style={{ color: "#0086CA" }}>here</span>.</p> */}


                            <p>In addition, you consent to the terms and conditions outlined <span onClick={this.terms} style={{ color: "#0086CA" }}>here</span>.</p>

                            <fieldset className="input-field-group">
                                <div className={`input-field full-width ${formSubmission && !this.state.terms_check ? "error" : ""}`}>
                                    <ul>
                                        <li>
                                            <input onChange={this.inputTermsCheck} type="radio" name="agree-to-terms" id="agree-to-terms" value="true" />
                                            <label htmlFor="agree-to-terms">I/We Agree*</label>
                                        </li>
                                    </ul>
                                </div>

                                <div className="input-field full-width">
                                    <ButtonSubmit
                                        readOnly={submitted}
                                        callback={this.handleSubmit}
                                        value={"Submit Application"} />

                                    <div className={`form-error form-error-b ${this.state.error ? 'is-active' : ''}`}>
                                        {this.state.errorCode === 0 || this.state.errorCode === 1 || this.state.errorCode === 2 || this.state.errorCode === 3 || this.state.errorCode === 4 ?
                                            <>We found you already have an account. Please check your email for a password reset link, or proceed to <Link to="/" style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#D4311E' }}>Login</Link> or <Link to="/forgot-password" style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#D4311E' }}>Reset Your Password.</Link></> :
                                            this.state.error ? this.state.error : "An error has occured, please try again later."}
                                    </div>
                                </div>
                            </fieldset>

                            {/* Terms Modal */}
                            <section className="terms-conditions-wrapper">
                                <TermsModal terms={terms} toggle={this.terms} />
                            </section>
                        </Fragment>
                    } />


                </form>
            </form>
        )
    }
}

export default withRouter(BrokerSignUp)
