import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEvnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebookF, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

const FooterPrimary = props => {
    return (
        <div className="footer-primary">
            <div className="footer-primary_inner">

                <div className="footer-widget">
                    <img src="../../../../../assets/images/logo-tight-1.webp" alt="Lima One Capital Logo" />

                    <p>
                        201 East McBee Ave. <br />
                        Suite 300 <br />
                        Greenville, SC 29601
                    </p>

                    <ul className="links-contact">
                        <li>
                            <a href="tel:8003904212">
                                <FontAwesomeIcon icon={faPhone} />
                                800-390-4212
                            </a>
                        </li>
                        <li>

                            <a href="mailto:hello@limaone.com">
                                <FontAwesomeIcon icon={faEnvelope} />
                                hello@limaone.com
                            </a>
                        </li>
                    </ul>

                    <ul className="links-social">
                        <li>
                            <a href="https://www.linkedin.com/company/lima-one-capital-llc/" target="_blank">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/LimaOneCapital" target="_blank">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/limaonecapital" target="_blank">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/limaonecapital/" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>
                    </ul>

                </div>

                <div className="footer-widget">
                    <h3>Programs</h3>
                    <ul>
                        <li>
                            <a href="https://limaone.com/hard-money-fix-n-flip/">FixNFlip Bridge</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/new-construction-loans/">New Construction</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/rental/">Rental</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/multifamily/">Multifamily</a>
                        </li>
                    </ul>
                </div>

                <div className="footer-widget">
                    <h3>Company</h3>
                    <ul>
                        <li>
                            <a href="https://limaone.com/brokers/">Broker Partners</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/referral-program/">Referral Partners</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/the-company/">About Us</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/leadership-team/">Leadership Team</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/employment/">Careers</a>
                        </li>
                    </ul>
                </div>

                <div className="footer-widget">
                    <h3>Learn</h3>
                    <ul>
                        <li>
                            <a href="https://limaone.com/case-studies/">Case Studies</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/blog/">Blog</a>
                        </li>
                        <li>
                            <a href="https://limaone.com/market-insights/">Market Insights</a>
                        </li>
                    </ul>
                </div>

                <div className="footer-widget">
                    <h3>Quick Links</h3>
                    <ul>
                        <li>
                            <Link to="/">Login</Link>
                        </li>
                    </ul>
                </div>

                {/* <div className="footer-widget">
                    <h3>Subsribe To Our Blog</h3>
                    <form action="">
                        <input type="text" placeholder="Full Name" />
                        <input type="email" placeholder="Email Address" />
                        <button role="submit">Learn More</button>
                    </form>
                </div> */}


            </div>
        </div>
    )
}

export default FooterPrimary;