import React from "react";
import InputWrapper from "./InputWrapper";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";

const InputText = React.forwardRef((props, ref) => {
//     return (
//         <InputWrapper {...props}>
//              <input ref={ref} type={props.type ? props.type : 'text'} name={props.name} id={props.id} className={props.className} tabIndex={props.tabIndex} placeholder={props.placeholder} readOnly={props.readOnly} disabled={props.disabled || props.readOnly} onChange={props.onChange} value={props.value} data-cleanup={props.cleanup} maxlength={props?.maxlength} autoComplete={"off-off"} />
//         </InputWrapper>
//     )
// })
    return (
        <InputWrapper {...props}>
            {/*<InputMask*/}
            {/*    ref={ref}*/}
            {/*    inputRef={ref}*/}
            {/*    type={props.type ? props.type : "text"}*/}
            {/*    name={props.name}*/}
            {/*    id={props.id}*/}
            {/*    tabIndex={props.tabIndex}*/}
            {/*    placeholder={props.placeholder}*/}
            {/*    readOnly={props.readOnly}*/}
            {/*    disabled={props.disabled || props.readOnly}*/}
            {/*    onChange={props.onChange}*/}
            {/*    value={props.value}*/}
            {/*    data-cleanup={true}*/}
            {/*    maxLength={props?.maxlength}*/}
            {/*    autoComplete={"cc-csc"}*/}
            {/*    mask={props.mask}*/}
            {/*    maskChar={props.maskChar}*/}
            {/*/>*/}
            <Controller
                as={InputMask}
                {...props}
            />
        </InputWrapper>
    );
});

export default React.memo(InputText);
