import React, { Fragment } from 'react';
import TransitionIn from '../../../../../components/shared/TransitionIn';
import ThirdPartyContacts from './ThirdPartyContacts';
import Skeleton from 'react-loading-skeleton';

import Members from './Members';

export default function Root(props) {

    const content = (
        <Fragment>
            <Members members={props.members} />
            {
                props.deal ? <TransitionIn content={
                    <ThirdPartyContacts dealDetails={props.deal} fetchDeal={props.fetchDeal} />
                } /> : <Skeleton height="200rem" />
            }
        </Fragment>
    )

    return (
        <TransitionIn content={content} />
    )
}