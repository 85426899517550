import React, {useState, useEffect} from 'react'
import {Redirect, withRouter} from "react-router-dom";
import {useForm} from "react-hook-form"
import ApplicationWrapper from '../_components/ApplicationWrapper'
import {handleInput} from '../_components/input_handler'; // global form input handlers
import InputText from '../_components/InputText';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import MemoizedIcon from '../_components/MemoizedIcon';
import Financials from './Financials';
import LegalQuestions from './LegalQuestions';
import PrimaryGuarantor from './PrimaryGuarantor';
import Borrower from './Borrower';

import {SharedApi} from '../_components/SharedAPI';
import SelectCustomer from '../_components/SelectCustomer';
import BrokerConsentForm from "../_components/BrokerConsentForm";
import {api} from "../_api";

import TransitionIn from '../../../App/components/shared/TransitionIn';
import {Textfit} from "react-textfit";
import InputMask from "react-input-mask";
import {SectionHeading} from "../../../App/components/shared/Section/Heading";
import InputTextMasked from "../_components/InputTextMasked";

const Application_LOC = props => {

    const [tabIndex, setTabIndex] = useState(0)
    const [responseMessage, setResponseMessage] = useState(null)
    const [redirect, setRedirect] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [borrowing_entities, setBorrowingEntities] = useState([])
    const [additionalGuarantors, setAdditionalGuarantors] = useState(0)
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [selectedBE, setSelectedBE] = useState(null)
    const [existing_customer, setExistingCustomer] = useState(null)
    const [existing_borrower, setExistingBorrower] = useState(null)
    const [customers, setCustomers] = useState([])
    const {register, handleSubmit, errors, setValue, setError, getValues, unregister, watch, clearError, control} = useForm()
    const isBroker = window.localStorage.getItem('accountType') === "CustomerAccount" ? false : true;
    let params = props.location.state ? props.location.state : props.match.params;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const onSubmit = async formData => {
        if (!document.getElementById('agree-to-terms').checked) {
            setError("terms", {
                type: "manual",
                message: "Make a selection.",
            });
            return
        }

        setSubmitted(true)

        api.submit('Line of Credit', formData, setError, setResponseMessage, setSubmitted, setRedirect, isBroker, params?.broker)
    }

    const selectCustomer = e => {
        setSelectedCustomer(e.target.value);
    }

    const selectBE = e => {
        const BE = borrowing_entities.find(obj => obj.id == e.target.value)

        if (!BE) return

        const updateArray = [
            {borrowing_entity_name: BE?.name},
            {company_type: BE?.entityType},
            {business_EIN: BE?.EIN?.replace(/-/g, '')},
            {entity_registered_to_do_business_in_what_states: BE.states}
        ]

        setValue(updateArray)
    }


    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            return;
        }

        SharedApi.fetchBorrowingEntities(setBorrowingEntities, props.broker)
        if (isBroker) {
            SharedApi.fetchCustomersbyBroker(setCustomers)
        }
    }, [loaded]);

    const calcTotal = (totalId, fieldsArray) => {
        var total = 0
        fieldsArray.forEach(field => {
            total += document.getElementById(field).value ? parseFloat(document.getElementById(field).value.replace(/,/g, "").replace(/\$/g, "")) : 0
        })

        let total_liquid_assets = document.getElementById(totalId)

        console.log(totalId, total)

        // total_liquid_assets.value = "$" + total.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + (total.toString().split(".").length === 1 ? "00" : total.toString().split(".")[1].substring(0, 2))
        total_liquid_assets.value = formatter.format(total);
    }

    const inputCurrency = e => {
        var v = e.target.value.replace(/,/g, "").replace(/\$/g, ""), dollar = '0', cents = '00';

        v = v.replace(/[\[\]&]+/g, '');
        v = v.replace(/-/g, '')
        v = v.replace(/[^\d.-]/g, '')

        if ((v.match(/^[0-9.,$]+$/) || v === "")) {

            if (v.indexOf('.') !== -1) {
                var price = v.split('.');
                dollar = price[0] || '0';
                cents = price[1] || '00';
            }

            if (cents.length === 1) {
                if (dollar) {
                    var dollarNumbers = dollar.split('');
                    var dollarLength = dollarNumbers.length;
                    cents = dollarNumbers[dollarLength - 1] + cents;
                    dollar = '';
                    for (var i = 0; i < dollarLength - 1; i++) {
                        dollar += dollarNumbers[i];
                    }
                    if (!dollar) {
                        dollar = '0';
                    }
                }
            }

            if (v.length === 1) {
                cents = '0' + v;
            }

            if (cents.length === 3) {
                var centNumbers = cents.split('');
                dollar = dollar === '0' ? centNumbers[0] : dollar + centNumbers[0];
                cents = centNumbers[1] + centNumbers[2];
            }

            e.target.value = "$" + dollar.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + cents;
            calcTotal('total_non-liquid_assets', ['non_liquid_real_estate', 'other_personal_property', 'autos'])
            calcTotal('total_liquid_assets', ['cash', 'retirement_accounts', 'stocks_bonds', 'other_liquid_assets'])
            calcTotal('debt_total', ['debt_real_estate', 'debt_revolving', 'debt_installment', 'debt_other', 'debt_notes_payable'])

            let total_non_liquid_assets = parseFloat(document.getElementById('total_non-liquid_assets').value.replace(/,/g, "").replace(/\$/g, ""))
            let total_liquid_assets = parseFloat(document.getElementById('total_liquid_assets').value.replace(/,/g, "").replace(/\$/g, ""))
            let total_debt = parseFloat(document.getElementById('debt_total').value.replace(/,/g, "").replace(/\$/g, ""))
            let net_worth = (total_liquid_assets + total_non_liquid_assets - total_debt).toFixed(2)

            setValue("net_worth", "$" + net_worth.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + (net_worth.toString().split(".")[1] == undefined ? "00" : net_worth.toString().split(".")[1].substring(0, 2)))
        }
    }

    const addGuarantor = () => {
        setAdditionalGuarantors(additionalGuarantors + 1)
    }

    const removeGuarantor = (e) => {
        var key = `guarantor_${e.target.getAttribute('id').replace("remove_", "")}`
        var elem = document.getElementById(key)
        if (elem) elem.remove()
    }

    const renderAdditionalGuarantors = () => {
        let jsxMarkup = []

        for (var i = 0; i < additionalGuarantors; i++) {
            var key = `guarantor_${i}`
            jsxMarkup.push(
                <li className="input-field-group" key={key} id={key}>
                    <a href={`#${key}`} tabIndex="-1"><span></span></a>

                    <div className="input-field-group additional-guarantor">
                        <InputText type={'text'} label={'First Name*'}
                                   id={`${key}_first_name`} errors={errors} required/>

                        <InputText type={'text'} label={'Last Name*'}
                                   id={`${key}_last_name`} errors={errors} required/>

                        <InputText type={'email'} label={'Email*'} id={`${key}_email`}
                                   errors={errors} required/>

                        <InputTextMasked id={`${key}_phone_number`} control={control} label={'Phone*'} errors={errors} mask="999-999-9999" maskChar=" " type="tel" name="phone" placeholder="XXX-XXX-XXXX" />
                    </div>
                    <button type="button" className="additional-guarantors_remove" id={`remove_${i}`}
                            onClick={(e) => removeGuarantor(e)}>
                        <MemoizedIcon icon={faTimesCircle} className="form-button_icon"/>
                        Remove Guarantor
                    </button>
                </li>
            )
        }

        return jsxMarkup
    }

    if (redirect) {
        return <Redirect to={{
            pathname: "/thank-you/loc",
            state: {
                title: "Line of Credit",
                whitelabel: params?.broker ? true : false
            }
        }}/>
    }


    const tabIndexx = () => {
        setTabIndex(tabIndex + 1)
        return tabIndex
    }

    const globalProps = {
        errors: errors,
        tabIndex: tabIndexx,
        borrowingEntities: borrowing_entities,
        selectBE: selectBE,
        control: control,
        inputEIN: handleInput.inputEIN,
        inputCurrency: inputCurrency,
        inputSSN: handleInput.inputSSN,
        inputCreditScore: handleInput.inputCreditScore,
        inputPercentage: handleInput.inputPercentage,
        inputZip: handleInput.inputZip,
        inputNumber: handleInput.inputNumber,
        inputPhone: handleInput.inputPhone,
        validateSSN: handleInput.validateSSN,
        validateZip: handleInput.validateZip,
        validatePhone: handleInput.validatePhone,
        selectCustomer: selectCustomer,
        selectedCustomer: selectedCustomer,
        setSelectedCustomer: setSelectedCustomer,
        existing_customer: existing_customer,
        setExistingCustomer: setExistingCustomer,
        existing_borrower: existing_borrower,
        setExistingBorrower: setExistingBorrower,
        setBorrowingEntities: setBorrowingEntities,
        selectedBE: selectedBE,
        setSelectedBE: setSelectedBE,
        fetchBorrowers: SharedApi.fetchBorrowingEntities,
        customers: customers,
        isLOC: true,
        isBroker: isBroker,
        watch: watch,
        setError: setError,
        setValue: setValue,
        clearError: clearError,
        broker: params?.broker
    }

    let userId = window.localStorage.getItem('userID') ? window.localStorage.getItem('userID') : window.localStorage.getItem('userId');
    let isLead = window.localStorage.getItem('isLead') ? window.localStorage.getItem('isLead').toString() === "true" : true;
    
    return (
        <TransitionIn content={
            <ApplicationWrapper handleSubmit={handleSubmit(onSubmit)} errors={errors} ref={register({required: true})}
                                submitted={submitted} setSubmitted={setSubmitted} responseMessage={responseMessage}
                                setResponseMessage={setResponseMessage} showSubmit={true}
                                isEmployee={props?.isEmployee}>
                <div className="row">

                    {(isBroker && userId && !params?.broker && !isLead) &&
                        <p>Your whitelabel URL: <a
                            href={`${process.env.REACT_APP_WHITELABEL_URL}/applications/line-of-credit/${userId}`}>{process.env.REACT_APP_WHITELABEL_URL}/applications/line-of-credit/{userId}</a>
                        </p>
                    }

                    <section className="section borrowing-entities" id="application-loc">
                        <div className="section_heading " style={{display: "block"}}>
                            <SectionHeading heading={"Borrower and Primary Guarantor Application"} max={30}/>
                            <p className={"please-fill"}>Please fill out the application below in its entirety.
                                Your changes will not be saved until the application is submitted.</p>
                            {!params?.broker &&
                                <SelectCustomer {...globalProps} ref={register({required: true})}
                                                notReqRef={register({required: false})}/>
                            }
                        </div>

                        <Borrower {...globalProps} ref={register({required: true})}
                                  notReqRef={register({required: false})}/>

                        <PrimaryGuarantor {...globalProps} ref={register({required: true})}
                                          notReqRef={register({required: false})}/>

                        <LegalQuestions {...globalProps} ref={register({required: true})}
                                        notReqRef={register({required: false})}/>

                        {/* Implement additional guarantors */}
                        {(!isBroker || params?.broker) &&
                            <section className="section additional-guarantors" id="additional_guarantors_section" style={{ marginTop: '5rem' }}>
                                <div className="section_heading">
                                    <h1>Additional Guarantors</h1>
                                </div>

                                {/* ----- Additional Guarantors (dynamically generated) ----- */}
                                <fieldset>
                                    {renderAdditionalGuarantors()}
                                    <button type="button" className="form-button additional-guarantors_add"
                                            onClick={addGuarantor}>
                                        Additional Member
                                    </button>
                                </fieldset>
                            </section>
                        }

                        <Financials {...globalProps} ref={register({required: true})}
                                    notReqRef={register({required: false})}/>

                        {(isBroker && !params?.broker) &&
                            <BrokerConsentForm {...globalProps} ref={register({required: true})}
                                               notReqRef={register({required: false})}/>}

                    </section>

                </div>

            </ApplicationWrapper>
        }/>
    )
}

export default withRouter(Application_LOC)