import React, { Fragment, useState } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { Section } from '../../../../../components/shared/Section';
import TransitionIn from '../../../../../components/shared/TransitionIn';
import { SearchLoan } from './SearchLoan';

function ResourcesCustomer() {

    const [loanSearchOpen, setLoanSearchOpen] = useState(false)

    const headingResources = (
        <span>Customer Resources</span>
    )

    const bodyResources = (
        <Fragment>
            <p>Below you will find downloadable customer resources.</p>
            <ul>
                <li>
                    <img src={"/assets/images/servicing_requests/Document_Icons_XLS_Icon.svg"} alt="" />
                    <a download href="/documents/resources/customer/2023_Budget_Import_Template_v5.2.xlsx"
                        data-tip="Use this to submit your rehab/construction budget" data-for="app-tooltip">
                        Construction Budget Template
                    </a>
                </li>
                <li>
                    <img src={"/assets/images/servicing_requests/Document_Icons_PDF_Icon.svg"} alt="" />
                    <a download href="/documents/resources/customer/Lima One Onboarding - NEW Contractor Profile.pdf"
                        data-tip="Have your contractor complete this for rehab/construction deals" data-for="app-tooltip">
                        Lima One Onboarding - NEW Contractor Profile
                    </a>
                </li>
                <li>
                    <img src={"/assets/images/servicing_requests/Document_Icons_PDF_Icon.svg"} alt="" />
                    <a download href="/documents/resources/customer/Property Management Questionnaire.pdf"
                        data-tip="Complete this for Rental30 loan requests" data-for="app-tooltip">
                        Property Management Questionnaire
                    </a>
                </li>
                <li>
                    <img src={"/assets/images/servicing_requests/Document_Icons_XLS_Icon.svg"} alt="" />
                    <a download href="/documents/resources/customer/Rent Roll Spreadsheet.xlsx"
                        data-tip="Complete this for Rental30 loan requests" data-for="app-tooltip">
                        Rent Roll Template
                    </a>
                </li>
                <li>
                    <img src={"/assets/images/servicing_requests/Document_Icons_XLS_Icon.svg"} alt="" />
                    <a download href="/documents/resources/customer/Track record and REO schedule.xlsx"
                        data-tip="Tell us about your completed projects from the last 36 months" data-for="app-tooltip">
                        Track Record and REO Schedule
                    </a>
                </li>
            </ul>
        </Fragment>
    )

    const headingForms = (
        <span>
            Other Forms
        </span>
    )

    const bodyForms = (
        <ul>
            <li>
                <img src={"/assets/images/servicing_requests/CapEx_Funds_Request_Form_Icon.svg"} alt="" />
                <Link to={process.env.REACT_APP_SESSION_URL + "/servicing-requests/capex"}>CapEx Request Form</Link>
            </li>
            <li>
                <img src={"/assets/images/servicing_requests/Payoff_Request_Form_Icon.svg"} alt="" />
                <Link to={process.env.REACT_APP_SESSION_URL + "/servicing-requests/payoff"}>Payoff Request Form</Link>
            </li>
            <li>
                <img src={"/assets/images/servicing_requests/Extension_Request_Form_Icon.svg"} alt="" />
                <Link to={process.env.REACT_APP_SESSION_URL + "/servicing-requests/extension"}>Loan Extension Request Form</Link>
            </li>
        </ul>
    )

    const bodyLoanLookup = (
        <Fragment>
            <p>Quickly search for a loan and add yourself to it.</p>
            <ul>
                <li>
                    <img src={"/assets/images/servicing_requests/Extension_Request_Form_Icon.svg"} alt="" />
                    <button onClick={() => setLoanSearchOpen((loanSearchOpen) => true)}>Add My Loan</button>
                </li>
            </ul>
        </Fragment>
    )

    const headingLoanLookup = (
        <span>Loan Lookup</span>
    )

    const content = (
        <React.Fragment>
            <Section heading={headingResources} body={bodyResources} id={"customer-resources"} />
            <Section heading={headingForms} body={bodyForms} id={"customer-resources_forms"} />
            <Section heading={headingLoanLookup} body={bodyLoanLookup} id={"customer-resources_loan-lookup"} />
            <SearchLoan isOpen={loanSearchOpen} setLoanSearchOpen={setLoanSearchOpen} onClose={() => setLoanSearchOpen((loanSearchOpen) => false)} />
        </React.Fragment>
    )

    return (
        <TransitionIn content={content} />
    )
}

export default ResourcesCustomer
