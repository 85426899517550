import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import ReactModal from "react-modal";
import { ReactSVG } from "react-svg";
import DocumentUpload from "./DocumentUpload";

const CommentsSection = ({
                           setSelectedCommentBox,
                           checklistItem,
                           handleChange,
                           comments,
                         }) => {
  const customerCommentsRef = useRef();
  const brokerCommentsRef = useRef();

  function outputsizeCustomer() {
    if (customerCommentsRef?.current) {
      let height = customerCommentsRef.current.offsetHeight;
      brokerCommentsRef.current.style.height = `${height < 40 ? 40 : height}px`;
    }
  }
  outputsizeCustomer();
  function outputsizeBroker() {
    if (brokerCommentsRef?.current) {
      let height = brokerCommentsRef.current.offsetHeight;
      customerCommentsRef.current.style.height = `${height < 40 ? 40 : height}px`;
    }
  }
  outputsizeBroker();

  useEffect(() => {
    new ResizeObserver(outputsizeCustomer).observe(customerCommentsRef.current);
    new ResizeObserver(outputsizeBroker).observe(brokerCommentsRef.current);
  }, []);

  return (
      <>
        <td>
        <textarea
            onClick={(e) => setSelectedCommentBox(e.target)}
            onChange={handleChange.bind(checklistItem)}
            value={comments[checklistItem.Id]}
            placeholder={"Comment here"}
            data-id={checklistItem.Id}
            ref={customerCommentsRef}
            resize={"vertical"}
        />
        </td>
        <td>
        <textarea
            disabled
            value={checklistItem.TC_Origination__Default_Comment__c}
            placeholder={"There are no comments yet."}
            ref={brokerCommentsRef}
            resize={"vertical"}
        />
        </td>
      </>
  );
};

export const DesktopView = ({
                              item,
                              setSelectedChecklist,
                              setSelectedCommentBox,
                              handleChange,
                              comments,
                              setShowModal,
                              showModal,
                              uploadFiles,
                              uploadSubmitted,
                              selectedChecklist,
                              clearFiles,
                              opportunityId,
                              urls
                            }) => {
  const renderChecklistItems = () => {
    return item.checklistItems.map((checklistItem, i) => {
      const date = new Date(
          checklistItem.TC_Origination__User_Last_Modified__c
      );

      if (!checklistItem?.TC_Origination__No_Comments__c) {
        return (
            <tr>
              <td style={{ padding: '0.5rem' }}>{checklistItem.Name}</td>
              <td style={{ textAlign: "center" }}>
                {checklistItem.TC_Origination__Status__c === 'Open (Pending 2nd)' ? 'In Review' : checklistItem.TC_Origination__Status__c}
              </td>
              <CommentsSection
                  setSelectedCommentBox={setSelectedCommentBox}
                  checklistItem={checklistItem}
                  handleChange={handleChange}
                  comments={comments}
              />
              <td>
                <button
                    className="button-submit green"
                    style={{ height: "26px" }}
                    type="button"
                    onClick={() => {
                      setShowModal(true);
                      setSelectedChecklist(checklistItem);
                    }}
                >
                  <FontAwesomeIcon icon={faUpload} />
                  <span className="upload-files">
                  {!checklistItem?.files?.length ? "UPLOAD FILES" : `FILE DRAWER (${checklistItem?.files?.length})`}
                </span>
                </button>
              </td>
              <td style={{ textAlign: "right", padding: '0.5rem' }}>
                {date.getMonth() +
                    1 +
                    "/" +
                    date.getDate() +
                    "/" +
                    date.getFullYear()}
              </td>
            </tr>
        );
      }
    });
  };

  return (
      <>
        <table className="locTable">
          <tbody>
          <tr style={{ background: 'lightgray', height: '2rem'}}>
            <th style={{ width: "15%", padding: '0.5rem' }}>ITEM</th>
            <th style={{ width: "10%", padding: '0.5rem', textAlign: 'center' }}>STATUS</th>
            <th style={{ width: "15%", padding: '0.5rem' }}>YOUR COMMENTS</th>
            <th style={{ width: "15%", padding: '0.5rem' }}>LIMA ONE COMMENTS</th>
            <th style={{ width: "20%", padding: '0.5rem', textAlign: 'center' }}>FILES</th>
            <th style={{ width: "10%", padding: '0.5rem', textAlign: "right" }}>LAST UPDATED</th>
          </tr>

          {item?.checklistItems && renderChecklistItems()}
          </tbody>
        </table>

        <ReactModal
            isOpen={showModal}
            ariaHideApp={false}
            contentLabel="onRequestClose Example"
            onRequestClose={() => {
              setShowModal(false);
              setSelectedChecklist(null);
            }}
            shouldCloseOnOverlayClick={true}
            dialogClassName="add-members-modal"
            className="loc-doc-upload-modal"
        >
          <div className="close-bar" style={{ textAlign: "right", padding: "0" }}>
            <button
                onClick={() => {
                  setShowModal(false);
                  setSelectedChecklist(null);
                }}
            >
              <ReactSVG src="/assets/images/xicon.svg" />
            </button>
          </div>
          <div class="document-upload-modal">
            <DocumentUpload
                handleSubmit={(files) => uploadFiles(files)}
                submitted={uploadSubmitted}
                checklist={selectedChecklist}
                opportunityId={opportunityId}
                clearFiles={clearFiles}
                urls={urls}
            />
          </div>
        </ReactModal>
      </>
  );
};
