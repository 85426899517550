import React from 'react';
import { SectionExpandable } from '../../../../../components/shared/Section';
import TransitionIn from '../../../../../components/shared/TransitionIn';
import './index.scss';
import PaymentYear from './PaymentYear';
import SkeletonTable from '../../../Dashboard/SkeletonTable';

export default function PaymentHistory(props) {

    const dealHistory = props.dealHistory;

    const heading = "Payment History";

    const body = (
        dealHistory ?
            <div className="payment-history expandable">

                <div className="payment-history_legend">
                    <span>Payment Type</span>
                    <span>Applied Date</span>
                    <span>Amount</span>
                </div>

                <ul>
                    {
                        dealHistory.length > 0 ?
                            <>
                                {

                                    dealHistory.map((x, i) => {
                                        return <PaymentYear year={x} index={i} />
                                    })

                                }
                            </>
                            :
                            <li className='payment-history_year'>
                                <span className="no-results">
                                    Payment History is not available for this deal.
                                </span>
                            </li>
                    }
                </ul>

            </div>
            : <SkeletonTable />
    )

    return (
        <TransitionIn content={<SectionExpandable heading={heading} body={body} flush={true} />} />
    )
}
