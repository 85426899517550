import React from 'react';
import "./index.scss";

const InputRadioVariable = (props) => {
    let label = props.label ? props.label : props.fd[props.fd_key].label
    return (
        <div className={`input-field ${props.fullwidth ? "full-width" : ""} ${props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? "error" : ""}`}>
            <label htmlFor={props.fd_key}>{label}</label>
            <ul>
                {
                    props.config.map(x => {
                        return (
                            <li>
                                <input onChange={props.inputRadio} data-input={props.fd_key}
                                type="radio" name={props.fd_key} id={props.fd_key} value={x.value}
                                checked={props.fd[props.fd_key].iVal.value !== "" ? props.fd[props.fd_key].iVal.value : ""} />
                            {x.label}
                            </li>
                        )
                    })
                }
            </ul>
            <span className="error-message">{props.fd[props.fd_key].error !== 'error message' ? props.fd[props.fd_key].error : ""}</span>
        </div>
    )
}

export default InputRadioVariable