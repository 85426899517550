import axios from 'axios'
import states from '../../assets/config/_us_states.json'
import Swal from 'sweetalert2'

export const getBinary = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

export const checkFileSize = async (e) => {

    // check current file size to not exceed 5Mb
    const input = e.target;
    const megabytes = (input.files[0].size / 1024) / 1024;

    let base64 = await getBinary(input.files[0])

    // check if file is empty
    if (base64.replace(/^data:.+;base64,/, '') === "data:") {
        input.value = null;
        input.parentNode.classList.add("fileError");
        Swal.fire({
            icon: 'warning',
            title: 'Empty file.',
            text: 'Please do not upload blank files.',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonColor: '#1D3B62'
        });

        return;
    }

    if (megabytes > 4) {
        input.value = null;
        input.parentNode.classList.add("fileError");
        Swal.fire({
            icon: 'warning',
            title: 'File size too large.',
            text: 'Max file size is 4MB',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonColor: '#1D3B62'
        });

        return;
    } else {
        // if the file size is less than 5Mb and had an error before, we remove it now that it passes the 5Mb validation
        input.parentNode.classList.remove("fileError");
    }


    // check combined file sizes of what's in DOM
    const fileInputs = Array.from(document.getElementsByTagName("INPUT")).filter((input) => input.type === "file");
    let totalMb = 0;
    let fileSizesHTML = ``;
    fileInputs.filter((input) => input.files.length).forEach((input) => {
        const mb = (input.files[0].size / 1024) / 1024;
        totalMb += mb;
        fileSizesHTML += `<li>${input.files[0].name} - ${(mb).toFixed(2)}MB</li>`
    });

    // check if combined files is over 10Mb
    if (totalMb > 4) {
        input.value = null;
        input.parentNode.classList.add("fileError");
        Swal.fire({
            icon: 'warning',
            title: 'Combined file sizes too large. Max total file size is 4MB.',
            html: `
                        <ul>
                        ${fileSizesHTML}
                        </ul>
                        `,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonColor: '#1D3B62'
        })
    }
}

const api = {

    async submit(applicationType, formData, setError, setResponseMessage, setSubmitted, setSubmissionResponse, isBroker, brokerId) {
        let guarantors = []
        let properties = []

        if (!document.getElementById('signature').value) {
            setError("signature", {
                type: "manual",
                message: "Signature is required.",
            });
            setSubmitted(false)
            return
        } else formData['signature'] = document.getElementById('signature').value
        if (formData['number_of_FixNFlips_sold_in_the_past_3_years']) formData['volumeofflips'] = formData['number_of_FixNFlips_sold_in_the_past_3_years'];
        if (formData['number_of_fixnflips_sold_in_the_past_3_years']) formData['volumeofflips'] = formData['number_of_fixnflips_sold_in_the_past_3_years'];
        if (formData['number_of_new_Construction_builds_in_the_past_3_years']) formData['new_construction_builds'] = formData['number_of_new_Construction_builds_in_the_past_3_years'];
        if (formData['number_of_new_construction_builds_in_the_past_3_years']) formData['new_construction_builds'] = formData['number_of_new_construction_builds_in_the_past_3_years'];
        if (formData['estimated_credit_score']) formData['licenses_held'] = formData['estimated_credit_score'];

        const contactPhoneNames = ['HOA_Contact_Phone__c', 'Insurance_Contact_Phone__c', 'Title_Contact_Phone__c', 'Property_Access_Contact_Phone__c', 'Payoff_Contact_Phone__c']
        for (const phoneName of contactPhoneNames) {
            if (formData[phoneName]) {
                const rawPhoneNumber = formData[phoneName].replaceAll('-', '')
                const formattedPhoneNumber = '1' + rawPhoneNumber
                formData[phoneName] = formattedPhoneNumber
            }
        }

        if (formData['total_assets']) formData['total_real_estate_assets_owned'] = formData['total_assets']

        if (formData?.number_of_rental_units_currently_owned_regardless_of_purchase_date && isBroker && !formData?.customer_id) {
            try {
                let value = formData['number_of_rental_units_currently_owned_regardless_of_purchase_date']

                if (value > 23) {
                    value = "24+"
                } else if (value > 11) {
                    value = "12 - 23"
                } else if (value > 4) {
                    value = "5 - 11"
                } else if (value > 0) {
                    value = "1 - 4"
                } else {
                    value = "0"
                }

                formData['number_of_rental_units_currently_owned_regardless_of_purchase_date_og'] = formData['number_of_rental_units_currently_owned_regardless_of_purchase_date']
                formData['number_of_rental_units_currently_owned_regardless_of_purchase_date'] = value
            } catch (err) {
                console.error(err)
            }
        }

        for (const [key, value] of Object.entries(formData)) {
            let domElement = document.querySelector(`form[id='app-form'] input[name='${key}']`)

            // clean up values for currency and percentages
            if (domElement && domElement.hasAttribute("data-cleanup") && domElement.getAttribute("data-cleanup") == "true") {
                let v = value.replace(/[\[\]&]+/g, '');
                v = v.replace(/-/g, '')
                v = v.replace(/[^\d.-]/g, '')
                formData[key] = v
            }

            if (value == 'true') {
                formData[key] = true
            }

            if (value == 'false') {
                formData[key] = false
            }

            if (key.includes('CREProperty_Type__c')) {
                const propertyId = key.replace('CREProperty_Type__c', '')
                const property = {
                    CREProperty_Type__c: formData[`CREProperty_Type__c${propertyId}`],
                    Street_Address__c: formData[`Street_Address__c${propertyId}`],
                    Unit_Number__c: formData[`Unit_Number__c${propertyId}`],
                    City__c: formData[`City__c${propertyId}`],
                    State__c: formData[`State__c${propertyId}`],
                    Zip__c: formData[`Zip__c${propertyId}`]
                }
                delete formData[`CREProperty_Type__c${propertyId}`]
                delete formData[`Street_Address__c${propertyId}`]
                delete formData[`Unit_Number__c${propertyId}`]
                delete formData[`City__c${propertyId}`]
                delete formData[`State__c${propertyId}`]
                delete formData[`Zip__c${propertyId}`]

                properties.push(property);
            }
        }

        if (applicationType === 'Line of Credit') {
            let state = states.find((x, index) => x.abbreviation === formData['state'])
            formData['state'] = state.name

            for (let i = 0; i < 20; i++) {
                if (document.getElementById('guarantor_' + i)) {
                    let guarantor = {
                        first_name: document.getElementById(`guarantor_${i}_first_name`).value,
                        last_name: document.getElementById(`guarantor_${i}_last_name`).value,
                        email: document.getElementById(`guarantor_${i}_email`).value,
                        phone_number: document.getElementById(`guarantor_${i}_phone_number`).value
                    }
                    guarantors.push(guarantor)
                }
            }

            formData = {...formData,
                additional_guarantors: guarantors,
            }
        }


        if (!formData?.registered_to_do_business_in_what_states && formData?.entity_registered_to_do_business_in_what_states) {
            formData['registered_to_do_business_in_what_states'] = formData['entity_registered_to_do_business_in_what_states'];
        } else if (formData?.registered_to_do_business_in_what_states && !formData?.entity_registered_to_do_business_in_what_states) {
            formData['entity_registered_to_do_business_in_what_states'] = formData['registered_to_do_business_in_what_states'];
        }

        if (!formData?.estimated_credit_score && formData?.licenses_held) {
            formData['estimated_credit_score'] = formData['licenses_held'];
        } else if (formData?.estimated_credit_score && !formData?.licenses_held) {
            formData['licenses_held'] = formData['estimated_credit_score'];
        }

        if (formData?.areas_of_operation) {
            formData['which_areas_of_real_estate_do_you_operate'] = {}
            formData['areas_of_operation'].forEach((key) => {
                formData['which_areas_of_real_estate_do_you_operate'][key] = true;
            })
        }

        formData = {...formData,
            Name: formData?.Street_Address__c,
            StageName: 'Application',
            Type: applicationType,
            properties
        }

        if (isBroker) {
            formData = {...formData, new_customer: formData?.customer_id ? false : true}
        }

        if (formData['Portfolio_Type__c']) {
            formData['Name'] = formData?.borrowing_entity_name
            formData['Property_Model__c'] = formData['Portfolio_Type__c']
        }

        if (brokerId) {
            formData['new_customer'] = true
            formData['broker'] = brokerId
        }


        // have to add close date and target close date for opportunity to be created in SF
        if (formData?.Target_Close_Date__c) formData['CloseDate'] = formData.Target_Close_Date__c
        // if (!formData?.customer_id) formData = {...formData, customer_id: null}

        Swal.fire({
            html: `
            <div style="display: flex;align-items: center;justify-content: center"><img src="/assets/images/lo-loader.svg" style="margin-top: 1rem;padding-bottom: 1rem;height: 5rem;" /></div>
            <br />
            <p>Your application is processing.<br /><span style="bold">DO NOT</span> close or refresh this window.<br />This can take up to 2 minutes.</p>`,
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        })

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");

        let payload = encodeJson(formData, ['Lot_Status__c', 'Loan_Purpose__c', 'country_of_citizenship']);

        try {
            let data = await axios.post(process.env.REACT_APP_LIMA + `/lima/api/${(isBroker && !brokerId) ? 'broker' : 'customer'}/${applicationType === 'Line of Credit' ? 'submitlocapplication' : 'submitpropertyapplication'}`, payload)

            Swal.close()

            if (applicationType === 'Line of Credit') {

                window.localStorage.setItem("isLead",false)
                window.location.href = "/thank-you/loc"
            }

            setSubmissionResponse(data?.data)
            setSubmitted(false)
        } catch (error) {

            Swal.close()

            if (error?.response?.status === 500) {
                setResponseMessage('Error 500. Something went wrong.')
                setSubmitted(false)
                return
            }
            if (error?.response) {
                setResponseMessage(error.response.data.error)
            } else {
                setResponseMessage("Bad data.")
            }
            setSubmitted(false)
        }
    }


}

const htmlEncode = (str) => {
   return str.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
        return '&#'+i.charCodeAt(0)+';';
    });
}

const encodeJson = (jsonObj, whitelist) => {
    // console.log("WHITELIST", whitelist)
    for (var key in jsonObj) {
        if (jsonObj.hasOwnProperty(key)) {
            if ((typeof jsonObj[key] === 'string') || (jsonObj[key] instanceof String)) {
                if (whitelist && !whitelist.includes(key)) {
                    // console.log("KEY", key)
                    jsonObj[key] = htmlEncode(jsonObj[key])
                }
            }
        }
    }

    return jsonObj;
}

export { api, htmlEncode, encodeJson }