import React, {useState} from 'react';
import './index.scss';
import axios from 'axios'
// import FileUpload from '../../components/_applications/_rental/form_components/_form_portfolio/_portfolio_file_upload';
import ButtonSubmit from '../../../../../../components/shared/ButtonSubmit';
import { formDataTemplate } from './_form_data'; // state.formData object
import FileUploader from '../../../../../../components/legacy/FileUploader';

const DocumentUpload = (props) => {

    const [uploadComplete, setUploadComplete] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [enableUpload, setEnableUpload] = useState(true)
    const [formData, setFormData] = useState(
    {
        rentrollfilename: [],
        rentrollfiledata: []
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        let allValid = true

        for (var i = 0; i < formData.rentrollfiledata.length; i++) {
            if (! formData.rentrollfiledata[i].iVal.isValid) allValid = false
        }

        if (! allValid) {
            setErrorMessage("Invalid file.")
            return;
        }

        setSubmitted(true)

        console.log(formData)

        for (var i = 0; i < formData.rentrollfiledata.length; i++) {
            let fd = {
                opportunityId: props.dealId,
                rentrollfiledata: formData.rentrollfiledata[i].iVal.value,
                rentrollfilename: formData.rentrollfilename[i].iVal.value
            }

            axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
            axios.post(process.env.REACT_APP_LIMA +  `/lima/api/deals/uploaddocument`, JSON.stringify(fd))
            .then((data) => {
                setUploadComplete(true)
                setSubmitted(false)

                let files = props.files
                files.push({
                    name: fd.rentrollfilename,
                    created_at: new Date().toString()
                })

                if (props.isLOC) {
                    props.setFiles(props.dealId, files)
                } else {
                    props.setFiles(files)
                }

                setFormData({
                    rentrollfilename: [],
                    rentrollfiledata: []
                })

                if (props.callback) {
                    props.callback()
                }
            })
            .catch((error) => {
                console.log(error)
                setUploadComplete(true)
                setSubmitted(false)
                setErrorMessage("Something went wrong.")
                setFormData(
                {
                    rentrollfilename: [],
                    rentrollfiledata: []
                })
            });

            setEnableUpload(true)
        }
    }

    var align = props.align ? props.align : 'right'

    return (
        <React.Fragment>
            <div className="document-upload">
                <form style={{ height: '100%' }} onSubmit={handleSubmit}>
                    <div className="rentrollupload">
                        <FileUploader fd={formData} setFormData={setFormData} accept={props.accept} setEnableUpload={setEnableUpload} />
                    </div>
                    <div style={{ width: '100%', textAlign: align }}>
                        <div style={{ display: 'block' }}>
                            <ButtonSubmit value="UPLOAD" callBack={handleSubmit} readOnly={submitted} disabledIf={enableUpload} style={{ right: '0', marginTop: '20px', width: '510px' }} />
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
    
}

export default DocumentUpload