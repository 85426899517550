import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import animations from '../../../../animations';
import { useSpring, animated } from '@react-spring/web';
import { Textfit } from 'react-textfit';

function SectionExpandableHeading(props) {

    const styleCopied = useSpring(animations("toggleInUp", props.expanded));
    const styleCopy = useSpring(animations("toggleInDown", props.expanded));

    const toggleExpand = () => {
        props.setExpanded(!props.expanded);
        if (props.callback) {
            props.callback();
        };
    };

    return (
        <React.Fragment>

            <h2 className={props.click ? "heading is-clickable" : "heading"}

                role={props.click && !props.controls ? "button" : ""}

                onClick={props.click ? () => props.setExpanded(!props.expanded) : null}>

                {props.heading || "Error: content is not available."}

                <span className="mask"></span>
            </h2>

            <div className="controls-and-toggle">
                {
                    (props.body && props.controls && !props.click) ?

                        <div className={`controls ${props.noGrow ? "no-grow" : ""}`} onFocus={props.body ? () => props.setExpanded(true) : null}>
                            {props.controls}
                        </div>
                        : null/* optional controls */
                }

                <button className={`toggle ${props.controls ? 'with-controls' : null} ${props.body ? "" : "disabled"}`}
                    onClick={props.body ? toggleExpand : null}
                    type="button"
                    data-tip={props.body ? "Click here to expand/collapse this section." : "Disabled"}
                    data-for="app-tooltip"
                    aria-controls={props.id}
                    tabIndex={props.body ? 0 : -1}>

                    {props.expanded ?

                        <animated.span style={props.animate ? styleCopied : null}>
                            <FontAwesomeIcon icon={faChevronUp} />
                        </animated.span>

                        :

                        <animated.span style={props.animate ? styleCopy : null}>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </animated.span>

                    }
                </button>
            </div>
        </React.Fragment>
    )
}


function SectionHeading(props) {

    return (
        <Fragment>
            <h1 className={`heading`} style={{ display: "block"}}>
                <Textfit mode="single" max={props.max || 30}>
                    {props.heading /* heading */}
                </Textfit>

                <span className="mask"></span>
            </h1>
            {/* <span className="mask"></span> */}
            <div className={`controls`}>
                    {props.controls}
                </div>
        </Fragment>
    )
}

export { SectionHeading, SectionExpandableHeading }