// object used to populate the formData fields in state
const formData = {
    first_name: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "First Name is required."
    },
    last_name: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "Last Name is required."
    },
    state: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "Please select your State."
    },
    email: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "A valid email is required."
    },
    phone_number: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "A valid phone number is required."
    },
    FixFlip: {
        iVal: {
            value: false,
            isValid: true
        },
        error: ""
    },
    NewConstruction: {
        iVal: {
            value: false,
            isValid: true
        },
        error: ""
    },
    RentalsCommercial: {
        iVal: {
            value: false,
            isValid: true
        },
        error: ""
    },
    MultiFamily: {
        iVal: {
            value: false,
            isValid: true
        },
        error: ""
    },
    MixedUse: {
        iVal: {
            value: false,
            isValid: true
        },
        error: ""
    },
    number_of_rental_units_currently_owned_regardless_of_purchase_date: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "This field is required."
    },
    currently_estimated_value_of_your_real_estate_portfolio: {
        iRaw: "$",
        iVal: {
            value: "",
            isValid: false
        },
        error: "This field is required."
    },
    number_of_fixnflips_sold_in_the_past_3_years: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "This field is required."
    },
    number_of_new_construction_builds_in_the_past_3_years: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "This field is required."
    },
    areas_of_operation: {
        key: "areas_of_operation",
        label: "Which Areas of Real Estate Do You Primaryly Operate In?",
        iVal: { 
            value: [],
            isValid: true
        },
        error: "Please select your areas of operation."
    },
    has_property_needs_financing: { 
        key: "has_property_needs_financing",
        label: "Do You Currently Have a Property That Needs Financing?*",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Please select an option.",
        form: "shared" 
    },
    notes: { 
        key: "notes",
        label: "Notes",
        iRaw: "",
        iVal: {
            value: "",
            isValid: true
        },
        message: "Message",
        error: "",
        form: "shared" 
    },
}

export { formData }