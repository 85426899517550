import './index.scss';
import React, {useState, useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {useForm} from "react-hook-form"
import ButtonSubmit from '../../../ButtonSubmit';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import Selling from './Selling';
import Refinancing from './Refinancing';
import InputText from "../../_components/InputText";
import InputSelect from "../../_components/InputSelect";
import InputDate from "../../_components/InputDate";
import {checkFileSize, encodeJson, getBinary} from '../../_api';

export const fileTypes = ".xls,.xlsx,.pdf,.doc,.docx,.xlsm"


const ExtensionRequest = props => {


    const [submitted, setSubmitted] = useState(false);
    const [success, setSuccess] = useState(null); // state for API submission
    const {register, handleSubmit, errors, watch, setError} = useForm()
    const [refinancing, setRefinancing] = useState(null);

    const watchMonthlyPayments = watch("monthly_payments");
    const watchLinkListing = watch("link_to_listing");
    const watchExitStrategy = watch("exit_strategy");
    const watchFloodPolicy = watch("flood_policy");

    useEffect(() => {

    }, [watch, errors])

    const onSubmit = async formData => {
        setSubmitted(true);

        if (formData['exit_strategy'] === "List to Sell") {
            if (formData["link_to_listing"] === "" && formData["listing_agreement"] && formData["listing_agreement"].length === 0) {
                setError("link_to_listing", {
                    type: "manual",
                    message: "Please provide a link to the listing or upload the Listing Agreement..",
                });
                setError("listing_agreement", {
                    type: "manual",
                    message: "Please provide a link to the listing or upload the Listing Agreement..",
                });
                setSubmitted(false);
                return;
            }
            if (formData['price_plan_to_reduce'] === 'Yes' && !formData['price_expected_reduction_date']) {
                setError("price_expected_reduction_date", {
                    type: "manual",
                    message: "",
                });
                setSubmitted(false);
                return;
            }
        }

        let filesToCheck = ["approval_commitment_letter", "risk_hazard_policy", "flood_policy", "paid_receipt", "property_tax", "listing_agreement", "sellers_agreement", "risk_policy_paid_receipt", "flood_policy_paid_receipt"]

        let filesArray = []

        for (let fileType of filesToCheck) {
            if (formData[fileType]) {
                for (let i = 0; i < formData[fileType].length; i++) {
                    try {
                        let base64 = await getBinary(formData[fileType][i])
                        filesArray.push({
                            fileData: base64.replace(/^data:.+;base64,/, ''),
                            fileName: `${fileType}_${formData[fileType][i]['name']}`
                        })
                    } catch (error) {

                    }
                }
            }

            delete formData[fileType]
        }


        let files = {files: filesArray}

        let body = ``

        var labels = document.getElementsByTagName('LABEL');

        for (let key in formData) {
            if (key && !filesToCheck.includes(key)) {
                let label = [].filter.call(labels, el => el.htmlFor && el.htmlFor === key);
                body += `<tr><td><b>${(label && label.length) ? label[0].innerHTML : key}</b></td></tr>`
                body += `<tr><td>${formData[key] || "N/A"}</td></tr>`
            }
        }

        encodeJson(formData)

        let fd = {...formData, ...files, body}

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.post(process.env.REACT_APP_LIMA + `/lima/api/extension-request`, fd
        ).then((data) => {
                setSubmitted(false);
                setSuccess(true);
            }
        ).catch(error => {
            setSubmitted(false);
            setSuccess(false);
        });
    }

    let tabIndex = 0
    // console.log(errors);

    const fileInputStyle = {
        maxHeight: "2rem",
        paddingTop: "0.4rem",
        maxWidth: "15rem",
        border: "1px solid gray"
    }

    const needFloodPolicyReceipt = !watchFloodPolicy ? false : watchFloodPolicy.length;

    // If API submission returns 200, redirect to thank you, otherwise display form
    if (success === true) {
        return <Redirect
            to={{
                pathname: '/thank-you/form',
                state: {
                    external: props?.external === true,
                    message: "Thank you! Approval for an extension may take approximately 1 to 2 weeks. Your Servicing Analyst will be in contact with you for any additional information needed and to complete the extension process once approved."
                }
            }}
        />
    } else

        return (
            <main className="form main" id="form-request-extension">
                <section className="section">

                    Please complete the following extension application with as much detail as possible. Approval for an
                    extension may take approximately 1 to 2 weeks.
                    Your Servicing Analyst will be in contact with you for any additional information needed and to
                    complete
                    the extension process once approved.

                </section>

                <form className={`form-body ${errors.length > 0 ? 'form-error' : ''}`}
                      onSubmit={handleSubmit(onSubmit)}>
                    <div className="payoff-request-form">

                        <fieldset className="input-field-group gaps">
                            <InputText ref={register({required: true})} type={'email'} label={'Email Address*'}
                                       name={'email'} errors={errors}/>
                            <InputText ref={register({required: true})} type={'text'} label={'Loan Number*'}
                                       name={'loan_number'} errors={errors}/>
                            <InputText ref={register({required: true})} type={'text'} label={'Borrower Name*'}
                                       name={'borrower_name'} errors={errors}/>
                            <InputText ref={register({required: true})} type={'text'} label={'Property Address*'}
                                       name={'property_address'} errors={errors}/>

                            <div className={`input-field full-width ${errors?.received_extensions ? "error" : ""}`}>
                                <label htmlFor={"received_extensions"}>Has this loan received one or more extensions?*</label>
                                <br/>
                                <ul className={"radio-field"}>
                                    <li>
                                        <input ref={register({required: true})} style={{width: '24px'}} type="radio"
                                               name={"received_extensions"} id={"received_extensions_yes"} value="Yes"/><label
                                        htmlFor={"received_extensions_yes"}>Yes</label>
                                    </li>
                                    <li>
                                        <input ref={register({required: true})} style={{width: '24px'}} type="radio"
                                               name={"received_extensions"} id={"received_extensions_no"}
                                               value="No"/><label htmlFor={"received_extensions_no"}>No</label>
                                    </li>
                                </ul>
                                {errors?.received_extensions &&
                                    <span className="error-message">Make a selection.</span>}
                            </div>
                        </fieldset>

                        <fieldset className="input-field-group gaps">

                            <InputSelect ref={register({required: true})} label={'What is the current exit strategy?*'}
                                         name={'exit_strategy'} config={[
                                {
                                    name: "List to Sell",
                                    value: "List to Sell"
                                },
                                {
                                    name: "Under Contract",
                                    value: "Under Contract"
                                },
                                {
                                    name: "Refinance with Lima One Capital",
                                    value: "Refinance with Lima One Capital"
                                },
                                {
                                    name: "Refinance with another lender",
                                    value: "Refinance with another lender"
                                }
                            ]} errors={errors}/>

                            <InputSelect ref={register({required: true})} label={'What is the reasoning for the delay?*'}
                                         name={'reason_for_delay'} config={[
                                {
                                    name: "Cash Flow Issues",
                                    value: "Cash Flow Issues"
                                },
                                {
                                    name: "Change in SOW",
                                    value: "Change in SOW"
                                },
                                {
                                    name: "Contractor Issues",
                                    value: "Contractor Issues"
                                },
                                {
                                    name: "Damage to Property",
                                    value: "Damage to Property"
                                },
                                {
                                    name: "Labor Shortages",
                                    value: "Labor Shortages"
                                },
                                {
                                    name: "Permitting Delays",
                                    value: "Permitting Delays"
                                },
                                {
                                    name: "Selling/Refi Delays",
                                    value: "Selling/Refi Delays"
                                },
                                {
                                    name: "Stop Work Order",
                                    value: "Stop Work Order"
                                },
                                {
                                    name: "Supply Chain Issues",
                                    value: "Supply Chain Issues"
                                },
                                {
                                    name: "Unexpected Costs",
                                    value: "Unexpected Costs"
                                }
                            ]} errors={errors}/>

                            <InputText ref={register({required: false})} type={'text'} label={'Elaborate on Delay'}
                                       name={'delay_elaboration'} errors={errors}/>

                            <InputDate ref={register({required: false})} style={{width: '50%'}}
                                       label={'If you are experiencing construction delays, when is the expected completion date?'}
                                       name={'expected_completion_date'} errors={errors}/>

                            <div style={{width: '50%'}}></div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                top: '1.5em',
                                paddingBottom: '1.5em'
                            }}>
                                Please provide proof of up-to-date property insurance and a paid receipt for that
                                policy. The expiration of the policy must extend through the extended maturity
                                date.<br/>
                                <br/>
                                <span><u>Policy Requirements</u></span>
                                New Construction: Builder's Risk Policy<br/>
                                Rehab: Hazard Policy<br/>
                                Flood (If necessary)<br/>

                                <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px'}}
                                     className={errors?.risk_hazard_policy ? "fileError" : ""}>
                                    <div style={{width: '200px'}}>Builder's Risk/Hazard Policy*:</div>
                                    <input ref={register({required: true})} type="file" accept={fileTypes} name={"risk_hazard_policy"}
                                           style={fileInputStyle} multiple onChange={checkFileSize}/>
                                </div>
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px'}}
                                     className={errors?.risk_policy_paid_receipt ? "fileError" : ""}>
                                    <div style={{width: '200px'}}>Risk Policy Paid Receipt*:</div>
                                    <input ref={register({required: true})} type="file" accept={fileTypes}
                                           name={"risk_policy_paid_receipt"} style={fileInputStyle} multiple onChange={checkFileSize} />
                                </div>
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px'}}
                                     className={errors?.flood_policy ? "fileError" : ""}>
                                    <div style={{width: '200px'}}>Flood Policy (if necessary):</div>
                                    <input ref={register({required: false})} type="file" accept={fileTypes} name={"flood_policy"}
                                           style={fileInputStyle} multiple onChange={checkFileSize} />
                                </div>
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px'}}
                                     className={errors?.flood_policy_paid_receipt ? "fileError" : ""}>
                                    <div style={{width: '200px'}}>Flood Policy Paid
                                        Receipt{needFloodPolicyReceipt ? "*" : ""}:
                                    </div>
                                    <input ref={register({required: needFloodPolicyReceipt})} type="file" accept={fileTypes}
                                           name={"flood_policy_paid_receipt"} style={fileInputStyle} multiple onChange={checkFileSize} />
                                </div>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                top: '3.5em',
                                paddingBottom: '1.5em',
                                width: '100%'
                            }}>
                                Please provide up-to-date property tax information, showing the property tax obligation
                                is paid in full or a receipt of paid taxes.<br/>
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: '20px'}}
                                     className={errors?.property_tax ? "fileError" : ""}>
                                    <div style={{width: '200px'}}>Property Tax Information*:</div>
                                    <input ref={register({required: true})} type="file" accept={fileTypes} name={"property_tax"}
                                           style={fileInputStyle} multiple onChange={checkFileSize}/>
                                </div>
                            </div>
                        </fieldset>

                        <br/>
                        <br/>
                        <br/>
                        <br/>


                        <fieldset className="input-field-group gaps">
                            <div className={`input-field full-width ${errors?.monthly_payments ? "error" : ""}`}>
                                <label htmlFor={"monthly_payments"} className={"m-3px"}>Are you up to date on your monthly payments?*</label>
                                <br/>
                                <ul className={"radio-field"}>
                                    <li>
                                        <input ref={register({required: true})} style={{width: '24px'}} type="radio"
                                               name={"monthly_payments"} id={"monthly_payments_yes"} value="Yes"/><label
                                        htmlFor={"monthly_payments_yes"}>Yes</label>
                                    </li>
                                    <li>
                                        <input ref={register({required: true})} style={{width: '24px'}} type="radio"
                                               name={"monthly_payments"} id={"monthly_payments_no"} value="No"/><label
                                        htmlFor={"monthly_payments_no"}>No</label>
                                    </li>
                                </ul>
                                {watchMonthlyPayments === "No" &&
                                    <span style={{display: 'inline', color: 'red', marginLeft: '0.5rem'}}>An extension will not receive approval unless the monthly payments are up to date.</span>
                                }
                            </div>

                            <div className="input-field-group">
                                During the processing of your application, your Servicing Analyst will reach out within
                                1 to 2 weeks to discuss the terms of the extension fee, any additional documents, or
                                signatures needed to complete the extension.
                                <fieldset className="input-field-group gaps">
                                    <div className={`input-field full-width`}>
                                        <label htmlFor={"extension_fee"} className={"m-3px"}>Do you agree to satisfy the extension fee upon approval?</label>
                                        <br/>
                                        <ul className={"radio-field"}>
                                            <li>
                                                <input ref={register({required: true})} type="checkbox"
                                                       name={"extension_fee"} id={"extension_fee"} value="Yes"/><label
                                                htmlFor={"price_reductions_30_days_yes"}>Agree</label>
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>

                            </div>
                        </fieldset>


                        {watchExitStrategy === "List to Sell" &&
                            <Selling
                                submitted={submitted}
                                errors={errors}
                                success={success}
                                ref={register({required: true})}
                                notReqRef={register({required: false})}
                                fileInputStyle={fileInputStyle}
                                watch={watch}
                            >
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: '20px'}}
                                     className={errors?.property_tax ? "fileError" : ""}>
                                    <div style={{width: '440px'}}>If the property is not listed, please provide the
                                        Listing Agreement{watchLinkListing ? "*" : ""}:
                                    </div>
                                    <input ref={register({required: !watchLinkListing})} type="file" accept={fileTypes}
                                           name={"listing_agreement"} style={fileInputStyle} onChange={checkFileSize} />
                                </div>
                            </Selling>
                        }

                        {watchExitStrategy === "Under Contract" &&
                            <>
                                <h2>If the property is currently under contract, please fill out the following</h2>
                                <div className={"input-field-group gaps"}>
                                    <InputDate ref={register({required: true})}
                                               label={'When is the expected closing date?'}
                                               name={'expected_closing_date'} errors={errors}/>
                                    <InputText ref={register({required: true})} type={'text'}
                                               label={'Has the closing date been delayed? If so, what is causing the delay?*'}
                                               name={'contract_reason_for_delay'} errors={errors}/>
                                    <div style={{
                                        display: 'flex',
                                        width: '100%',
                                        top: '1.5em',
                                        paddingBottom: '1.5em',
                                        alignItems: 'center'
                                    }} className={errors?.sellers_agreement ? "fileError" : ""}>
                                        Please provide a copy of the Seller's Agreement*:
                                        <input ref={register({required: true})} type="file" accept={fileTypes} name={"sellers_agreement"}
                                               style={{...fileInputStyle, left: '1rem'}} multiple  onChange={checkFileSize} />
                                    </div>
                                </div>
                            </>
                        }

                        {(watchExitStrategy === "Refinance with another lender" || watchExitStrategy === "Refinance with Lima One Capital") &&
                            <Refinancing
                                submitted={submitted}
                                errors={errors}
                                success={success}
                                ref={register({required: true})}
                                fileInputStyle={fileInputStyle}
                                fileTypes={fileTypes}
                                withLO={watchExitStrategy === "Refinance with Lima One Capital"}
                            />
                        }


                        <div className="row" style={{marginTop: '2rem'}}>
                            Approval for an extension may take approximately 1 to 2 weeks. Your Servicing Analyst will
                            be in contact with you for any additional information needed to complete the extension
                            process once approved.
                            <br/>
                            <br/>
                            <ButtonSubmit readOnly={submitted}
                                          disabledIf={window.localStorage.getItem("employeeToken")}/>
                            {success === false ? "An error ocurred during submission.  Please try again later." : ""}
                        </div>

                    </div>
                </form>
            </main>
        )
}

export default withRouter(ExtensionRequest)