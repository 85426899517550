import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import './index.scss';
import animations from '../../../../../animations';
import { useSpring, animated } from '@react-spring/web';
import {useRouter} from "../../useRouter";


// Expandable NavLink for main menu.  

// Accepts the following props:
// 1. loans - array of loan objects
// 2. text - button/NavLink text
// 3. url - base url structure for NavLinks (dynamically replaces :id with loan id)

const NavLinkExpandable = props => {
    const [expanded, setExpanded] = useState(false);
    const router = useRouter();

    // declare props and their fallbacks
    const url = props.url || '/dashboard/deal/:id'; // url structure for loan NavLinks
    const loans = props.loans || []; // array of loans
    const text = props.text || 'Click to Expand'; // button/NavLink text 
    const icon = props.icon || 'icon'; // icon for button/NavLink

    useEffect(() => {
        setExpanded(false);
    }, [router.location]);

    const closeNav = () => {
        if (props.toggleNav) props.toggleNav();
        setExpanded(false)
    }

    const toggle = (e) => {
        e.stopPropagation(); // stop bubbling of child click events (prevents toggle of 'expanded' on NavLink clicks)
        if (e.target.classList.contains('link-expandable')) {
            setExpanded(!expanded);
        }
    };

    const styleExpanded = useSpring(animations("fadeIn", expanded))

    return (

        <button role="button" onClick={toggle} className={`nav-item link-expandable ${expanded ? 'expanded' : ''}`}>
            <div>
                <span className="button-icon">{icon}</span>
                <span>{`${text} (${loans.length})`}</span>
                <span className="toggle-icon"><FontAwesomeIcon icon={faChevronDown} /></span>
            </div>

            {expanded ?
                <animated.ul style={styleExpanded}>
                    {
                        loans.length > 0 ?
                            loans.map(loan => {
                                if(loan?.id) {
                                    return (
                                        <li onClick={closeNav}>
                                            <NavLink to={url.replace(':id', loan.id)} activeClassName="is-active" exact>
                                                {loan.nickname || loan.address || '-'}
                                            </NavLink>
                                        </li>
                                    )
                                }
                            })
                            :
                            <li> <i>No loans currently available</i> </li>
                    }
                </animated.ul>
                : null}
        </button>

    );
};

export default withRouter(NavLinkExpandable)