import React, { useState, createContext, useEffect } from 'react';

export const DealContext = createContext();


export const DealProvider = ({ children }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        if (window.localStorage.getItem('dealContext')) {
            setData(window.localStorage.getItem('dealContext'));
        }
    }, [])

    return (
        <DealContext.Provider value={[data, setData]}>
            {children}
        </DealContext.Provider>
    )
}