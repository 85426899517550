import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './index.scss';

const SkeletonTable = (props) => {

    const count = props.count || 5;

    return (
        <React.Fragment>
            <SkeletonTheme color="#F1F1F1" highlightColor="white">
                <div style={{ position: "relative" }}>
                    <Skeleton height="3.25rem" duration={1} />
                </div>
            </SkeletonTheme>

            <div className="skeleton-body">
                <Skeleton height={"2.25rem"} duration={1} style={{ margin: "0.1rem 0rem !important", display: "block" }} count={count} />
            </div>
        </React.Fragment>
    )
}

export default SkeletonTable