import React from 'react';
import TransitionIn from '../../App/components/shared/TransitionIn';

const AppMulti = () => {
    return (
        <TransitionIn content={
            <main className="form">
                <div className="form-body">
                    <section className="section">
                        <div className="section_heading">
                            <h1>Application | Multifamily</h1>
                            <p>To submit an application please call <a href="tel:18003904212">1-800-390-4212</a> or your Sales Representative.</p>
                        </div>
                    </section>
                </div>
            </main>
        } />
    )
}

export default AppMulti