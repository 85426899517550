import React from 'react';
import jsonexport from "jsonexport/dist";
import RequestDrawNew from '../ConstructionRequestDrawNew';
import './index.scss';
import currency from 'currency.js';
import {faDownload, faSpinner} from '@fortawesome/free-solid-svg-icons'
import { SectionExpandable } from '../../../../../../components/shared/Section';
import ReactTooltip from 'react-tooltip';
import Basic from '../../../../../../components/shared/Section/controls/Basic';
import TransitionIn from '../../../../../../components/shared/TransitionIn';
import CopyTable from '../../../../../../components/shared/Section/controls/CopyTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ConstructionDraws extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDraw: this.props.intitialCurrentDraw,
      drawActive: this.props.dealDrawActive,
      drawActiveInitial: true
    };

    this.downloadDraws = this.downloadDraws.bind(this);
    this.clearDraw = this.clearDraw.bind(this);
    this.tableRef = React.createRef();
    this.copiedNotificationRef = React.createRef();
  };

  componentDidMount() {
    ReactTooltip.rebuild();
  };

  showDraw(items, active) {
    if (active === this.state.drawActive) {
      this.setState({
        currentDraw: [],
        drawActive: null,
        drawActiveInitial: true
      })
    } else {
      this.setState({
        drawActiveInitial: false,
        currentDraw: items,
        drawActive: active
      });
    }
  };

  clearDraw() {
    this.setState({
      currentDraw: []
    });
  };

  downloadDraws() {
    const draws = this.props.dealDraws;

    let _this = this;
    let _drawsFiltered = draws.filter(x => x.status !== ""); // filter to only contain approved draws for download
    let _draws = _drawsFiltered.map(item => {
      const container = {};
      container.date = item.date;
      container.items = item.items.filter(y => y.value > 0);
      container.items = container.items.map(x => {
        return { code: x.code, name: x.name, amount: x.value }
      });
      return container;
    })

    jsonexport(_draws, { rename: ["Date", "Code", "Description", "Amount"] }, function (err, csv) {
      if (err) return console.error(err);

      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = `Draws ${_this.props.dealDraws[0].projectName}.csv`;
      hiddenElement.click();
    });
  }

  getPreviousTotalSpent = code => {
    let total = 0;

    for (var i = 0; i <= this.state.drawActive; i++) {
      this.props.dealDraws[i].sublots.forEach(sublot => {
        sublot.items.forEach(item => {
          if (item.code == code) {
            if (item.value) {
              total += parseFloat(item.value);
            };
          };
        })
      })

      // this.props.dealDraws[i].items.forEach(item => {
      //   if (item.code == code) {
      //     if (item.value) {
      //       total += parseFloat(item.value);
      //     };
      //   };
      // });
    };

    return total;
  }

  // removed copy table (now included in shared component CopyTable)

  render() {
    const state = this.state;
    let remainingBalances = {};
    console.log("PROPS", this.props)
    return (
      <>
        <SectionExpandable flush
          class="draw-status section"
          heading="Draw Status"
          controls={
            <Basic
              disabled={this.props.dealDraws !== null && this.props.dealDraws.length > 0 ? false : true}
              callback={this.downloadDraws}
              iconDefault={faDownload}
              dataFor={"app-tooltip"}
              dataTip={"Download Draw History"}
            /> 
          }
          body={
            <div className="draw-status-box">

              <ul className="draw-icon-group">
                {
                  this.props.dealDraws && this.props.dealDraws.length > 0 ? this.props.dealDraws.map((draw, i) => {
                    return (
                      <li key={draw.date} dataKey={i} onClick={() => this.showDraw(draw.sublots, i)} className={`draw-icon ${this.state.drawActive === i || (this.props.dealDrawActive === i && this.state.drawActiveInitial) ? "is-active" : ""}`}>
                        <a href="#previous-draw-request">
                          <ul>
                            <li>
                              <img src={draw.status !== '' ? '/assets/images/circle_check.png' : '/assets/images/circle_gear.png'} alt="img" />
                            </li>
                            <li>{draw.projectName.split(" ")[0]}</li>
                            <li>{draw.date}</li>
                          </ul>
                        </a>
                      </li>
                    );
                  }) : (this.props.dealDraws === false) ?  <li style={{ padding: "1rem 0" }}>
                    <TransitionIn content={<p style={{ fontStyle: "italic" }}>No Previous Draws</p>} />
                  </li> : <li style={{ padding: "1rem 0" }}>
                    <TransitionIn content={this.props.loadingDeals ? (<p>Fetching draws... <FontAwesomeIcon icon={faSpinner} className={"spin"} /></p>) : this.props.serverError ? "Failed to load draws..." : "Draw statuses are not available."} />
                  </li>
                }
              </ul>
            </div>
          } />

        {
          (state.currentDraw.length > 0 && this.props.dealBudget) || (this.props.intitialCurrentDraw.length > 0 && this.props.dealBudget) ? (

            <SectionExpandable flush controls={<CopyTable tableRef={this.tableRef} />} heading="Previous Draw Request" id="previous-draw-request" body={

              <TransitionIn content={
                <div className={`construction-budget-items collapse-widget`} >

                  <div className="construction-budget-collapse">
                    <table ref={this.tableRef} className='table'>
                      <thead>
                        <tr>
                          <th className="item">
                            <span>
                              BUDGET ITEM
                            </span>
                          </th>
                          <th className="amount">
                            <span>
                              AMOUNT
                            </span>
                          </th>
                          <th className="balance">
                            <span>
                              REMAINING BALANCE
                            </span>
                          </th>
                          <th className="budget">
                            <span>
                              BUDGET
                            </span>
                          </th>
                        </tr>
                      </thead>

                      
                        {
                          state.currentDraw.length > 0 ? state.currentDraw.map((element, i) => {
                            return (
                              <tbody className="draw-table-body" data-sublot={element.sublot}>
                                  <tr className='sublot-title'>
                                      <td colSpan={4}><span>{element.sublotName}</span></td>
                                  </tr>

                              {element.items.map((lineItem, i) => {
                                  const lineItemFound = this.props.dealBudget.items.find(i => lineItem.code === i.code); // match line item to budget api response by code
                                  let budget = lineItemFound ? lineItemFound.budget : 0; // get budget if line item exists with corresonding budget in budget api response
                                  let remaining = 0; // declare remaining variable

                                  if (!budget) {
                                    remaining = currency(remaining).format();
                                    budget = currency(budget).format();
                                  }
                                  else {
                                    remaining = currency(budget - this.getPreviousTotalSpent(lineItem.code)).format();
                                    budget = currency(budget).format();
                                  }

                                  remainingBalances[lineItem.code] = remaining.replace(/,/g, "").replace(/\$/g, "");

                                  return (
                                    
                                    <tr key={lineItem.code}>
                                      <td>
                                        <span>
                                          {lineItem.name}
                                        </span>
                                      </td>
                                      <td>
                                        <span>
                                          {currency(lineItem.value).format()}
                                        </span>
                                      </td>
                                      <td>
                                        <span>
                                          {remaining}
                                        </span>
                                      </td>
                                      <td>
                                        <span>
                                          {budget}
                                        </span>
                                      </td>
                                    </tr>
                                  )
                              })}
                              </tbody>
                            )
                          }) : this.props.intitialCurrentDraw.map(element => {
                            return (
                              <tbody className="draw-table-body" data-sublot={element.sublot}>
                                  <tr className='sublot-title'>
                                      <td colSpan={4}><span>{element.sublotName}</span></td>
                                  </tr>
                              {element.items.map((lineItem, i) => {
                                const lineItemFound = this.props.dealBudget.items.find(i => lineItem.code === i.code); // match line item to budget api response by code
                                let budget = lineItemFound ? lineItemFound.budget : 0; // get budget if line item exists with corresonding budget in budget api response
                                let remaining = 0; // declare remaining variable

                                if (!budget) {
                                  remaining = currency(remaining).format();
                                  budget = currency(budget).format();
                                }
                                else {
                                  remaining = currency(budget - lineItem.value).format();
                                  budget = currency(budget).format();
                                }
                                remainingBalances[lineItem.code] = remaining.replace(/,/g, "").replace(/\$/g, "")
                                return (
                                  <tr key={lineItem.code}>
                                    <td>
                                      <span>
                                        {lineItem.name}</span>
                                    </td>
                                    <td>
                                      <span>
                                        {currency(lineItem.value).format()}</span>
                                    </td>
                                    <td>
                                      <span>
                                        {remaining}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {budget}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              })}
                              </tbody>
                            )
                          })
                        }
                    </table>
                  </div>
                </div>
              } />
            } />
          )
            : ""
        }
        <div>
          {(this.props.dealBudget.totalBalance <= 0) || (!this.props.dealDrawStatus) ? "" :
            <RequestDrawNew fetchDealDraws={this.props.fetchDealDraws} clearDraw={this.clearDraw} dealId={this.props.dealId} remainingBalances={remainingBalances} />
          }
        </div>
      </>
    )



  }
}

export default ConstructionDraws;