import React from 'react'
import InputSelect from '../_components/InputSelect'
import InputText from '../_components/InputText'
import companies from '../../../assets/config/_companies.json'
import states from '../../../assets/config/_us_states.json'
import InputTextMasked from "../_components/InputTextMasked";

const Borrower = React.forwardRef((props, ref) => {

    let borrower_id = props.watch('borrowing_entity_id')

    return (
        <>
            {/* ----- Select Borrowing Entity ----- */}
            <fieldset className="input-field-group row grow-2 select-borrower">

                {/* {props.broker && <SelectCustomer isLOC={isLOC} fd={fd} formSubmission={formSubmission} customers={state.customers} handleCustomersBE={handleCustomersBE} inputPhone={inputPhone} inputStates={inputStates} inputSelectMulti={inputSelectMulti} inputText={inputText} />} */}

                <div className="column">
                    {!props.broker &&
                        <InputSelect ref={props.notReqRef} config={props.borrowingEntities} text="New Entity"
                            label={'Select New or Existing Borrowing Entity*'} name={'borrowing_entity_id'}
                            tabIndex={props.tabIndex} errors={props.errors} onChange={props.selectBE} />
                    }
                </div>
            </fieldset>

            <hr />

            {/* ----- Borrowing Entity Name, Company Type, Business EIN ----- */}
            <fieldset className="input-field-group borrower-info-new">
                <div className="loc-borrower col-left">
                    <InputText ref={ref} fullwidth={true} type={'text'} label={'Borrowing Entity Name*'} name={'borrowing_entity_name'} tabIndex={props.tabIndex} errors={props.errors} />
                    <InputSelect ref={ref} fullwidth={true} config={companies} label={'Company Type*'} name={'company_type'} tabIndex={props.tabIndex} errors={props.errors} />
                    <InputTextMasked ref={ref} control={props.control} fullwidth={true} label={'Business EIN*'} errors={props.errors} mask="99-9999999" maskChar=" " type="tel" name="business_EIN" placeholder="XX-XXXXXXX" tabIndex={props.tabIndex} />
                </div>
                {/* ----- States Checkbox list ----- */}
                <div className="col-right" id="loc">
                    
                    <div className="states-checkbox">
                    <div className={`input-field full-width  ${'entity_registered_to_do_business_in_what_states' in props.errors ? 'error' : ''}`}>
                        <label htmlFor={`entity_registered_to_do_business_in_what_states`}>Registered to Do Business in What States*</label>
                        <ul>
                            {states.map(x => {
                                return (
                                    <li key={x.name}>
                                        <label>
                                            <input ref={ref} type="checkbox" name={`entity_registered_to_do_business_in_what_states`} value={x.name} />
                                            {x.name}
                                        </label>
                                    </li>
                                )
                            })}
                        </ul>
                        {'entity_registered_to_do_business_in_what_states' in props.errors && <span className="error-message">Registered to Do Business in What States* is required.</span>}
                    </div>
                    </div>
                </div>
            </fieldset>
        </>
    )

})

export default React.memo(Borrower)