import React from 'react'
import InputText from './InputText'
import InputTextMasked from "./InputTextMasked";

const ThirdPartyContacts = React.forwardRef((props, ref) => {

    return (
        <>
        {/* <br />
        <br /> */}
        {/* ----- Third Party Info ----- */}
        {props.application_type !== 'construction' &&
            <>
            <h1 className="heading-third-party first">Property Access Contact Information</h1>

            <div className="row grow-3">
                <div className="column">
                    <InputText ref={props.notReqRef} type={'text'} label={'Property Access Contact Name'} name={'Property_Access_Contact_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>
                <div className="column">
                    <InputText ref={props.notReqRef} type={'email'} label={'Property Access Contact Email'} name={'Property_Access_Contact_Email__c'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>
                <div className="column">
                    <InputTextMasked ref={props.notReqRef} control={props.control} label={'Property Access Contact Phone'} errors={props.errors} mask="999-999-9999" maskChar=" " type="tel" name="Property_Access_Contact_Phone__c" placeholder="XXX-XXX-XXXX" tabIndex={props.tabIndex} />
                </div>
            </div>
            </>
        }

        <h1 className="heading-third-party">Title Contact Information</h1>

        <div className="row">
            <div className="column">
                <InputText ref={props.notReqRef} type={'text'} label={'Title Company Name'} name={'Title_Company_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputText ref={props.notReqRef} type={'text'} label={'Title Contact Name'} name={'Title_Contact_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputText ref={props.notReqRef} type={'email'} label={'Title Contact Email'} name={'Title_Contact_Email__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputTextMasked ref={props.notReqRef} control={props.control} label={'Title Contact Phone'} errors={props.errors} mask="999-999-9999" maskChar=" " type="tel" name="Title_Contact_Phone__c" placeholder="XXX-XXX-XXXX" tabIndex={props.tabIndex} />
            </div>
        </div>

        <h1 className="heading-third-party">Insurance Contact Information</h1>

        <div className="row">
            <div className="column">
                <InputText ref={props.notReqRef} type={'text'} label={'Insurance Company Name'} name={'Insurance_Company_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputText ref={props.notReqRef} type={'text'} label={'Insurance Contact Name'} name={'Insurance_Contact_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputText ref={props.notReqRef} type={'email'} label={'Insurance Contact Email'} name={'Insurance_Contact_Email__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputTextMasked ref={props.notReqRef} control={props.control} label={'Insurance Contact Phone'} errors={props.errors} mask="999-999-9999" maskChar=" " type="tel" name="Insurance_Contact_Phone__c" placeholder="XXX-XXX-XXXX" tabIndex={props.tabIndex} />
            </div>
        </div>

        {props.application_type === 'rental' &&
        <>
            <h1 className="heading-third-party">Payoff Contact Information</h1>
            <div className="row">
                <div className="column">
                    <InputText ref={props.notReqRef} type={'text'} label={'Payoff Company Name'} name={'Payoff_Company_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>
                <div className="column">
                    <InputText ref={props.notReqRef} type={'text'} label={'Payoff Contact Name'} name={'Payoff_Contact_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>
                <div className="column">
                    <InputText ref={props.notReqRef} type={'email'} label={'Payoff Contact Email'} name={'Payoff_Contact_Email__c'} tabIndex={props.tabIndex} errors={props.errors} />
                </div>
                <div className="column">
                    <InputTextMasked ref={props.notReqRef} control={props.control} label={'Payoff Contact Phone'} errors={props.errors} mask="999-999-9999" maskChar=" " type="tel" name="Payoff_Contact_Phone__c" placeholder="XXX-XXX-XXXX" tabIndex={props.tabIndex} />
                </div>
            </div>
        </>
        }

        <h1 className="heading-third-party">HOA Contact Information (If Applicable)</h1>

        <div className="row">
            <div className="column">
                <InputText ref={props.notReqRef} type={'text'} label={'HOA Company Name'} name={'HOA_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputText ref={props.notReqRef} type={'text'} label={'HOA Contact Name'} name={'HOA_Contact_Name__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputText ref={props.notReqRef} type={'email'} label={'HOA Contact Email'} name={'HOA_Contact_Email__c'} tabIndex={props.tabIndex} errors={props.errors} />
            </div>
            <div className="column">
                <InputTextMasked ref={props.notReqRef} control={props.control} label={'HOA Contact Phone'} errors={props.errors} mask="999-999-9999" maskChar=" " type="tel" name="HOA_Contact_Phone__c" placeholder="XXX-XXX-XXXX" tabIndex={props.tabIndex} />
            </div>
        </div>
        </>
    )

})

export default React.memo(ThirdPartyContacts)