import React from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import { Demo, RouteBasic, RouteVariable, RouteUnprotected, } from '../Wrappers';
import CreateAccount from './CreateAccount';
import NavBarLegacy from '../../components/unprotected/NavBarLegacy';
import FourOhFour from '../RoutesProtected/FourOhFour';
import ThankYouApp from '../../../routes/app-thankyou';
import SignUp from '../../../routes/signup';
import SignUpTwo from '../../../routes/signuptwo';
import BrokerSignUp from '../../../routes/brokersignup';
import SignUpEmailSent from '../../../routes/signupemailsent';
import SetPassword from '../../../routes/setpassword';
import BrokerSignUpTwo from '../../../routes/brokersignuptwo';
import ForgotPassword from './ForgotPassword/enteremail'
import ChangePassword from './PasswordReset';
import AdditionalGuarantorForm from '../../../components/_applications/_additionalGuarantor';
import ThankYouGuarantor from '../../../routes/ThankYouGuarantor';
import SignUpThankYou from './Signup_ThankYou';
import { Base, WhiteLabelApplications, AccountAccess, Min, AccountAccessGeneric } from '../Layouts';
import Application_Construction from "../../../components/_applications/_construction";
import Application_Rental from "../../../components/_applications/_rental";
import Application_FixNFlip from "../../../components/_applications/_fixnflip";
import Application_LOC from "../../../components/_applications/_loc";
import SignupRF from './Signup';
import AccountSetupPassword from './AccountSetup';
import ApplicationPayoffRequest from './ApplicationPayoffRequest';
// import ThankYouWhiteLabel from './ThankYouWhiteLabel';
import LossMitigation from '../../../components/_applications/_servicing/_lossMitigation';

export default function RoutesUnprotected(props) {

    // temporary fix until route layouts updated (hide nav on white labels)
    const whitelabel = function () {
        if (
            window.location.pathname.includes("/line-of-credit") ||
            window.location.pathname.includes("rental") ||
            window.location.pathname.includes("/fix-n-flip") ||
            window.location.pathname.includes("new-construction")
        ) {
            return true
        }
    };

    return (
        <>
            {
                // temp fix - see function above
                whitelabel() ? null :
                    <div>
                        <NavBarLegacy />
                    </div>
            }

            <Route path="/">
                <Switch>
               
                    <RouteUnprotected exact={true}
                        path={"/"}
                        {...props}
                        component={LoginForm}
                        layout={AccountAccess}
                        layoutVariant={"centered"}
                    />

                    {/* Non-session broker routes */}
                    <RouteBasic layout={WhiteLabelApplications}
                        layoutID={"broker-signup"}
                        exact={true}
                        path={"/broker/signup"}
                        component={BrokerSignUp}
                                title={"Broker Signup"}
                        {...props} />

                    <RouteBasic layout={AccountAccess} path="/broker/account-setup/:token" component={BrokerSignUpTwo} {...props} />

                    {/* Signup routes */}
                    <RouteBasic path={"/signup"}
                        exact={true}
                        layout={AccountAccess}
                        component={SignupRF}
                                title={"Signup"}
                        {...props} />

                    <RouteBasic path="/signup/:brokeremail"
                        layout={AccountAccess}
                        component={SignupRF}
                        {...props} />

                    <RouteBasic layout={WhiteLabelApplications} path="/signup-setpassword" title="Set Password" component={SignUpEmailSent} {...props} />
                    <RouteBasic layout={AccountAccess} layoutVariant="centered" path="/signup-thankyou" title="Thank You" component={SignUpThankYou} {...props} info={''} />

                    {/* Thank you routes */}
                    <RouteBasic layout={Min} exact={true} path={"/thank-you/:type"} title={"Thank You"} component={ThankYouApp} {...props} />
                    <RouteBasic layout={Base} path="/thankyou/additional-guarantor" title={"Thank You"} component={ThankYouGuarantor} {...props} />

                    {/* Create account routes */}
                    <RouteBasic
                        layout={AccountAccess}
                        path="/create-account/:email"
                        component={CreateAccount}
                        layoutVariant={"centered"}
                        title={"Create Account"}
                        {...props} />

                    <RouteBasic
                        layout={AccountAccess}
                        layoutVariant={"centered"}
                        path="/create-account"
                        component={CreateAccount}
                        title={"Create Account"}
                        {...props} />

                    {/* Password setting routes */}
                    <RouteBasic path="/account-setup/:token"
                        layout={AccountAccess}
                        component={AccountSetupPassword}
                                title={"Account Setup"}
                        {...props} />

                    <RouteBasic layout={Min} path="/set-password/:token" title="Set Password" component={SetPassword} {...props} />

                    <RouteBasic
                        layout={AccountAccess}
                        layoutVariant={"centered"}
                        path="/forgot-password"
                        component={ForgotPassword}
                        title={"Forgot Password"}
                        {...props} />

                    <RouteBasic
                        layout={AccountAccess}
                        layoutVariant={"centered"} path="/change-password/:token" title={"Change Password"} component={ChangePassword} {...props} />

                    {/* Misc routes */}
                    <RouteBasic path="/additional-guarantor-form/:invitee_id" component={AdditionalGuarantorForm} layout={WhiteLabelApplications} title={"Additional Guarantor Form"} whitelabel={true} {...props} />

                    {/* White label application routes */}
                    <RouteBasic path={`/applications/line-of-credit/:broker`} component={Application_LOC} layout={WhiteLabelApplications} title={"Line of Credit Application"} whitelabel={true} {...props} />
                    <RouteBasic path={`/applications/fix-n-flip/:broker`} component={Application_FixNFlip} layout={WhiteLabelApplications} title={"Fix & Flip Application"} whitelabel={true} {...props} />
                    <RouteBasic path={`/applications/rental/:broker`} component={Application_Rental} layout={WhiteLabelApplications} title={"Rental Application"} whitelabel={true} {...props} />
                    <RouteBasic path={`/applications/new-construction/:broker`} component={Application_Construction} layout={WhiteLabelApplications} title={"New Construction Application"} whitelabel={true} {...props} />

                    <RouteBasic path={`/applications/payoff-request`} component={ApplicationPayoffRequest} layout={WhiteLabelApplications} external={true} {...props} />
                    <RouteBasic path={`/loss-mitigation`} component={LossMitigation} layout={WhiteLabelApplications} {...props} />

                    {/* 404 route */}
                    <RouteBasic layout={AccountAccessGeneric} path component={FourOhFour} {...props} title="Resource Not Found" />
                </Switch>
            </Route>
        </>
    )
}