import React from 'react';
import './index.scss';
import currency from 'currency.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import TransitionIn from '../../../../../../../../components/shared/TransitionIn';

function PaymentEntry(props) {
    console.log('lizzoans', props)
    const [expanded, setExpanded] = React.useState(false);

    const format = (x) => currency(x).format() || '-';

    const amount = (value) => {
        if (!isNaN(value)) {
            return format(value);
        } else {
            return '-'
        };
    }

    const formattedDate = (inputDate) => {
        if (inputDate) {
            var splitDate = inputDate.split('-'); // expects yyyy-mm-dd
            if (splitDate.count == 0) {
                return null;
            }

            var year = splitDate[0];
            var month = splitDate[1];
            var day = splitDate[2];

            return month + '/' + day + '/' + year;
        } else return '-'
    }

    const payment = props.accountHistory;

    const getOtherPayment = () => {
        let amountPayment = 0; // initial value

        // creating an array of the payment components/parts to find the largest absolute value
        const paymentParts = [
            payment.To_Charges_Principal,
            payment.To_Charges_Interest,
            payment.Late_Charge,
            payment.To_Late_Charge,
            payment.To_Impound,
            payment.To_Reserve,
            payment.To_Prepay,
            payment.To_Interest,
            payment.To_Principal,
            payment.To_Current_Bill,
            payment.To_Other_Payments,
            payment.To_Unpaid_Interest,
            payment.Late_Charge];

        // positive total amount...
        if (payment.Total_Amount > 0) {
            amountPayment = payment.Total_Amount // if to past due is positive use this
        } else {
            if (payment.Source_Type === "RegPmt" || payment.Source_Type === "NSF" || payment.Source_Type === "MAN" || payment.Source_Type === "MANR") {

                if (payment.Reference === "") {
                    console.log("TEST PMT", payment)
                    amountPayment = + parseFloat(payment.To_Interest) + parseFloat(payment.To_Impound) + parseFloat(payment.To_Principal)
                } else amountPayment = payment.Total_Amount;

            } else if (payment.Reference === "WAIVE") {
                paymentParts.forEach(x => {
                    if (!(x === "0.0" || x == "0.00")) {
                        amountPayment = x
                    }
                });
            } else {
                const amountOther = Math.max(...paymentParts); // else grab the largest value from the remaining payment parts

                if (amountOther > 0) {
                    amountPayment = amountOther; // set it here if max part is > 0
                } else {
                    amountPayment = 0; // or just return
                }
            }
        }

        return amountPayment;
    }

    // below could be cleaned up with a switch...
    const getOtherStatusType = () => {

        // handle all cases not "Oth"
        if (payment.Source_Type !== "Oth") {

            // handle regular payments 
            if (payment.Source_Type === 'RegPmt') {

                if (payment.Reference === "PMT ADV") {
                    return "Payment Advance";
                };

                if (payment.Reference === "IR") {
                    return "Regular Payment – IR"
                }

                else return "Regular Payment";
            };

            if (payment.Source_Type === "Adj-LC") {
                return "Late Charge (Waived)";
            };

            if (payment.Source_Type === "PMT ADV") {
                return "Payment Advance";
            }

            if (payment.Source_Type === 'LateCh') {
                return "Late Charge";
            }

            if (payment.Source_Type === 'NSF') {
                return "Insufficient Funds";
            }

            if (payment.Source_Type === 'MANR') {
                return "Reversed Payment";
            }

            if (payment.Source_Type === 'MAN') {
                return "Regular Payment";
            }

            if (payment.Source_Type === 'WC') {
                return "Regular Payment";
            }

            else return payment.Source_Type;
        };

        if (payment.Source_Type === "Oth") {
            // if (payment.Reference === 'LOAN MOD') {
            //     return "Loan Modification";
            // }

            if (payment.Reference === 'IR') {
                return "Regular Payment – IR";
            }

            // if (payment.Reference === 'CHECK') {
            //     return "Check";
            // }

            // if (payment.Reference === 'WAIVE') {
            //     return "Late Charge (Waived)"
            // }

            // if (payment.Reference === 'LC') {
            //     return 'Late Charge (Paid)'
            // }

            // if (payment.Reference === 'WC') {
            //     return "Regular Payment";
            // }

            // if (payment.Reference !== "") {
            //     return payment.Reference
            // }

            // else return "Other"

            else return "Suspense Reserves"
        };
    };

    const lateCharge = (x) => {
        if (x.Late_Charge !== 0) {
            return format(x.Late_Charge)
        } else if (x.To_Late_Charge !== 0) {
            return format(x.To_Late_Charge)
        } else return "0.00"
    };

    return (
        <li className="payment-history-entry">
            <button className="payment-history-entry_heading" onClick={() => setExpanded(!expanded)}>
                <span className="inner"> {getOtherStatusType()} </span>
                {/* {
                    process.env.REACT_APP_DEV_MODE ?
                        <span style={{ color: "red" }}> {payment.Source_Type} - {payment.Reference || "n/a"} </span>
                        : null
                } */}
                <span className="inner"> {formattedDate(props.accountHistory.Date_Received)} </span>
                <span className="inner"> {amount(getOtherPayment())} </span>
                <FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '.25em', transform: `rotate(${expanded ? '180deg' : '0deg'})` }} />
            </button>

            {expanded ?
                <TransitionIn content={
                    <div className="data">
                        <div className="data_inner">
                            <table className='table'>
                                <tbody>

                                    <tr>
                                        <th>
                                            <span>
                                                Due Date
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {formattedDate(props.accountHistory.Due_Date) || '-'}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            <span>
                                                Principal
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {format(props.accountHistory.To_Principal)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <span>
                                                Interest
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {format(props.accountHistory.To_Interest)}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            <span>
                                                Late Charges
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {lateCharge(props.accountHistory)}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            <span>
                                                Tax & Insurance Impound
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {format(props.accountHistory.To_Impound)}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            <span>
                                                Reserve
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {format(props.accountHistory.To_Reserve)}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            <span>
                                                Loan Charges
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {format(props.accountHistory.To_Charges_Principal)}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            <span>
                                                Prepayment Penalty
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {format(props.accountHistory.To_Prepay)}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            <span>
                                                Other Payments
                                            </span>
                                        </th>
                                        <td>
                                            <span>
                                                {format(props.accountHistory.To_Other_Payments)}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            <span>
                                                Loan Balance</span>
                                        </th>
                                        <td>
                                            <span>
                                                {format(props.accountHistory.Loan_Balance)}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                } />
                : null}
        </li>
    )
}

export default PaymentEntry