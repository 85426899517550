import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { useSpring, animated } from '@react-spring/web';
import animations from '../../../../../animations';
import './index.scss';

const Basic = props => {

    const [active, setActive] = useState(false);

    const dataTip = props.dataTip;
    const dataFor = props.dataFor;

    // transistion animation and conditional styles
    const styleActive = useSpring(animations("toggleInStatusGreen", active));
    const styleDefault = useSpring(animations("toggleInStatusDefault", active))
    const styleActiveBG = useSpring({
        background: active ? "#038B57" : "white"
    });

    const iconDefault = props.iconDefault || faCheckCircle;
    const iconActive = props.iconActive || faCheckCircle;

    const callback = () => {
        if (props.callback) {
            props.callback()
        } else return false;
    };

    const handleClick = () => {
        if (active) return;

        callback();

        setActive(true);

        setTimeout(() => {
            setActive(false);
        }, 2000);

    };


    return (
        <React.Fragment>

            <animated.button className={`control-basic ${active ? "is-disabled" : ""}`}
                onClick={() => handleClick()}
                data-tip={dataTip}
                data-for={dataFor}
                disabled={props.disabled || false}
                style={styleActiveBG}>
                    
                {
                    active ?
                        <animated.div style={styleActive}>
                            <FontAwesomeIcon icon={iconActive} />
                        </animated.div>
                        :
                        <animated.div style={styleDefault}>
                            <FontAwesomeIcon icon={iconDefault} />
                        </animated.div>
                }

            </animated.button>

        </React.Fragment>
    )
};



export default Basic;