import React, { Fragment, useRef } from 'react';
import { SectionExpandable } from '../../../../../../components/shared/Section';
import CopyTable from '../../../../../../components/shared/CopyTable';
import TransitionIn from '../../../../../../components/shared/TransitionIn';
import { log } from '../../../../../../utility';
import util from '../../../../../../utility/data';
import SkeletonTable from '../../../../../../components/shared/SkeletonTable';
import animations from '../../../../../../animations';
import { useSpring, animated } from '@react-spring/web';
import './index.scss';

export default function Members(props) {

    const data = props.members;

    // clean up data - replace empty fields with "-"
    if (data) {
        data.forEach(member => {
            member.name = member.firstname + " " + member.lastname
        })
        util.formatReplace(data, "-");
    };

    const tableRef = useRef();

    const animation = "fadeIn";
    const styleLoaded = useSpring(animations(animation, data?.length > 0 ? true : false));

    const heading = (
        <Fragment>
            Deal Members ({data?.length || 0})
        </Fragment>
    )

    const controls = (
        <Fragment>
            <CopyTable tableRef={tableRef} />
        </Fragment>
    )

    // - section body error 
    const bodyError = (
        <span ref={tableRef} className="error-message">
            There was a problem loading data.
        </span>
    );

    const body = (

        // - loading animation
        data === null ?

            <SkeletonTable count={2} />

            // - table with data
            : data.length > 0 ?

                // - note the ref below - needed to copy this table
                <animated.table id="table-deal-members" ref={tableRef} className="table" style={styleLoaded}>

                    <thead>
                        <tr key="table-heading">
                            <th>
                                <span>Name</span>
                            </th>
                            <th>
                                <span>Email</span>
                            </th>
                            <th>
                                <span>Role</span>
                            </th>
                            <th>
                                <span>Status</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            data.length > 0 ?
                                data.map(member => {
                                    return (
                                        <tr key={member.first + member.id}>
                                            <td>
                                                <span>
                                                    {member.name}
                                                </span>
                                            </td>
                                            <td>
                                                <a href={`mailto:${member.email}`} target="_blank">
                                                    {member.email}
                                                </a>
                                            </td>
                                            <td>
                                                <span>
                                                    {member.role}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {member.status}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr key={"no-results"}>
                                    <td>
                                        <span>
                                            No results found.
                                        </span>
                                    </td>
                                </tr>
                        }
                    </tbody>

                </animated.table>

                // - error messaging
                : bodyError
    )


    return (
        <TransitionIn content={
            <SectionExpandable heading={heading} controls={controls} body={body} animate={"fadeInDown"} flush noGrow />
        } />
    )
}

