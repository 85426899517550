import React from 'react';
import { log } from '../../../../../utility';
import ReactTooltip from 'react-tooltip';

export default function LOCUploadDocs(props) {

    const LOCs = props.recentLOCs && props.recentUnderwritings ? [...props.recentLOCs, ...props.recentUnderwritings] : null;

    // if no LOC exists, exit with no render
    if (!LOCs || LOCs.length === 0) return null;

    // else if LOCs do exist, evaluate both the Percentage Complete and Checklist item doc upload status
    // checlist items incomplete evaluation (sub filter as part of the main filter)

    const evaluateDocumentsUploaded = item => {
        if (item?.checklistItems) {
            // filter the checklist items by Has_Attachment__c equals false (false == outstanding/incomplete)
            const checklistItemDoc = item.checklistItems.filter(item => {
                return item.Has_Attachment__c === false;
            })

            // if any checklist items are outstanding, overall evaulation of LOC is true
            if (checklistItemDoc.length === 0) {
                return false
            } else {
                return true;
            }
        }
    };

    // combine the evaluateDocumentsUploaded function above and the additional Percent_Checklist_Secondary_Complete__c check to filter LOCs
    const evaluateLOCs = (arr) => {
        return arr.filter(x =>
            !(x.Percent_Checklist_Secondary_Complete__c === 100 ||
                x.Percent_Checklist_Secondary_Complete__c === '100' ||
                x.LOC_Percent_Complete__c === 100 ||
                x.LOC_Percent_Complete__c === '100' ||
                x.Portal_Status__c === "Approved")
            && evaluateDocumentsUploaded(x))
    }

    // create the rows to render if applicable - link to corresponding item in LOC list section
    const rows = evaluateLOCs(LOCs).map(x => {
        return (
            <tr>
                <td>
                    <span>
                        Borrower Application for {x.borrower?.Borrowing_Entity_Name__r?.Name}
                    </span>
                </td>
                <td>
                    <span>
                        -
                    </span>
                </td>
                <td>
                    <a href={`/dashboard#checklist-${x.borrower.Id}`} onClick={() => document.getElementById('checklist-' + x.borrower.Id).click()} data-tip="Click here to upload documents for this Borrower Application." data-for="app-tooltip">Upload Borrower Documents</a>
                </td>
                {ReactTooltip.rebuild()}
            </tr>
        )
    })

    return rows;
}