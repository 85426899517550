

// date formatting utility - expects yyyy-mm-dd
const formattedDate = (inputDate) => {
    if (inputDate.length) {
        let splitDate = inputDate.split('-');

        if (splitDate.count == 0) {
            return null;
        }

        let year = splitDate[0];
        let month = splitDate[1];
        let day = splitDate[2];

        return month + '/' + day + '/' + year;
    } else return '-'
}

// logging utility for dev module.  if dev mode is falsey no logs are printed globally
// requires msg (string message) and obj (object to log after string null if none), 
// and optional print (boolean to disable logging)
const log = (msg, obj, print) => {
    if (print === false) return; // explicitly must be false to disable logging
    if (obj === false) return;
    if (print === false && obj === false) return;

    if (process.env.REACT_APP_DEV_MODE) {
        if (msg && !obj) {
            console.log(msg);

        } else if (!msg && obj) {
            console.log(obj);

        } else if (msg && obj) {
            console.log(msg, obj);
        }
    }
}

// find and return an object from array by the largest value
// params arr (array of objects), key (object property to compare), and val (true to return 
// the property value only, false to return the complete object)
const getMaxValue = function (arr, key, obj) {
    if (arr.length > 0) {
        const entry = arr.reduce((a, b) => (a[key] > b[key] ? a : b));

        if (obj) {
            return entry || {};
        } else {
            return (entry[key] || "no value");
        }

    } else {
        return null;
    };
}


// flat join of an indefinite number of arrays (note there is actually a way to do this using spread alone in ES6 spec)
const joinArrays = (...arr) => {
    if (arr) {

        const res = arr.reduce((acc, val) => {
            return acc.concat(...val);
        }, []);

        return res;
    }
}

// generate a random ID
const generateId = (max, min) => {
    // Credit: MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
    min = Math.ceil(min);
    max = Math.floor(max);
    return 'id-' + Math.floor(Math.random() * (max - min) + min).toString();
}

// filtering function (for all object propeties in shallow objects)
function filterData(data, value) {
    return data.filter(o =>
        Object.keys(o).some(k => o[k].toLowerCase().includes(value.toLowerCase())));
}

// sorting function (pass array and the key to sort)
function sortData(data, key) {
    return data.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // ignore upper and lowercase
        var nameB = b[key].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });
};

const dateToday = () => {
    const date = new Date(); // get today's date
    const day = date.getDay(); // get today's day of the week
    date.setDate(date.getDate() - 1); // update date to display to yesterday

    // check for non-workdays and display fridays date
    switch (day) {
        case 0: {
            date.setDate(date.getDate() - 2); // day = sunday, back up 2 days
            break;
        }

        case 1: {
            date.setDate(date.getDate() - 3); // day = monday, back up 3 days
            break;
        }

        case 6: {
            date.setDate(date.getDate() - 1); // day saturday, back 1 day
            break;
        }

        default: {
            break;
        }
    }

    return date.toDateString()
};

function removeDuplicates(array, key) {
    var check = new Set();
    return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
};

export { formattedDate, log, generateId, filterData, sortData, removeDuplicates, dateToday, getMaxValue }
