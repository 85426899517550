import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTimes, faUser, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

class NavBarLegacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navToggle: false
        }

        this.handleResize = this.handleResize.bind(this)
        this.handleToggle = this.handleToggle.bind(this);
    }

    freezeScroll() {
        document.querySelector('body').style.overflow = this.state.navToggle ? "hidden" : "auto";
    }

    handleResize() {
        if (window.innerWidth > 1023) {
            this.setState({
                navToggle: false
            }, this.freezeScroll)
        }
    }

    handleToggle() {
        this.setState({ navToggle: !this.state.navToggle }, this.freezeScroll)
    }


    render() {
        window.addEventListener('resize', this.handleResize);

        return (
            <div className="nav-wrapper">
                <div className="nav-wrapper_inner">
                <ul className="auxillary-links">
                    <li>
                        <FontAwesomeIcon className="nav-link-icon" icon={faUser} />
                        <Link to="/">Login</Link>

                    </li>
                    <li>
                        <FontAwesomeIcon className="nav-link-icon" icon={faClipboardCheck} />
                        <a href="https://limaone.com/contact" target="_blank" rel="noopener noreferrer">Contact Us</a>
                    </li>
                </ul>

                <nav className="nav-bar">
                    <div className={`nav-modal ${this.state.navToggle ? "is-active" : ""}`}></div>

                    <a href="https://limaone.com/" className="logo">
                        <img src="/assets/images/Lima-One-Logo-retina.png" alt="" />
                    </a>

                    <div className={`nav-links ${this.state.navToggle ? "is-active" : ""}`}>


                        <ul className="nav-links-list-primary">

                            <li className="has-children">
                                <button className="main">Loan Programs <FontAwesomeIcon className="nav-link-icon" icon={faChevronDown} /></button>
                                <div className="nav-links-list-secondary">
                                    <ul className="nav-link-secondary-main">
                                        <li>
                                            <a href="https://limaone.com/hard-money-fix-n-flip/">FixNFlip</a>
                                        </li>
                                        <li className="has-children">
                                            <a href="https://limaone.com/rental30/">Rental</a>
                                        </li>
                                        <li>
                                            <a href="https://limaone.com/multifamily/">Multifamily</a>
                                        </li>
                                        <li>
                                            <a href="https://limaone.com/new-construction-loans/">New Construction</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>


                            <li className="has-children">
                                <button className="main">Broker & Referral Partners <FontAwesomeIcon className="nav-link-icon" icon={faChevronDown} /></button>
                                <div className="nav-links-list-secondary">
                                    <ul className="nav-link-secondary-main">
                                        <li>
                                            <a href="https://limaone.com/brokers/">Brokers</a>
                                        </li>
                                        <li>
                                            <a href="https://limaone.com/referral-program/">Referral Program</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="has-children">
                                <button className="main">Learning Center <FontAwesomeIcon className="nav-link-icon" icon={faChevronDown} /></button>
                                <div className="nav-links-list-secondary">
                                    <ul className="nav-link-secondary-main">
                                        <li><a href="https://limaone.com/case-studies/">Case Studies</a></li>
                                        <li>
                                            <a href="https://limaone.com/blog/">Blog</a>
                                        </li>
                                        <li>
                                            <a href="https://limaone.com/market-insights/">Market Insights</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="has-children">
                                <a href="https://limaone.com/the-company/" className="main">About Us<FontAwesomeIcon className="nav-link-icon" icon={faChevronDown} /></a>
                                <div className="nav-links-list-secondary">
                                    <ul className="nav-link-secondary-main">
                                        <li>
                                            <a href="https://limaone.com/leadership-team/">Leadership Team</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="other-link">
                                <a href="https://limaone.com/employment">Careers</a>
                            </li>
                            <li className="other-link" >
                                <Link to="/signup" className="get-started" style={{textTransform: "uppercase"}}>Get Started</Link>
                                {/* <a href="https://portal.limaone.com/signup" className="get-started" style={{textTransform: "uppercase"}}>Get Started</a> */}
                            </li>

                        </ul>
                    </div>

                    <button className="nav-toggle" onClick={this.handleToggle}>
                    <img src="/assets/images/Lima-One-Logo-retina.png" alt="" className="nav-toggle-logo" />
                        <FontAwesomeIcon icon={this.state.navToggle ? faTimes : faBars} />
                    </button>
                </nav>
                </div>
            </div>
        )
    }
}

export default NavBarLegacy;