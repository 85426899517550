import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import TransitionIn from '../../../../../../components/shared/TransitionIn';
import './index.scss';
import PaymentEntryList from './PaymentEntryList';

// This is the wrapper for the Payment History - Year.  It renders the list of entries for each year.

const PaymentYear = props => {
    const [expanded, setExpanded] = useState(() => props.index <= 4 ? true : false);

    return (
        <li className="payment-history_year">

            <button className="payment-history_year_heading" onClick={() => setExpanded(!expanded)}>
                <span className="year">
                    {props.year.year} ({props.year.payments.length})
                </span>
                <FontAwesomeIcon icon={faChevronDown} style={{ transform: `rotate(${expanded ? '180deg' : '0deg'})` }} />
            </button>

            {
                expanded ?
                    <TransitionIn content={
                        <div className="content">
                            <PaymentEntryList data={props.year} />
                        </div>
                    } />
                    : null
            }
        </li>
    )
}

export default PaymentYear