import React from 'react';
import './index.scss';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import { log } from '../../../utility';

// accepts the following props:
// ----- value: button text
// ----- valueReadOnly: button text in read-only state
// ----- callback: handler for click 
// ----- readOnly: toggle for access (true blocks button access during an API call, for example - controlled in parent state)
// ----- spinner: if true a spinner is visible while read-only is true (defaults to true)

function ButtonSubmit(props) {

    const callback = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (props.readOnly) {
            log('callback prohibited in read-only state')
        } else {
            if (props.callBack) {
                log('callback in props')
                props.callBack()
            } else {
                log('no callback provided in props')
            }
        }
    }

    return (
        <button readOnly={props.readOnly}
            type="submit"
            className={`button-submit ${props.readOnly ? "read-only" : ""}`}
            onClick={props.callback} style={props.style} disabled={props.disabledIf ? props.disabledIf : false}>

            {props.readOnly ? (props.valueReadOnly || "Processing") : (props.value || "Submit")}

            {props.spinner !== false ?
                <Loader className="spinner"
                    type="Grid"
                    color="#0C224B"
                    height={"1rem"}
                    width={"1rem"}
                /> : ""
            }
        </button>
    )
}

export default React.memo(ButtonSubmit)