import React from 'react'

export function FirstPrompt(props) {

    return (
        <div className={"first-prompt"}>
            <div className={"flex-column"} style={{ marginTop: '5rem'}}>
                <h1>THANK YOU</h1>
                <h5>A Sales Representative will be in touch with you shortly.</h5>
                <h5 className={"colors-black"}>Here&apos;s what to expect to discuss on your kickoff call.</h5>
            </div>

            <ul>
                <div className={"verticalLine"}>

                </div>
                <li>
                    <img src={"/assets/images/bullet.png"} /> A custom consultation and quote around the loan program best suited for your current deal
                </li>
                <li>
                    <img src={"/assets/images/bullet.png"} /> Lima One Capital’s loan origination process and estimated timeline
                </li>
                <li>
                    <img src={"/assets/images/bullet.png"} /> Opportunities for opening a line of credit to help you scale your business
                </li>
            </ul>
            <div className={"flex-column"}>
                <h5>If you have a deal in hand, and want to start with our formal application, continue with portal sign-up creation.</h5>
                <button type={"button"} onClick={() => props.setPassword(true)}>Start Application</button>
            </div>
        </div>
    )

}