import React from 'react';
import './index.scss';
import axios from 'axios';

import jwt_decode from "jwt-decode";

import { handleInput } from '../../../../assets/javascript/utilities/utilities_form_handle_input' // global form input handlers
import { formData } from './_form_data'; // state.formData object
import InputText from '../../../../components/_forms/_inputText';
import { Redirect } from 'react-router';
import ButtonSubmit from '../../../components/shared/ButtonSubmit';
import { Link } from 'react-router-dom';

export default class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      formSubmission: false,
      serverError: false,
      formData: formData,
      errors: {
        email: '',
        emailval: ''
      },
      response: {
        messageCode: '',
        message: '',
        status: ''
      },
      params: {
        email: ''
      }
    }

    this.inputText = handleInput.inputText.bind(this);
  }

  cloneFormData = () => {
    return JSON.parse(JSON.stringify(this.state.formData));
  }

  validateForm = (log) => {
    const obj = this.state.formData
    console.log('validating... =>', obj)
    const objArray = Object.values(obj); // transform formData to array to loop thru and check for all completed validation 

    const test = (x) => x.iVal.isValid;
    let result = objArray.every(test); // test validation

    if (log) {
      const keys = Object.keys(obj);
      let resultArray = []

      keys.forEach((x, index) => {

        let value = {
          [x]: objArray[index].iVal.isValid
        }

        console.log(value)

        resultArray.push(value)
      });
    }

    console.log(result)

    if (!result) {
      this.setState({
        submitted: false
      })
    }

    return result; // return boolean for form validation
  }


  handleSubmit = (event) => {
    if (event) event.preventDefault();

    this.setState({ formSubmission: true })

    if (this.state.submitted) return;

    this.setState({ submitted: true })

    let fd = this.state.formData
    let _this = this;

    if (this.validateForm()) {
      let username = this.state.formData.email.iVal.value;

      axios.post('/lima/api/users/create-account-route', {
        "email": username,
        "simple": true
      })
        .then(function (response) {
          _this.setState({ response: response.data, serverError: false, submitted: false })
          console.log(response.data)
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
          _this.setState({ serverError: true, submitted: false }, console.log(_this.state))
        });
    } else {
      console.error('Invalid Form')
    }
  }

  componentDidMount() {
    let params = this.props.location.state ? this.props.location.state : this.props.match.params;

    if (!params) {
      params = { email: '' }
    }

    this.setState({ params, email: true });

    if (params.email) {
      let fd = this.cloneFormData()
      fd.email = {
        iRaw: params.email,
        iVal: {
          value: params.email,
          isValid: true
        },

      }

      let this_ = this
      this.setState({ formData: fd }, function () {
        this_.handleSubmit()
      })
    }
  }

  componentDidUpdate() {
  }

  render() {
    const { errors, formSubmission, formData: fd, params, submitted } = this.state;

    if (this.props.match.params.email) {
      return <Redirect to={{
        pathname: "/create-account",
        state: {
          email: this.props.match.params.email
        }
      }} />
    }


    return (
      <section className="create-account-rf">
          <h1>Create Your Portal Account</h1>
          <h3>Enter your email below to get started!</h3>
        
        <form onSubmit={this.handleSubmit} noValidate>
          <label htmlFor="email">Email Address*</label>
            <InputText formSubmission={formSubmission} fd={fd} fd_key={"email"} inputText={this.inputText} tabIndex={1} readOnly={params.email} placeholder="address@email.com" />

            <ButtonSubmit
              readOnly={submitted}
              type="submit"
              value={"Create Account"}
              valueReadOnly={"Creating Account..."}
              className={`button-submit ${submitted ? "read-only" : ""}`}
              onClick={this.handleSubmit}
              variant={"rounded"} />
        </form>

        <br /><br />

        <div className="create-account-form-resp">
          {(this.state.response.status !== undefined && this.state.response.status.length > 0) &&
            <div>
              <br />
              {(() => {
                if (this.state.response.code === 0 || this.state.response.code === 1 || this.state.response.code === 2 || this.state.response.code === 4) {
                  return (
                    <h3>Please check your email to complete the account registration by setting up your password, or <Link to="/forgot-password">request a new password.</Link></h3>
                  )
                } else if (this.state.response.code === 3) {
                  return (
                    <h3>We found you already have an account. Proceed to <Link to="/">Login</Link> or <Link to="/forgot-password">Reset Your Password.</Link></h3>
                  )
                } else if (this.state.response.code === 5) {
                  return (
                    <h3>Unfortunately we could not find an existing account for your email. Complete the <Link to={"/signup"}>customer sign up</Link> or <Link to={"/broker/signup"}>broker sign up</Link> for Portal access.</h3>
                  )
                } else {
                  return (
                    <h3>Something unexpected happened, please contact support for assistance.</h3>
                  )
                }
              })()}

              
            </div>
          }

{this.state.serverError && this.state.response.status === ""  ? <h3>Something unexpected happened, please contact support for assistance.</h3> : null}
        </div>
      </section>
    )
  }
}
