import React, { useState } from 'react';
import './index.scss';
import ButtonSubmit from '../../shared/ButtonSubmit/index';
import axios from 'axios';
import Options from '../Options';

const TopBar = props => {

  const [user, setUser] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault();
    // e.stopPropagation();

    if (submitted) return;

    setSubmitted(true);

    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("employeeToken");

    try {
      let response = await axios.post('/lima/api/users/impersonate', { username: user })
      let { token, name, email, accountType, userId, isLead } = response.data;

      window.localStorage.setItem("token", token);
      window.localStorage.setItem("name", name);
      window.localStorage.setItem("username", email);
      window.localStorage.setItem("accountType", accountType);
      window.localStorage.setItem("isLead", isLead);

      if (accountType === 'BrokerAccount' || accountType === 'WholesaleAccount') {
        window.localStorage.setItem('userId', userId)
      }

      // if (name.length > 0) {
      //   window.Appcues.identify(email);
      // }

      props.login(accountType, email, name, token, userId, isLead); // reset app to new user as employee

      // if user is coming from a deep linked URL, continue to that route once logged in
      setSubmitted(false)
    } catch (error) {
      if (error.response && error.response.data.error) {
        alert(error.response.data.error);
      }

      setSubmitted(false)
    }
  }


  return (
    <div className="app-top-bar">

      <div className="top-bar-left">
        {
          (window.localStorage.getItem('employeeToken')) &&
          <React.Fragment>
            <form className={`form-body`} onSubmit={handleSubmit} >
              <input type="email" className='account-search-bar' value={user} onChange={(e) => setUser(e.target.value)} placeholder="user@email.com"></input>
              <ButtonSubmit callBack={handleSubmit} readOnly={submitted} value={"View Account"} />
            </form>
          </React.Fragment>
        }
      </div>

      <div className="top-bar-right">
        {props.username}
        <Options />
      </div>

      <div className="decor top-left">
        <div className="inner"></div>
      </div>
      <div className="decor top-right">
        <div className="inner"></div>
      </div>
      <div className="decor bottom-left">
        <div className="inner"></div>
      </div>
      <div className="decor bottom-right">
        <div className="inner"></div>
      </div>

    </div>
  )
}

export default TopBar
