import React, {Fragment} from "react";
import { RouteBasic } from "../../../Wrappers";
import DashboardTopNav from '../Navigation';
import { Route, Switch } from 'react-router-dom';
import { Base } from '../../../Layouts'
import Resources from './BrokerResources';
import LoansInProcess from '../LoansInProcess';
import LoansFunded from '../LoansFunded';
import './index.scss';

function BrokerDashboard(props) {

  return (
    props.user.isLead?.toString() === "true" ?
      <div className="broker-dashboard">
            <Base>
                <Resources />
            </Base>
      </div>
      :
      <div className="broker-dashboard">
      <DashboardTopNav {...props} />

      <Switch>
        <Route exact path={`${process.env.REACT_APP_SESSION_URL}`}>
            <Base>
                <LoansInProcess />
                <LoansFunded />
            </Base>
        </Route>

        <RouteBasic exact
          path={`${process.env.REACT_APP_SESSION_URL}/resources`}
          component={Resources}
          accountType={props.accountType}
          layout={Base} />

      </Switch>
    </div>
  )
}

export default BrokerDashboard;
