import React from 'react'
import './index.scss'

const InputRadioMultiple = React.forwardRef((props, ref) => {

    let style = props.style ? props.style : { display: 'inline' }

    return (
        <section className="section select-property-type">
            <fieldset className={`input-field-group ${props.name in props.errors && "error"}`}>
                <div className={`input-field ${props.fullwidth && 'full-width'} ${props.name in props.errors && "error"}`}>
                    <label htmlFor={props.name}>{props.label}</label>
                    <ul className={"radio-field"} style={style}>
                        {
                            props.config.map((type, index) => {
                                return (
                                    <li key={type + index} style={{ float: 'left' }}>
                                        <input ref={ref} name={props.name} id={props.name + "_" + type} type="radio" value={type} />
                                        <label htmlFor={props.name + "_" + type}>{type}</label>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <span className="error-message">{props.name in props.errors && `${props.label} is required.`}</span>
                </div>
            </fieldset>
        </section>
    )

});

export default React.memo(InputRadioMultiple)