import React from 'react';
import './index.scss';

class AccountActions extends React.Component {

  logout(event) {
    event.preventDefault();
    window.localStorage.clear();
  }

  render() {
    return (
      <ul className="account-actions">
        <li>
          <a href="/profile">Profile</a>
        </li>
        <li>
          <a href="/dashboard" onClick={this.logout}>Logout</a>
        </li>
      </ul>
    )
  }
}

export default AccountActions