import React from 'react'
import PropertyInformation from '../../_components/PropertyInformation'
import { PropertyInformation as LoanInfo } from './PropertyInformation'
import ThirdPartyContacts from '../../_components/ThirdPartyContacts'
import Questionnaire from './Questionnaire'

const Portfolio = React.forwardRef((props, ref) => {

    return (
        <>
            <PropertyInformation {...props} ref={ref} />
            <LoanInfo {...props} ref={ref} />
            <Questionnaire {...props} ref={ref} />
            <ThirdPartyContacts {...props} ref={ref} />
        </>
    )

})

export default React.memo(Portfolio)