import React from 'react'
import InputRadioBinary from '../_components/InputRadioBinary'
import InputHiddenSelect from '../_components/InputHiddenSelect'
import InputHiddenText from '../_components/InputHiddenText'

const Questionnaire = React.forwardRef((props, ref) => {

    return (
        <div className="row input-radio-fields" style={{ paddingTop: '1rem' }}>
            <fieldset className="input-field-group boxed">

                <table>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Property_type_be_changing__c"}
                                              label="Will the property type be changing (i.e. from a SFR to a 2-unit, etc.)*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Any_Zoning_Changes__c"}
                                              label="Does the property require any zoning changes to complete your project?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Intend_to_subdivide_the_property__c"}
                                              label="Do you intend to subdivide the property or request partial releases?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Removing_load_bearing_walls__c"}
                                              label="Do you intend to remove two or more load-bearing walls?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Adding_livable_square_footage__c"}
                                              label="Are you adding livable square footage to the existing structure? (e.g. finishing a basement or attic)*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Expanding_building_envelope__c"}
                                              label="Are you expanding the building envelope to add livable space? (e.g. an addition to the property, an ADU, etc.)*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Use_Lima_One_Capital_s_Preferred_Title_I__c"}
                                              label="Use Lima One Capital's Preferred Title Insurance Provider?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Use_Lima_One_Capital_s_Preferred_Propert__c"}
                                              label="Use Lima One Capital's Preferred Property Insurance Provider?*"
                            />
                        </td>
                    </tr>
                </table>

            </fieldset>
        </div>
    )

})

export default React.memo(Questionnaire)