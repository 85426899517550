import React, { useState, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import ApplicationWrapper from "../_components/ApplicationWrapper";
import { handleInput } from "../_components/input_handler"; // global form input handlers
import { SharedApi } from "../_components/SharedAPI";
import SelectBorrower from "../_components/SelectBorrower";
import SelectCustomer from "../_components/SelectCustomer";
import PropertyInformation from "../_components/PropertyInformation";
import LoanInfo from "../_components/LoanInfo";
import Questionnaire from "./Questionnaire";
import ThirdPartyContacts from "../_components/ThirdPartyContacts";
import { api } from "../_api";
import SelectFormType from "../_rental/SelectFormType";
import TransitionIn from "../../../App/components/shared/TransitionIn";
import { Section } from "../../../App/components/shared/Section";

const Application_FixNFlip = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [responseMessage, setResponseMessage] = useState(null);
  const [submissionResponse, setSubmissionResponse] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [borrowing_entities, setBorrowingEntities] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedBE, setSelectedBE] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [existing_customer, setExistingCustomer] = useState(null);
  const [existing_borrower, setExistingBorrower] = useState(null);
  const [renderMore, setRenderMore] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    unregister,
    watch,
    clearError,
      control
  } = useForm();
  const isBroker =
    window.localStorage.getItem("accountType") === "CustomerAccount" ? false : true;
  let params = props.location.state ? props.location.state : props.match.params;

  const watchFormType = watch("Portfolio_Type__c", null);
  const watchCustomerSelection = watch("existing_customer");
  const watchBESelection = watch("existing_borrower");

  const onSubmit = async (formData) => {
    if (!document.getElementById("agree-to-terms").checked) {
      setError("terms", {
        type: "manual",
        message: "Make a selection.",
      });
      return;
    }

    setSubmitted(true);

    api.submit(
      "FixNFlip",
      formData,
      setError,
      setResponseMessage,
      setSubmitted,
      setSubmissionResponse,
      isBroker,
      params?.broker
    );
  };

  const selectBE = (e) => {
    const BE = borrowing_entities.find((obj) => obj.id == e.target.value);

    if (!BE) return;

    const updateArray = [
      { borrowing_entity_name: BE?.name },
      { company_type: BE?.entityType },
      { business_EIN: BE?.EIN?.replace(/^(\d{2})/, "$1-") },
    ];

    BE.states.forEach((state) => {
      updateArray.push({
        [`registered_to_do_business_in_what_states_${state}`]: "checked",
      });
    });

    setValue(updateArray);
    setSelectedBE(BE);
  };

  const selectCustomer = (e) => {
    setSelectedCustomer(e.target.value);
  };

  useEffect(() => {
    if (watchFormType && !renderMore) {
      document.getElementById('app-scroll-anchor').scrollTo(0, document.getElementById('app-scroll-anchor').scrollTop - 60)
      setTimeout(() => setRenderMore(true), 100);
    }
  }, [watch])

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      return;
    }

    SharedApi.fetchBorrowingEntities(setBorrowingEntities, props.broker);

    if (isBroker) {
      SharedApi.fetchCustomersbyBroker(setCustomers);
    }
  }, [loaded, watchCustomerSelection, submissionResponse]);

  if (submissionResponse?.opportunity_id) {
    return (
      <Redirect
        to={{
          pathname: "/thank-you/deal",
          state: {
            title: "FixNFlip | Bridge",
            opportunity_id: submissionResponse?.opportunity_id,
            whitelabel: params?.broker ? true : false,
          },
        }}
      />
    );
  }

  const tabIndexx = () => {
    setTabIndex(tabIndex + 1);
    return tabIndex;
  };

  const globalProps = {
    application_type: "fixnflip",
    errors: errors,
    tabIndex: tabIndexx,
    isBroker: isBroker,
    borrowingEntities: borrowing_entities,
    customers: customers,
    selectBE: selectBE,
    selectCustomer: selectCustomer,
    selectedCustomer: selectedCustomer,
    setSelectedCustomer: setSelectedCustomer,
    existing_customer: existing_customer,
    setExistingCustomer: setExistingCustomer,
    existing_borrower: existing_borrower,
    setExistingBorrower: setExistingBorrower,
    setSelectedBE: setSelectedBE,
    control: control,
    inputEIN: handleInput.inputEIN,
    inputCurrency: handleInput.inputCurrency,
    inputSSN: handleInput.inputSSN,
    inputCreditScore: handleInput.inputCreditScore,
    inputPercentage: handleInput.inputPercentage,
    inputZip: handleInput.inputZip,
    inputPhone: handleInput.inputPhone,
    inputNumber: handleInput.inputNumber,
    validateSSN: handleInput.validateSSN,
    validateZip: handleInput.validateZip,
    validatePhone: handleInput.validatePhone,
    fetchBorrowers: SharedApi.fetchBorrowingEntities,
    setBorrowingEntities: setBorrowingEntities,
    watch: watch,
    setError: setError,
    clearError: clearError,
    broker: params?.broker,
    multiProperty: watchFormType === "Portfolio",
  };

  let showSubmit = false;

  if (isBroker) {
    if (watchCustomerSelection === "false") {
      showSubmit = true;
    } else {
      if (selectedCustomer) {
        if (watchBESelection === "true" && selectedBE) {
          showSubmit = true;
        } else if (watchBESelection === "false") {
          showSubmit = true;
        }
      }
    }
  } else {
    if (watchBESelection === "true" && selectedBE) {
      showSubmit = true;
    } else if (watchBESelection === "false") {
      showSubmit = true;
    }
  }

  if (params?.broker) showSubmit = true;
  let isLead = window.localStorage.getItem('isLead') ? window.localStorage.getItem('isLead').toString() === "true" : true;

  const heading = (
    "FixNFlip | Bridge Application"
  )



  const body = (
    <ApplicationWrapper
      handleSubmit={handleSubmit(onSubmit)}
      errors={errors}
      ref={register({ required: true })}
      submitted={submitted}
      setSubmitted={setSubmitted}
      responseMessage={responseMessage}
      setResponseMessage={setResponseMessage}
      showSubmit={showSubmit && renderMore} >

      {isBroker &&
        window.localStorage.getItem("userId") &&
        !params?.broker &&
        !isLead && (
          <p>
            Your whitelabel URL:{" "}
            <a
              href={`${process.env.REACT_APP_WHITELABEL_URL
                }/applications/fix-n-flip/${window.localStorage.getItem(
                  "userId"
                )}`}
            >
              {process.env.REACT_APP_WHITELABEL_URL}/applications/fix-n-flip/
              {window.localStorage.getItem("userId")}
            </a>
          </p>
        )}

      <p className={"please-fill"}>
        Please fill out the application below in its entirety. Your
        changes will not be saved until the application is submitted.
        <br />
        <br />
      </p>
      <SelectCustomer {...globalProps} ref={register({required: true})}
                      notReqRef={register({required: false})} register={register} />
      <SelectBorrower {...globalProps} ref={register({required: true})}
                      notReqRef={register({required: false})} register={register} />

      {showSubmit && (
        <>
          <SelectFormType errors={errors} ref={register({required: true})}
                          notReqRef={register({required: false})} register={register} watchFormType={watchFormType} />
          <div id={"top-of-form"} style={{ visibility: 'hidden' }} />
          {renderMore && (
            <>
              <PropertyInformation {...globalProps} ref={register({required: true})}
                                   notReqRef={register({required: false})} register={register} />
              <LoanInfo {...globalProps} ref={register({required: true})}
                        notReqRef={register({required: false})} register={register} />
              <Questionnaire {...globalProps} ref={register({required: true})}
                             notReqRef={register({required: false})} register={register} />
              <ThirdPartyContacts {...globalProps} ref={register({required: true})}
                                  notReqRef={register({required: false})} register={register} />
            </>
          )}
        </>
      )}
    </ApplicationWrapper>
  )

  return (
    <TransitionIn content={
      <Section heading={heading} body={body} max={30} />
    } />
  );
};

export default withRouter(Application_FixNFlip);

