import React from "react";
import { Component } from "react";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import DocumentUpload from "./legacy/document_upload";
import ReactModal from "react-modal";
import { ReactSVG } from "react-svg";
import ChecklistTable from "./ChecklistTable";
import { SectionExpandable } from '../../../../../components/shared/Section';
import SkeletonTable from '../../../../../components/shared/SkeletonTable';
import TransitionIn from '../../../../../components/shared/TransitionIn';
import CopyTable from '../../../../../components/shared/Section/controls/CopyTable';

class BorrowerApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acctLOCs: [],
      recentLOCs: [],
      recentUnderwritings: [],
      loaded: false || props.loaded,
      showModal: false,
      selectedLOC: null,
      files: [],
      urls: [],
      iframeURL: null
    };
    this.ref = React.createRef();
  }

  handleOpenModal = (id) => {
    this.setState({ selectedLOC: id, showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ selectedLOC: null, showModal: false });
  };

  getUnderwriting = (borrowingEntityId) => {
    return this.props.recentUnderwritings.find(
      (obj) => obj.Id === borrowingEntityId
    );
  };


  collapseToggle(e, borrowingEntityId) {
    let el = e.target.closest("tr").getElementsByTagName("svg")[0];
    // let checklist = document.getElementById(`tr-${borrowingEntityId}`);
    let checklistiFrame = document.getElementById(
      `triframe-${borrowingEntityId}`
    );

    let iframe = checklistiFrame.getElementsByTagName("iframe")[0];

    el.classList.toggle("opened");
    // checklist.classList.toggle("opened")
    checklistiFrame.classList.toggle("opened");
    // if (process.env.REACT_APP_CHECKLIST_IFRAME == 'true' && !iframe.hasAttribute("src")) {
    //   iframe.setAttribute("src", iframe.getAttribute("data-src"))
    // }
  }

  setFiles = (loc_id, files) => {
    let _thisFiles = this.props.locFiles;
    _thisFiles[loc_id] = files;
    this.props.setLocFiles(_thisFiles)
  };


  render() {
    let renderedUnderwritings = [];

    return (
      <TransitionIn content={
        <>
          <SectionExpandable flush heading="Borrower Applications" controls={<CopyTable tableRef={this.ref} />} body={

            ((!this.props.recentLOCS && !this.props.recentUnderwritings)) ? <SkeletonTable /> :

              <React.Fragment>
                <table className="locTable table" id="BorrowerApplications" ref={this.ref}>
                  <thead>
                    <tr>
                      <th style={{ width: "40%" }}>
                        <span>
                          Borrowing Entity
                        </span>
                      </th>
                      <th style={{ width: "40%" }}>
                        <span>
                          Status
                        </span>
                      </th>
                      <th style={{ width: "15%" }} className={"hide-mobile"}>
                        <span>
                          Expiration Date
                        </span>
                      </th>
                      <th style={{ width: "5%" }} className={"hide-mobile1"}>
                        <span>
                          {/* placeholder */}
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>


                    {
                      this.props.recentLOCs.length !== 0 &&
                      this.props.recentLOCs.map((item, index) => {

                        let displayStatus = item.Portal_Status__c !== "Approved" ? "Denied" : "Approved";
                        let underwriting = this.getUnderwriting(item.Id);
                        let renderChecklist = item.Portal_Status__c === "Documents Needed" ||
                          item.Portal_Status__c === "Conditionally Approved" ||
                          item.Portal_Status__c === "In Underwriting";
                        if (underwriting) {
                          renderChecklist = underwriting.Portal_Status__c === "Documents Needed" ||
                            underwriting.Portal_Status__c === "Conditionally Approved" ||
                            underwriting.Portal_Status__c === "In Underwriting" ||
                            underwriting.Sales_LOC_Status__c === "Application Received";
                        }

                        renderedUnderwritings.push(item.Id);

                        return (
                          <React.Fragment>
                            <tr id={item.Id} style={{ background: "#63aad00e" }}>
                              <td>
                                <span>
                                  {item.borrower.Borrowing_Entity_Name__r.Name}
                                </span>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <span className="normal line-credit">
                                  Line of Credit
                                </span>
                              </td>
                              <td>
                                <span className="normal" style={{ color: `${displayStatus === "Approved" ? "green" : "none"}`}}>
                                  {displayStatus}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {item.expiration_date || "-"}
                                </span>
                              </td>
                              {!underwriting ? (
                                <td style={{ textAlign: "right" }}>
                                  
                                </td>
                              ) : (
                                <td></td>
                              )}
                              {!underwriting && renderChecklist ? (
                                <td
                                  onClick={(e) => this.collapseToggle(e, item.Id)}
                                  style={{ textAlign: "right" }}
                                  id={"checklist-" + item.borrower.Id}
                                >
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </td>
                              ) : (
                                <td></td>
                              )}
                            </tr>

                            { underwriting && underwriting.Portal_Status__c !== "Approved" && (
                                <tr>
                                  <td>
                                    <span className="normal">
                                      Line of Credit (Application)
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {underwriting ? underwriting.Portal_Status__c || underwriting.Sales_LOC_Status__c : item.Portal_Status__c}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }} className={"hide-mobile"}>
                                    <span>
                                      {/* empty */}
                                    </span>
                                  </td>

                                  {renderChecklist ? (
                                    <span onClick={(e) => this.collapseToggle(e, item.Id)} style={{ textAlign: "center" }}  id={"checklist-" + item.borrower.Id}>
                                      <FontAwesomeIcon icon={faChevronDown} />
                                    </span>
                                  ) : <td></td>}
                                </tr>
                              )}

                            { renderChecklist && (
                                <tr className="checklist" id={`triframe-${item.Id}`}>
                                  <td colSpan="5">
                                      <ChecklistTable
                                        callback={this.props.updateLOCs}
                                        item={item}
                                        handleOpenModal={this.handleOpenModal}
                                        urls={this.props.urls}
                                        setUrls={(urls) => this.props.setLocUrls(urls)}
                                      />
                                  </td>
                                </tr>
                            )}

                          </React.Fragment>
                        );
                      })}

                    {this.props.recentUnderwritings.length !== 0 &&
                      this.props.recentUnderwritings.map((item, index) => {

                        let renderChecklist = item.Portal_Status__c === "Documents Needed" ||
                          item.Portal_Status__c === "Conditionally Approved" ||
                          item.Portal_Status__c === "In Underwriting" ||
                          item.Sales_LOC_Status__c === "Application Received";

                        // let renderChecklist = false
                        if (!renderedUnderwritings.includes(item.Id)) {
                          return (

                            <React.Fragment>
                              <tr id={item.Id} style={{ background: "#63aad00e" }}>
                                <td>
                                  <span>
                                    {item.borrower.Borrowing_Entity_Name__r.Name}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {/* empty */}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    -
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {/* empty */}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <span className="normal line-credit">
                                    Line of Credit (Application)
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {item.Sales_LOC_Status__c || item.Portal_Status__c}
                                  </span>
                                </td>
                                <td
                                  style={{ textAlign: "right" }}
                                  className={"hide-mobile"}
                                ></td>
                                {renderChecklist ? (
                                  <td onClick={(e) => this.collapseToggle(e, item.Id)} style={{ textAlign: "right" }}  id={"checklist-" + item.borrower.Id}>
                                    <span>
                                      <FontAwesomeIcon icon={faChevronDown} />
                                    </span>
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>

                              {renderChecklist && (
                                <>
                                  <tr className="checklist" id={`triframe-${item.Id}`}>
                                    <td colSpan="5">
                                      {process.env.REACT_APP_CHECKLIST_IFRAME == 'true' ?
                                        <iframe data-src={this.state.iframeURL + item.checklistId} title="publicLOCChecklistUrl" frameBorder="0" width="100%" sandbox="allow-forms allow-scripts allow-same-origin"></iframe>
                                        :
                                        <ChecklistTable
                                          callback={this.props.updateLOCs}
                                          item={item}
                                          handleOpenModal={this.handleOpenModal}
                                          urls={this.props.locUrls}
                                          setUrls={(urls) => this.props.setLocUrls(urls)}
                                        />
                                      }
                                    </td>
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          );
                        }
                      })}



                    {this.props.acctLOCs.length !== 0 &&
                      this.props.acctLOCs.map((item, index) => {

                          return (

                            <React.Fragment>
                              <tr id={item.Id} style={{ background: "#63aad00e" }}>
                                <td>
                                  <span>
                                    {item.Name}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {/* empty */}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    -
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {/* empty */}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <span className="normal line-credit">
                                    Line of Credit (Application)
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {item.Entity_Milestone__c}
                                  </span>
                                </td>
                                <td
                                  style={{ textAlign: "right" }}
                                  className={"hide-mobile"}
                                ></td>
                              </tr>

                              
                            </React.Fragment>
                          );
                      })}
                    {this.props.recentUnderwritings.length === 0 &&
                      this.props.recentLOCs.length === 0 &&
                      this.props.acctLOCs.length === 0 && (
                          <tr>
                            <td>
                              <span>
                              No results found.
                              </span>
                            </td>
                          </tr>
                      )}
                  </tbody>
                </table>



              </React.Fragment>
          } />
          <ReactModal
            isOpen={this.state.showModal}
            ariaHideApp={false}
            contentLabel="onRequestClose Example"
            onRequestClose={this.handleCloseModal}
            shouldCloseOnOverlayClick={true}
            dialogClassName="add-members-modal"
            className="loc-doc-upload-modal">

            <div className="close-bar" style={{ textAlign: "right", padding: "0" }}>
              <button onClick={this.handleCloseModal}>
                <ReactSVG src="/assets/images/xicon.svg" />
              </button>
            </div>

            <div class="document-upload-modal">
              <DocumentUpload
                setFiles={this.setFiles}
                files={this.props.locFiles[this.state.selectedLOC]}
                dealId={this.state.selectedLOC}
                isLOC={true}
                callback={this.props.updateLOCs} />
            </div>

          </ReactModal>
        </> // end TransitionIn
      } />
    );
  }
}

export default BorrowerApplications;
