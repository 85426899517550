// logging shortcut
const log = function(obj) {
    console.log(obj);
}

// clones a simple object (no methods - properties/data only)
const cloneData = function (data) {
    return JSON.parse(JSON.stringify(data));
}

// currency formatter
const format = {
    currency: function(i) {
        // format here
        return i;
    }
}

export { log, cloneData, format }