import React from 'react';

// props =>
// "fd_key" refers to formData object in state - required

const InputNumber = (props) => {
    let label = props.label ? props.label : props.fd[props.fd_key].label
    
    return (
        <div className={`${props.inner ? 'input-field_inner' : 'input-field'} ${props.fullwidth ? "full-width" : ""} ${props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? "error" : ""}`}>
            <label htmlFor={props.fd_key}>{label}</label>
            <input type="number" name={props.fd_key} id={props.fd_key} data-input={props.fd_key} value={props.fd[props.fd_key].iVal.value ? props.fd[props.fd_key].iVal.value : undefined} onChange={props.inputNumber} placeholder={props.placeholder} min={props.min} max={props.max} step={props.step} />
            <span className="error-message">{(props.formSubmission && !props.fd[props.fd_key].iVal.isValid)  && props.fd[props.fd_key].error }</span>
        </div>
    )
}

export default InputNumber