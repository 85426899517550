import React from "react";
import { ReactSVG } from 'react-svg';
import axios from 'axios';
import ButtonSubmit from '../../../components/shared/ButtonSubmit';
import { Link } from 'react-router-dom';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: null,
            cpassword: null,
            passwordmatch: null,
            email: null,
            token: null,
            errors: {
                password: '',
                cpassword: '',
                passwordmatch: ''
            },
            params: {
                email: '',
                token: ''
            },
            submitted: false
        }

        this.submitNewPass = this.submitNewPass.bind(this);
    }

    componentDidMount() {
        let params = this.state.params;
        params.email = this.props.computedMatch?.params?.email ?? window.localStorage.getItem("email");
        params.token = this.props.computedMatch?.params?.token ?? window.localStorage.getItem("token");

        console.log(params.token )
    }

    submitNewPass = (e) => {
        e.preventDefault()
        var newPass1 = document.getElementById("input__newpassword").value;
        var newPass2 = document.getElementById("input__confirmpassword").value;

        this.setState({
            submitted: true
        })

        console.log('submit')
        if (newPass1 && newPass1 == newPass2) {
            let params = this.state.params;
            axios.defaults.headers.common['Authorization'] = "Bearer " + params.token;
            axios.post(process.env.REACT_APP_LIMA + '/lima/api/users/updatepassword',
                {
                    "email": params.email,
                    "password": newPass1
                }
            )
                .then((res) => {
                    var psuccess = document.getElementById("password-success");
                    psuccess.classList.remove("hide");

                    this.setState({
                        submitted: false
                    })
                })
                .catch(() => {
                    this.setState({
                        submitted: false
                    })
                })
        } else {
            var perror = document.getElementById("password-error");
            perror.classList.remove("hide");

            this.setState({
                submitted: false
            })
        }
    }

    render() {
        const {submitted} = this.state;

        return (
            <div>
                <h1>Change Password</h1>

                <form className="change-password" onSubmit={this.submitNewPass}>

                    <label htmlFor="input__newpassword" data-content="New Password">
                        New Password*
                    </label>
                    <input id="input__newpassword" name="newpassword" type="password" placeholder="New Password" />

                    <label htmlFor="input__confirmpassword"data-content="Confirm Password">
                        Confirm Password*
                    </label>
                    <input id="input__confirmpassword" name="confirmpassword" type="password" placeholder="New Password" />


                    <div className="error hide" id="password-error">Passwords do not match.</div>
                    <div className="success hide" id="password-success">
                        <p>Password updated.  <Link to="/" className="sign-in-here">Sign in here</Link> to access your account.</p>
                    </div>
                    
                    <ButtonSubmit 
                    variant={"rounded"}
                    callback={this.submitNewPass}
                    value={"Change Password"}
                    readOnly={submitted}
                    valueReadOnly={"Changing Password..."}
                    />

                </form>
            </div>

        );
    }
}

export default ChangePassword;