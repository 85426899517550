import React, { Component } from 'react';
import './index.scss';
import axios from 'axios';
import currency from 'currency.js';

// global utilities
// import { log, cloneData } from '../../../../assets/javascript/utilities/utilities_global';
import { log, cloneData } from '../../../../../../../../utility/legacy/utilities_global';
import { state } from '../../../../../../../../utility/legacy/utilities_state';
import { validateInput } from '../../../../../../../../utility/legacy/utilities_validation_input'; // global input validation utilities
import SkeletonTable from '../../../../../../../../components/shared/SkeletonTable';


// lineItem methods (update and validate)
import { lineItem } from './lineItems';

class DrawSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineItems: null,
            formSubmission: false,
            formValid: null,
            drawTotal: 0,
            amounts: []
        };

        // utilities and helpers
        this.checkState = state.check.bind(this) // helper to log current state
        this.cloneData = cloneData.bind(this); // helper to copy entire state to a new object (not a reference to actual state)

        // lineItem functions (bind for state access)
        this.updateLineItem = lineItem.updateLineItem.bind(this); // 
        this.validateLineItems = lineItem.validateLineItems.bind(this);

        // handle submit (check for valid lineItems and update parent/submit form
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // validate lineItems and if true update parent state / handle callback passed down as prop from parent
    validate() {
        let flattenedLineItems = []
        this.state.lineItems.forEach(sublot => {
            sublot.items.forEach(item => {
                flattenedLineItems.push(item)
            })
        })
        this.validateLineItems(
            this.state.formValid ?
                this.props.updateParent(flattenedLineItems.filter(x => x.amount != 0), this.props.handleSubmitPlaceholder) :
                log('form submission error')
            ); // handle error here (trigger error)
    }

    // For Submit Draw Request (trigger form submission (drawsheet) and validation of drawsheet if needed)
    handleSubmit() {
        let showmodal = false;
        let formValid = false

        var error = null
        let noEntry = true

        this.state.lineItems.forEach(sublot => {
            sublot.items.forEach(x => {
                const amount = parseFloat(x.amount.toString().replace(/,/g, "").replace(/\$/g, "")).toFixed(2)
                const balance = parseFloat(x.balance).toFixed(2)

                if (balance < 0) {
                    error = 'Cannot request a negative line item amount*'
                }

                if (amount > 0) {
                    noEntry = false
                }
            })
        })
        if (noEntry && !error) {
            error = 'A minimum of one line item is required to submit your draw request*'
        }

        if (error) {
            showmodal = false;
            formValid = false;
        } else {
            showmodal = true;
            formValid = true;
        }

        this.setState({
            modal: showmodal,
            formValid: formValid,
            error: error
        }, this.validate);
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";

        axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/budget', {
            dealid: this.props.dealId
        })
            .then((data) => {
                // data.data.budget.items.forEach(x => {
                //     x.budget = parseFloat(x.budget).toFixed(2);
                //     x.balance = parseFloat(x.balance).toFixed(2);

                //     return x;
                // })

                // let budgetItems = []
                data.data.budget.sublots.forEach(x => {
                    x.items.forEach(y => {
                        y.budget = parseFloat(y.budget).toFixed(2);
                        y.balance = parseFloat(y.balance).toFixed(2);

                        // budgetItems.push(y)
                        return y
                    })
                })
                let budgetItems = data.data.budget.sublots
                this.setState({
                    // lineItems: data.data.budget.items
                    lineItems: budgetItems
                })
            })
            .catch(function (error) {
                console.log(error); // handle api error
            });

        // update total of amounts for the current draw line items
    }

    componentDidUpdate() {
        // log(this.state)
    }

    inputCurrency(e, code) {
        let iv = e.target.value; // target value
        const op = e.target.dataset.input; // state.inputFrom.objectProperty (op) captured from input dataset.input

        var amounts = this.state.amounts

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.,$]+$/) || iv === "" || iv === "$") {
            var atEnd = iv === "" || iv === "$"
            var cleanIV = iv.replace(/,/g, "").replace(/\$/g, "")

            var fv = "0"

            if (iv !== "") {
                var hasPeriodBefore = amounts[code] && amounts[code].includes('.')
                var hasPeriod = iv.includes('.')
                fv = parseFloat(hasPeriod ? cleanIV.split('.')[0] : cleanIV)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if (!hasPeriodBefore && hasPeriod) {
                    fv += "."
                } else if (hasPeriod) {
                    fv += "." + cleanIV.split('.')[1].substring(0, 2)
                }
            }

            iv = validateInput.float(cleanIV);

            if (iv === undefined) {
                iv = null
            }

            if (atEnd) {
                iv = 0;
                fv = ""
            }

            if (atEnd) {
                amounts[code] = "$0"
            } else {
                amounts[code] = "$" + fv
            }
        } else {
            if (!amounts[code] || amounts[code].length < 1) {
                amounts[code] = "$0"
            }
        }

        // update state and log the new state
        this.setState({
            amounts
        })


    }

    render() {
        const { lineItems } = this.state;

        if(this.state.lineItems === null) {
            return <SkeletonTable />
        }

        return (
            <div className="construction-budget-items collapse-widget">
                <div className="construction-budget-collapse">
                    <section className="draw-request-form">
                        <fieldset className="draw-request">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>
                                            <span>
                                                Budget Item
                                            </span>
                                        </th>
                                        <th>
                                            <span>
                                                Amount
                                            </span>
                                        </th>
                                        <th>
                                            <span>
                                                Remaining Balance
                                            </span>
                                        </th>
                                        <th>
                                            <span>
                                                Budget
                                            </span>
                                        </th>
                                    </tr>
                                </thead>

                                {lineItems.map((sublot) => {
                                    return (
                                        <>
                                        <tbody className="draw-table-body" data-sublot={sublot.sublot}>
                                            <tr className='sublot-title'>
                                                <td colSpan={4}><span>{sublot.sublotName}</span></td>
                                            </tr>
                                            {sublot.items.map((i) => {
                                                let elementKey = `${sublot.sublot}-${i.code}`
                                                const budget = currency(i.budget).format();
                                                const amountStr = elementKey in this.state.amounts ? this.state.amounts[elementKey] : "$0"
                                                const amount = parseFloat(amountStr.replace(/,/g, "").replace(/\$/g, ""))
                                                // const amountFloat = parseFloat(amountClean).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                const balance = (this.props.remainingBalances && elementKey in this.props.remainingBalances ? parseFloat(this.props.remainingBalances[elementKey]) : parseFloat(i.budget)) - amount
                                                
                                                return (
                                                    <tr className="line-item" data-key={elementKey} key={elementKey} onChange={this.updateLineItem}>
                                                        <td >
                                                            <span className={`input-field data-only`}>
                                                                <label htmlFor={''}>Budget Item</label>
                                                                <input id={''} data-inputkey={"name"} tabIndex="-1" type="text"
                                                                    value={i.name} />
                                                            </span>
                                                        </td>
                                                        <td >
                                                            <span className={`input-field ${i.balance == "0.00" ? 'no-input' : ''}`}>
                                                                <label htmlFor={''}>Amount</label>
                                                                <input id={''} data-inputkey={"amount"} type="text" placeholder="$0"
                                                                    value={elementKey in this.state.amounts ? this.state.amounts[elementKey] : "$0"} onChange={(e) => this.inputCurrency(e, elementKey)} />
                                                            </span>
                                                        </td>
                                                        <td >
                                                            <span className={`input-field data-only`}>
                                                                <label htmlFor={''}>Remaining Balance</label>
                                                                <input id={''} data-inputkey={"balance"} type="text"
                                                                    value={currency(i.balance).format()} tabIndex="-1" placeholder="0.00" />
                                                            </span>
                                                        </td>
                                                        <td >
                                                            <span className={`input-field data-only`}>
                                                                <label htmlFor={''}>Remaining</label>
                                                                <input id={''} data-inputkey={"budget"} tabIndex="-1" type="text"
                                                                    value={currency(i.budget).format()} />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        </>
                                    )
                                })}
                            </table>

                            <div className={`line-item-status ${!this.state.formValid && this.state.formSubmission ? "error" : ""}`}>
                                {this.state.error}
                            </div>

                            <div className="submit-wrapper">
                                <div className="total-label">
                                    Draw Request Total: <span> {typeof this.state.drawTotal ? currency(this.state.drawTotal).format() : "$0.00"}</span>
                                </div>
                                <button className="button-submit-draw-request" type="button" onClick={this.handleSubmit} data-for="app-toolip" data-tip="Submit this Draw Request" disabled={window.localStorage.getItem("employeeToken")}>
                                    Request Draw
                                </button>
                            </div>

                            <p style={{ color: "#D4311E", fontWeight: "bolder" }}>
                                {this.state.formValid === false ? this.state.error : ""}
                            </p>

                        </fieldset>
                    </section>
                </div>
            </div>
        )
    }
}



export default DrawSheet