import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Section } from '../../../../components/shared/Section';

export default function PropertyApplications(props) {

    const sectionPropertyAppsHeading = (
      <Fragment>
        Property Applications
      </Fragment>
    );
  
    const sectionPropertyAppsBody = (
      <Fragment>
             <ul>
              <li>
                <Link to={`${process.env.REACT_APP_SESSION_URL}/applications/fix-n-flip`}>
                  <img src="/assets/images/FIXNFLIP-ICONv2.png" alt="img" className={"fixnflip"} />
                  <p>
                    Purchase or rehab an individual property in order to sell it for a profit.
                  </p>
                </Link>
              </li>
              <li>
                <Link to={`${process.env.REACT_APP_SESSION_URL}/applications/rental`}>
                  <img src="/assets/images/rentalIcon.png" alt="img" className={"rental"} />
                  <p>
                    Purchase or refinance cash-flowing rental properties.
                  </p>
                </Link>
              </li>
              <li>
                <Link to={`${process.env.REACT_APP_SESSION_URL}/applications/multifamily`}>
                  <img src="/assets/images/Multifamily-191x125.png" className={"multifamily"} />
                  <p>
                    Application for Multifamily.
                  </p>
                </Link>
              </li>
              <li>
                <Link to={`${process.env.REACT_APP_SESSION_URL}/applications/new-construction`}>
                  <img src="/assets/images/new-construction-for-website-home-page-2-271x125.png" className="construction" />
                  <p>
                    Application for New Construction.
                  </p>
                </Link>
              </li>
            </ul>
      </Fragment>
    );

    return (
        <Section heading={sectionPropertyAppsHeading} body={sectionPropertyAppsBody} />
    )
}