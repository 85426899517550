import React from 'react';
import axios from 'axios';
import FAQExpandable from './FAQExpandable';
import './index.scss';
import { Section } from '../../../components/shared/Section';
import TransitionIn from '../../../components/shared/TransitionIn';

const FrequentlyAskedQuestions = props => {
    function getPaymentusLink(e) {
        e.preventDefault();

        axios.get('/lima/api/makepayment')
            .then((res) => {
                let url = res.data.url;
                window.open(url, "_blank")
            })
            .catch(console.log)
    }

    const heading = "Frequently Asked Questions";

    const body = (
        <article className="faq">

            <FAQExpandable
                heading="When is my payment due, and how do I make my payment to Lima One?" content={
                    <>
                        <p>By the fifth business day of each month, invoices are uploaded to the online portal.  You can access your account information through the portal, and make your payment through the site.  Your payment due date is reflected on the portal.  A late charge of 5% of your monthly payment amount is assessed if your payment is not received within ten (10) days of your due date.  Please note, if you have a commitment funded loan, your monthly interest payment will increase as the principal balance of your loan increases.  Please review the monthly statement for detail regarding your construction draws and billing calculations.</p>
                        <p>We offer multiple payment options, however, we do not accept payment by debit or credit cards. We recommend setting your account up for recurring ACH withdrawal from your designated account (you can access the authorization form <a download href="/documents/ACH-Recurring-Payment-Authorization-Form.docx">here</a>), but you have the ability to make the monthly payment through ACH manually at the site, call in to your customer service analyst to take the payment over the phone, or by physically mailing a check (please include your loan number and property address on the “memo” line) to our office at:</p>

                        <p style={{ width: '100%', textAlign: 'center' }}><span>Lima One Capital LLC</span></p>
                        <p style={{ width: '100%', textAlign: 'center' }}><span>PO Box 612348</span></p>
                        <p style={{ width: '100%', textAlign: 'center' }}><span>San Jose, CA 95161</span></p>

                        <p>Please follow the link below to access the payment portal.  If you are unable to log in, please contact your customer service analyst so your credentials can be reset.  You will not be able to view your information in the portal until after month-end.</p>

                        <p style={{ width: '100%', textAlign: 'center' }}><a href="#" target="_blank" onClick={getPaymentusLink}>Payment Portal</a></p>
                    </>
                } />

            <FAQExpandable
                heading={'Are the real estate taxes and insurance premiums paid by Lima One?'}
                content={
                    <>
                        <p>If you have a loan with an escrow account to manage the payment of your real estate taxes and insurance premiums as they come due, Lima One would be responsible for making those payments in a timely manner.  If you have a Fixed Rental 30, Rental 30 Premier, 5/1 ARM Rental, or a 10/1 ARM Rental loan product, your account is escrowed for taxes and insurance.  You are responsible for any supplemental taxes that come due even if you have an escrowed loan. If you have a short-term loan product, including Multi-Family housing loan, you are responsible to ensure the real estate taxes and required insurance premiums are paid in a timely manner as they come due. You are also responsible for tracking and paying for your own Liability policy, as well as ensure that any HOA dues are paid timely as they come due.</p>
                        <p>If you make any changes to your insurance policy (change carrier or coverage), please ensure the mortgagee clause is updated and that Lima One is notified so that we can take appropriate action to update our records accordingly.  You may forward any tax or insurance documentation to us at <a href="mailto:servicing@limaone.com">Servicing@limaone.com.</a></p>
                    </>
                } />


            <FAQExpandable
                heading={'What is cross-collateralization for partial payoffs?'}
                content={
                    <>
                        <p>Cross-collateralization is an allocated amount of principal, established in your closing documentation, that is added to the payoff letters of individual properties in a portfolio loan. Cross-collateralization is directly applied to your principal balance and is no way a fee or penalty.</p>
                    </>
                } />


            <FAQExpandable
                heading={'Does Lima One offer extensions for FixNFlip loans?'}
                content={
                    <>
                        <p>
                            Extensions are offered in 3 month increments for qualified loans. Borrowers must be current on their monthly interest payments and have a verified exit strategy. An extension must be requested at least 30 days before the maturity date to allow Lima One Capital the ability to approve the extension before the maturity date, all loans that go past maturity will be charged interest at the default rate.
                        </p>
                        <p>
                            We require that you provide proof that your real estate taxes are paid current, and that you have extended your insurance policy through the new expected maturity date.  The application webform will also request information about your expected exit strategy.  It also will offer you the option to pay a discounted fee for the extension up-front (0.5%) or a higher fee (1.0%) if you opt to pay the fee at the time the loan is being paid off.
                        </p>
                    </>
                } />

            <FAQExpandable
                heading={'How do I change my information in the system?'}
                content={
                    <>
                        <p>If you need assistance to change any of your information in the system, please reach out directly to your assigned customer service analyst.</p>
                    </>
                } />

            <FAQExpandable
                heading={'How do I request a construction draw?'}
                content={
                    <>
                        <p>If you have a loan with construction funds held in escrow, you will be working directly with our Construction Management team to arrange for the release of those draws.  Your assigned construction analyst can assist you with draw requests and any additional questions you may have regarding your project. For a PDF walkthrough on how to request a draw click <a download href="/documents/resources/How-to-Request-a-Draw.pdf">here</a>.</p>
                    </>
                } />
        </article>
    )

    return (
        <TransitionIn content={ <Section heading={heading} body={body} /> } />
    )

}

export default FrequentlyAskedQuestions