import React from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";

import './index.scss';
import ButtonSubmit from "../../components/ButtonSubmit";

class SetPassword extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      submitted: false,
      password: null,
      cpassword: null,
      passwordmatch: null,
      email: null,
      token: null,
      errors: {
        password: '', 
        password2: '',
        passwordmatch: ''
      },
      params: {
        email: '',
        token: ''
      }
    }
  }

  componentDidMount() {
    let params = this.props.location.state ? this.props.location.state : this.props.match.params;
    if(params && 'token' in params) {
      let decoded = jwt_decode(params.token)
      params.email = decoded.aud
    } else {
      params = { email: '' }
    }
    this.setState({params, email: true});
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(this.state.submitted) return;

    this.setState({submitted: true})
    // let errors = this.state.errors;
    // let formData = document.querySelector('#enteremail');
    // let valid = 1;

    if (this.validateForm()) {
      let params = this.state.params;
      let passwordval = document.getElementById("input__password");
      var body = {"password": passwordval.value};
      var _this = this

      axios.defaults.headers.common['Authorization'] = "Bearer " + params.token;
      axios.defaults.headers.common['Content-Type'] = "application/json";
      axios.post('/lima/api/users/updatepassword',body)
      .then(function (response) {
        console.log(response)
        window.location.href = "/?t=reset"
      })
      .catch(function (error) {
        console.log(error);
        _this.setState({submitted: false})
      });
      
    } else {
      console.error('Invalid Form')
      _this.setState({submitted: false})
    }
  }

  validateForm = () => {
    let errors = this.state.errors;
    let passwordval = document.getElementById("input__password");
    let cpasswordval = document.getElementById("input__password2");
    let passwordCheck = /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/;

    let valid = 0;
  
    if (passwordval.value === "") {
      errors.password = "Password is required";
      valid = 1;
    } else if(!passwordCheck.test(passwordval.value)){
      errors.password = "Password must have 1 lowercase letter, 1 uppercase letter, 1 number, and be at least 8 characters long.";
      valid = 1;
    } else {
      errors.password = "";
    }

    if (cpasswordval.value === "") {
      errors.password2 = "Confirm Password is required";
      valid = 1;
    } else {
      errors.password2 = "";
    }

    if (cpasswordval.value !== passwordval.value) {
      errors.passwordmatch = "Password and confirm password do not match.";
      valid = 1;
    } else {
      errors.passwordmatch = "";
    }

    
    //Set any feild to true to kick off errors
    if (valid === 1) {
      this.setState({errors, password: true});
      return false;
    } else {
      this.setState({errors, password: null});
      return true;
    }
  }

  render() {
    const {errors, params} = this.state;

    return (
      <div className="sign-up set-password">
        <div className="content">

        <div className="sign-up-box-left1">
          <h1>Set Your Portal Password</h1>
          <h3>Go ahead and pick a password to create your portal account.</h3>
          <br />
        </div>
        
        <div className="sign-up-box-right1">
          <form onSubmit={this.handleSubmit} id="signupform2" className="signupform">
            <div className="sign-up-inner1">
              <div className="sign-up-left1">
                <div className="floating">
                  <input id="input__password" className={errors.password.length > 0 && {} ? 'floating__input password-input inperror' : 'floating__input password-input'} name="password" type="password" placeholder="Password*" style={{ borderBottom: "2px solid white"}} />
                  <label htmlFor="input__password" className={errors.password.length > 0 && {} ? 'floating__label laberror' : 'floating__label'} data-content="Password*">
                    <span className="hidden--visually">Password*</span>
                  </label>
                  {errors.password.length > 0 && <span className="erro-mess">{errors.password}</span>}
                </div>
                <div className="floating">
                  <input id="input__password2" className={errors.password2.length > 0 && {} ? 'floating__input password2-input inperror' : 'floating__input password2-input'} name="password2" type="password" placeholder="Confirm Password*"  style={{ borderBottom: "2px solid white"}} />
                  <label htmlFor="input__password2" className={errors.password2.length > 0 && {} ? 'floating__label laberror' : 'floating__label'} data-content="Confirm Password*">
                    <span className="hidden--visually">Confirm Password*</span>
                  </label>
                  {errors.password2.length > 0 && <span className="erro-mess">{errors.password2}</span>}
                </div>
              </div>
            </div>
            <div className="sign-up-inner6" style={{ padding: '1rem' }}>
              <br />
              <ButtonSubmit readOnly={this.state.submitted} style={{ margin: '0 auto' }} />
            </div>
          </form>
        </div>
        </div>
      </div>
    )
  }
}

export default SetPassword;