import React, { Fragment, useState, useEffect } from 'react';
import './index.scss';
import animations from '../../../animations';
import { useSpring, animated } from '@react-spring/web';
import { throttle } from 'throttle-debounce';
import TransitionIn from '../../shared/TransitionIn';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default withRouter(function TopNav(props) {

    const [expanded, setExpanded] = useState(false);
    const [mobile, setMobile] = useState(false)

    const toggle = () => {
        if (mobile) {
            setExpanded(!expanded)
        }
    }

    const styleExpanded = useSpring(animations("fadeInDown", expanded));
    const fadeIn = useSpring(animations("fadeIn", !mobile));
    const fadeInButton = useSpring(animations("fadeIn", mobile));
    
    useEffect(() => {
        checkWindowSize();
    }, [props.id]);

    const checkWindowSize = throttle(200, false, () => {
        setExpanded(false);

        if (window.innerWidth > "1023") {
            setMobile(false);
        } else {
            setMobile(true);
        }
    })

    const navMobile = (
        <Fragment>
            {
                expanded ?
                    <animated.ul className="top-navigation_list mobile" style={expanded ? styleExpanded : null} onClick={toggle}>
                        {props.links}
                    </animated.ul>
                    :
                    null
            }
        </Fragment>
    )

    // just pass a frag with li tags
    const navDesktop = (
        <animated.ul className="top-navigation_list desktop" style={fadeIn} >
            {props.links}
        </animated.ul>
    )


    window.addEventListener("resize", checkWindowSize);

    return (
        <Fragment>
            <nav className="top-navigation">
                <div className="background-navbar"></div>

                {
                    mobile ?
                        <animated.button onClick={toggle} style={fadeInButton}>
                            <span>
                            {
                                expanded ?
                                        <FontAwesomeIcon icon={faChevronUp} />
                                    :
                                        <FontAwesomeIcon icon={faChevronDown} />
                            }
                            </span>
                            <span>
                                {props.buttonText || "Click for More"}
                                </span>
                        </animated.button>
                        : null
                }


                <div className="top-navigation_content">
                    {mobile ? navMobile : navDesktop}
                </div>

                {
                    mobile ?
                        <animated.div className={`background ${expanded ? "is-active" : ""}`} onClick={toggle}>
                            {/* background */}
                        </animated.div>
                        : null
                }
            </nav>

            <div className="top-navigation-spacer">
                {/* spacer  */}
            </div>
        </Fragment>
    )
})