import React, { useState } from 'react'
import InputSelect from './InputSelect';
import InputText from './InputText';
import states from '../../../assets/config/_us_states.json'
import companies from '../../../assets/config/_companies.json';
import { Link } from 'react-router-dom';

const SelectBorrower = React.forwardRef((props, ref) => {
    let showOptions = true
    let showSelect = props.existing_borrower === true
    let showInputs = props.existing_borrower === false

    if (props.isBroker) {
        if (!props.existing_customer) {
            showSelect = false
            showInputs = true
        }

        showOptions = (props.isBroker && props.existing_customer !== null && (props.existing_customer && props.selectedCustomer))
    }

    if (props.broker) showOptions = false

    return (
        <div className="borrowing-entities">
            {/* <br /> */}
            <p>If submitting a deal application on behalf of a new customer or a new borrowing entity, you’ll eventually need to submit a {props?.whitelabel ? "line of credit application" :  <Link to="/dashboard/applications/line-of-credit">line of credit application </Link> } for them to be approved as a borrower.  Please contact your sales representative with any questions.</p>
            <br />
            <div className="input-field-group">
                {showOptions &&
                    <div className={`input-field full-width ${props?.errors?.existing_borrower && 'error'}`} >
                        {`Will ${props.isBroker ? 'the borrower' : 'you'} be using an existing entity for this upcoming deal?`}
                        <br />
                        <ul className={"radio-field"}>
                            <li>
                                <input ref={ref} style={{ width: '24px' }} type="radio" name={"existing_borrower"} id={"existing_borrower_yes"} value="true" onChange={() => props.setExistingBorrower(true)} /><label htmlFor={"existing_borrower_yes"}>Yes</label>
                            </li>
                            <li>
                                <input ref={ref} style={{ width: '24px' }} type="radio" name={"existing_borrower"} id={"existing_borrower_no"} value="false" onChange={() => props.setExistingBorrower(false)} /><label htmlFor={"existing_borrower_no"}>No</label>
                            </li>
                        </ul>
                        {props?.errors?.existing_borrower && <span className="error-message">Make a selection.</span>}
                    </div>
                }




                {showSelect && props.existing_borrower !== null ?
                    <>
                        <div className={`input-field full-width select-borrower ${props?.errors?.existing_borrower && 'error'}`}>
                            <label htmlFor="borrowing_entity_id">Select Borrowing Entity*</label>
                            <select ref={ref} name="borrowing_entity_id" onChange={(e) => props.selectBE(e)}>
                                <option key="CHOOSE" value="">Choose Option</option>
                                {
                                    props.borrowingEntities ? props.borrowingEntities.map((b, index) => {
                                        return (
                                            <option key={b.id + index} value={b.id}>{b.name}</option>
                                        );
                                    }) : ""
                                }
                            </select>
                        </div>
                        {/* <br /> */}
                        <div style={{ width: '100%', display: 'none' }}>
                            <InputText ref={ref} fullwidth={true} type={'text'} label={'Legal name of entity/borrower in which the loan will be closed*'} name={'borrowing_entity_name'} tabIndex={props.tabIndex} errors={props.errors} />
                        </div>
                    </>
                    : ((showInputs && props.existing_borrower !== null) || props.broker) ?
                        <section className="section">
                            <fieldset className="input-field-group requested-loan-info borrower-info-new" style={{ border: '0.15rem solid #f1f1f1' }}>
                                <div className="input-field full-width">
                                    <h1>New Borrowing Entity</h1>
                                    {/* <br /> */}
                                </div>

                                <div className="col-left">
                                    <InputText ref={ref} fullwidth={true} type={'text'} label={'Borrowing Entity Name*'} placeholder="Borrowing Entity Name*" name={'borrowing_entity_name'} tabIndex={props.tabIndex} errors={props.errors} />
                                    <InputSelect ref={ref} fullwidth={true} config={companies} label={'Company Type*'} name={'company_type'} tabIndex={props.tabIndex} errors={props.errors} />
                                    <InputText ref={ref} onChange={props.inputPercentage} type={'text'} label={'Ownership Percentage of Borrowing Entity*'} name={'ownership_percentage_of_borrowing_entity'} placeholder={"0%"} tabIndex={props.tabIndex} cleanup={true} errors={props.errors} />
                                </div>
                                
                                <div className="col-right">
                                    {/* ----- States Checkbox list ----- */}
                                    <div className="input-field-group_inner states-checkbox" id="loc">
                                        <div className={`input-field full-width ${'entity_registered_to_do_business_in_what_states' in props.errors ? 'error' : ''}`}>
                                            <label htmlFor={`entity_registered_to_do_business_in_what_states`} id="entity_registered_to_do_business_in_what_states">Registered to Do Business in What States*</label>
                                            <ul>
                                                {states.map(x => {
                                                    return (
                                                        <li key={x.name}>
                                                            <label>
                                                                <input ref={ref} type="checkbox" name={`entity_registered_to_do_business_in_what_states`} value={x.name} onClick={() => props.clearError("entity_registered_to_do_business_in_what_states")} />
                                                                {x.name}
                                                            </label>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            {'entity_registered_to_do_business_in_what_states' in props.errors && <span className="error-message">Registered to Do Business in What States* is required.</span>}
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </section>
                        : <span></span>}


            </div>
        </div>
    )

})

export default React.memo(SelectBorrower)
