import React from 'react';

// props =>
// "fd_key" refers to formData object in state - required

const InputCurrency = (props) => {
  // console.log(props.fd_key)
  // console.log(props.fd)
  // console.log(props.fd[props.fd_key])
  if (! (props.fd_key in props.fd)) return ""

  let label = props.label ? props.label : props.fd[props.fd_key].label
      
  return (
       <div className={`${props.inner ? 'input-field_inner' : props.notInputField ? '' : 'input-field'} ${props.fullwidth ? "full-width" : ""} ${props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? "error" : ""}`}>
        <label htmlFor={props.fd_key}>{label}</label>
        <input type="text" name={props.fd_key} id={props.fd_key} data-input={props.fd_key} onChange={props.inputCurrency}
          value={props.fd[props.fd_key].iRaw ? props.fd[props.fd_key].iRaw : undefined} placeholder="$0" autoComplete="off" />
        <span className="error-message">{(props.formSubmission && !props.fd[props.fd_key].iVal.isValid)  && props.fd[props.fd_key].error }</span>
      </div>
    )
}

export default InputCurrency