import React from 'react'
import ButtonSubmit from '../../ButtonSubmit';
import { Section } from '../../../App/components/shared/Section';

const SubmitApplication = React.forwardRef(({ errors, submitted, errorMsg, termsToggle, isBroker, isEmployee }, ref) => {

    const heading = (
        "Submit Application"
    )

    const body = (
        <React.Fragment>
            <p>
                My transmission of this application as an “electronic record” containing my “electronic signature,” as
                those terms are defined in applicable federal and/or state laws (excluding audio and video recordings),
                or my facsimile transmission of this application containing a facsimile of my signature, shall be as effective,
                enforceable and valid as if a paper version of this application were delivered containing my original written signature.
            </p>
            <p>
                Completion and/or submission of this Application is not to be considered a loan commitment from the Lender or the Lender’s broker for the Loan.
            </p>


            {/* ----- Signature and Date ----- */}
            <fieldset className="input-field-group sign-and-date row grow-2">
                <div className={`column input-field ${'signature' in errors ? 'error' : ''}`}>
                    <label htmlFor={"signature"}>{isBroker ? "First and Last Name of Broker*" : "First and Last Name of Primary Guarantor*"}</label>
                    <input ref={ref} type="text" name={"signature"} id={"signature"} data-input={"signature"} autoComplete={"off-off"} />
                    {'signature' in errors && <span className="error-message">Signature is required.</span>}
                </div>
                <div className={`column input-field ${'signature_date' in errors ? 'error' : ''}`}>
                    <label htmlFor={"signature_date"}>Date*</label>
                    <input ref={ref} type="date" name={"signature_date"} id={"signature_date"} data-input={"signature_date"} value={new Date().toISOString().slice(0, 10)} />
                    {'signature_date' in errors && <span className="error-message">Signature Date is required.</span>}
                </div>
            </fieldset>

            <p>In addition, you consent to the terms and conditions outlined <button onClick={termsToggle} style={{ color: "#0086CA" }}>here</button>.</p>

            <fieldset className="input-field-group">
                <div className={`input-field full-width ${'agree-to-terms' in errors ? 'error' : ''}`}>
                    <ul>
                        <li>
                            <input ref={ref} type="radio" name="agree-to-terms" id="agree-to-terms" />
                            <label htmlFor="agree-to-terms">I/We Agree*</label>
                        </li>
                    </ul>
                </div>

                <div className="input-field full-width submit-section">

                    <ButtonSubmit readOnly={submitted} disabledIf={errors.length > 0 || errorMsg || isEmployee} />

                    <div className={`form-error ${(errorMsg || errors.length > 0) ? 'is-active' : ''}`}>
                        {errorMsg !== "" ? errorMsg : "An error has occured, please try again later."}
                    </div>
                </div>
            </fieldset>


        </React.Fragment>
    )

    return (
        <Section heading={heading} body={body} max={20} />
    )
})

export default React.memo(SubmitApplication)