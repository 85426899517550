import React, { useState, useEffect } from 'react';
import ToolTips from './ToolTips';
import { log } from '../../../utility';

import './index.scss';

const Options = (props) => {

    const optionsOnLoad = window.localStorage.options;
    const optionsDefault = { toolTips: true };

    const [options, setOptions] = useState(optionsOnLoad ? JSON.parse(optionsOnLoad) : optionsDefault);

    function optionsUpdate (option, value) {
        let optionsCurrent = options;

        optionsCurrent[option] = value;

        setOptions(optionsCurrent);
        window.localStorage.options = JSON.stringify(optionsCurrent);
    }
   
    useEffect(() => {

        if(!optionsOnLoad) {
            window.localStorage.options = JSON.stringify(options);
        };
    },[])

    return (
        <React.Fragment>
            <ToolTips options={options} optionsUpdate={optionsUpdate} />
        </React.Fragment>
    )
}

export default Options;