import React from 'react';
import axios from 'axios';
import './index.scss';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import { SectionExpandable } from '../../../../../../components/shared/Section';

class ConstructionBudgetUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base64file: "",
      filename: "",
      uploadSuccess: null,
      uploadSubmitted: false,
      uploadError: null
    }
    this.setUploadStatus = this.setUploadStatus.bind(this);
    this.getBinary = this.getBinary.bind(this);
    this.pushApi = this.pushApi.bind(this)
  }

  setUploadStatus(result) {
    this.setState({
      uploadSuccess: result,
      uploadError: !result
    })
  }

  pushApi(opportunityid, budgetfilename, budgetfiledata, cb, cb_two) {
    this.setState({
      uploadSuccess: null,
      uploadError: null
    })

    let files = {
      "opportunityid": opportunityid,
      "budgetfilename": budgetfilename,
      "budgetfiledata": budgetfiledata
    }

    files = JSON.stringify(files);

    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
    axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/budget/new',
      files
    ).then((data) => {
      cb(true)
      cb_two()
    }).catch(function (error) {
      console.log(error);
      cb(false);
    });
  }

  getBinary(e) {
    this.setState({
      uploadSubmitted: true
    })

    let file = e.target.files[0];
    let name = e.target.value.split(/(\\|\/)/g).pop();
    let opportunityid = this.props.dealID;

    var reader = new FileReader();

    reader.onloadend = () => {
      var b64 = reader.result.replace(/^data:.+;base64,/, '');
      this.pushApi(opportunityid, name, b64, this.setUploadStatus, this.props.fetchDealDetails);
    };

    reader.readAsDataURL(file);

  }

  render() {
    const heading = "Construction Budget";

    const body = (
      <>
        <p>Please <a href="/documents/Construction_Import_Template_v5.0.xlsx" download>download your construction template budget</a> and, once completed, upload it below for your convenience.</p>
        <br />
        <div style={{ display: "flex", alignItems: "center" }}>
          <input type="file" name="file" id="file" data-input="file" onChange={this.getBinary} style={{ marginRight: "1rem" }} />
          <Loader
            visible={this.state.uploadSubmitted && (!this.state.uploadSuccess || this.state.uploadError)}
            type="Grid"
            color="#D9D9D9"
            height={30}
            width={30} />
        </div>
        <p>
          <br />
          <span style={{ display: `${(this.state.uploadSuccess) || (this.state.uploadError) ? "inline-block" : "none"}`, marginRight: "0.5rem" }} className={`upload-message ${this.state.uploadError ? "error" : ""}`}>
            {(this.state.uploadSuccess) ? "Your file was successfully uploaded!" : "Something went wrong with your upload - please try again later."}
          </span>
          <br />
          You have successfully uploaded {this.props.dealDetails.uploadedBudgets} files.
        </p></>
    )

    return (
      <SectionExpandable class={ "construction-budget-upload" } body={ body } heading={ heading } />
    );
  }
}


export default ConstructionBudgetUpload;