import { NavLink, withRouter } from 'react-router-dom';
import React from 'react';

const NavLinkGeneric = props => {
    const icon = props.icon || 'icon';
    const text = props.text || 'NavLink Text';
    const url = props.url || '';
    const target = props.target || '';

    return (
        <NavLink to={url} onClick={props.toggleNav} className="nav-item" activeClassName="is-active" exact target={target}>
            <div>
                <span className="button-icon">{icon}</span>
                <span>{text}</span>
            </div>
        </NavLink>
    )
}

export default withRouter(NavLinkGeneric)