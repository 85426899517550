import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import './index.scss';
import { log } from '../../utility';
import DevInfo from '../../components/shared/DevInfo';
import ReactGA from 'react-ga';
import ReactTooltip from 'react-tooltip';
import TransitionIn from '../../components/shared/TransitionIn';
import GoogleAnalytics from '../../components/shared/Tracking/GoogleAnalytics';
import { Helmet } from 'react-helmet';

// simple route for scaffolding/placeholders
const Demo = props => {

    useEffect(() => {
        ReactTooltip.rebuild();
    });
    if (window?.Appcues) {
        window.Appcues.page();
    }
    return (
        <Route path={props.path} exact={props.exact} render={({ ...rest }) => {

            return (
                <div className="route-wrapper">
                    <DevInfo info={rest.info} />
                    {props.text ? <h3 style={{ color: "white" }}>{props.text}</h3> : null}
                    {props.content ? props.content : null}
                </div>
            )
        }} />
    )
}

// basic route with added capability of passing props to children (standard for app)
const RouteBasic = ({ component: Component, layout: Layout, ...rest }) => {
    // log('RouteBasic - rest', { ...rest }, true);

    useEffect(() => {
        ReactTooltip.rebuild();
    });
    if (window?.Appcues) {
        window.Appcues.page();
    }

    return (
        <Route {...rest} render={(props) => (
            <Layout {...props} {...rest}>
                <DevInfo info={rest.info} />
                <Component accountType={rest.accountType} loans={rest.loans} {...props} {...rest} />
            </Layout>
        )} />
    )
};

// variable route that can render either a customer or broker component.  
// useful when the component does not include logic to differentiate (ie the applications contain their own logic for this)
// requires a seperate customer and broker component
const RouteVariable = ({
    componentBroker: ComponentBroker,
    componentCustomer: ComponentCustomer,
    ...rest }) => {

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    if (window?.Appcues) {
        window.Appcues.page();
    }

    if (rest.accountType === "CustomerAccount") {
        return (
            <Route {...rest} render={(props) => (
                <React.Fragment>
                    <ComponentCustomer {...props} {...rest} />
                </React.Fragment>
            )} />
        )
    } else {
        return (
            <Route {...rest} render={(props) => (
                <React.Fragment>
                    <ComponentBroker {...props} {...rest} />
                </React.Fragment>
            )} />
        )
    }
};

// basic protected route that redirects to login if signed out.  includes layout wrapper
const RouteProtected = ({
    component: Component,
    layout: Layout,
    ...rest
}) => {
    log('Routing - session valid - attempting to access protected route', '"' + rest.path + '"', false);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    if (rest.accountType) {
        log('Routing - session valid - accessing protected route', '"' + rest.path + '"');

        if (window?.Appcues) {
            window.Appcues.page();
        }
        return (
            <Route {...rest} render={(props) =>
                <Layout {...props} {...rest} >
                    <DevInfo info={rest.info} />
                    <Component {...props} {...rest} />
                </Layout>
            } />
        )
    } else {
        console.log('Routing - session invalid - redirecting to login...');
        return <Redirect to="/" />
    }
}

// basic unprotected Route.  includes layout wrapper
const RouteUnprotected = ({
    component: Component,
    layout: Layout,
    ...rest
}) => {

    useEffect(() => {
        ReactTooltip.rebuild();
    });
    if (window?.Appcues) {
        window.Appcues.page();
    }
    return (
        <Route {...rest} render={(props) =>
            <Layout {...props} {...rest} >
                <DevInfo info={rest.info} />
                <Component {...props} {...rest} />
            </Layout>
        } />
    )
}

export { Demo, RouteBasic, RouteVariable, RouteUnprotected, RouteProtected }
