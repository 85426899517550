import React, { useState } from 'react';
import TransitionIn from '../../../../components/shared/TransitionIn';

const FAQExpandable = props => {
    const [expanded, setExpanded] = useState();

    return (
        <React.Fragment>

            <button className={`faq-heading ${expanded ? 'is-active' : null}`} onClick={() => setExpanded(!expanded)}>
                <h6> {props.heading} </h6>
                <span>{expanded ? '-' : '+'}</span>
            </button>

            {expanded ? <TransitionIn content={
                <div className="faq-content">
                    {props.content}

                </div>
            } animation="fadeInDown" /> : null}



        </React.Fragment>
    )
}

export default FAQExpandable