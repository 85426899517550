// ----- v SUMMARY v ------------------------------------------------------------------------ 
// - Component for the Plaid link (dynamically rendered based on API and props)
// - Sprint 16 - US-7 - JR 2021-09-03

// ----- v DESCRIPTION v --------------------------------------------------------------------
// - Action Item child (<tr> with link to user plaid application/setup).  
// - Renders the Plaid link if the count of plaid accts. is either a) 0 or b) 1+ AND 
//   the Last Modified Date for plaid is older than the latest LOC Creation Date.

// - Note the original ask utilized the Plaid_Finance_Inst_Cnt__c instead of the count 
//   of Plaid acctounts.  This field is not reliable in SF as multiple accounts can exist
//   and the query we structured is better-suited to capture the data needed.

// - All LOCs are considered (see DevOps ticket notes).  Both datasets are passed into
//   this component as props and the arrays are joined.

// - API call was originally in this component, but to eliminate redundant calls on the
//   expansion of the collapse section the API calls were moved to the Cust. Dashboard  

// ----- v PROPS v --------------------------------------------------------------------------
// - recentLOCs - (required) approved/current LOCs
// - recentUnderwritings - (required) submitted LOCs
// - plaidModDates - (required) plaid modification dates array

// ----- v CRITICAL NOTES v -----------------------------------------------------------------
// - Plaid Data is lifted to the parent state in Custom Dashboard.  rendering logic will
//   remain here.  Consider moving the API call code itself to a file in this directory 
//   and export to the Parent to keep functionality at least together.

// ----- v STATE v --------------------------------------------------------------------------
// - Plaid daialogue box visibilty maintained using useState hook 

// ----- v API CALLS v ----------------------------------------------------------------------
// ----- v RENDERING v ----------------------------------------------------------------------

// ----- v IMPORTS v ------------------------------------------------------------------------


import React, { useState, useEffect } from 'react';
import './index.scss';
import { getMaxValue } from '../../../../../utility';
import DialogueBox from '../../../../../components/shared/DialogueBox';
import ButtonStandard from '../../../../../components/shared/ButtonStandard';
import ReactTooltip from 'react-tooltip';


const ActionItemPlaid = props => {

    // Method to hide the dialog box and call the plaid API (passed in as prop)
    const updatePlaid = function () {
        setPlaidDialogueBox(false); // toggle the dialog box (child component state managed here)
        props.getPlaidData(); // call Plaid API (passed from Dashboard Customer parent)
    };

    const envFieldName = process.env.REACT_APP_LIMA === "https://uat-limaone-api.us-e2.cloudhub.io" ||
        process.env.REACT_APP_LIMA === "https://prod-limaone-api.us-e2.cloudhub.io" ? "Proof of Liquidity" : "Connect to Plaid";

    // ------------------------------------------------------------------------------------------

    const [plaidDialogueBox, setPlaidDialogueBox] = useState(false); // Boolean - Plaid Dialogue Box visibility 
    const [itemVisible, setItemVisible] = useState(false); // stateful control of ActionItem rendering

    const LOCs = [...props.recentLOCs || [], ...props.recentUnderwritings || []];
    const plaidModDates = props.plaidModDates; // - Array - Plaid modification dates 
    const [LOCLast, setLOCLast] = useState(false);

    // ------------------------------------------------------------------------------------------

    const checklist = function () {

        const LOCLast = getMaxValue(LOCs, "CreatedDate", true);

        if (LOCLast && LOCLast?.checklistItems && LOCLast.checklistItems.length > 0) {

            const LOCLastChecklistStatusR = LOCLast.checklistItems.find(x => x.Name === envFieldName);

            if (LOCLastChecklistStatusR) {
                const LOCLastChecklistStatus = LOCLastChecklistStatusR.TC_Origination__Status__c

                if (!(LOCLast.Portal_Status__c === "Approved" || LOCLastChecklistStatus === "Closed")) {
                    setItemVisible(true);
                    setLOCLast(LOCLast.borrower.Borrowing_Entity_Name__r.Name);
                };
            }
        };
    };

    // ------------------------------------------------------------------------------------------

    useEffect(() => {

        // prevent any logic from executing until all props are vailable from API call higher in tree
        if (props.recentLOCs === null || props.recentUnderwritings === null || props.plaidModDates === null) {
            return;
        };

        // 1. LOCs records exist, Plaid records don't exist
        if (LOCs.length > 0 && plaidModDates.length === 0) {
            checklist();
        };

        // 2.  LOCs records exist, Plaid records exist
        if (LOCs.length > 0 && plaidModDates.length > 0) {

            if (new Date(getMaxValue(plaidModDates, "date")) < new Date(getMaxValue(LOCs, "CreatedDate", false))) {
                checklist();
            }
        }

        // fake commit

    }, [LOCs, plaidModDates])

    if (itemVisible) {
        return (
            <React.Fragment>
                <DialogueBox
                    visible={plaidDialogueBox} // required to show/hide element
                    closeCallback={updatePlaid} // callback to hide element + other parent logic
                    closeButton={false} // hide the close button that toggles visible
                    closeBackground={false} // prevent background click to toggle visible
                    content={
                        <div>
                            <h3>Action Items - Verify Assets</h3>
                        <br />
                            <p>
                                Your Plaid Asset Verification will open in another tab.
                                Once completed you may return to this tab. <br />
                                To see updated results on your needed Action Items please click
                                "refresh" below.
                            </p>

                            <ButtonStandard callback={updatePlaid} value={"Refresh Action Items"} />
                        </div>
                    } />

                <tr>
                    <td>
                        <span>
                            {`Borrower Application ${LOCLast ? "for " + LOCLast : ""}`}
                        </span>
                    </td>
                    <td>
                        <span>
                            {`-`}
                        </span>
                    </td>
                    <td>
                        <a href={`${process.env.REACT_APP_PLAID_URL}?recordId=${props.user.userId}`}
                            data-tip="Click here to verify assets for this Borrower Application.  Opens external link."
                            data-for="app-tooltip"
                            target="_blank"
                            onClick={() => setPlaidDialogueBox(true)}>
                            Verify Assets
                        </a>
                    </td>
                </tr>
                {ReactTooltip.rebuild()}
            </React.Fragment>
        );
    } else return null
}

export default ActionItemPlaid