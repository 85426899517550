import React from "react";
import InputWrapper from "./InputWrapper";

const InputText = React.forwardRef((props, ref) => {
//     return (
//         <InputWrapper {...props}>
//              <input ref={ref} type={props.type ? props.type : 'text'} name={props.name} id={props.id} className={props.className} tabIndex={props.tabIndex} placeholder={props.placeholder} readOnly={props.readOnly} disabled={props.disabled || props.readOnly} onChange={props.onChange} value={props.value} data-cleanup={props.cleanup} maxlength={props?.maxlength} autoComplete={"off-off"} />
//         </InputWrapper>
//     )
// })
  return (
    <InputWrapper {...props}>
      <input
        ref={ref}
        type={props.type ? props.type : "text"}
        name={props.name}
        id={props.id}
        tabIndex={props.tabIndex}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        disabled={props.disabled || props.readOnly}
        onChange={props.onChange}
        value={props.value}
        data-cleanup={props.cleanup}
        maxLength={props?.maxlength}
        autoComplete={"cc-csc"}
      />
    </InputWrapper>
  );
});

export default React.memo(InputText);
