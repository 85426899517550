import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ButtonSubmit from '../../../components/shared/ButtonSubmit';
import { Redirect, Link } from 'react-router-dom';
import './index.scss';
import TransitionIn from '../../../components/shared/TransitionIn';

export default function LoginForm(props) {
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        document.getElementById("favicon").href = "/favicon.ico"
    }, [])

    const onSubmit = async data => {
        setSubmitted(true);

        try {
            let username = data.Email;
            let password = data.Password;

            let response = await axios.post('/lima/api/users/validate', { username, password });

            if (!response?.data) {
                throw new Error("Bad response from server.")
            }

            let { token, name, email, accountType, userId, isLead } = response.data;

            window.localStorage.setItem("token", token);
            window.localStorage.setItem("name", name);
            window.localStorage.setItem("username", email);
            window.localStorage.setItem("accountType", accountType);
            window.localStorage.setItem("userID", userId)
            window.localStorage.setItem('dealContext', [])
            window.localStorage.setItem('userId', userId)
            window.localStorage.setItem('isLead', isLead)

            if (accountType === 'Employee') {
                window.localStorage.setItem("employeeToken", token)
            }

            // pass all args in order... could possibly be tidied with destructuring...
            props.login(accountType, email, name, token, userId, isLead);
        }
        catch (error) {
            console.log(error);

            setSubmitted(false);
            setError(true);
        }
    }

    // handle post-login urls redirects here
    if (props.accountType) {
        if (props.referral.includes(process.env.REACT_APP_SESSION_URL)) {

            // send the user to the protected redirect (deep link)
            return <Redirect to={props.referral} />
        } else {

            // send the user to the main dashboard - using local storage since we need it immediately - cold be moved to login set state callback!
            if (!window.localStorage.locRedirected) {
                window.localStorage.locRedirected = true; // set local storage locRedirected to true to prevent future reditects

                // if the loc count is less than one and no prios loc redirect, send to loc app
                return <Redirect to={`${process.env.REACT_APP_SESSION_URL}/applications/line-of-credit`} />
            } else {

                // else send to main dashbaord as usual
                return <Redirect to={process.env.REACT_APP_SESSION_URL} />
            }
        }
    }

    const login = (
        <section className="login-form-rf">
        <h1>
            Customer Sign In
        </h1>

        <form onSubmit={handleSubmit(onSubmit)} >

            <label htmlFor="Email">Email Address*</label>
            <input type="text"
                placeholder="address@email.com"
                name="Email"
                ref={register({ required: true, min: 2, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })} />

            {errors["Email"]?.type === "pattern" && <p style={{ textAlign: "center" }}>Valid email address required.</p>}


            <label htmlFor="Password">Password</label>
            <input
                placeholder="Password*"
                name="Password"
                type="password"
                ref={register({ required: true, min: 1 })} />


            <ButtonSubmit
                readOnly={submitted}
                type="submit"
                value={"Sign In"}
                valueReadOnly={"Signing In..."}
                className={`button-submit ${props.readOnly ? "read-only" : ""}`}
                onClick={handleSubmit}
                variant={"rounded"} />
        </form>

        <div>
           
            <aside style={{ textAlign: "center" }}>
            {(props.referral !== '/') && (props.referral.includes(process.env.REACT_APP_SESSION_URL)) ? <p style={{ textAlign: "center" }}>{`Directing to "${props.referral}" after login.`}</p> : null}
                {errors["Password"] && <p style={{ textAlign: "center" }}>Valid Email and Password are required.  For help with your password click below.</p>}
                {error ? <p>Invalid login credentials.  For help with your password click below.</p> : ''}
            </aside>

            <Link to="/forgot-password">Forgot Password?</Link>
            <Link to="/create-account">Create Account</Link>
        </div>
    </section>
    )

    return (

        <TransitionIn content={ login } />
    );
}