import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './index.scss'

class DealStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inspection: null,
            fetchedInspectionData: false
        }

        this.groupedSteps = {
            'Processing': ['Quoted Deal', 'Submitted Deal', 'Application'],
            'In Underwriting': ['Submitted to Operations', 'Processing', 'Borrower Underwriting', 'Approval Submitted 1', 'Approval Approved 1', 'Property Underwriting', 'Approval Submitted 2', 'Approval Approved 2', 'Final Estimate Issued'],
            'Clear to Close': ['Clear to Close']
        }
        this.groupedStepsLOC = {
            'Denied': ['Submission Rejected', 'Denied'],
            'In Review': ['Submitted for Large Line Approval', 'In Process'],
            'Approved': ['Conditional Approval', 'Approval'],
            'Expired': ['Expired']
        }
    }

    componentDidUpdate = () => {
        // if (this.props.dealDetails.id && !this.state.fetchedInspectionData) {
        //     this.setState({ fetchedInspectionData: true })
        //     axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        //     axios.defaults.headers['Content-Type'] = "application/json";

        //     axios.post('/lima/api/deals/inspection-data', {
        //         dealid: this.props.dealDetails.id
        //     }).then((data) => {
        //         if (data.data.CompletedDate) {
        //             this.setState({ inspection: data.data })
        //         }
        //     }).catch(function (error) {
        //     })
        // }
    }

    renderSteps = () => {
        const { status } = this.props.dealDetails;

        let currentStep = 0;

        let groupedSteps = this.groupedSteps

        Object.keys(this.groupedSteps).map(function (key, index) {
            var values = groupedSteps[key]
            if (values.includes(status)) currentStep = index
        })

        return Object.keys(this.groupedSteps).map(function (key, index) {
            var values = groupedSteps[key]

            return (
                <div className={`progress-step ${values.includes(status) && currentStep !== Object.keys(groupedSteps).length - 1 ? 'is-active' : index <= currentStep ? 'is-complete' : ''}`}>
                    <span style={{ color: 'black' }}>{key}</span>
                </div>
            )
        });

    }

    getLatestLOC = borrowerId => {
        if (this.props.dealDetails.locStatus) {
            let results = this.props.dealDetails.locStatus.map(locStatus => {
                if (locStatus.BorrowingEntityId == borrowerId) return locStatus
            })

            if (results.length > 0) {
                let latest = results[0]

                results.forEach(locStatus => {
                    if (new Date(locStatus?.CreatedDate) > new Date(latest?.CreatedDate)) {
                        latest = locStatus
                    }
                })

                return latest
            }
        }
    }

    renderLOCs = () => {
        if (this.props.dealDetails.borrowingEntities) {
            return this.props.dealDetails.borrowingEntities.map(obj => {
                let latestLoc = this.getLatestLOC(obj.BorrowingEntity__c)
                let groupedStepsLOC = this.groupedStepsLOC
                let acctLoc = this.props.dealDetails.acctLoc

                if (latestLoc || acctLoc) {

                    let displayText = "Submission Needed"
                    let statusIndex = Object.keys(this.groupedStepsLOC).length - 1

                    let date;
                    if(latestLoc){

                        Object.keys(this.groupedStepsLOC).map(function(key, index) {
                            var values = groupedStepsLOC[key]
                            if (values.includes(latestLoc.LOC_Status__c)) {
                                displayText = key
                                statusIndex = index
                            }
                        })

                        if (! latestLoc.LOC_Status__c && latestLoc.Sales_LOC_Status__c == 'Application Received') {
                            statusIndex = 1
                            displayText = 'Application Received'
                        }
                        date = new Date(latestLoc.CreatedDate)
                    }
                    
                    if(acctLoc.Entity_Milestone__c){
                        displayText = acctLoc.Entity_Milestone__c;
                        Object.keys(this.groupedStepsLOC).map(function(key, index) {
                            var values = groupedStepsLOC[key]
                            if (values.includes(acctLoc.Entity_Milestone__c)) {
                                displayText = key
                                statusIndex = index
                            }
                        })
                        if (acctLoc.Entity_Milestone__c == 'New') {
                            statusIndex = 1
                            displayText = 'Application Received'
                        }
                        date = new Date(acctLoc.ApplicationReceipt__c)

                    }

                    let icon = undefined
                    let className = undefined

                    switch (statusIndex) {
                        case 0: {
                            icon = faTimes
                            className = 'danger'
                            break;
                        }
                        case 1: {
                            icon = faCog
                            className = 'ordered'
                            break;
                        }
                        case 2: {
                            icon = faCheck
                            className = 'completed'
                            break;
                        }
                        default: {
                            icon = faExclamation
                            className = 'needed'
                            break;
                        }
                    }

                    let dateString = date?.toLocaleDateString()

                    return (
                        <React.Fragment>
                            <div className="deal-status-item">
                                <FontAwesomeIcon icon={icon} className={className} />
                                <span>{dateString}</span>
                                <p>Line of Credit {displayText}</p>
                            </div>
                        </React.Fragment>
                    )
                }
            })
        }
    }

    renderAppraisal = () => {
        let displayText = "Needed"

        let icon = faExclamation
        let className = "needed"

        if (this.props.dealDetails.appraisal.OrderedDate) {
            displayText = "Ordered"
            className = "ordered"
            icon = faCog
            if (this.props.dealDetails.appraisal.Paid == 'true') {
                displayText = "In Process"
                icon = faCog
            } else {
                displayText = "Pending Payment"
                icon = faExclamation
                className = "needed"
            }
        }

        if (this.props.dealDetails.appraisal.CompletedDate) {
            displayText = "Completed"
            icon = faCheck
            className = "completed"
        }

        return (
            <React.Fragment>
                <div className="deal-status-item">
                    <FontAwesomeIcon icon={icon} className={className} />
                    <span>{this.props.dealDetails.appraisal.CompletedDate ? new Date(this.props.dealDetails.appraisal.CompletedDate).toLocaleDateString() : this.props.dealDetails.appraisal.OrderedDate ? new Date(this.props.dealDetails.appraisal.OrderedDate).toLocaleDateString() : "TBD"}</span>
                    <p>Appraisal {displayText}</p>
                </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className="deal-status-wrapper">
            <div className="deal-status">
               
                    <div className="deal-details_panel left">
                        <div className="progress">
                            <div className="progress-track"></div>
                            {this.renderSteps()}
                        </div>
                    </div>

                    <div className="deal-details_panel status-container">
                        {this.renderLOCs()}

                        {this.props.dealDetails.appraisal &&
                            this.renderAppraisal()
                        }

                        {this.state.inspection &&
                            <React.Fragment>
                                <div className="deal-status-item ">
                                    <FontAwesomeIcon icon={this.state.inspection.CompletedDate ? faCheck : this.state.inspection.OrderedDate ? faCog : faExclamation} className={this.state.inspection.CompletedDate ? 'completed' : this.state.inspection.OrderedDate ? 'ordered' : 'needed'} />
                                    <span>{this.state.inspection.CompletedDate ? new Date(this.state.inspection.CompletedDate).toLocaleDateString() : this.state.inspection.OrderedDate ? new Date(this.state.inspection.OrderedDate).toLocaleDateString() : "TBD"}</span>
                                    <p>Inspection {this.state.inspection.CompletedDate ? "Completed" : this.state.inspection.OrderedDate ? "Ordered" : "Needed"}</p>
                                </div>
                            </React.Fragment>
                        }

                        {this.props.dealDetails.title &&
                            <React.Fragment>
                                <div className="deal-status-item ">
                                    <FontAwesomeIcon icon={this.props.dealDetails.title.CompletedDate ? faCheck : this.props.dealDetails.title.OrderedDate ? faCog : faExclamation} className={this.props.dealDetails.title.CompletedDate ? 'completed' : this.props.dealDetails.title.OrderedDate ? 'ordered' : 'needed'} />
                                    <span>{this.props.dealDetails.title.CompletedDate ? new Date(this.props.dealDetails.title.CompletedDate).toLocaleDateString() : this.props.dealDetails.title.OrderedDate ? new Date(this.props.dealDetails.title.OrderedDate).toLocaleDateString() : "TBD"}</span>
                                    <p>Title {this.props.dealDetails.title.CompletedDate ? "Completed" : this.props.dealDetails.title.OrderedDate ? "Ordered" : "Needed"}</p>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default DealStatus;