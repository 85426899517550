import React from 'react';
import "./index.scss";


const InputRadioBinary = (props) => {
    let label = props.label ? props.label : props.fd[props.fd_key].label
    return (
        <div className={`input-field ${props.fullwidth ? "full-width" : ""} ${props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? "error" : ""}`}>
            <label htmlFor={props.fd_key}>{label}</label>
            <ul>
                <li><input onChange={props.inputRadio} data-input={props.fd_key}
                    type="radio" name={props.fd_key} id={props.fd_key} value={"true"}
                    checked={props.fd[props.fd_key].iVal.value === true ? true : false} /> Yes</li>
                <li><input onChange={props.inputRadio} data-input={props.fd_key}
                    type="radio" name={props.fd_key} id={props.fd_key} value={"false"}
                    checked={props.fd[props.fd_key].iVal.value === false ? true : false} /> No</li>
            </ul>
            <span className="error-message">{(props.formSubmission && !props.fd[props.fd_key].iVal.isValid) && props.fd[props.fd_key].error}</span>
        </div>
    )
}

export default InputRadioBinary