const validateInput = {

    // text field validation
    text: (i) => {
        // i = i.trim(); // clean up the input 

        if(i.length < 1) {
            i = "";
        }

        return i
    },

    number: (i) => {
        if (i.match(/^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/)) {
            return parseInt(i.replace(",",""), 10);
        }
    },

    float: (i) => {
        if (i.match(/^[+-]?\d+(\.\d+)?$/)) {
            return parseFloat(i);
        }
    },

    email: (i) => {
        // i = i.trim(); 

        if(i) {
            console.log('vaild email');
        } else {
            i = "";
        }
        

        return i;
    },
    
    phone: (i) => {
        // i = i.trim();
        if (i.match(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/) 
        && i.replace(/\D/g, '').length >= 10) {
        i = i.replace(/\D/g, '');
            return i
        }
    },

    date: (i) => {
        // date validation
    },

    ssn: (i) => {
        if (i.match(/^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/) && i.length === 9) {
            return parseInt(i.replace(",", ""), 10);
        }
    }
}

export { validateInput }