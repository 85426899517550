import React from 'react'
import InputRadioBinary from '../../_components/InputRadioBinary'
import InputHiddenSelect from '../../_components/InputHiddenSelect'
import InputHiddenText from '../../_components/InputHiddenText'

const Questionnaire = React.forwardRef((props, ref) => {

    return (
        <div className="row input-radio-fields" style={{ paddingTop: '1rem' }}>
            <fieldset className="input-field-group boxed">

                <table>
                    <tr>
                        <td>
                            <InputHiddenText ref={props.register} inverse={true} watch={props.watch} name="Properties_currently_rented__c" label="Are all properties currently rented?*" tabIndex={props.tabIndex} errors={props.errors}
                                             children={[
                                                 {
                                                     name: 'Occupancy_Percent__c',
                                                     label: 'What is the current occupancy %?',
                                                     onChange: props.inputPercentage,
                                                     placeholder: '0%',
                                                     cleanup: true
                                                 }
                                             ]}
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Any_properties_subject_to_lease__c"}
                                              label="Are any of the properties subject to a lease purchase option, or ground lease?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputHiddenText ref={props.register} watch={props.watch} name="Existing_Mortgage__c" label="Is there an existing mortgage on the property?*" tabIndex={props.tabIndex} errors={props.errors}
                                             children={[
                                                 {
                                                     name: 'Mortgage_Company__c',
                                                     label: 'Who is the mortgage company?*'
                                                 },
                                                 {
                                                     name: 'Estimated_Outstanding_Balance__c',
                                                     label: 'Estimated outstanding balance*',
                                                     placeholder: "$0.00",
                                                     onChange: props.inputCurrency,
                                                     cleanup: true
                                                 }
                                             ]}
                            />
                        </td>
                        <td>
                            <InputHiddenText ref={props.register} watch={props.watch} name="Any_other_existing_liens__c" label="Are there any other existing liens on the property?*" tabIndex={props.tabIndex} errors={props.errors}
                                             children={[
                                                 {
                                                     name: 'Lien_Holder_Name__c',
                                                     label: 'Who are the lien holders?*'
                                                 },
                                                 {
                                                     name: 'Estimated_Lien_Outstanding_Balance__c',
                                                     label: 'Estimated outstanding balance*',
                                                     placeholder: "$0.00",
                                                     onChange: props.inputCurrency,
                                                     cleanup: true
                                                 }
                                             ]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"All_the_properties_currently_vested__c"}
                                              label="If the subject transaction includes a refinance, Are all properties currently vested in the proposed borrowing entity?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Down_payment_required__c"}
                                              label="Is any part of the down payment or operating reserve required, borrowed or encumbered by a debt obligation?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Properties_in_rent_ready__c"}
                                              label="Are all properties in rent ready condition?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Sponsors_or_entity_members_occupy__c"}
                                              label="Do any of the sponsors or entity members intend to occupy any of the properties?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Has_personal_or_business_relationship__c"}
                                              label="If the subject transaction includes a purchase. Does any loan party have a personal or business relationship with the seller?*"
                            />
                        </td>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Use_Lima_One_Capital_s_Preferred_Title_I__c"}
                                              label="Use Lima One Capital's Preferred Title Insurance Provider?*"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <InputRadioBinary ref={ref} tabIndex={props.tabIndex} errors={props.errors}
                                              name={"Use_Lima_One_Capital_s_Preferred_Propert__c"}
                                              label="Use Lima One Capital's Preferred Property Insurance Provider?*"
                            />
                        </td>
                    </tr>
                </table>

            </fieldset>
        </div>
    )

})

export default React.memo(Questionnaire)