import React, { useRef } from 'react';
import TransitionIn from '../../../../../components/shared/TransitionIn';

import { SectionExpandable } from '../../../../../components/shared/Section'
import CopyTable from '../../../../../components/shared/CopyTable';
import SkeletonTable from '../../../../../components/shared/SkeletonTable';
import animations from '../../../../../animations';
import { useSpring, animated } from '@react-spring/web';

function Property(props) {
    const details = props.deal?.properties || null;
    const tableRef = useRef();

    const animation = "fadeInDown";
    const styleLoaded = useSpring(animations(animation, details));

    const heading = (
        <span>Deal Properties ({details !== null ? details?.length : 0})</span>
    )

    const controls = (
        <CopyTable tableRef={tableRef} />
    )

    const body = (
        <React.Fragment>

            {
                details === null ? <SkeletonTable count={1} /> :

                    <animated.table ref={tableRef} className="table" style={styleLoaded}>

                        <thead>
                            <tr>
                                <th>
                                    <span>ADDRESS</span>
                                </th>
                                <th>
                                    <span>STATE</span>
                                </th>
                                <th>
                                    <span>CITY</span>
                                </th>
                                <th>
                                    <span>ZIP</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                details.length > 0 ?

                                    details.map(property => {
                                        return (
                                            <tr>
                                                <td>
                                                    <span>
                                                        {property.street}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>
                                                        {property.state}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>
                                                        {property.city}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>
                                                        {property.zip}
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })


                                    :

                                    <tr>
                                        <td>
                                            <span>No properties are available for this deal.</span>
                                        </td>
                                    </tr>

                            }

                        </tbody>

                    </animated.table>


            }

        </React.Fragment>
    )


    return (
        <TransitionIn content={
        <SectionExpandable body={body} heading={heading} controls={controls} 
        id="deal-properties" animate={"fadeInDown"} flush />
    } />
    )

}

export default Property