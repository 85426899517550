// object used to populate the formData fields in state
const formData = {
    first_name: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    last_name: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    middle_name: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: true
        },
        optional: true,
        error: "error message"
    },
    mailing_address: {
        iVal: {
            value: [],
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    city: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    state: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    zip: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    date_of_birth: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    ssn: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    email: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    cell: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: true,
        error: "error message"
    },
    business_name: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: true,
        error: "error message"
    },
    // licenses_held: {
    //     iRaw: "",
    //     iVal: {
    //         value: "",
    //         isValid: false
    //     },
    //     error: "error message"
    // },
    are_you_foreign_national: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    signature: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        optional: false,
        error: "error message"
    },
    sign_date: {
        iRaw: new Date().toISOString().slice(0, 10),
        iVal: {
            value: new Date().toISOString().slice(0, 10),
            isValid: true
        },
        optional: false,
        error: "error message"
    }
}

export { formData }