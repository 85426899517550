import React, { useState } from 'react';

const InputAO = props => {
    const [isActive, setActive] = useState(false);

    const handeClick = function (e) {
        e.preventDefault();
        props.handleEvent(e); // updating form data in parent
        setActive(!isActive); // toggling active state
    }

    return (
        <button className={`input-ao ${props.isActive ? "is-active" : null}`}
            id={"input-ao-" + props.value}
            tabIndex="0"
            type="button"
            data-value={props.value}
            onClick={handeClick}>

            <img src={props.img} alt={props.valueDisplay} />
            <p>{props.valueDisplay}</p>

        </button>
    )
}

export default InputAO;