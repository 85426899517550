// ----- v SUMMARY v ------------------------------------------------------------------------ 
// - Main entry point for App.

// ----- v DESCRIPTION v --------------------------------------------------------------------

// ----- v PROPS v --------------------------------------------------------------------------

// ----- v STATE v --------------------------------------------------------------------------

// ----- v API CALLS v ----------------------------------------------------------------------

// ----- v CRITICAL NOTES v -----------------------------------------------------------------

// ----- v IMPORTS v ------------------------------------------------------------------------

// Libraries - React | Axios
import React from 'react';
import axios from 'axios';

// Libraries - Tracking for AppCues and Google Analytics
import AppCues from './components/shared/AppCues';
import GoogleAnalytics from './components/shared/Tracking/GoogleAnalytics';

// Styles (see additional specific style imports in this file)
import './index.scss';

// Session Management
import IdleNew from './components/protected/IdleTimer';

// Navigation (dashboard session) | Top Bar (dashboard session) | 
import MainNavNew from './components/protected/MainNavNew';
import TopBar from './components/protected/TopBar';
import DevNav from "./components/shared/DevNav";

// Router | Route Wrappers | Route Layouts 
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { RouteBasic, RouteVariable } from './routing/Wrappers';
import { Base, Min } from './routing/Layouts';

// Routes - Unprotected (non-session) | Routes White Label | Routes General
import RoutesUnprotected from './routing/RoutesUnprotected';

// Routes - Dashboard (sessin - variable route for Customer and Broker accounts)
import DashboardCustomer from './routing/RoutesProtected/Dashboard/Customer';
import DashboardBroker from './routing/RoutesProtected/Dashboard/Broker';

// Routes - Deal (session - subroutes/views are nested in the main routes)
import DealAudit from './routing/RoutesProtected/DealAudit';
import { DealProvider } from './routing/RoutesProtected/Deal/DealContext';

// Routes - User Profile (session) | Frequently Asked Questions (session)
import Profile from './routing/RoutesProtected/Profile';
import FAQ from './routing/RoutesUnprotected/FAQ';

// Routes - Applications/Forms (session)
import Applications from './routing/RoutesProtected/Applications';
import ServicingRequests from './routing/RoutesProtected/ServicingRequests';

// Routes - Thank You for Applications/Forms (session/non-session)
import ThankYouWhiteLabel from './routing/RoutesUnprotected/ThankYouWhiteLabel';
import ApplicationThankYou from './routing/RoutesProtected/ApplicationThankYou';

// Routes - 404 (session/non-session)
import FourOhFour from './routing/RoutesUnprotected/FourOhFour';

// Routes - Development Sandbox
import Sandbox from './routing/RoutesProtected/DevSandbox';
import Swal from "sweetalert2";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // user information (passed later as user prop via passUser function)
            accountType: '', // acts as logged in/out state - "" = logged out (needed by all sessions/protected routes!)
            username: "", // user email address - here if needed but mostly used from local storage
            name: "", // user name - here if needed but mostly used from local storage
            token: "", // JWT token - here if needed but mostly used from local storage
            userId: "", // needed to pass to Google Analytics
            isLead: false,

            // referral for redirect logic (post-login)
            referral: "/", // url ref for redirects on load (deep linking) - captured on initial page/app load

            // deal arrays for navigation and basic deal info population  (passed later as nav prop via passLoans function)
            loansFunded: null, // empty array needed on mount
            loansConstruction: null, // empty array needed on mount
            loansInProcess: null, // empty array needed on mount
        }

        this.login = this.login.bind(this);  // login method bound to app instance
        this.logout = this.logout.bind(this); // logout method bound to app instance

        this.fetchDeals = this.fetchDeals.bind(this); // fetch nav links needed for nav object prop/nav menu

        // clear the redirect deep link ref captured on page/app load... experimental - do not remove | JR 2021-12-01
        // this.clearReferral = this.clearReferral.bind(this); 
        // NOTE - methods for managing session - handled in IdleTime (timeout and renew token)
    }

    // get ref url on initial page load - save to state in mount for use after login    
    path = window.location.pathname;

    // method to fetch nav links on successful validation/login
    fetchDeals() {
        if(this.state.isLead?.toString() === "true"){
            return;
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.token;
        axios.defaults.headers['Content-Type'] = "application/json";

        // url for nav links (dynamic depending on account type)
        const url = this.state.accountType === "CustomerAccount" ?
            process.env.REACT_APP_LIMA + '/lima/api/deals/nav' :
            process.env.REACT_APP_LIMA + '/lima/api/deals/brokernav';

        axios.get(url)
            .then(res => {
                const data = res.data;

                this.setState({
                    loansFunded: data.funded_loans,
                    loansConstruction: data.construction,
                    loansInProcess: data.loans_in_process,
                })
            })
            .catch(error => {
                // leave initial empty arrays in state and let error fall silent...

                this.setState({
                    loansFunded: [],
                    loansConstruction: [],
                    loansInProcess: [],
                })
            })

    }

    // Clear the current ref URL for deep links... experimental - do not remove | JR 2021-12-01
    // clearReferral() {
    //     log('Referral cleared.')
    //     this.setState({
    //         referral: '/'
    //     })
    // }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.token;
        axios.defaults.headers['Content-Type'] = "application/json";
        axios.defaults.baseURL = process.env.REACT_APP_LIMA; // set axios API base url from env/config vars

        // check the local storage first in case of existing session!!!
        // if session data exists 
        const accountType = window.localStorage.accountType;
        const username = window.localStorage.username;
        const token = window.localStorage.token;
        const name = window.localStorage.name;
        const userId = window.localStorage.userId;
        const isLead = window.localStorage.isLead;

        // if accountType is not present, update state with account info for session
        // some of these, especially the accountType, should be removed from localStorage and re-queried on load or renew token
        if ((accountType)) {
            this.setState({
                accountType: accountType,
                username: username,
                name: name,
                isLead: isLead,
                token: token,
                userId: userId,
                referral: this.path || '/',
            }, this.fetchDeals); // once session is successfully established, call the nav links api endpoint to populate menu
        } else {
            if (this.path === "/") return; // prevent pointless re-render/state update
            this.setState({
                referral: this.path
            })
        }

    }

    componentDidUpdate() {
        // silence...
    }

    // method for login to pass to login component (args from response of login API - already set in local storage)
    // NOTE that not all of these are/will be used as of now for state mgmt (login redirects for example need immediate access to some local storage)
    // but these are here for use in the app if needed at any point
    login(accountType, email, name, token, userId, isLead) {
        this.setState({
            accountType: accountType,
            username: email,
            name: name,
            token: token,
            userId: userId,
            isLead: isLead
        }, this.fetchDeals) // FUTURE - move all post-login API calls and tracking here in a wrapper function/callback
    }

    //  Idle arg is experimental - do not remove | JR 2021-12-01
    logout(idle) {
        // REFACTOR - later managed in <AppCues /> | JR 2021-12-01?
        if (window?.Appcues) {
            window.Appcues.reset(); // reset/clear appcues session
        }

        // clear local storage (explicit keys only - do not remove all)
        window.localStorage.accountType = '';
        window.localStorage.token = '';
        window.localStorage.name = '';
        window.localStorage.username = '';
        window.localStorage.sessionStart = '';
        window.localStorage.employeeToken = "";
        window.localStorage.userId = "";
        window.localStorage.isLead = '';

        // note that we DO NOT reset the locRedirect as it needs to persist across sessions
        // it may be useful later to store the user email in case diff users ever use same browser 
        // so we can compare and reset for new user if needed

        // update new state / reset app
        this.setState({
            userId: "",
            accountType: '',
            username: '',
            name: '',
            token: '',
            userID: '',
            referral: '/',
            loansFunded: [],
            loansConstruction: [],
            loansInProcess: [],
            isLead: false
            // idle: idle || false // experimental - not used as of 7-23-21
        });
    };

    // method for creating a nav object to be passed as prop to MainNav (easier to manage as an object)
    passLoans() {
        return {
            loansConstruction: this.state.loansConstruction,
            loansFunded: this.state.loansFunded,
            loansInProcess: this.state.loansInProcess,
            fetchDeals: this.fetchDeals,
            logout: this.logout
        };
    };

    // method for creating a user object to be passed as prop (easier to manage prop as an object)
    passUser() {
        return {
            name: this.state.name,
            email: this.state.username,
            accountType: this.state.accountType,
            userId: this.state.userId,
            isLead: this.state.isLead
        };
    };

    render() {
        const { accountType, username, referral, name, isLead } = this.state; // data destructured from state

        const loans = this.passLoans(); // create new nav object to pass as prop
        const user = this.passUser(); // create new user object to pass as prop 

        return (
            <div className={`${accountType ? "session" : ""} app-wrapper-new`}>
                <AppCues user={user} /* all appcues methods here for session and non-session users */ />

                {/* Experimental Features */}
                {/* Global Options */}
                {/* <Options /> */}

                {accountType ? // if logged in, render/start idle timer
                    <IdleNew
                        logout={this.logout} // logout function passed from top level of app
                        actionInterval={10} // determines how long in minutes an interval passes between user actions before renewing session (must be less than idleTimeout)
                        modalTimer={2} // determines time modal displays in minutes for renewing session/auto log out
                        idleTimeout={20} // determines time passed in minutes until user is idle (idling triggers modal - must exceed 0 or evaluates to falsey!!!)
                    />
                    : null /* else no session == no timeout needed*/}

                <Router>
                    <DealProvider>
                        <DevNav /* this is for DEV ONLY for quick access to all URLs */ />

                        <Switch>
                            <Route path={`${process.env.REACT_APP_SESSION_URL}`} render={() => {
                                if (!accountType) {
                                    return <Redirect to="/" message={''} />
                                } else {

                                    return (
                                        <div className="app-protected">
                                            <div className="app-protected_side left">
                                                <MainNavNew nav={loans} accountType={accountType} user={user} />
                                            </div>

                                            <div className="app-protected_side right" id="app-scroll-anchor">
                                                <TopBar username={username} accountType={accountType} login={this.login} />

                                                <Switch>

                                                    {/* Experimental Features */}
                                                    {process.env.REACT_APP_DEV_MODE ?
                                                        <RouteBasic exact path={`${process.env.REACT_APP_SESSION_URL}/sandbox`}
                                                            title={'Sandbox'}
                                                            component={Sandbox}
                                                            accountType={accountType}
                                                            layout={Base}
                                                            nav={loans}
                                                            user={user} />
                                                        : null}

                                                    <RouteBasic exact
                                                        path={`${process.env.REACT_APP_SESSION_URL}/profile`}
                                                        title={'Profile'}
                                                        component={Profile}
                                                        accountType={accountType}
                                                        layout={Base}
                                                        name={name} />

                                                    <RouteBasic exact={true}
                                                        path={`${process.env.REACT_APP_SESSION_URL}/faq`}
                                                        title={'Frequently Asked Questions'}
                                                        component={FAQ}
                                                        layout={Base} />

                                                    <RouteBasic exact={false}
                                                        path={`${process.env.REACT_APP_SESSION_URL}/applications`}
                                                        title={'Applications'}
                                                        component={Applications}
                                                        accountType={accountType}
                                                        layout={Base}
                                                        loans={loans}
                                                        user={user} />

                                                    <RouteBasic exact={false}
                                                        path={`${process.env.REACT_APP_SESSION_URL}/deal/:id`}
                                                        accountType={accountType}
                                                        component={DealAudit}
                                                        layout={Min} />

                                                    <RouteBasic exact={true}
                                                        path={`${process.env.REACT_APP_SESSION_URL}/deal/`}
                                                        title={'404'}
                                                        accountType={accountType}
                                                        component={FourOhFour}
                                                        layout={Min} />

                                                    <RouteBasic path={`${process.env.REACT_APP_SESSION_URL}/servicing-requests`}
                                                        component={ServicingRequests}
                                                        accountType={accountType}
                                                        layout={Base} />

                                                    <RouteVariable exact={false}
                                                        path={`${process.env.REACT_APP_SESSION_URL}`}
                                                        user={user}
                                                        loans={this.passLoans()}
                                                        componentCustomer={DashboardCustomer}
                                                        componentBroker={DashboardBroker}
                                                        accountType={accountType}
                                                        layout={Base} />

                                                    <RouteBasic path={`${process.env.REACT_APP_SESSION_URL}/applications`}
                                                    component={ApplicationThankYou} />

                                                </Switch>
                                            </div>
                                        </div>
                                    )
                                }
                            }} />

                            {/* TEMP FIX */}
                            <RouteBasic path="/applications/thank-you" layout={Min} component={ThankYouWhiteLabel} accountType={accountType} />

                            <RoutesUnprotected
                                accountType={accountType}
                                login={this.login}
                                referral={referral}
                                isLead={isLead}
                                />

                        </Switch>

                    </DealProvider>

                    <RouteBasic
                        component={GoogleAnalytics}
                        layout={Min}
                        logValue={""} // value to pre-prend logging for easier filtering in console
                        user={user} />

                </Router>

            </div>
        )
    }
}

export default App;
