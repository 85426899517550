import React from "react";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import InputDate from "./InputDate";
import configNC from "./configs/_loan_purpose_new_construction.json";
import configLP from "./configs/_loan_purpose.json";
import configPM from "./configs/_property_manager.json";
import { Section } from "../../../App/components/shared/Section";

const LoanInfo = React.forwardRef((props, ref) => {
  const watchLoanPurpose = props.watch("Loan_Purpose__c");

  const renderInputs = () => {
    switch (props.application_type) {
      case "rental":
        return (
          <div className="row grow-4">
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={
                  "Purchase Price (provide the original purchase price for refinances)*"
                }
                name={"Purchase_Price__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Estimated Market Monthly Rent Amount*"}
                name={"Estimated_Monthly_Rent_Amount__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Annual Taxes*"}
                name={"Annual_Taxes__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Annual HOA Dues*"}
                name={"Annual_HOA__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Current As-Is Market Value*"}
                name={"Current_As_Is_Market_Value__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Current or Estimated Rent Amount*"}
                name={"Current_Monthly_Rent_Amount__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Annual Insurance*"}
                name={"Annual_Insurance__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Requested Loan Amount*"}
                name={"Requested_Loan_Amount__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
          </div>
        );
      case "fixnflip":
        return (
          <div className="row grow-3">
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={
                  "Purchase Price (the original purchase price for refinances)*"
                }
                name={"Purchase_Price__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Current As-Is Market Value*"}
                name={"Current_As_Is_Market_Value__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Total Rehab Budget Remaining*"}
                name={"Total_Rehab_Budget_Remaining__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Total Verifiable Rehab Already Completed to Date*"}
                name={"Total_Verifiable_Rehab__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Estimated After Repair Value*"}
                name={"Estimated_after_Repair_Value__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Requested Loan Amount*"}
                name={"Requested_Loan_Amount__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
          </div>
        );
      case "construction":
        return (
          <div className="row grow-4">
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={
                  "Purchase Price (the original purchase price for refinances)*"
                }
                name={"Purchase_Price__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Current As-Is Market Value*"}
                name={"Current_As_Is_Market_Value__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Total Rehab Budget Remaining*"}
                name={"Total_Rehab_Budget_Remaining__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Total Verifiable Rehab Already Completed to Date*"}
                name={"Total_Verifiable_Rehab__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Estimated After Repair Value*"}
                name={"Estimated_after_Repair_Value__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Requested Closed Amount*"}
                name={"Requested_Closed_Amount__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
            <div className="column">
              <InputText
                ref={ref}
                onChange={props.inputCurrency}
                placeholder="$0.00"
                type={"text"}
                label={"Requested Loan Amount*"}
                name={"Requested_Loan_Amount__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                cleanup={true}
              />
            </div>
          </div>
        );
      default: return undefined;
    }
  };

  let renderDOP = false;

  if (props.application_type === "construction") {
    renderDOP =
      watchLoanPurpose && watchLoanPurpose === "Mid Construction Refinance";
  } else {
    renderDOP = watchLoanPurpose && watchLoanPurpose !== "Purchase";
  }

  const body = (
    <>
    <div className="row grow-3">
      <div className="column">
        <InputSelect
          ref={ref}
          config={
            props.application_type === "construction" ? configNC : configLP
          }
          label={"Loan Purpose*"}
          name={"Loan_Purpose__c"}
          tabIndex={props.tabIndex}
          errors={props.errors}
        />
      </div>

      {renderDOP && props.application_type !== "construction" && (
        <div className="column">
          <InputDate
            ref={ref}
            label={"Date the property was originally purchased*"}
            name={"Originally_Purchased_Date__c"}
            tabIndex={props.tabIndex}
            errors={props.errors}
            max={new Date().toISOString().slice(0, 10)}
          />
        </div>
      )}

      <div className="column">
        <InputDate
          ref={ref}
          label={"Target Close Date*"}
          name={"Target_Close_Date__c"}
          tabIndex={props.tabIndex}
          errors={props.errors}
          min={new Date().toISOString().slice(0, 10)}
        />
      </div>


      {(props.application_type === "construction" ||
        props.application_type === "fixnflip") && (
          <>
              {props.application_type !== "construction" &&
                  <div className="column">
                      <InputSelect
                          ref={ref}
                          config={[
                              {
                                  name: "FixNFlip (Rehab work is being completed)",
                                  value: "FixNFlip",
                              },
                              {
                                  name: "Bridge Loan (No rehab)",
                                  value: "Bridge Loan",
                              },
                          ]}
                          label={"Loan Program*"}
                          name={"Loan_Program__c"}
                          tabIndex={props.tabIndex}
                          errors={props.errors}
                      />
                  </div>
              }
            <div className="column">
              <InputSelect
                ref={ref}
                config={[
                  {
                    name: "Sell",
                    value: "Sell",
                  },
                  {
                    name: "Hold as rental",
                    value: "Hold as rental",
                  },
                ]}
                label={"Exit Strategy*"}
                name={"Exit_Strategy__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
              />
            </div>

            <div className="column">
              <InputText
                ref={ref}
                type={"text"}
                placeholder="XX months"
                label={
                  "How long do you anticipate it taking from loan close, to selling/refinancing the property?*"
                }
                name={"Anticipation_period_for_loan_close__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
              />
            </div>

            {props.application_type === "construction" && (
              <div className="column">
                <InputSelect
                  ref={ref}
                  config={[
                    {
                      name: "Purchasing Lot",
                      value: "Purchasing Lot",
                    },
                    {
                      name: "Paying Off Lot Loan",
                      value: "Paying Off Lot Loan",
                    },
                    {
                      name: "Lot Owned Free & Clear",
                      value: "Lot Owned Free & Clear",
                    },
                  ]}
                  label={"Lot Status*"}
                  name={"Lot_Status__c"}
                  tabIndex={props.tabIndex}
                  errors={props.errors}
                />
              </div>
            )}
          </>
        )}

      {renderDOP && props.application_type === "construction" && (
        <div className="column">
          <InputDate
            ref={ref}
            label={"Date the property was originally purchased*"}
            name={"Originally_Purchased_Date__c"}
            tabIndex={props.tabIndex}
            errors={props.errors}
            max={new Date().toISOString().slice(0, 10)}
          />
        </div>
      )}
      {/* </div> */}

      {props.application_type === "rental" && (
        // <div className="row">
        <div className="column">
          <InputSelect
            ref={ref}
            config={configPM}
            fullwidth={true}
            label={"Who intends to serve as the property manager?*"}
            name={"Property_Manager__c"}
            tabIndex={props.tabIndex}
            errors={props.errors}
          />
          {/* </div> */}
        </div>
      )}
    </div>
    <hr />
    {/* <div className="row"> */}
    {renderInputs()}
    {/* </div> */}
    {/* </div> */}
  </>
  )

  return (
    <Section heading={ "Requested Loan Information" } body={ body } max={20} />
  );
});

export default React.memo(LoanInfo);
