import React from 'react';
import './index.scss';

// import global utilities
import { handleInput } from '../../../../../../../../../utility/legacy/utilities_form_handle_input';
import { validateForm } from '../../../../../../../../../utility/legacy/utilities_form_validation';
import { cloneFormData } from './form_utilities/_form_data';

import InputSelect from '../../../../../../../../../components/legacy/_forms/InputSelect';
import InputNumber from '../../../../../../../../../components/legacy/_forms/_inputNumber';
import InputText from '../../../../../../../../../components/legacy/_forms/_inputText';

import { Section } from '../../../../../../../../../components/shared/Section';
import TransitionIn from '../../../../../../../../../components/shared/TransitionIn';

import states from '../../../../../../../../../config/_us_states.json';

import { formData } from './form_config/form_data';

import { api } from './form_api/api';

class NewBeneficiary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: formData,
      formSubmission: false,
      formValid: false,
      formError: false
    }

    this.postNewBeneficiary = api.postNewBeneficiary.bind(this); // bind for access to state (updates and data)
    this.validateForm = validateForm.bind(this); // bound from global utiliities

    this.handleSubmit = this.handleSubmit.bind(this); // submission handler (modal form)
    // input handlers (global utililities)
    this.inputSelect = handleInput.inputSelect.bind(this);
    this.inputText = handleInput.inputText.bind(this);
    this.inputNumber = handleInput.inputNumberAsString.bind(this);
    this.cloneFormData = cloneFormData.bind(this); // clone formData (move to global or use clone data in global utilities...)
  }

  // submission, validation, and post
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      formSubmission: true
    }, this.handleValidation)
  }

  handleValidation() {
    this.setState({
      formValid: validateForm(this.state.formData, true)
    }, this.handleAPIPost)
  }

  handleAPIPost() {
    if (this.state.formValid) {
      this.postNewBeneficiary(this.state, this.props.updateBeneficiaries)
    }
  }

  componentDidUpdate() {
    // console.log('benny modal', this.state)
  }

  render() {
    const { formData: fd, formError } = this.state;

    const heading = "Bank Account for Construction Draws"

    const body = (
      <form className={`form new-beneficary ${this.state.formError ? 'error' : ''}`} onSubmit={this.handleSubmit}>


        <div className="form-body">
          <fieldset className="input-field-group row grow-2">
            <div className="column">
              <InputText fd={fd} fd_key="name_on_account" fullwidth={true} formSubmission={this.state.formSubmission} inputText={this.inputText} label="Name On Account*" />
            </div>
            <div className="column">
              <InputText fd={fd} fd_key="bank_name" fullwidth={true} formSubmission={this.state.formSubmission} inputText={this.inputText} label="Bank Name*" />
            </div>
            <div className="column">
              <InputText fd={fd} fd_key="billing_street_address" fullwidth={true} formSubmission={this.state.formSubmission} inputText={this.inputText} label="Billing Street Address*" />
            </div>
            <div className="column">
              <InputText fd={fd} fd_key="city" fullwidth={true} inputText={this.inputText} formSubmission={this.state.formSubmission} label="City*" />
            </div>
            <div className="column">
              <div className="input-field-group_inner row grow-2">
                <div className="column">
                  <InputSelect fd={fd} fd_key="state" config={states} formSubmission={this.state.formSubmission} inputSelect={this.inputSelect} lable="State*" />
                </div>
                <div className="column">
                  <InputNumber fd={fd} fd_key="zip" formSubmission={this.state.formSubmission} inputNumber={this.inputNumber} label="Zip*" />
                </div>
              </div>
            </div>
            <div className="column">
              <InputNumber fd={fd} fd_key="bank_routing_number" fullwidth={true} formSubmission={this.state.formSubmission} inputNumber={this.inputNumber} label="Bank Routing Number*" />
            </div>
            <div className="column">
              <InputNumber fd={fd} fd_key="bank_account_number" fullwidth={true} formSubmission={this.state.formSubmission} inputNumber={this.inputNumber} label="Bank Account Number*" />
            </div>
            <div className="column">
              <InputText fd={fd} fd_key="bank_instructions" fullwidth={true} formSubmission={this.state.formSubmission} inputText={this.inputText} label="Bank Instructions*" />
            </div>
          </fieldset>
          {
            formError ? <p className="beneficiary-error">We were unable to process your request to add a new bank account.  Please check that the information you have entered is correct and re-submit your request.  If the error persists please come back at a later time.</p> : null
          }
          <div className="button-group">
            <button className="btn-submit" onClick={this.props.toggleBeneficiaryForm} type="button">
              Cancel
            </button>

            <button className="btn-submit" type="submit">
              Submit
            </button>
          </div>

        </div>

      </form>
    );

    return (
      <TransitionIn content={
        <Section heading={heading} body={body} />
      } />
    )
  }
}

export default NewBeneficiary
