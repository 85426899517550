import axios from 'axios'

const SharedApi = {

    async fetchCustomersbyBroker(setCustomers) {
        try {
            // fetch initial borrowing entity data to populate fields
            axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
            const res = await axios.get(process.env.REACT_APP_LIMA + '/lima/api/broker/customer-accounts')
            setCustomers(res.data.customers)
        } catch (err) {
            console.error(err)
        }
    },

    async fetchBorrowingEntities(setBorrowingEntities, broker, customer_id) {
        try {
            // fetch initial borrowing entity data to populate fields
            axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
            const res = await axios.post(process.env.REACT_APP_LIMA + '/lima/api/borrower/get-borrowing-entities', {
                accountid: `${broker ? customer_id : ''}` // blank since customer side will not be choosing a customer...
            })


            let data = res.data.borrowingEntities.sort((a, b) => (a.name > b.name) ? 1 : -1);

            let formattedData = data.map(obj => {
                return {...obj, value: obj.id }
            })

            setBorrowingEntities(formattedData)
        } catch (err) {
            console.error(err)
        }
    }

}

export { SharedApi }