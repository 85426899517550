import React from 'react'
import InputSelect from './InputSelect'
import './index.scss'

const InputHiddenSelect = React.forwardRef((props, ref) => {

    let watchedValue = props.watch(props.name, '')

    let defaultConfig = [
        {
            name: 'Yes',
            value: "Yes"
        },
        {
            name: 'No',
            value: "No"
        }
    ]

    return (
        <div className={`input-field radio-field hidden-input-field ${props.name in props.errors ? 'error' : ''}`}>
            <p>{props.label}</p>
                <ul>
                    <li>
                        <label htmlFor={props.name + "_true"}><input ref={ref} id={props.name + "_true"} type="radio" value="true" name={props.name}  />Yes</label>
                    </li>
                    <li>
                        <label htmlFor={props.name + "_false"}><input ref={ref} id={props.name + "_false"} type="radio" value="false" name={props.name} />No</label>
                    </li>
                </ul>

            {
            props.children.map((x, index) => {
                let required = watchedValue === (props.inverse ? 'false': 'true')
                return (
                    <div key={x + index} className={`hidden-input ${(watchedValue === '' || watchedValue === (props.inverse ? 'true': 'false')) && "is-hidden"}`}>
                        <div className={`hidden-input_inner ${x in props.errors && "error"}`}>
                            <InputSelect ref={ref({required: required})} onChange={x.onChange} config={x.config || defaultConfig} label={x.label} name={x.name} tabIndex={props.tabIndex} errors={props.errors} />
                            {x.name in props.errors && <span className="error-message">Valid entry required.</span>}
                        </div>
                    </div>
                )
            })
            }
            {props.name in props.errors && <span className="error-message">{props.label} is required.</span>}
        </div>
    )

})

export default React.memo(InputHiddenSelect)