import { faCloudUploadAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import ButtonSubmit from "../../../components/shared/ButtonSubmit/index";
import "./upload-table.scss";

const DocumentUpload = (props) => {
  const [files, setFiles] = useState([]);
  const inputRef = useRef();
  const headingRef = useRef();

  const cancelDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnter = (e) => {
    cancelDefaults(e);
    headingRef.current.classList.add("hovered");
  };

  const dragLeave = (e) => {
    cancelDefaults(e);
    headingRef.current.classList.remove("hovered");
  };

  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.addEventListener(
        "dragenter",
        (e) => dragEnter(e),
        false
      );
      headingRef.current.addEventListener(
        "dragover",
        (e) => dragEnter(e),
        false
      );
      headingRef.current.addEventListener(
        "dragleave",
        (e) => dragLeave(e),
        false
      );
      headingRef.current.addEventListener(
        "drop",
        (e) => {
          dragLeave(e);
          getBinary(e.dataTransfer.files);
        },
        false
      );
    }
  }, []);

  useEffect(() => {}, [files]);
  useEffect(() => {
    setFiles((files) => []);
  }, [props.clearFiles]);

  const getBinary = (files) => {
    Array.from(files).forEach((file) => {
      const name = file.name.split(/(\\|\/)/g).pop();
      const fileExtension = name.substr(name.lastIndexOf("."), name.length);

      if (!file) return;

      const fileTypesAllowed = ['.pdf', '.xls', '.xlsx', '.doc', '.docx']

      if (!fileTypesAllowed.includes(fileExtension)) return;

      const reader = new FileReader();

      reader.onloadend = function () {
        const b64 = reader.result.replace(/^data:.+;base64,/, "");
        res(b64);
      };

      reader.readAsDataURL(file);

      const res = (b64) => {
        if (b64)
          setFiles((files) => [
            ...files,
            {
              strippedname: name.substr(0, name.lastIndexOf(".")) || name,
              rentrollfilename: name,
              rentrollfiledata: b64,
              opportunityId: props.dealId,
              id: "_" + Math.random().toString(36).substr(2, 9),
            },
          ]);
      };
    });
  };

  return (
    <form
      className="form file-upload"
      onSubmit={(e) => {
        e.preventDefault();
        props.handleSubmit(files);
      }}
    >
      <fieldset disabled={props?.submitted ? props.submitted : false}>
        <input
          ref={inputRef}
          type="file"
          multiple
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
          onChange={(e) => getBinary(e.target.files)}
        />

        <div className="heading" ref={headingRef}>
          <div>
            <img src="/assets/images/Upload_Icon.svg" alt="UPLOAD" />
            <p>
              <span className={"hide-mobile"}>Drag & Drop files here</span>
            </p>
            <p className={"hide-mobile"}>OR</p>
            <button type="button" onClick={() => inputRef.current.click()}>
              Browse Files
            </button>
            {files.length > 0 && (
                <ButtonSubmit
                    value="UPLOAD"
                    callBack={() => props.handleSubmit(files)}
                    readOnly={props?.submitted ? props.submitted : false}
                    spinner={props?.submitted ? props.submitted : false}
                    disabledIf={props?.submitted ? props.submitted : false}
                    style={{ right: "0", marginTop: "20px", width: "510px" }}
                />
            )}
          </div>
        </div>

        <label>Ready to upload</label>
        <table className={"upload-files-table"}>
          <tr>
            <th style={{ width: "90%", textAlign: "center" }}></th>
            <th style={{ width: "10%", textAlign: "center" }}></th>
          </tr>
          {files.length > 0 ? (
            files.map((file) => (
              <>
                <tr>
                  <td>{file.rentrollfilename}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() =>
                        setFiles((files) =>
                          files.filter((file1) => file1.id !== file.id)
                        )
                      }
                    />
                  </td>
                </tr>
              </>
            ))
          ) : (
            <tr>
              <td>No files selected.</td>
              <td></td>
            </tr>
          )}
        </table>

       
      </fieldset>
    </form>
  );
};

export default DocumentUpload;