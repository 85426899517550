import React, { useState } from "react";
// import ReactModal from "react-modal";
import axios from "axios";
import { useForm } from "react-hook-form";
import "./SearchLoan.scss";
import InputText from "../../../../../../components/_applications/_components/InputText";
import ButtonSubmit from "../../../../../components/shared/ButtonSubmit/index";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import DialogueBox from '../../../../../components/shared/DialogueBox';

export const SearchLoan = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [addedLoans, setAddedLoans] = useState(false);
  const [result, setResult] = useState(null);

  const onSubmit = async (formData) => {
    setSubmitted(true);

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + window.localStorage.getItem("token");
    axios
      .post(process.env.REACT_APP_LIMA + `/lima/api/lookup-loan`, formData)
      .then((data) => {
        setSubmitted(false);
        setResult(data.data);
      })
      .catch((error) => {
        setSubmitted(false);
      });
  };

  const onSubmitAdd = async (formData) => {
    const IDs = Object.keys(formData).map((key) => {
      if (formData[key] === true) {
        return key;
      }
    });

    setSubmitted(true);

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + window.localStorage.getItem("token");
    axios
      .post(process.env.REACT_APP_LIMA + `/lima/api/add-loan`, IDs)
      .then((data) => {
        setSubmitted(false);
        setAddedLoans((addedLoans) => true);
      })
      .catch((error) => {
        setSubmitted(false);
      });
  };

  const renderForm = () => {
    return (
      <form
        className={`form-body ${errors.length > 0 ? "form-error" : ""}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row grow-2">
          <div className="column">
            <InputText
              ref={register({ required: true })}
              type={"text"}
              label={"Loan Number*"}
              name={"loan_number"}
              errors={errors}
            />
          </div>

          <div className="column">
            <InputText
              ref={register({ required: true, minLength: 4, maxLength: 4 })}
              type={"text"}
              label={"Last 4 of SSN*"}
              name={"ssn"}
              errors={errors}
            />
          </div>
        </div>

        <div className="row grow-1 center">
          <div className="column">
            <ButtonSubmit
              readOnly={submitted}
              disabledIf={window.localStorage.getItem("employeeToken")}
            />
          </div>
        </div>
      </form>
    );
  };

  const renderResults = () => {
    if (result.length < 1) {
      return <p>No results found.</p>;
    }

    return (
      <form
        className={`form-body ${errors.length > 0 ? "form-error" : ""}`}
        onSubmit={handleSubmit(onSubmitAdd)}
      >
        <table>
          <thead>
            <tr>
              <th>Loan Number</th>
              <th>Address</th>
              <th className="hide-mobile">Stage</th>
              <th className="hide-mobile">Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {result.map((elem) => {
              return (
                <tr key={elem.Id}>
                  <td>{elem.Loan_Number__c}</td>
                  <td>{elem.Property_Address__c || elem.Street__c}</td>
                  <td className="hide-mobile">{elem.StageName}</td>
                  <td className="hide-mobile">{elem.Type}</td>
                  <td className="checkbox-input">
                    <input
                      type="checkbox"
                      name={elem.Id}
                      ref={register({ required: false })}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ButtonSubmit
          readOnly={submitted}
          disabledIf={window.localStorage.getItem("employeeToken")}
        />
      </form>
    );
  };

  const content = (
    <div className="section-new">
      <div className="section_heading">
        <h2 className="heading">Loan Lookup Form</h2>
      </div>
   <br />
      {/* <h1>Loan Lookup Form</h1>
      {result !== null && (
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            setResult((result) => null);
            setAddedLoans((addedLoans) => false);
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      )}
      <button
        type="button"
        className="close-btn"
        onClick={() => {
          setResult((result) => null);
          setAddedLoans((addedLoans) => false);
          props.setLoanSearchOpen((addedLoans) => false);
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button> */}
      {addedLoans ? (
        <p>Loan(s) added successfully!</p>
      ) : result === null ? (
        <p>
          Please answer the following security questions to lookup your loan.
        </p>
      ) : result.length > 0 ? (
        <p>Please select the loans you wish to add.</p>
      ) : <></>}
      {!addedLoans && (
        <main className="form loc-borrower " id="form">
          {result === null ? renderForm() : renderResults()}
        </main>
      )}
    </div>
  )

  return (
  
    <DialogueBox visible={props.isOpen} content={ content } closeButton closeBackground closeCallback={() => {
        setResult((result) => null);
        setAddedLoans((addedLoans) => false);
        props.setLoanSearchOpen((addedLoans) => false);
    }} />

    // <ReactModal
    //   isOpen={props.isOpen}
    //   ariaHideApp={false}
    //   contentLabel="onRequestClose Example"
    //   onRequestClose={props.onClose}
    //   shouldCloseOnOverlayClick={true}
    //   dialogClassName="add-members-modal"
    //   className="search-loan-modal"
    // >
    //   <div className="body">
    //     <h1>Loan Lookup Form</h1>
    //     {result !== null && (
    //       <button
    //         type="button"
    //         className="back-btn"
    //         onClick={() => {
    //           setResult((result) => null);
    //           setAddedLoans((addedLoans) => false);
    //         }}
    //       >
    //         <FontAwesomeIcon icon={faAngleLeft} />
    //       </button>
    //     )}
    //     <button
    //       type="button"
    //       className="close-btn"
    //       onClick={() => {
    //         setResult((result) => null);
    //         setAddedLoans((addedLoans) => false);
    //         props.setLoanSearchOpen((addedLoans) => false);
    //       }}
    //     >
    //       <FontAwesomeIcon icon={faTimes} />
    //     </button>
    //     {addedLoans ? (
    //       <h2>Loan(s) added successfully!</h2>
    //     ) : result === null ? (
    //       <p>
    //         Please answer the following security questions to lookup your loan.
    //       </p>
    //     ) : result.length > 0 ? (
    //       <p>Please select the loans you wish to add.</p>
    //     ) : <></>}
    //     {!addedLoans && (
    //       <main className="form loc-borrower " id="form">
    //         {result === null ? renderForm() : renderResults()}
    //       </main>
    //     )}
    //   </div>
    // </ReactModal>
  );
};
