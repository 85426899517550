import React, { Fragment } from 'react';
import './index.scss';
import { Helmet } from 'react-helmet';

// Component for transitioning in elements
import TransitionIn from '../../../components/shared/TransitionIn';

// Routing imports
import { Switch, Route } from 'react-router-dom';

import ApplicationsLinkBorrower from './ApplicationsLinkBorrower';
import ApplicationsLinkProperty from './ApplicationsLinkProperty';
import ApplicationsRoutes from './ApplicationsRoutes';

const Applications = (props) => {

  // legacy prop (prior to refactor) used to dictate customer vs 
  // broker render of apps (per app component)
  const isBroker = props.accountType === "CustomerAccount" ? false : true;
  const isLead = props.user.isLead?.toString() === "true";
  const applicationLinks = (
    <Fragment>
      <ApplicationsLinkBorrower {...props} isBroker={isBroker} />
      {!isLead && 
      <ApplicationsLinkProperty {...props} />
      }
    </Fragment>
  )

  return (
    <div className="select-application-new">
      <Switch>
        <Route exact path={`${process.env.REACT_APP_SESSION_URL}/applications/`}>
          <Helmet>
            <title>Lima One | Applications</title>
          </Helmet>
          <TransitionIn content={applicationLinks} />
        </Route>

        <ApplicationsRoutes {...props} />
      </Switch>
    </div>
  );

}

export default Applications;
