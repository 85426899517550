import React, { useState } from 'react';
import './index.scss';

// pass in {...props} for access to all view data and functions/methods
// explicit props are the file name and file data/binary fields from formData in state (props)

const FileUpload = React.forwardRef((props, ref) => {

    const [base64file, setBase64File] = useState("")
    const [filename, setFileName] = useState("")

    const getBinary = (e) => {
        const file = e.target.files[0];
        const name = e.target.value.split(/(\\|\/)/g).pop();

        const fileExtension = name.substr(name.lastIndexOf("."), name.length);

        if (!file) return;
  
        const fileTypesAllowed = ['.pdf', '.xls', '.xlsx', '.doc', '.docx']
  
        if (!fileTypesAllowed.includes(fileExtension)) return;

        const reader = new FileReader();

        reader.onloadend = function () {
            const b64 = reader.result.replace(/^data:.+;base64,/, '');
            res(b64);
        };

        reader.readAsDataURL(file);

        const res = (b64) => {
            if (b64) {
                setFileName(name)
                setBase64File(b64)
            }
        }
    }

    return (
        <fieldset className="input-field-group file-upload-group" id="file-upload-group" style={props.style}>
            <div className={`input-field file-upload ${props.name in props.errors ? 'error' : ''}`} style={{padding: "0rem !important"}}>
                <label htmlFor="">
                    <input ref={ref} className="btn-submit file-upload" type="file" name={`${props.file_name}_file`} id="file" onChange={getBinary} accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf" />
                    <input ref={ref} style={{ display: 'none' }} type="text" name={`${props.file_name}`} value={filename} />
                    <input ref={ref} style={{ display: 'none' }} type="text" name={`${props.binary_name}`} value={base64file} />
                </label>
                {props.name in props.errors && <span className="error-message">*Upload required for submission.</span>}
            </div>
        </fieldset>
    );
})

export default FileUpload;