import React from "react";
import states from '../../../assets/config/_us_states.json';
import InputText from "./InputText";
import { Section } from "../../../App/components/shared/Section";
import InputTextMasked from "./InputTextMasked";

const SelectCustomer = React.forwardRef((props, ref) => {

    let customer_id = props.watch('customer_id');

    const areas_of_operation = [
        { name: "FixNFlip", value: "Fix & Flip" }, { name: "New Construction", value: "New Construction" },
        { name: "Rental", value: "Rentals, Commercial" }, { name: "Multifamily", value: "Multi Family" }
    ]

    if (!props.isBroker && !props.broker) {
        return <></>
    }

    return (
        <div className="borrowing-entities">
            <div className="input-field-group">
                {!props.broker &&
                    <div className={`input-field full-width ${props?.errors?.existing_customer && 'error'}`}>
                        <label htmlFor="existing_customer">Have you either received a quoted, submitted a file, or closed a
                            loan with this client and Lima One Capital before?</label>
                        <ul className={'select-borrower-customer radio-field'}>
                            <li style={{ float: 'left' }}><input ref={ref} type="radio"
                                name={"existing_customer"} id="existing_customer_yes" value="true" onChange={() => {
                                    props.setExistingCustomer(true)
                                    props.setExistingBorrower(null)
                                    props.setBorrowingEntities([])
                                    props.setSelectedCustomer(null)
                                    props.setSelectedBE(null)
                                }
                                } /><label htmlFor={"existing_customer_yes"}>Yes</label>
                            </li>
                            <li style={{ float: 'left' }}><input ref={ref} type="radio"
                                name={"existing_customer"} id="existing_customer_no" value="false" onChange={() => {
                                    props.setExistingCustomer(false)
                                    props.setExistingBorrower(false)
                                    props.setBorrowingEntities([])
                                    props.setSelectedCustomer(null)
                                    props.setSelectedBE(null)
                            }} /><label htmlFor={"existing_customer_no"}>No</label>
                            </li>
                        </ul>

                        {props?.errors?.existing_customer && <span className="error-message">Make a selection.</span>}
                    </div>
                }


                {props.existing_customer === true ?
                    <>
                        <div className={`input-field full-width select-customer`} style={{ marginTop: '1.5rem'}}>
                            <label htmlFor="customer_id">Select Customer (If you don’t see your customer in the dropdown below, please select “No” to the above question.)</label>
                            <select name="customer_id" ref={ref} onChange={(e) => {
                                props.fetchBorrowers(props.setBorrowingEntities, props.isBroker, e.target.value)
                                props.selectCustomer(e)
                            }}>
                                <option key="CHOOSE" value="">Choose Option</option>
                                {props.customers.length > 0 ? props.customers.map(x => {
                                    return (
                                        <option name={x.id} value={x.id} id={x.id}>
                                            {x.first_name + " " + x.last_name}
                                        </option>
                                    )
                                }) : ""}
                            </select>
                            {props?.errors?.new_customer && <span className="error-message">Make a selection.</span>}
                        </div>
                    </>
                    : (props.broker || (props.existing_customer === false && !props.isLOC)) ?

                        <Section heading="New Customer Information" max={20} body={
                            <div className="input-field-group requested-loan-info" style={{ border: '0.15rem solid #f1f1f1' }}>

                                <fieldset className="row">
                                    <div className="column">
                                        <InputText ref={ref} type={'text'} label={'First Name*'} name={'first_name'} id={'first_name'} tabIndex={props.tabIndex} errors={props.errors} />
                                    </div>
                                    <div className="column">
                                        <InputText ref={ref} type={'text'} label={'Last Name*'} name={'last_name'} id={'last_name'} tabIndex={props.tabIndex} errors={props.errors} />
                                    </div>
                                    <div className="column">
                                        <InputText ref={ref} type={'email'} label={'Email*'} name={'email'} id={'email'} tabIndex={props.tabIndex} errors={props.errors} />
                                    </div>
                                    <div className="column">
                                        <InputTextMasked ref={ref} control={props.control} label={'Phone Number*'} errors={props.errors} mask="999-999-9999" maskChar=" " type="tel" name="phone_number" placeholder="XXX-XXX-XXXX" />
                                    </div>
                                </fieldset>

                                <fieldset className="row grow-2" style={{ justifyContent: 'space-between' }}>
                                    <div className="input-field-group_inner states-checkbox" style={{ width: '45%' }}>
                                        <div className={`input-field full-width custom-scroll ${props?.errors?.registered_to_do_business_in_what_states && "error"}`} style={{ marginRight: '0.25rem'}}>
                                            <label htmlFor={"registered_to_do_business_in_what_states"} id="registered_to_do_business_in_what_states">Registered to do business in what states*</label>
                                            <div style={{ display: 'flex', width: '100%', height: '200px', overflowY: 'auto'}}>
                                                <ul style={{ width: '100%' }}>
                                                    {states.map(x => {
                                                        return <li key={x.name}>
                                                            <label>
                                                                <input ref={ref} type="checkbox" name={`registered_to_do_business_in_what_states`} value={x.name} onClick={() => props.clearError("registered_to_do_business_in_what_states")} />
                                                                {x.name}
                                                            </label>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                            <span className="error-message">{props?.errors?.registered_to_do_business_in_what_states && "This field is required."}</span>
                                        </div>

                                        
                                    </div>

                                    <div className="input-field-group_inner states-checkbox" style={{ width: '45%' }}>
                                        <div className={`input-field full-width custom-scroll ${props?.errors?.areas_of_operation && "error"}`}  style={{ marginLeft: '0.25rem'}}>
                                            <label htmlFor={"areas_of_operation"}>Which areas of real estate does the client primarily operate in?</label>
                                            <div style={{ display: 'flex', width: '100%', height: '200px', overflowY: 'auto'}}>
                                                <ul style={{ width: '100%' }}>
                                                    {areas_of_operation.map(x => {
                                                        return <li key={x.name}>
                                                            <label>
                                                                <input type="checkbox" name={"areas_of_operation"} ref={ref} value={x.value} />
                                                                {x.name}
                                                            </label>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                            <span className="error-message">{props?.errors?.areas_of_operation && "This field is required."}</span>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="row grow-3">
                                    <div className="column">
                                        <InputText ref={ref}
                                            onChange={props.inputCurrency}
                                            type={'text'}
                                            label={'Value of Existing REO Portfolio*'}
                                            name={'currently_estimated_value_of_your_real_estate_portfolio'}
                                            id={'currently_estimated_value_of_your_real_estate_portfolio'}
                                            placeholder={"$0.00"}
                                            tabIndex={props.tabIndex}
                                            cleanup={true}
                                            errors={props.errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={ref}
                                            onChange={props.inputNumber}
                                            type={'text'}
                                            label={'Number of rental units currently owned/bought in the last 3 years*'}
                                            name={'number_of_rental_units_currently_owned_bought_in_last_3_years'}
                                            id={'number_of_rental_units_currently_owned_bought_in_last_3_years'}
                                            tabIndex={props.tabIndex}
                                            errors={props.errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={ref}
                                            onChange={props.inputNumber}
                                            type={'text'}
                                            label={'Number of FixNFlips sold in the past 3 years*'}
                                            name={'number_of_fixnflips_sold_in_the_past_3_years'}
                                            id={'number_of_fixnflips_sold_in_the_past_3_years'}
                                            tabIndex={props.tabIndex}
                                            errors={props.errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={ref}
                                            onChange={props.inputNumber}
                                            type={'text'}
                                            label={'Number of new construction builds in the past 3 years*'}
                                            name={'number_of_new_construction_builds_in_the_past_3_years'}
                                            id={'number_of_new_construction_builds_in_the_past_3_years'}
                                            tabIndex={props.tabIndex}
                                            cleanup={true}
                                            errors={props.errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={ref}
                                            onChange={props.inputCreditScore}
                                            type={'text'}
                                            label={'Estimated Credit Score*'}
                                            name={'estimated_credit_score'}
                                            id={'estimated_credit_score'}
                                            placeholder={"XXX"}
                                            tabIndex={props.tabIndex}
                                            cleanup={true}
                                            errors={props.errors} />
                                    </div>

                                    <div className="column">
                                        <InputText ref={ref}
                                            onChange={props.inputCurrency}
                                            type={'text'}
                                            label={'Total Assets*'}
                                            name={'total_assets'}
                                            id={'total_assets'}
                                            placeholder={"$0.00"}
                                            tabIndex={props.tabIndex}
                                            cleanup={true}
                                            errors={props.errors} />
                                    </div>
                                </fieldset>

                            </div>
                        } />
                        : <span></span>}
            </div>
        </div>
    )

})

export default React.memo(SelectCustomer)