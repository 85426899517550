import React, { Fragment } from 'react';
import './index.scss';
import { Link, Route, Switch } from 'react-router-dom';
import PayoffRequest from '../../../../components/_applications/_servicing/_payoffRequest';
import ExtensionRequest from '../../../../components/_applications/_servicing/_extension';
import CapExRequestForm from '../../../../components/_applications/_servicing/_capExForm';
import LossRequest from '../../../../components/_applications/_servicing/_lossDraft';
import { Section } from '../../../components/shared/Section';
import TransitionIn from '../../../components/shared/TransitionIn';

const ServicingRequests = function (props) {

  // Dynamically set the heading - please replace with Helmet - JR | 2021-12-08
  const getHeading = (url) => {

    let urlMod = url.replace('/lo-user-dashboard/servicing-requests/', '')

    if (urlMod === '') return "";
    if (urlMod.includes('capex')) return ' - CapEx Funds Form';
    if (urlMod.includes('loss-draft')) return " - Loss Draft Form";
    if (urlMod.includes('payoff')) return ' - Payoff Form';
    if (urlMod.includes('extension')) return ' - Extension Form';
  }

  const headingText = (
    <Fragment>
      Servicing Requests {getHeading(props.location.pathname)}
    </Fragment>
  
  )

  const heading = (
    <TransitionIn content={headingText} />
  )

  const bodyMain = (
    <ul>
      <li>
        <img src="/assets/images/servicing_requests/Extension_Request_Form_Icon.svg" />
        <Link to={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/extension`} data-tip="Use this form to request a loan extension" data-for="app-tooltip">
          Extension Request Form
        </Link>
      </li>
      <li>
        <img src="/assets/images/servicing_requests/Payoff_Request_Form_Icon.svg" />
        <Link to={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/payoff`} data-tip="Use this form to request a payoff letter for your loan" data-for="app-tooltip">
          Payoff Request Form
        </Link>
      </li>
      <li>
        <img src="/assets/images/servicing_requests/CapEx_Funds_Request_Form_Icon.svg" />
        <Link to={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/capex`} data-tip="Use this form to request a reimbursement from your CapEx account" data-for="app-tooltip">
          CapEx Funds Request Form
        </Link>
      </li>
      <li>
        <img src="/assets/images/servicing_requests/Loss_Draft_Request_Form_Icon.svg" />
        <Link to={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/loss-draft`} data-tip="Experience property damage or loss and need to submit a request for reimbursement? Complete this form" data-for="app-tooltip">
          Loss Draft Request Form
        </Link>
      </li>
      <li>
        <img src="/assets/images/servicing_requests/Document_Icons_PDF_Icon.svg" />
        <a download href="/documents/ACH-Recurring-Payment-Authorization-Form.docx" data-tip="Setup automatic payments for your loan" data-for="app-tooltip">
          Download ACH Form
        </a>
      </li>
    </ul>
  )

  const body = (
    <Switch>
      <Route exact path={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/`} render={() => {
        return (
          <TransitionIn content={bodyMain} />
        )
      }
      } />
      <Route exact path={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/extension`} component={ExtensionRequest} />
      <Route exact path={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/payoff`} component={PayoffRequest} />
      <Route exact path={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/capex`} component={CapExRequestForm} />
      <Route exact path={`${process.env.REACT_APP_SESSION_URL}/servicing-requests/loss-draft`} component={LossRequest} />
    </Switch>
  )

  return (
      <Section heading={heading} body={body} id="servicing-requests" />
  )
}

export default ServicingRequests
