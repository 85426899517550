const formData = {   
    "name_on_account": {
        label: "Name on Account",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "bank_name": {
        label: "Bank Name",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "billing_street_address": {
        label: "Billing Street Address*",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "city": {
        label: "City*",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "state": {
        label: "State*",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "zip": {
        label: "Zip*",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "bank_account_type": {
        label: "Bank Account Type",
        iRaw: "",
        iVal: {
            value: "",
            isValid: true
        },
        message: "Message",
        error: "Error",
    },
    "bank_routing_number": {
        label: "Bank Routing Number",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "bank_account_number": {
        label: "Bank Account Number",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    },
    "bank_instructions": {
        label: "Bank Instructions",
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        message: "Message",
        error: "Error",
    }
}

export { formData }