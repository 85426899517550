import React from 'react';
import './index.scss';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';

// accepts the following props:
// ----- value: button text
// ----- valueReadOnly: button text in read-only state
// ----- callback: handler for click 
// ----- readOnly: toggle for access (true blocks button access during an API call, for example - controlled in parent state)
// ----- spinner: if true a spinner is visible while read-only is true (defaults to true)

function ButtonSubmit(props) {
    const callback = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (props.readOnly) {
            console.log('callback prohibited in read-only state...');
        } else {
            if (props.callBack) {
                console.log('callback in props', props.callBack)
                props.callBack()
            } else {
                console.log('no callback provided in props...')
            }
        }
    }

    return (
        <button
            readOnly={props.readOnly}
            type="submit" // default
            className={`button-submit-rf ${props.readOnly ? "read-only" : ""} ${props.variant}`} // optionally prop for style variants
            onClick={props.callback} // required for onClick handler
            style={props.style} // optional inline styles
            disabled={props.disabledIf ? props.disabledIf : false} // required to prevent multiple submissions
        >

            {props.readOnly ?
                (props.valueReadOnly || "Processing") : // optional/default messaging
                (props.value || "Submit") /* optional/default button text */}

            {props.spinner !== false ?
                <Loader // loading/processing spinner/icon
                    className="spinner"
                    type="Grid"
                    color="#0C224B"
                    height={"1.25rem"}
                    width={"1.25rem"}
                /> : null}
        </button>
    )
}

export default ButtonSubmit