import React from 'react'
import InputText from './InputText'
import './index.scss'

const InputHiddenText = React.forwardRef((props, ref) => {

    let watchedValue = props.watch(props.name, '')

    return (
        <div className={`input-field radio-field hidden-input-field ${props.name in props.errors ? 'error' : ''}`}>
                <p htmlFor={props.name}>{props.label}</p>
                <ul>
                    <li>
                        <label><input ref={ref} type="radio" value={`${props.yesNo ? "Yes" : "true"}`} name={props.name}  />Yes</label>
                    </li>
                    <li>
                        <label><input ref={ref} type="radio" value={`${props.yesNo ? "No" : "false"}`} name={props.name} />No</label>
                    </li>
                </ul>

            {
            props.children.map((x, index) => {
                let required = watchedValue === (props.inverse ? (props.yesNo ? 'No' : 'false') : (props.yesNo ? 'Yes' : 'true'))
                return (
                    <div key={x + index} className={`hidden-input ${(watchedValue === '' || watchedValue === (props.inverse ? (props.yesNo ? 'Yes' : 'true') : (props.yesNo ? 'No' : 'false'))) && "is-hidden"}`}>
                        <div className={`hidden-input_inner ${x in props.errors && "error"}`}>
                            <InputText ref={ref({required: required})} onChange={x.onChange} type={x.type || 'text'} label={x.label} name={x.name} placeholder={x.placeholder} tabIndex={props.tabIndex} errors={props.errors} cleanup={x.cleanup} />
                            {x.name in props.errors && <span className="error-message">Valid entry required.</span>}
                        </div>
                    </div>
                )
            })
            }
            {props.name in props.errors && <span className="error-message">{props.label} is required.</span>}
        </div>
    )

})

export default React.memo(InputHiddenText)