import React from "react";
import BrokerSign from '../../components/broker/signup';
import TransitionIn from "../../App/components/shared/TransitionIn";

function BrokerSignUp() {
  return (
    <TransitionIn content={
      <BrokerSign />
    }
    />
  )
}

export default BrokerSignUp;