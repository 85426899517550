import React from 'react';

// props =>
// "fd_key" refers to formData object in state - required

const InputSelect = (props) => {
    let label = props.label ? props.label : props.fd[props.fd_key].label
   
    
    if(props.floating) {
        return (
            <React.Fragment>
                <div className="floating">
                    <label htmlFor={props.fd_key}>{label}</label><br />
                    <select className={`area-input ${props.className}`} style={props.style} multiple={props.multiple ? true : false} name={props.fd_key} id={props.fd_key} onChange={props.inputSelect} data-input={props.fd_key} value={props.fd[props.fd_key].iVal.value} tabIndex={props.tabIndex}>
                        {!props.multiple && <option key="CHOOSE" value="">Choose Option</option>}
                        {props.config.map((x, index) => {
                            return (
                                <option key={props.fd_key + index} name={props.fd_key} value={x.value ? x.value : undefined} id={x.value}>
                                    {x.name}
                                </option>
                            )
                        })}
                    </select>
                    <br />
                    <span className="error-message">{(props.formSubmission && !props.fd[props.fd_key].iVal.isValid)  && props.fd[props.fd_key].error }</span>
                </div>
            </React.Fragment>
        )
    }


    return (
        <div style={props.style} className={`input-field ${props.fullwidth ? "full-width" : ""} ${props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? "error" : ""}`}>
            <label htmlFor={props.fd_key}>{label}</label>
            <select className={`area-input ${props.className}`} multiple={props.multiple ? true : false} name={props.fd_key} id={props.fd_key} onChange={props.inputSelect} data-input={props.fd_key} value={props.fd[props.fd_key]?.iVal?.value}>
                {!props.showChoose ? "" : <option key="CHOOSE" value="">Choose Option</option>}
                {props.config.map((x, index) => {
                    return (
                        <option key={props.fd_key + index} name={props.fd_key} value={x.value ? x.value : undefined} id={x.value}>
                            {x.name}
                        </option>
                    )
                })}
            </select>
            <span className="error-message">{(props.formSubmission && !props.fd[props.fd_key]?.iVal?.isValid)  && props.fd[props.fd_key]?.error }</span>
        </div>
    )
}

export default InputSelect