const state = {

    // Helper function to clone state (immutable) to a new variable/object (mutable).
    // CRITICAL to use this so we never mutate state directly (not allowed in React)
    // Note that assigning state to a new var creates a reference, not a copy!!!
    // Works as long as the object being cloned contains no methods of its own.
    clone: function () {
        return JSON.parse(JSON.stringify(this.state));
    },

    // Helper function to log current state (pass as callback to setState to see your changes)
    // "log" arg will output to console and only requires a truthy value (optional)
    check: function () {
            console.log(this.state);
    }
}

export { state }