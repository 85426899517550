import React, { Fragment } from 'react';
import MainNavNew from '../../components/protected/MainNavNew';
import TopBar from '../../components/protected/TopBar';
import './index.scss';
import Footer from '../../components/unprotected/FooterLegacy';
import {Helmet} from "react-helmet";

const FlexCenter = (props, { ...rest }) => {
    return (
        <main className="layout_flex-center">
            {props.children}
        </main>
    )
}

// Absolutely no styling - very basic
const Min = (props) => {
    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}

const AccountAccessGeneric = (props) => {
    return (
        <Fragment>
            <main className='layout_generic grey'>
                {props.children}
            </main>
            <Footer />
        </Fragment>
    )
}

const Base = (props, { ...rest }) => {
    return (
<>
        <Helmet>
            <title>
                {`${!props.whitelabel ? 'Lima One ' : ''} ${props.title ? "- " + props.title : ""}`}
            </title>
        </Helmet>

        <main className={`layout_base`} id={props.layoutID || ""}>
            {/*<div className={"announcement-bar fix-login"}>We are currently experiencing issues with budget and draw requests. Budget/draw information may appear inaccurate until this banner is gone. Please email <a href={"mailto:construction@limaone.com"}>construction@limaone.com</a> and we will promptly respond to process your request.</div>*/}
            <div className="decor top-left">
                <div className="inner"></div>
            </div>
            <div className="decor top-right">
                <div className="inner"></div>
            </div>
            <div className="decor bottom-left">
                <div className="inner"></div>
            </div>
            <div className="decor bottom-right">
                <div className="inner"></div>
            </div>
            {props.children}
        </main>
</>
    )
}


const WhiteLabelApplications = (props, { ...rest }, Component) => {

    return (
        <>
            <Helmet>
                <title>{props.title}</title>
                <link rel="icon" href="#" />
            </Helmet>
        <main className="layout_white-label-applications">
            <div className="inner" id={"app-scroll-anchor"}>
                {props.children}
            </div>
        </main>
        </>
    )
}

const AccountAccess = (props, { ...rest }) => {
    return (
        <>
            <Helmet>
                <title>
                    {`${!props.whitelabel ? 'Lima One ' : ''} ${props.title ? "- " + props.title : ""}`}
                </title>
            </Helmet>
        <main className={`layout_account-access ${props.layoutVariant}`}>
            {/*<div className={"announcement-bar fix-login"}>We are currently experiencing issues with budget and draw requests. Budget/draw information may appear inaccurate until this banner is gone. Please email <a href={"mailto:construction@limaone.com"}>construction@limaone.com</a> and we will promptly respond to process your request.</div>*/}

            <article>
                {props.children}
            </article>
            <Footer />
        </main>
            </>
    )
}

export { Base, FlexCenter, WhiteLabelApplications, AccountAccess, Min, AccountAccessGeneric }
