import React from 'react';
import axios from 'axios';

const AppCues = props => {

    if(window.Appcues) {
        if(props.user.accountType) {
            window.Appcues.identify(props.user.email, {
                accountType: props.user.accountType,
            });
            console.log(`Appcues - identified user: ${props.user.email}`);

            // axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
            // axios.defaults.headers['Content-Type'] = "application/json";
        
            // // MOVE DASHBAORD API CALLS HERE (AXIOS.ALL) AND LOAD ONCE ALL COMPELTE OR FAIL
        
            // axios.get(process.env.REACT_APP_LIMA + '/lima/api/appcues/drawcount')
            //     .then((data) => {
            //         // console.log(data.data.drawCount);
            //         if (data.data.drawCount > 0) {
            //             // emit draw completed event to complete flow and update checklist - can be replaced with custom property later once the routing is fixed to not wipe the data in appcues.identify()
            //             window.Appcues.track('Draw Completed', { drawCompleted: true });
            //         }
            //     })
            //     .catch(function (error) {
            //         // handle api error
            //         // console.log(error);
            //     });
        } else {
            window.Appcues.anonymous();
        }
    }

    return null;
}

export default AppCues