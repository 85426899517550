import React from 'react';
import './index.scss';

// import global components
import { modalToggle } from '../../../../../../components/legacy/Modal';
import { Modal } from '../../../../../../components/legacy/Modal';

// import global utilities
// import { handleInput } from '../../assets/javascript/utilities/utilities_form_handle_input';
// import { validateForm } from '../../assets/javascript/utilities/utilities_form_validation';

import { handleInput } from '../../../../../../utility/legacy/utilities_form_handle_input';
import { validateForm } from '../../../../../../utility/legacy/utilities_form_validation';

// import local components
import DrawSheet from './components/Draw_Sheet';
import DrawRequestModal from './components/DrawModal';
import { formData } from './form_config/form_data_draw_request';

// import local utilities
import { cloneFormData } from './form_utilities/_form_data';
import { api } from './form_api/api';

import { SectionExpandable } from '../../../../../../components/shared/Section';
import TransitionIn from '../../../../../../components/shared/TransitionIn';

class RequestDrawNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: formData,
      beneficiaries: [],
      lineItems: [],
      formSubmission: false,
      formValid: false,
      formError: false,
      showBeneficiaryForm: false,
      dealid: "",
      modal: false,
      submission: false
    };

    // input handlers (global utililities)
    this.inputSelect = handleInput.inputSelect.bind(this);
    this.inputText = handleInput.inputText.bind(this);
    this.inputRadio = handleInput.inputRadio.bind(this);
    this.inputDate = handleInput.inputDate.bind(this);

    // local utililities 
    this.cloneFormData = cloneFormData.bind(this); // clone formData (move to global or use clone data in global utilities...)
    this.fetchBeneficiaries = api.beneficiary.fetchAll.bind(this); // call server to populate the beneficiary options in modal form

    this.toggleBeneficiaryForm = this.toggleBeneficiaryForm.bind(this); // toggle modal form for new benificiary

    this.modalToggle = modalToggle.bind(this); // bound from imported modal component in _general

    this.handleSubmit = this.handleSubmit.bind(this); // submission handler (modal form)
    this.postDrawRequest = api.postDrawRequest.bind(this); // bind for access to state (updates and data)

    this.validateForm = validateForm.bind(this); // bound from global utiliities

    this.updateBeneficiaries = this.updateBeneficiaries.bind(this);
  }


  cloneFormData = () => {
    return JSON.parse(JSON.stringify(this.state.formData));
  }


  // updating the list items in parent (here) and passing callback
  updateListItems = (data, cb) => {
    this.setState({
      lineItems: data
    }, cb)
  }

  // modal/form display toggles
  showModal() { this.setState({ modal: true }) }; // display modal

  toggleBeneficiaryForm() {

    this.setState({
      showBeneficiaryForm: !this.state.showBeneficiaryForm
    })
  }

  updateBeneficiaries(list, selected) {
    let fd = this.cloneFormData();

    fd.beneficiary.iRaw = selected;
    fd.beneficiary.iVal.value = selected;
    fd.beneficiary.iVal.isValid = true;

    this.setState({
      beneficiaries: list,
      formData: fd
    }, this.toggleBeneficiaryForm)
  }

  // submission, validation, and post
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      formSubmission: true
    }, this.handleValidation)
  }

  handleValidation() {
    this.setState({
      formValid: validateForm(this.state.formData, true)
    }, this.handleAPIPost)
  }

  handleAPIPost() {
    if (this.state.formValid) {
      this.postDrawRequest(this.state)
    }
  }

  componentDidMount() {
    this.fetchBeneficiaries(); // load existing beneficiaries

    // get the deal id on mount (should be passed as a prop...)
    this.setState({
      dealid: this.props.dealId
    })
  }

  componentDidUpdate() {
    // console.log(this.state) // helper to show updated state
    // console.log('RN props', this.props)
  }

  render() {
    const { formData, beneficiaries, formSubmission, showBeneficiaryForm, modal, formError, formValid, submission } = this.state; // destructure state

    const section = {
      heading: <>New Draw Request <span>Fill in the requested amount for each line item.</span></>,
      body: <div className="request-draw construction-budget-items hide-on-mobile" handleSelect={this.handleSelect}>

        <DrawSheet getTotalSpent={this.props.getTotalSpent} updateParent={this.updateListItems} handleSubmitPlaceholder={this.showModal} dealId={this.props.dealId} remainingBalances={this.props.remainingBalances} />
      </div>

    }

    return (
      <>
        <Modal
          modal={modal}
          children={<DrawRequestModal />}
          modalToggle={this.modalToggle}
          inputSelect={this.inputSelect}
          inputText={this.inputText}
          inputDate={this.inputDate}
          inputRadio={this.inputRadio}
          cloneFormData={this.cloneFormData}
          setFormData={(fd) => this.setState({ formData: fd })}
          fd={formData}
          beneficiaries={beneficiaries}
          formSubmission={formSubmission}
          showBeneficiaryForm={showBeneficiaryForm}
          toggleBeneficiaryForm={this.toggleBeneficiaryForm}
          handleSubmit={this.handleSubmit}
          formValid={formValid}
          formError={formError}
          submission={submission}
          updateBeneficiaries={this.updateBeneficiaries} />

        <TransitionIn content={<SectionExpandable flush heading={section.heading} body={section.body} id="section-construction" />} />
      </>

    );
  }
}

export default RequestDrawNew;