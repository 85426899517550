import React from 'react';
import './index.scss';
import axios from 'axios';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import DialogueBox from '../../../../../../components/shared/DialogueBox'
import SkeletonTable from '../../../../../../components/shared/SkeletonTable';

class DocumentsBox extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            fileURL: null
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        var url = process.env.REACT_APP_LIMA + '/lima/api/deals/getdocuments'
        var payload = {
            "dealid": this.props.dealId
        }

        if (this.props.forLOC) {
            url = process.env.REACT_APP_LIMA + '/lima/api/loc/getdocuments'
            payload = {
                "locId": this.props.dealId
            }
        }

        axios.post(url, payload).then((res) => {
            if ('files' in res.data) {
                this.props.setFiles(res.data.files)
                this.forceUpdate();
            }
        }).catch(console.log);

    }

    copyTable = () => {
        var urlField = this.props.tableRef.current
        var selection = document.getSelection();
        var range = document.createRange();
        range.selectNode(urlField);
        selection.removeAllRanges();
        selection.addRange(range);

        console.log('copy success', document.execCommand('copy'));
        selection.removeAllRanges();
    }

    render() {
        if (this.props.files === null) return <SkeletonTable count={3} />;

        let files = this.props.files.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        return (
            <>
                <div className="documents">
                    <div className="document-embed">
                        <table className="locTable table" ref={this.props.tableRef}>

                            <thead>
                                <tr>
                                    <th style={{ width: '70%' }}>
                                        <span>
                                            File Name
                                        </span>
                                    </th>
                                    <th style={{ width: '30%' }}>
                                        <span>
                                            Upload Date
                                        </span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {files.length !== 0 && files.map((item, index) => {
                                    if (files.length === 0) return (
                                        <tr>
                                            <td>
                                                <span>
                                                    No files are currently uploaded.
                                                </span>
                                            </td>
                                        </tr>
                                    )

                                    else return (
                                        <tr onClick={() => this.setState({ fileURL: item.url })} >
                                            <td>
                                                <span className="filename">{item.name}</span>
                                            </td>
                                            <td>
                                                <span>{new Date(item.created_at).toDateString()}</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {/* {files.length === 0 && <div><h1 style={{ paddingTop: '1rem' }}>No Results...</h1></div>} */}
                    </div>
                </div>

                {/* Below is experimental and not in production yet!!! */}
                {process.env.REACT_APP_ENABLE_DOC_PREVIEWS.toString() === 'true' &&
                    <>
                        <ReactModal
                            isOpen={this.state.fileURL}
                            ariaHideApp={false}
                            contentLabel="onRequestClose Example"
                            onRequestClose={() => this.setState({ fileURL: null })}
                            shouldCloseOnOverlayClick={true}
                            dialogClassName="add-members-modal"
                            className="file-preview-modal"
                        >
                            <FontAwesomeIcon icon={faTimesCircle} onClick={() => this.setState({ fileURL: null })} />
                            <iframe title="DOC PREVIEW" src={this.state.fileURL} />
                        </ReactModal>

                        {/* <DialogueBox visible={this.state.fileURL} closeButton closeCallback={() => this.setState({ fileURL: null })} content={  <iframe title="DOC PREVIEW" src={this.state.fileURL} />} /> */}
                    </>
                }
            </>
        )
    };
}

export default DocumentsBox;