import React, {useState, Fragment, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form"
import ButtonSubmit from '../../../ButtonSubmit';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import InputDate from "../../_components/InputDate";
import InputText from "../../_components/InputText";
import InputTextField from "../../_components/InputTextField";
import { Section } from '../../../../App/components/shared/Section';
import {checkFileSize, encodeJson, getBinary} from '../../_api';
import './index.scss';
import {fileTypes} from "../_extension";

const LossDraft = props => {

    const [submitted, setSubmitted] = useState(false);
    const [twentyKError, setTwentyKError] = useState(false);
    const [success, setSuccess] = useState(null); // state for API submission
    const { register, handleSubmit, errors } = useForm()

    const inputCurrency = e => {
        let iv = e.target.value; // target value

        // limit the character input of the text field to only numbers, commas, decimal points, or blanks
        if (iv.match(/^[0-9.,$]+$/) || iv === "" || iv === "$") {
            var atEnd = iv === "" || iv === "$"
            var cleanIV = iv.replace(/,/g, "").replace(/\$/g, "")

            var fv = "0"

            if (iv !== "") {
                fv = parseFloat(cleanIV)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            // iv = validateInput.number(cleanIV);

            if (atEnd) {
                fv = ""
            }

            e.target.value = "$" + fv
        } else {
            e.target.value = "$0.00"
        }
    }

    const inputPhone = e => {

        let iv = e.target.value;
        if (iv.match(/^[0-9.,-]+$/)) {
            // remove dashes
            iv = iv.replace(/-/g, '')
            iv = iv.replace(/\D/g, '')
            iv = iv.trim()

            // insert dashes into string for formatted value (fv)
            var fv = iv.substring(iv.length - 10 < 0 ? 0 : iv.length - 10, iv.length);

            if (fv.length < 11) {
                if (iv.length === 11) {
                    if (fv.length > 3)
                        fv = fv.replace(/^(\d{3})/, '$1-');
                    if (fv.length > 7)
                        fv = fv.replace(/-(\d{3})/, '-$1-');
                } else {
                    if (fv.length > 3)
                        fv = fv.replace(/^(\d{3})/, '$1-');
                    if (fv.length > 7)
                        fv = fv.replace(/-(\d{3})/, '-$1-');
                }
                e.target.value = fv
            } else {
            }
        } else {
            if (iv.length === 0) {
                e.target.value = e.target.value.substring(0, 10)
            }
        }
    }

    const onSubmit = async formData => {
        setSubmitted(true);

        let documents = []

        // if (parseFloat(formData['claim_amount'].replace(/,/g, "").replace(/\$/g, "")) > 20000) {
        //     if (formData['documents'].length < 2) { 
        //         setTwentyKError(true)
        //         setSubmitted(false)
        //         return;
        //     }
        // }

        // if (formData['documents'].length > 3) {
        //     alert('You can only upload a maximum of 3 files.')
        //     setSubmitted(false)
        //     return;
        // }

        if (formData['adjusters_report'].length > 0) {
            let base64 = await getBinary(formData['adjusters_report'][0])
            let type = formData['adjusters_report'][0]['name'].split(".").pop()
            documents.push({
                fileData: base64.replace(/^data:.+;base64,/, ''),
                fileName: `Insurance Adjusters Report.${type}`
            })
        }
        if (formData['repair_estimate'].length > 0) {
            let base64 = await getBinary(formData['repair_estimate'][0])
            let type = formData['repair_estimate'][0]['name'].split(".").pop()
            documents.push({
                fileData: base64.replace(/^data:.+;base64,/, ''),
                fileName: `Repair Estimate.${type}`
            })
        }
        if (formData['lien_waver'].length > 0) {
            let base64 = await getBinary(formData['lien_waver'][0])
            let type = formData['lien_waver'][0]['name'].split(".").pop()
            documents.push({
                fileData: base64.replace(/^data:.+;base64,/, ''),
                fileName: `Contractor Lien Waiver.${type}`
            })
        }
        if (formData['intent_to_repair'].length > 0) {
            let base64 = await getBinary(formData['intent_to_repair'][0])
            let type = formData['intent_to_repair'][0]['name'].split(".").pop()
            documents.push({
                fileData: base64.replace(/^data:.+;base64,/, ''),
                fileName: `Declaration of Intent to Repair.${type}`
            })
        }

        let files = { files: documents }

        encodeJson(formData)

        let fd = {...formData, ...files}

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.post(process.env.REACT_APP_LIMA + `/lima/api/loss-draft`, fd
        ).then((data) => {
            setSubmitted(false);
            setSuccess(true);
        }
        ).catch(error => {
            setSubmitted(false);
            setSuccess(false);
        });
    }

    const fileInputStyle = {
        maxHeight: "2rem",
        paddingTop: "0.4rem",
        maxWidth: "15rem",
        border: "1px solid gray"
    }

    // If API submission returns 200, redirect to thank you, otherwise show form
    if (success === true) {
        return <Redirect
            to={{
                pathname: '/thank-you/form',
                state: { external: props?.external === true, message: "Thank you we will reach out quickly to the email provided!" }
            }}
        />
    } else
        return (

            <Fragment>

                <p>Important Notice: All fields must be completed before the form can be submitted. The "Borrower Name" should be the name of the borrowing entity (the LLC).</p>



                <div className="form form-loss-draft">
                    <form className={`form-body ${errors.length > 0 ? 'form-error' : ''}`} onSubmit={handleSubmit(onSubmit)} >
                        <div className="row input-field-group grow-3">
                            <div className="column">
                                <InputText ref={register({ required: true })} type={'text'} label={'Loan Number*'} name={'loan_number'} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: true })} type={'text'} label={'Borrowing Entity*'} name={'borrowing_entity'} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: true })} type={'text'} label={'Guarantor Name*'} name={'gurantor_name'} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: true })} onChange={inputPhone} type={'text'} label={'Best Contact Number*'} name={'best_contact_number'} errors={errors} />
                            </div>

                            <div className="column">
                                <InputText ref={register({ required: true })} type={'email'} label={'Email Address*'} name={'email'} errors={errors} />
                            </div>
                            <div className="column">
                                <InputDate ref={register({ required: true })} label={'Date*'} name={'date'} errors={errors} />
                            </div>
                        </div>

                        <Section heading={"Loss Details"} body={
                            <Fragment>
                                <div className="row input-field-group">
                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Affected Property*'} name={'affected_property'} errors={errors} />
                                    </div>
                                    <div className="column">
                                        <InputDate ref={register({ required: true })} label={'Date of Loss*'} name={'date_of_loss'} errors={errors} />
                                    </div>

                                    <div className="column">
                                        <InputTextField ref={register({ required: true })} type={'text'} label={'Description of Loss*'} name={'description_of_loss'} errors={errors} />
                                    </div>
                                    <div className="column">
                                        <InputText ref={register({ required: true })} type={'text'} label={'Claim Amount*'} onChange={inputCurrency} name={'claim_amount'} errors={errors} />
                                    </div>
                                </div>
                            </Fragment>
                        } />

                        <Section heading={"Upload Required Documents"} body={
                            <Fragment>
                                <div className="row">
                                    <div className="column doc-upload-custom">
                                        <label for="adjusters_report">Insurance Adjusters Report</label>
                                        <input ref={register({ required: false })} type="file" accept={fileTypes} name="adjusters_report" style={fileInputStyle} onChange={checkFileSize} />
                                    </div>

                                    <div className="column doc-upload-custom">
                                        <label for="repair_estimate">Repair Estimate</label>
                                        <input ref={register({ required: false })} type="file" accept={fileTypes} name="repair_estimate" style={fileInputStyle} onChange={checkFileSize} />
                                    </div>

                                    <div className="column doc-upload-custom">
                                        <label for="lien_waiver">Contractor’s Lien Waiver*</label>
                                        <input ref={register({ required: true })} type="file" accept={fileTypes} name="lien_waver" style={fileInputStyle} onChange={checkFileSize} />
                                        {'lien_waver' in errors && <span className="error-message">Contractor's Lien Waiver* is Required.</span>}
                                    </div>

                                    <div className="column doc-upload-custom">
                                        <label for="intent_to_repair">Declaration of Intent to Repair*</label>
                                        <input ref={register({ required: true })} type="file" accept={fileTypes} name="intent_to_repair" style={fileInputStyle} onChange={checkFileSize} />
                                        {'intent_to_repair' in errors && <span className="error-message">Declaration of Intent to Repair* is Required.</span>}
                                    </div>
                                </div>

                                <br />
                                <p>Please download the <a download href="/documents/resources/Lima-One-Claims-Packet.pdf"> Lima One Packet</a> below to obtain the Contractor's Lien Waiver and Declaration of Intent to Repair forms.</p>
                                <br />

                                {twentyKError &&
                                    <div className="row">
                                        <p style={{ color: 'red' }}>Your claim exceeds $20,000. Please download the two PDFs and attach them below.</p>
                                    </div>
                                }

                                <div className="row ">
                                    <div className="column">
                                        <ButtonSubmit readOnly={submitted} disabledIf={window.localStorage.getItem("employeeToken")} />
                                        {success === false ? "An error ocurred during submission.  Please try again later." : ""}
                                    </div>
                                </div>
                            </Fragment>
                        } />





                    </form>
                </div>
            </Fragment>

        )
}

export default withRouter(LossDraft)
