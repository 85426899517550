import React from 'react';


const InputDate = (props) => {
    let label = props.label ? props.label : props.fd[props.fd_key].label
    return (
        <div className={`input-field ${props.fullwidth ? "full-width" : ""} ${props.formSubmission && !props.fd[props.fd_key].iVal.isValid ? "error" : ""}`}>
            <label htmlFor={props.fd_key}>{label}</label>
            <input type="date" name={props.fd_key} id={props.fd_key} data-input={props.fd_key} value={props.fd[props.fd_key].iVal.value} onChange={props.inputDate} min={props.min} max={props.max} />
            <span className="error-message">{(props.formSubmission && !props.fd[props.fd_key].iVal.isValid) && props.fd[props.fd_key].error }</span>
        </div>
    )
}

export default InputDate