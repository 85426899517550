import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { useSpring, animated } from '@react-spring/web';
import animations from '../../../../../animations';
import './index.scss';

const CopyTable = props => {

    const [copied, setCopied] = useState(false);

    // transistion animation and conditional styles
    const styleCopied = useSpring(animations("toggleInStatusGreen", copied));
    const styleCopy = useSpring(animations("toggleInStatusDefault", copied))
    const styleCopiedBG = useSpring({
        background: copied ? "#038B57" : "white"
    })

    const tableRef = props.tableRef || false;

    const copyTable = () => {
        if (!tableRef.current) return;

        if(copied) {
            return;
        };

        var urlField = props.tableRef.current;

        var selection = document.getSelection();
        var range = document.createRange();

        range.selectNode(urlField);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');

        selection.removeAllRanges();

        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);

    };

    if (tableRef) {
        return (
            <React.Fragment>

                <animated.button className={`copy-table ${copied ? "is-disabled" : ""}`}
                    onClick={() => copyTable()}
                    data-tip={"Click to copy the table to clipboard."}
                    data-for="app-tooltip" 
                    style={styleCopiedBG}>

                    {
                        copied ?
                            <animated.div style={styleCopied}>
                                <FontAwesomeIcon icon={faCheckCircle} />
                                <span className="copy-button-text">Copied</span>
                            </animated.div>
                            :
                            <animated.div style={styleCopy}>
                                <FontAwesomeIcon icon={faClone} />
                                <span className="copy-button-text">Copy</span>
                            </animated.div>
                    }

                </animated.button>

            </React.Fragment>
        )
    } else return null;
};



export default CopyTable;