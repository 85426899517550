import React from "react";
import axios from 'axios';
import './index.scss';

import { Section } from "../../../components/shared/Section";
import ControlAsync from "../../../components/shared/Section/controls/Aysnc";
import TransitionIn from '../../../components/shared/TransitionIn';
import ButtonSubmit from "../../../../components/ButtonSubmit";
import {useSpring} from "@react-spring/web";
import animations from "../../../animations";

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rData: "",
      phone: "",
      zip: "",
      submitted: false,
      success: false
    };

  }

  fetchData() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers['Content-Type'] = "application/json";
    axios.get(process.env.REACT_APP_LIMA + '/lima/api/fetchaccountprofile', {})
      .then((res) => {

        this.setState({ rData: res.data });
        var input__address = document.getElementById("input__address");
        var state = document.getElementById("state");
        var input__city = document.getElementById("input__city");

        let { address, phone, city, zip } = res.data

        input__address.value = address;
        input__city.value = city;

        var fv = phone.toString().replace(/\D/g, '');
        if (fv.length > 3)
          fv = fv.replace(/^(\d{3})/, '$1-');
        if (fv.length > 7)
          fv = fv.replace(/-(\d{3})/, '-$1-');

        state.value = res.data.state;
        this.setState({ phone: fv, zip })

        // var country = document.getElementById("country");
        for (var i = 0; i < state.options.length; i++) {
          if (state.options[i].value === res.data.state) {
            state[i].selected = 'selected'
          }
        }
      })
      .catch(console.log)
  }

  componentDidMount() {
    this.fetchData()
  }

  postForm = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });

    var input__address = document.getElementById("input__address").value;
    var input__state = document.getElementById("state").value;
    var input__phone = this.state.phone.replace(/-/g, '');
    var input__city = document.getElementById("input__city").value;
    var input__zip = this.state.zip;
    var state = this.state;

    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
    axios.post(process.env.REACT_APP_LIMA + '/lima/api/updateaccountprofile', {
      "id": state.rData.id,
      "BillingStreet": input__address,
      "BillingCity": input__city,
      "BillingState": input__state,
      "BillingPostalCode": input__zip,
      "Phone": input__phone
    })
      .then((res) => {
        this.setState({ success: true, submitted: false });

        setTimeout(() => {
          this.setState({ submitted: false, success: false })
        }, 2000)
      })
      .catch(() => {
        this.setState({ submitted: false, success: false })
      })
  }

  render() {
    const { submitted, success } = this.state;

    const styles = {
      saveBtn: {
        marginBottom: "0.5rem",
        marginLeft: submitted ? "-13rem" : "-7rem",
        color: "white",
        position: "absolute",
        ...(success && { backgroundColor: "green", pointerEvents: "none", cursor: "not-allowed" }),
      }
    };

    return (
      <TransitionIn content={
      <Section heading={'Profile - ' + this.props.name}
        controls={

          // <button className="submit hide-on-mobile save" onClick={this.postForm}>
          //   <ControlAsync working={submitted} success={success} />
          // </button>

          <ButtonSubmit value={success ? "SAVED!" : "SAVE"} id={"profile-btn"} type={"button"} readOnly={submitted} callback={this.postForm} data-tip="Click here to save your changes" data-for="app-tooltip" style={styles.saveBtn} />

        }
        body={
          <form className="profile-form form" onSubmit={this.postForm}>

            <div className="profile-form-bottom collapse-widget">
              <fieldset className="profile-form-content input-field-group">

                <div className="row grow-3">
                  <div className="input-field column">
                    <label htmlFor="input__address" data-content="Street Address">
                      <span className="hidden--visually">Street Address</span>
                    </label>
                    <input id="input__address" name="address" type="text" placeholder="Street Address" />
                  </div>

                  <div className="column input-field">
                    <label htmlFor="state">
                      <span>
                        State
                      </span>
                    </label>
                    <select name="state" className=" " id="state" >
                      <option>Select State</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District Of Columbia">District Of Columbia</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </select>
                  </div>

                  <div className=" input-field column">
                    <label htmlFor="input__phone" className="__label" data-content="Telephone">
                      <span className="hidden--visually">Telephone</span>
                    </label>
                    <input id="input__phone" className="__input" name="phone" type="text" placeholder="Phone Number" value={this.state.phone} onChange={this.inputPhone} tabIndex="4" />

                  </div>

                  <div className="input-field column">
                    <label htmlFor="input__city" className="__label" data-content="City">
                      <span className="hidden--visually">City</span>
                    </label>
                    <input id="input__city" className="__input" name="city" type="text" placeholder="City" />

                  </div>

                  <div className=" input-field column">
                    <label htmlFor="input__zip" className="__label" data-content="Zip">
                      <span className="hidden--visually">Zip</span>
                    </label>
                    <input id="input__zip" className="__input" name="zip" type="text" placeholder="Zip" value={this.state.zip} onChange={this.inputZip} maxLength="5" />

                  </div>
                </div>
              </fieldset>
            </div>
          </form>
        } />
      } />
    );
  }

  inputZip = (e) => {
    let iv = e.target.value;

    if (iv.match(/^[0-9]+$/) || iv === "") {
      this.setState({
        zip: iv
      })
    }
  }


  inputPhone = (e) => {
    let iv = e.target.value;
    // limit the character input of the text field to only numbers, commas, decimal points, or blanks
    if (iv.match(/^[0-9.,-]+$/) || iv === "") {
      // remove dashes
      iv = iv.replace(/-/g, '');

      if (iv.length < 11) {
        // remove white spaces
        iv = iv.trim();
        // insert dashes into string for formatted value (fv)
        var fv = iv.toString().replace(/\D/g, '');
        if (fv.length > 3)
          fv = fv.replace(/^(\d{3})/, '$1-');
        if (fv.length > 7)
          fv = fv.replace(/-(\d{3})/, '-$1-');

        this.setState({
          phone: fv
        })
      }
    }
  }
}

export default ProfileForm;