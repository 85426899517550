import React, { Fragment } from 'react';
import { RouteBasic } from '../../../Wrappers';
import { Min } from '../../../Layouts';
import AppMulti from '../../../../../routes/AppMulti';
import Application_FixNFlip from "../../../../../components/_applications/_fixnflip";
import Application_LOC from "../../../../../components/_applications/_loc";
import Application_Rental from "../../../../../components/_applications/_rental";
import Application_Construction from "../../../../../components/_applications/_construction";
import ApplicationThankYou from '../../ApplicationThankYou';

export default function ApplicationsRouting(props) {
    const isBroker = props.accountType === "CustomerAccount" ? false : true;
    const isEmployee = props.accountType === "Employee" ? true : false;

    return (
        <Fragment>
            {/* Subroutes for each application */}
            <RouteBasic exact
                path={`${process.env.REACT_APP_SESSION_URL}/applications/line-of-credit`}
                isBroker={isBroker}
                isEmployee={isEmployee}
                component={Application_LOC}
                layout={Min} />

            <RouteBasic exact
                path={`${process.env.REACT_APP_SESSION_URL}/applications/fix-n-flip`}
                isBroker={isBroker}
                isEmployee={isEmployee}
                component={Application_FixNFlip}
                layout={Min} />

            <RouteBasic exact
                path={`${process.env.REACT_APP_SESSION_URL}/applications/rental`}
                isBroker={isBroker}
                isEmployee={isEmployee}
                component={Application_Rental}
                layout={Min} />

            <RouteBasic exact
                path={`${process.env.REACT_APP_SESSION_URL}/applications/multifamily`}
                isBroker={isBroker}
                isEmployee={isEmployee}
                component={AppMulti}
                layout={Min} />

            <RouteBasic exact
                path={`${process.env.REACT_APP_SESSION_URL}/applications/new-construction`}
                isBroker={isBroker}
                isEmployee={isEmployee}
                component={Application_Construction}
                layout={Min} />

            <RouteBasic path={`${process.env.REACT_APP_SESSION_URL}/applications/thank-you`}>
                <ApplicationThankYou {...props} />
            </RouteBasic>
        </Fragment>
    )
}