import axios from 'axios';
import { apiHelper } from './api_helper';

const api = {

  beneficiary: {
    // get initial beneficiary list
    fetchAll() {

      axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
      axios.defaults.headers['Content-Type'] = "application/json";

      axios.get(process.env.REACT_APP_LIMA + '/lima/api/borrower/beneficiaries', { dealid: this.props.dealId })
        .then((data) => {
         
          // modify response to work with boilerplate select component (config requires a "value" key)
          data.data.accounts.forEach(x => {
            return x.value = x.id;
          });

          this.setState({
            beneficiaries: data.data.accounts
          })
        }, this.checkState)
        .catch(function (error) {
          // handle api error
          console.log(error);
        });
    },

    // send new beneficiary to server and handle response
    addNew(state) {
      // post data by passing state and form (beneficiary or draw request)
      // on success api returns an updated list and state needs to be updated with this list and select the new beneficiary as default
      data: apiHelper(state);
    },
  },

  // post draw request to server
  postDrawRequest(state) {
    // axios.interceptors.request.use(req => {
    //   console.log(JSON.stringify(req.data))
    //   return req; // must return req
    // });

    this.setState({
      submission: true
    })

    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers['Content-Type'] = "application/json";

    axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/draws/new',
      apiHelper.dataDrawRequest(state))
      .then((data) => {
        this.props.fetchDealDraws(); // fetch updated draws list
        this.props.clearDraw(); // reset latest draw display
        // window.location.pathname = "/draw-request-thank-you"; 

        // emit draw completed event to complete flow and update checklist - can be replaced with custom property later once the routing is fixed to not wipe the data in appcues.identify()
        window.Appcues.track('Draw Completed', { drawCompleted: true });

        window.location.reload();

        this.setState({
          submission: false,
          modal: false
      })
    })
    .catch(error => {
      console.log(error)
      this.setState({
        formError: true,
        submission: false
      })
    });
  }
}

export { api }