import React from 'react'
import InputText from '../../_components/InputText'
import LoanInfo from '../../_components/LoanInfo'
import PropertyInformation from '../../_components/PropertyInformation'
import ThirdPartyContacts from '../../_components/ThirdPartyContacts'
import Questionnaire from './Questionnaire'

const Single = React.forwardRef((props, ref) => {

    console.log(props)

    return (
        <>
            <PropertyInformation {...props} ref={ref} />
            <LoanInfo {...props} ref={ref} />

            <div className="row grow-4">
            <div className="column">
            <InputText
                ref={ref({ required: true })}
                onChange={props.inputNumber}
                label={"Sq Ft*"}
                name={"Square_Footage__c"}
                maxlength={"6"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                placeholder="XXX"
            />
            </div>

            <div className="column">
                <InputText
                ref={ref({ required: true })}
                onChange={props.inputNumber}
                label={"Number of Units*"}
                placeholder="XX"
                name={"Number_of_Units__c"}
                tabIndex={props.tabIndex}
                errors={props.errors}
                />
            </div>
          </div>
          
            <Questionnaire {...props} ref={ref} />

            <ThirdPartyContacts {...props} ref={ref} />
        </>
    )

})

export default React.memo(Single)