import React from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import ButtonSubmit from './../../App/components/shared/Buttons/Submit/index';

class SignUpTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      email: null,
      password: null,
      password2: null,
      token: null,
      errors: {
        email: '',
        password: '',
        password2: ''
      },
      params: {
        email: '',
        token: ''
      },
      passwordValidation: {
        lowercaseLetter: false,
        uppercaseLetter: false,
        oneNumber: false,
        passwordLength: false
      }
    }
  }

  validatePassword = (password) => {
    let validation = this.state.passwordValidation;
    if (!password) {
      validation.lowercaseLetter = false;
      validation.uppercaseLetter = false;
      validation.oneNumber = false;
      validation.passwordLength = false;
      this.setState({ passwordValidation: validation })
      return;
    }

    validation.passwordLength = password.length >= 8;
    validation.oneNumber = /\d/.test(password);
    validation.lowercaseLetter = /[a-z]/.test(password)
    validation.uppercaseLetter = /[A-Z]/.test(password)
    this.setState({ passwordValidation: validation })
  }


  componentDidMount() {
    let params = this.props.location.state ? this.props.location.state : this.props.match.params;
    if (params && 'token' in params) {
      let decoded = jwt_decode(params.token)
      params.email = decoded.aud
    } else {
      params = { email: '' }
    }
    this.setState({ params, email: true });
  }

  clearSubmitButton = () => {
    this.setState({
      submitted: false
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {

      // let formData = document.querySelector('#signupform2');
      let params = this.state.params;
      let emailval = document.querySelector(".email-input");
      let passwordval = document.querySelector(".password-input");

      var body = { "password": passwordval.value };

      this.setState({
        submitted: true
      })

      axios.defaults.headers.common['Authorization'] = "Bearer " + params.token;
      axios.defaults.headers.common['Content-Type'] = "application/json";
      axios.post('/lima/api/updatebrokerpassword', body)
        .then(function (response) {
          this.clearSubmitButton();
          window.location.href = '/signup-thankyou';
          if (response?.data?.rep) {
            window.localStorage.setItem('rep_email', response.data.rep.email)
            window.localStorage.setItem('rep_name', response.data.rep.name)
          }
          // return <Redirect to="/" />
        }.bind(this))
        .catch(function (error) {
          this.clearSubmitButton();
          console.error(error);
        }.bind(this));
    } else {
      this.setState({
        submitted: false
      })
      console.error('Invalid Form')
    }
  }

  validateForm = () => {

    let errors = this.state.errors;
    let passwordval = document.querySelector(".password-input");
    let password2val = document.querySelector(".password2-input");
    let emailval = document.querySelector(".email-input");
    let passwordCheck = /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/;

    let valid = 0;

    if (emailval.value === "") {
      errors.email = "Email is required";
      valid = 1;
    } else {
      errors.email = "";
    }

    if (passwordval.value === "") {
      errors.password = "Password is required";
      valid = 1;
    } else if (!this.state.passwordValidation.lowercaseLetter || !this.state.passwordValidation.uppercaseLetter || !this.state.passwordValidation.oneNumber || !this.state.passwordValidation.passwordLength) {
      errors.password = "Password must have 1 lowercase letter, 1 uppercase letter, 1 number, and be at least 8 characters long.";
      valid = 1;
    } else {
      errors.password = "";
    }

    if (password2val.value === "") {
      errors.password2 = "Confirm password is required";
      valid = 1;
    } else if (password2val.value !== passwordval.value) {
      errors.password2 = "Confirm password does not match password";
      valid = 1;
    } else {
      errors.password2 = "";
    }

    //Set any feild to true to kick off errors
    if (valid === 1) {
      this.setState({ errors, email: true });
      return false;
    } else {
      this.setState({ errors, email: null });
      return true;
    }
  }

  passwordCriteria = (name, checked) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: !checked ? '1px solid gray' : '', width: '12px', height: '12px', marginRight: '0.5rem', backgroundColor: checked ? 'darkgreen' : '' }}>
          {checked && <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'lightgray', fontSize: '12px' }} />}
        </span>
        <small>{name}</small>
      </div>
    )
  }

  render() {
    const { errors, params, submitted } = this.state;

    return (
      <div className="signup-rf account-setup" style={{ top: 'unset', margin: 'auto', position: 'relative' }}>

        <div className="signup-rf_panel one">
          <h1>Let’s Set Up Your Portal Account</h1>
          <p>Go ahead and pick a password to create your portal account. A Lima One Capital representative will be in touch to help answer your questions and discuss our broker program.</p>
          <p>You will be able to submit applications, upload documents, monitor the progress of your loans, make payments and more!</p>
        </div>

        <div className="signup-rf_panel two">
          <form onSubmit={this.handleSubmit} id="signupform2" className="signupform">
            <label htmlFor="input__fname" data-content="Email*" className={errors.email.length > 0 && {} ? "error" : null}>
              Email Address*
            </label>
            <input id="input__email" className={'floating__input email-input'} name="email" type="text" placeholder="Email*" value={params.email} />

            {/* {errors.email.length > 0 && <span className="erro-mess">{errors.email}</span>} */}

            <label htmlFor="input__password" className={errors.password.length > 0 && {} ? "error" : null} data-content="Password*">
              Password*
            </label>
            <input id="input__password" className={'password-input'} name="password" type="password" placeholder="Password*" value={this.state.password} onChange={(e) => this.validatePassword(e.target.value)} />
            
            {this.state.errors.password && <small style={{ color: 'red' }}>{this.state.errors.password}</small>}

            <div style={{ display: 'flex', flexBasis: '100%', marginBottom: '1rem', marginTop: !this.state.errors.password ? '-1rem' : '1rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '50%' }}>
                {this.passwordCriteria('1 lowercase letter', this.state.passwordValidation.lowercaseLetter)}
                {this.passwordCriteria('1 uppercase letter', this.state.passwordValidation.uppercaseLetter)}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '50%' }}>
                {this.passwordCriteria('1 number', this.state.passwordValidation.oneNumber)}
                {this.passwordCriteria('8+ characters', this.state.passwordValidation.passwordLength)}
              </div>
            </div>

            {/* {errors.password.length > 0 && <span className="erro-mess">{errors.password}</span>} */}

            <label htmlFor="input__password2" className={errors.password2.length > 0 && {} ? 'error' : null} data-content="Confirm Password*">
              Confirm Password*
            </label>
            <input id="input__password2" className={'password2-input'} name="password2" type="password" placeholder="Confirm Password*" />

            {/* {errors.password2.length > 0 && <span className="erro-mess">{errors.password2}</span>} */}

            <ButtonSubmit
              variant="rounded"
              readOnly={submitted}
              callback={this.handleSubmit} />

          </form>
        </div>
      </div>
    )
  }
}

export default SignUpTwo;