import React from 'react';
import PayoffRequest from '../../../../components/_applications/_servicing/_payoffRequest';
import './index.scss';

const ApplicationPayoffRequest = props => {
    return (
            <section className="payoff-request">
                <h1>Payoff Request</h1>
                <PayoffRequest {...props} external={true} /> 
            </section>
    )
}

export default ApplicationPayoffRequest