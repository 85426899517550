import { log } from '../../../../utility';
import { useState } from 'react';
import ReactGA from 'react-ga'

export default function GoogleAnalytics(props) {
    
    // string preceeding all logging
    const logValue = props.logValue || "Google Analytics";

    // disabled comment - true or leave empty to enable
    // log(`${logValue} - props:`, props, false); 

    // last view URL needed for the intitial redirect;
    const [viewLast, setViewLast] = useState(null);

    // initialize - first load
    const [initialized, setInitialized] = useState(false);

    // userId - is set
    const [userId, setUserId] = useState(false); 

    // current view URL
    let viewCurrent = props.location.pathname;

    // prevent tracking (not currently needed - helpful if component is placed in route wrappers)
    if (props.noTrack) return null;

    if (!initialized) {
        // initialize Google Analytics with option Debug mode (from env)
        ReactGA.initialize('UA-27082909-4', {
            debug: false
        });

        setInitialized(true);
    }

    // set the userId from SalesForce
    if(!userId && props.user.userId) {

        ReactGA.set({
            userId: props.user.userId
        });

        // log(`${logValue} - set userId: ${props.user.userId}`);

        setUserId(true);
    };

    // pageview event (wrapper to prevent extra calls for nested routes)
    if (viewCurrent !== viewLast) {
        // log(`${logValue} - event "pageview" - view updated: ${viewLast || "null"} => ${viewCurrent}`)
        // log(`${logValue} - event "pageview" - view sent:`, viewCurrent);

        // update the last view
        setViewLast(viewCurrent);

        // send pageview to GA
        ReactGA.ga('send', 'pageview', viewCurrent);
    };

    return null; // no rendering needed
}

