// object used to populate the formData fields in state
const formData = {
    email: {
        iRaw: "",
        iVal: {
            value: "",
            isValid: false
        },
        error: "A valid email is required."
    },
}

export { formData }