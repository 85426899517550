import axios from 'axios';
import { apiHelper } from './api_helper';

const api = {

  // post draw request to server
  postNewBeneficiary(state, cb) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers['Content-Type'] = "application/json";

    axios.post(process.env.REACT_APP_LIMA + '/lima/api/borrower/beneficiaries', 
     apiHelper.dataBeneficiary(state))
     .then((data) => {
      
      // update beneficairy list and close modal here
      const newList = data.data.accounts;

      newList.forEach(x => {
        x.value = x.id;
      });

      // console.log('new list', newList)
      cb(newList, newList[newList.length - 1].id);
    })
    .catch(error => {
      this.setState({
        formError: true
      })
    });
  }
}

export { api }