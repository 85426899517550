import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import TopNav from '../../../../components/protected/TopNav';

const DashboardTopNav = props => {

    const account = () => {
        if (props.accountType === "CustomerAccount") {
            return "Customer"
        } else return "Broker"
    };

    const links = (
        <Fragment>
            <li>
                <NavLink exact to={`${process.env.REACT_APP_SESSION_URL}`} activeClassName='is-active'>{`${account()} Dashboard`}</NavLink>
            </li>
            <li>
                <NavLink exact to={`${process.env.REACT_APP_SESSION_URL}/resources`} activeClassName='is-active'>{`${account()} Resources`}</NavLink>
            </li>
        </Fragment>
    );

    return (
        <TopNav links={links} buttonText={"Dashboard"} />
    );
};

export default DashboardTopNav