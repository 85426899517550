import React from 'react';
import { Section } from '../../App/components/shared/Section';

import './index.scss';

class SignUpEmailSent extends React.Component {
  render() {
    return (
      <Section heading="Check Your Email" body={
      <div className="sign-up">
        <p className="sign-up-title">Thank you for submitting an inquiry. A Lima One Capital representative will be in touch to help answer your questions and discuss which investment option is best for you.</p>
        <p className="sign-up-title-two">Please follow the link in your email from Lima One Capital to set your account password.</p>
      </div>
      } />
    )
  }
}

export default SignUpEmailSent;